import React, {useEffect, useState} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {  TextField, Typography,Button } from '@material-ui/core';
import { primaryColor, secondaryColor, whiteColor } from '../../../config/constants';
import { useTheme } from '@material-ui/core/styles';
import {Link, useHistory, useLocation} from "react-router-dom";
import { Input } from '@material-ui/core';
import firebase from "firebase";
import {firestore,storage} from '../../../config/firebase';
import Autocomplete from "@material-ui/lab/Autocomplete";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Snackbar from "@material-ui/core/Snackbar";



const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    title:{
        fontSize:24,
        color:secondaryColor,
        fontWeight:700,
        margin:20
    },
    background:{
        backgroundColor:"rgba(240, 102, 105, 0.13)",
        padding:30,
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between'
    },
    addbutton:{
        display:'flex',
        float:'right',
        margin:16,
        width:110,
        background:secondaryColor,
        color:whiteColor,
        padding:3,
        textAlign:'center',
        cursor:'pointer',
        textDecoration:"none"
    },
    editbutton:{
        color:whiteColor,
        backgroundColor:secondaryColor,
        padding:8,
        textAlign:'center',
        marginLeft:20,
        marginTop:10,
        cursor:'pointer',
        maxHeight:35,
        textDecoration:'none',
        width:150,
    },
    formcontainer:{
        marginLeft:20,
        marginRight:20
    },
    formtitle:{
        color:primaryColor,
        fontWeight:600,
        fontSize:16,
        paddingBottom:10,
        paddingTop:10
    },
    forminput:{
        minWidth:500,
        borderColor:secondaryColor,
        background:whiteColor,
        [theme.breakpoints.only('xs')]: {
            minWidth:'100%'
        },
    },
    imageuploaded:{
        marginBottom:20,
        padding:20,
        width:'50%',
        textAlign:'start',
        display:'flex'
    },
    buttons:{
        marginLeft:20,
        backgroundColor:secondaryColor,
        color:whiteColor
    }
}));


export default function AdminDetailsPage() {
    const classes = useStyles();
    //details
    const [value, setValue] = React.useState('female');
    const [userName,setUserName] = React.useState('')
    const [name,setName] = React.useState('')
    const [password,setPassword] = React.useState('')
    const [email,setEmail] = React.useState('')
    const [phone,setPhone] = React.useState('')
    const [allRoles,setAllRoles] = React.useState('')

    const [userRole,setUserRole] = React.useState('')
    const [snackBarOpen, setSnackBarOpen] = React.useState(false);
    const history = useHistory();

    const location = useLocation();

    useEffect(() => {
        getAllRoles();


        console.log('seeing location username',location.userName)

        firestore.collection('adminUsers').doc(location.userName).get().then(snapshot => {
            if(snapshot.data() != undefined){
                console.log('get particular admin user=', snapshot.data())
                setName(snapshot.data().name)
                setUserRole(snapshot.data().userRole)
                setPhone(snapshot.data().phone)
                setUserName(snapshot.data().userName)
                setPassword(snapshot.data().password)
                setEmail(snapshot.data().email)
                setValue(snapshot.data().status)
            }
        })
    },[])

    const getAllRoles = () =>{
        firestore.collection('adminRoles').get().then(snapshot => {
            const usersList = snapshot.docs.map(doc =>  {
                return doc.data()
            })
            setAllRoles(usersList)
            console.log('get all roles =',usersList)
        })
    }

    const handleUpdate = () =>{
        firestore.collection('adminUsers').doc(userName).update({
            userName: userName,
            password: password,
            userRole: userRole,
            name: name,
            email: email,
            phone: phone,
            status: value,
        }, {merge: true})
        history.push('/adminusers')
        // alert('added successfully')
    }

    const handleChange = (event) => {
        setValue(event.target.value);
    };


    return (
        <div>
            <Grid container spacing={0}>
                <Grid item xs={6}>
                    <Typography className={classes.title}>
                        Edit Admin User
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Button onClick={() => history.push('/adminusers')} style={{backgroundColor: primaryColor}} className={classes.addbutton} variant="contained">Go Back</Button>
                </Grid>
            </Grid>

            <div className={classes.formcontainer}>
                <Typography className={classes.formtitle} >UserName</Typography>
                <TextField className={classes.forminput} placeholder={userName} variant="outlined"  onChange={(e)=>setUserName(e.target.value)} />

                <Typography className={classes.formtitle} >Password</Typography>
                <TextField className={classes.forminput} placeholder={password} variant="outlined"  onChange={(e)=>setPassword(e.target.value)} />

                <Typography className={classes.formtitle} >Name</Typography>
                <TextField className={classes.forminput} placeholder={name} variant="outlined"  onChange={(e)=>setName(e.target.value)} />

                <Typography className={classes.formtitle} >Email</Typography>
                <TextField className={classes.forminput} placeholder={email} variant="outlined"  onChange={(e)=>setEmail(e.target.value)} />

                <Typography className={classes.formtitle} >Phone</Typography>
                <TextField className={classes.forminput} placeholder={phone} variant="outlined"  onChange={(e)=>setPhone(e.target.value)} />

                <Typography className={classes.formtitle} >Role</Typography>
                <Autocomplete
                    className={classes.forminput}
                    id="combo-box-demo"
                    onChange={(event, newValue) => setUserRole(newValue.name)}
                    options={allRoles}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => <TextField  className={classes.forminput}  {...params} label="Select the User Role" variant="outlined" />}
                />

                <Typography className={classes.formtitle}>Status</Typography>

                <FormControl component="fieldset">
                    <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>
                        <div style={{display:'flex'}}>
                            <FormControlLabel value="active" control={<Radio />} label="Active" />
                            <FormControlLabel value="inactive" control={<Radio   />} label="Inactive" />
                        </div>
                    </RadioGroup>
                </FormControl>
            </div>
            <Button onClick={() => handleUpdate()} style={{backgroundColor: secondaryColor}} className={classes.editbutton} variant="contained">Update user</Button>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={snackBarOpen}
                onClose={() => setSnackBarOpen(false)}
                message="User Updated Successfully!"
            />
        </div>

    )
}
