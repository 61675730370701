import Firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/messaging';

const firebaseConfig = {
    apiKey: "AIzaSyBz-qOvifBEYttzzezMpbUYoZCkDYeVels",
    authDomain: "bigwayz-backend.firebaseapp.com",
    projectId: "bigwayz-backend",
    storageBucket: "bigwayz-backend.appspot.com",
    messagingSenderId: "443758179544",
    appId: "1:443758179544:web:71e4a67d46bba287cc93ad",
    measurementId: "G-DGRPGKJW9G"
};

// Initialize Firebase
const firebase = Firebase.initializeApp(firebaseConfig);
const database = firebase.database();
export {database};
const auth = firebase.auth();
export {auth}
const firestore = firebase.firestore();
export {firestore}
const storage = firebase.storage();
export {storage}

export {Firebase}

export const placeholder = "https://firebasestorage.googleapis.com/v0/b/smd-firebase-ca66a.appspot.com/o/placeholder_logo.png?alt=media&token=5d957eb4-8643-4988-b368-4723343b91fa"

// const messaging = firebase.messaging();
//
// messaging.onMessage((payload) => {
//     console.log('Message received. ', payload);
//     // ...
// });
// export {messaging}
