import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MainNavBar from "../../components/MainNavBar";
import MainFooter from "../../components/MainFooter";
import HeaderImage from "../../assets/website/images/calcheader.png";
import { Button, Container, Grid, Paper, Typography } from "@material-ui/core";
import { Table } from "react-bootstrap";
import {
  primaryColor,
  secondaryColor,
  secondaryDarkColor,
  whiteColor,
} from "../../config/webconstants";
import ClearIcon from "@material-ui/icons/Clear";
import CalcImage from "../../assets/website/images/calcimage.png";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    overflowX: "hidden",
    backgroundColor: "#ffffff",
    [theme.breakpoints.down("sm")]: {
      marginTop: "56px",
    },
  },
  headercontainer: {
    height: 200,
    width: "70%",
    margin: "5% 0px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
      marginLeft: "5%",
      height: 100,
    },
  },
  header: {
    height: 200,
    width: "100%",
    objectFit: "contain",
    [theme.breakpoints.down("md")]: {
      height: 100,
    },
  },
  tablemaincontainer: {
    border: "1px solid",
    borderColor: primaryColor,
    borderRadius: 20,
    margin: "5% 0px",
  },
  tablemaincontainersecond: {
    border: "1px solid",
    borderColor: secondaryColor,
    borderRadius: 20,
    margin: "5% 0px",
  },
  table: {
    borderColor: primaryColor,
    borderRadius: 20,
    backgroundColor: primaryColor,
  },
  tablesecond: {
    borderColor: primaryColor,
    borderRadius: 20,
  },
  tabeheader: {
    backgroundColor: primaryColor,
    borderTopLeftRadius: 20,
    color: whiteColor,
    padding: "20px 0px",
  },
  tabeheadersecond: {
    backgroundColor: secondaryColor,
    borderTopLeftRadius: 20,
    color: whiteColor,
    padding: "20px 0px",
  },
  tabheading: {
    // borderTopLeftRadius:'15px',
    padding: "20px 0px",
    fontFamily: "Myraid",
    fontWeight: 600,
    [theme.breakpoints.down("md")]: {
      fontSize: 12,
    },
  },
  tablebody: {
    padding: "20px 30px",
    backgroundColor: whiteColor,
    borderRadius: 12,
  },
  tabletitle: {
    fontSize: 20,
    fontWeight: "500",
    maxWidth: "200px",
    minWidth: "200px ",
    fontFamily: "Myraid",
    [theme.breakpoints.down("md")]: {
      maxWidth: "100%",
      minWidth: "100%",
      fontSize: 16,
    },
  },
  tablesub: {
    fontSize: 14,
    fontWeight: "400",
    fontFamily: "Montserrat",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      fontSize: 12,
    },
  },
  input: {
    borderColor: primaryColor,
    border: "2px solid",
    borderRadius: 8,
    marginTop: 10,
    fontFamily: "Myraid",
    fontWeight: "500",
    textAlign: "center",
    "&:focus": {
      borderColor: primaryColor,
      border: "3px solid",
      marginTop: 10,
      outline: primaryColor,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      // marginTop:'50%'
    },
  },
  inputsecond: {
    borderColor: secondaryColor,
    border: "2px solid",
    borderRadius: 12,
    marginTop: 10,
    fontFamily: "Myraid",
    fontWeight: "500",
    textAlign: "center",
    "&:focus": {
      borderColor: secondaryColor,
      border: "3px solid",
      marginTop: 10,
      outline: secondaryColor,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      // marginTop:'50%'
    },
  },

  tablemaintitle: {
    padding: "10px 10px",
    fontSize: 22,
    fontWeight: 600,
    letterspacing: 1,
  },
  line: {
    margin: "5% 15%",
    padding: 1,
    backgroundColor: secondaryColor,
  },
  calccontainer: {
    backgroundColor: secondaryDarkColor,
    borderRadius: 20,
    margin: "0% 10%",
    paddingBottom: 20,
  },
  calccard: {
    padding: "2%",
    backgroundColor: secondaryColor,
    borderRadius: 20,
  },
  calcheader: {
    display: "flex",
    justifyContent: "space-around",
    padding: "10px 10px",
  },
  calccardtitle: {
    fontSize: 12,
    color: whiteColor,
    fontFamily: "Myraid",
  },
  calcarddesc: {
    fontSize: 25,
    color: whiteColor,
    fontWeight: "500",
    fontFamily: "Myraid",
  },
  calciamge: {
    height: 200,
    width: "100%",
    objectFit: "contain",
    margin: "3% 0px",
  },
  totalcontainer: {
    margin: "20px 5%",
    height: "fit-content",
  },
  bigwayzbutton: {
    width: "100%",
    color: whiteColor,
    backgroundColor: secondaryColor,
    marginTop: 20,
    fontFamily: "Myraid",
    fontWeight: 800,
    "&:hover": {
      backgroundColor: secondaryColor,
    },
  },
  price: {
    textAlign: "center",
    fontFamily: "Myraid",
    fontWeight: "500",
    marginTop: 10,
  },
  totalincomecard: {
    display: "flex",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  incomecalcmobile: {
    position: "fixed",
    bottom: 0,
    width: "100%",
    display: "none",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  incomebuttons1: {
    padding: "4% 5%",
    backgroundColor: primaryColor,
    borderTopRightRadius: "0px",
    borderTopLeftRadius: "12px",
  },
  incomebuttons2: {
    padding: "4% 5%",
    backgroundColor: secondaryColor,
    borderTopLeftRadius: "0px",
    borderTopRightRadius: "12px",
    height: "100%",
  },
  incometitle: {
    fontSize: 12,
    color: whiteColor,
    fontFamily: "Myraid",
  },
  incomeprice: {
    fontSize: 16,
    color: whiteColor,
    fontFamily: "Myraid",
    fontWeight: 500,
  },
  incometitle2: {
    fontSize: 17,
    color: whiteColor,
    fontWeight: "600",
    paddingTop: 5,
    fontFamily: "Myraid",
    textAlign: "center",
  },
}));

export default function Calculator() {
  const classes = useStyles();
  const history = useHistory();

  const [data, setdata] = useState([
    [
      {
        title: "Domestic Money Transfer",
        subtitle: "Average transaction of 4000",
        numberTranscation: 1,
        value: 26,
      },
      {
        title: "Cash Withdrawal	– Aadhaar & Mirco ATM ",
        subtitle: "Average transaction of 3000",
        numberTranscation: 1,
        value: 7.25,
      },
      {
        title: "Mobile Recharge",
        subtitle: "Average transaction of 4000",
        numberTranscation: 1,
        value: 3,
      },
      {
        title: "DTH Recharge",
        subtitle: "Average transaction of 200",
        numberTranscation: 1,
        value: 4,
      },
      {
        title: "Bill Payments",
        subtitle: "Average transaction of 300",
        numberTranscation: 1,
        value: 0.78,
      },
    ],
    [
      {
        title: "Products Sold",
        subtitle: "Average transaction of 500",
        numberTranscation: 1,
        value: 25,
      },
    ],
    [
      {
        title: "Two Wheeler Insurance",
        subtitle: "Average transaction of 1500",
        numberTranscation: 1,
        value: 90,
      },
      {
        title: "Private Vehicle Insurance",
        subtitle: "Average transaction of 6000",
        numberTranscation: 1,
        value: 550,
      },
      {
        title: "Commercial	Vehicle Insurance",
        subtitle: "Average transaction of 10000",
        numberTranscation: 1,
        value: 1000,
      },
    ],
    [
      {
        title: "Personal Accident Care",
        subtitle: "Average transaction of 1500",
        numberTranscation: 1,
        value: 0,
      },
      {
        title: "Accidental Hospitalization Care",
        subtitle: "Average transaction of 1500",
        numberTranscation: 1,
        value: 0,
      },
      {
        title: "Hospi Cash",
        subtitle: "Average transaction of 1500",
        numberTranscation: 1,
        value: 0,
      },
    ],
  ]);

  const [totalincome, settotalincome] = useState(0);

  const inputChange = (e, i, j) => {
    if (e.target.value >= 0 && e.target.value <= 100) {
      const list = data;
      list[i][j].numberTranscation = e.target.value;
      setdata(() => [...list]);
    }
  };

  useEffect(() => {
    let total = 0;
    data?.forEach((d) => {
      d.forEach((t) => {
        if (t?.numberTranscation !== 0) {
          total = total + t?.numberTranscation * t?.value;
        }
      });
    });
    settotalincome(total);
  }, [data]);

  return (
    <div>
      <MainNavBar />
      <div className={classes.root}>
        <div className={classes.headercontainer}>
          <img src={HeaderImage} alt="header" className={classes.header} />
        </div>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={8}>
              <div className={classes.tablemaincontainer}>
                <Table responsive className={classes.table} borderless>
                  <thead className={classes.tabeheader}>
                    <tr>
                      <th
                        className={classes.tabheading}
                        style={{ borderTopLeftRadius: "15px" }}
                      >
                        Bigwayz Finz
                      </th>
                      <th className={classes.tabheading}>
                        No. of Daily Transactions
                      </th>
                      <th
                        className={classes.tabheading}
                        style={{ borderTopRightRadius: "15px" }}
                      >
                        Income(₹)
                      </th>
                    </tr>
                  </thead>

                  <tbody className={classes.tablebody}>
                    {data[0]?.map((d, i) => (
                      <tr key={i}>
                        <td>
                          <Typography className={classes.tabletitle}>
                            {d?.title}
                          </Typography>
                          <Typography className={classes.tablesub}>
                            {d?.subtitle}
                          </Typography>
                        </td>
                        <td>
                          <input
                            type="number"
                            className={classes.input}
                            value={d?.numberTranscation}
                            onChange={(e) => inputChange(e, 0, i)}
                          />{" "}
                        </td>
                        <td className={classes.PriceHead}>
                          <Typography className={classes.price}>
                            {d?.numberTranscation
                              ? `₹${parseFloat(
                                  d?.numberTranscation * d?.value
                                ).toFixed(2)}`
                              : `₹0.00`}
                          </Typography>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>

              <div className={classes.tablemaincontainersecond}>
                <Table responsive className={classes.tablesecond} borderless>
                  <thead className={classes.tabeheadersecond}>
                    <tr>
                      <th
                        className={classes.tabheading}
                        style={{ borderTopLeftRadius: "15px" }}
                      >
                        Bigwayz Assist
                      </th>
                      <th className={classes.tabheading}>
                        No. of Daily Transactions
                      </th>
                      <th
                        className={classes.tabheading}
                        style={{ borderTopRightRadius: "15px" }}
                      >
                        Income(₹)
                      </th>
                    </tr>
                  </thead>
                  <tbody className={classes.tablebody}>
                    {data[1]?.map((d, i) => (
                      <tr key={i}>
                        <td>
                          <Typography className={classes.tabletitle}>
                            {d?.title}
                          </Typography>
                          <Typography className={classes.tablesub}>
                            {d?.subtitle}
                          </Typography>
                        </td>
                        <td>
                          <input
                            type="number"
                            className={classes.inputsecond}
                            value={d?.numberTranscation}
                            onChange={(e) => inputChange(e, 1, i)}
                          />
                        </td>
                        <td>
                          <Typography className={classes.price}>
                            {d?.numberTranscation
                              ? `₹${parseFloat(
                                  d?.numberTranscation * 25
                                ).toFixed(2)} - ₹${parseFloat(
                                  d?.numberTranscation * 75
                                ).toFixed(2)}`
                              : `₹0.00`}
                          </Typography>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>

              <div className={classes.tablemaincontainersecond}>
                <Table responsive className={classes.tablesecond} borderless>
                  <thead className={classes.tabeheadersecond}>
                    <tr>
                      <th
                        className={classes.tabheading}
                        style={{ borderTopLeftRadius: "15px" }}
                      >
                        Bigwayz Suraksha
                      </th>
                      <th className={classes.tabheading}>
                        No. of Daily Transactions
                      </th>
                      <th
                        className={classes.tabheading}
                        style={{ borderTopRightRadius: "15px" }}
                      >
                        Income(₹)
                      </th>
                    </tr>
                  </thead>
                  <tbody className={classes.tablebody}>
                    <Typography className={classes.tablemaintitle}>
                      Suraksha Plus
                    </Typography>

                    {data[2]?.map((d, i) => (
                      <tr key={i}>
                        <td>
                          <Typography className={classes.tabletitle}>
                            {d?.title}
                          </Typography>
                          <Typography className={classes.tablesub}>
                            {d?.subtitle}
                          </Typography>
                        </td>
                        <td>
                          <input
                            type="number"
                            className={classes.inputsecond}
                            value={d?.numberTranscation}
                            onChange={(e) => inputChange(e, 2, i)}
                          />
                        </td>
                        <td>
                          <Typography className={classes.price}>
                            {d?.numberTranscation
                              ? `₹${parseFloat(
                                  d?.numberTranscation * d?.value
                                ).toFixed(2)}`
                              : `₹0.00`}
                          </Typography>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <div className={classes.line}></div>

                {/* <Table responsive className={classes.tablesecond} borderless>
                  <tbody className={classes.tablebody}>
                    <Typography className={classes.tablemaintitle}>
                      Suraksha Care
                    </Typography>
                    {data[3]?.map((d, i) => (
                      <tr key={i}>
                        <td>
                          <Typography className={classes.tabletitle}>
                            {d?.title}
                          </Typography>
                          <Typography className={classes.tablesub}>
                            {d?.subtitle}
                          </Typography>
                        </td>
                        <td>
                          <input
                            type="number"
                            className={classes.inputsecond}
                            value={d?.numberTranscation}
                            onChange={(e) => inputChange(e, 3, i)}
                          />
                        </td>
                        <td>
                          <Typography className={classes.price}>
                            {d?.numberTranscation
                              ? `₹${parseFloat(
                                  d?.numberTranscation * d?.value
                                ).toFixed(2)}`
                              : `₹0.00`}
                          </Typography>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table> */}
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              className={classes.totalincomecard}
              style={{ margin: "3% 0px", height: "fit-content" }}
            >
              <Paper elevation={1} className={classes.calccontainer}>
                <Paper elevation={2} className={classes.calccard}>
                  <div className={classes.calcheader}>
                    <div>
                      <Typography className={classes.calccardtitle}>
                        Income Per Day
                      </Typography>
                      <Typography className={classes.calcarddesc}>{`₹${parseInt(
                        totalincome
                      )}`}</Typography>
                    </div>
                    <div>
                      <ClearIcon style={{ fontSize: 40, color: whiteColor }} />
                    </div>
                    <div>
                      <Typography className={classes.calccardtitle}>
                        Number of Days
                      </Typography>
                      <Typography className={classes.calcarddesc}>
                        30
                      </Typography>
                    </div>
                  </div>
                  <img
                    src={CalcImage}
                    alt="img"
                    className={classes.calciamge}
                  />
                </Paper>
                <div className={classes.totalcontainer}>
                  <Typography className={classes.calccardtitle}>
                    Total Income
                  </Typography>
                  <Typography className={classes.calcarddesc}>
                    <span style={{ fontSize: 30 }}>{`₹${parseInt(
                      (totalincome * 30).toFixed(2)
                    )}`}</span>
                    /month
                  </Typography>
                  <Typography className={classes.calccardtitle}>
                    (i) – The following earning is subject to revision
                  </Typography>
                  <Button
                    varinat="contained"
                    className={classes.bigwayzbutton}
                    onClick={() => {
                      history.push("/contactus");
                    }}
                  >
                    Join BigWayz
                  </Button>
                
                </div>
              </Paper>
            </Grid>
          </Grid>
        </Container>
        <div className={classes.incomecalcmobile}>
          <Grid container>
            <Grid item xs={6}>
              <Paper className={classes.incomebuttons1}>
                <Typography className={classes.incometitle}>
                  Total income
                </Typography>
                <Typography className={classes.incomeprice}>{`₹${(
                  totalincome * 30
                ).toFixed(2)}/month`}</Typography>
              </Paper>
            </Grid>
            <Grid item xs={6} onClick={() => history.push("/contactus")}>
              <Paper className={classes.incomebuttons2}>
                <Typography className={classes.incometitle2}>
                  Join Bigwayz
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </div>
      <MainFooter showContact={false} />
    </div>
  );
}
