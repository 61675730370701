import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Checkbox, FormGroup, TextField, Typography } from "@material-ui/core";
import {
  primaryColor,
  secondaryColor,
  whiteColor,
} from "../../../config/constants";
import { Link, useLocation } from "react-router-dom";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { firestore, storage } from "../../../config/firebase";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Firebase from "firebase/app";
import Paper from "@material-ui/core/Paper";
import InfoIcon from "@material-ui/icons/Info";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import axios from "axios";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
    margin: 20,
  },
  title: {
    fontSize: 24,
    color: secondaryColor,
    fontWeight: 700,
    margin: 5,
    marginLeft: 20,
  },
  background: {
    backgroundColor: "rgba(240, 102, 105, 0.13)",
    padding: 30,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  addbutton: {
    display: "flex",
    float: "right",
    margin: 5,
    marginTop: 5,
    width: 110,
    background: secondaryColor,
    color: whiteColor,
    padding: 3,
    textAlign: "center",
    cursor: "pointer",
    textDecoration: "none",
  },
  editbutton: {
    color: whiteColor,
    backgroundColor: secondaryColor,
    padding: 8,
    textAlign: "center",
    marginTop: 10,
    cursor: "pointer",
    maxHeight: 35,
    textDecoration: "none",
    width: 150,
  },
  formcontainer: {
    marginLeft: 20,
    marginRigth: 20,
  },
  formtitle: {
    color: primaryColor,
    fontWeight: 600,
    fontSize: 16,
    paddingBottom: 10,
    paddingTop: 10,
  },
  forminput: {
    minWidth: 600,
    borderColor: secondaryColor,
    background: whiteColor,
    [theme.breakpoints.only("xs")]: {
      minWidth: "100%",
    },
  },
  imageuploaded: {
    marginBottom: 20,
    padding: 20,
    width: "50%",
    textAlign: "start",
    display: "flex",
  },
  buttons: {
    marginLeft: 20,
    backgroundColor: secondaryColor,
    color: whiteColor,
  },
}));

export default function AddPackage() {
  const classes = useStyles();
  const [value, setValue] = React.useState("female");
  const [userName, setUserName] = React.useState("");
  const [snackBarOpen, setSnackBarOpen] = React.useState(false);
  const history = useHistory();
  const location = useLocation();
  const [role, setRole] = useState("PP");
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [Finz, setFinz] = useState(false);
  const [Assist, setAssist] = useState(false);
  const [Insurance, setInsurance] = useState(false);
  const [Loyalty, setLoyalty] = useState(false);
  const [Loans, setLoans] = useState(false);
  const [PromoteYourBusiness, setPromoteYourBusiness] = useState(false);
  const [Bills, setBills] = useState(false);
  const [Matm, setMatm] = useState(false);
  const [Aeps, setAeps] = useState(false);
  const [ThermalPrinter, setThermalPrinter] = useState(false);
  const [BigwazBoard, setBigwazBoard] = useState(false);
  const [attachedUrl, setAttachedUrl] = useState("");
  const [fileName, setFileName] = useState("");
  const [pdf, setPdf] = useState("");

  useEffect(() => {
    // addCategory();
    if (location.item != null && location.item != undefined) {
      console.log("got category from locaiton =", location.item);
      setUserName(location.item.name);
      setValue(location.item.value);
    }
  }, []);

  function uploadImageFunction(files) {
    let file = files[0];
    setFileName(file.name);
    console.log("image uploaded", file.name);
    if (file.name.endsWith(".pdf")) {
      setPdf(true);
    }
    const uploadTask = storage.ref(`/adminImages/${file.name}`).put(file);

    uploadTask.on(
      "state_changed",
      (snapShot) => {},
      (err) => {
        console.log(err);
      },
      () => {
        storage
          .ref("adminImages")
          .child(file.name)
          .getDownloadURL()
          .then((fireBaseUrl) => {
            // getting url here
            setAttachedUrl(fireBaseUrl);
            console.log("image uploaded", fireBaseUrl);
          });
      }
    );
  }

  const addPackage = () => {
    console.log(role, name, price);
    axios
      .post(
        "https://us-central1-bigwayz-backend.cloudfunctions.net/create_package",
        {
          role: role,
          packageName: name,
          price: price,
          attachedPDF: attachedUrl,
          finz: Finz,
          assist: Assist,
          insurance: Insurance,
          loyalty: Loyalty,
          loans: Loans,
          promoteYourBusiness: PromoteYourBusiness,
          bills: Bills,
          MATM: Matm,
          AEPS: Aeps,
          thermalPrinter: ThermalPrinter,
          bigwayzBoard: BigwazBoard,
        }
      )
      .then((response) => console.log(response.data));
    // history.push('/packages')
    setSnackBarOpen(true);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <>
      <Paper className={classes.paper}>
        <Grid container spacing={0}>
          <Grid item xs={6}>
            <Typography className={classes.title}>Add Package</Typography>
          </Grid>
          <Grid item xs={6}>
            <Button
              onClick={() => history.push("/packages")}
              style={{ backgroundColor: primaryColor }}
              className={classes.addbutton}
              variant="contained"
            >
              Go Back
            </Button>
          </Grid>
        </Grid>

        <Typography className={classes.formtitle}>Role</Typography>
        <FormControl variant="outlined" className={classes.forminput}>
          <InputLabel htmlFor="outlined-age-native-simple">Role</InputLabel>
          <Select
            native
            value={role}
            onChange={(e) => setRole(e.target.value)}
            label="Age"
            inputProps={{
              name: "age",
              id: "outlined-age-native-simple",
            }}
          >
            <option value="PP"> PP - Premium partner</option>
            <option value={"CP"}>CP - Consultant Partner</option>
            <option value={"BP"}>BP - Bigwayz Partner </option>
            <option value={"BD"}>BD - Bigwayz Digipreneur</option>
            <option value={"BM"}>BM - Bigwayz Merchant</option>
            <option value={"MDT"}>MDT - Master Distributor</option>
            <option value={"DT"}>DT -Distributor</option>
            <option value={"BR"}>BR - Bigwayz Retailer </option>
          </Select>
        </FormControl>

        <Typography className={classes.formtitle}>Package Name</Typography>
        <TextField
          className={classes.forminput}
          placeholder="Package Name"
          variant="outlined"
          onChange={(e) => setName(e.target.value)}
        />

        <Typography className={classes.formtitle}>Package Price</Typography>
        <TextField
          className={classes.forminput}
          placeholder="Package price"
          inputProps={{ type: "number" }}
          variant="outlined"
          onChange={(e) => setPrice(e.target.value)}
        />

        <Typography className={classes.formtitle}>Select Service</Typography>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={Finz}
                onChange={() => setFinz(!Finz)}
                name="Finz"
              />
            }
            label="Digital Banking"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={Assist}
                onChange={() => setAssist(!Assist)}
                name="E-commerce"
              />
            }
            label="E-commerce"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={Insurance}
                onChange={() => setInsurance(!Insurance)}
                name="Insurance"
              />
            }
            label="Insurance"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={Loyalty}
                onChange={() => setLoyalty(!Loyalty)}
                name="Promote Your Business"
              />
            }
            label="Promote Your Business"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={Loans}
                onChange={() => setLoans(!Loans)}
                name="Invoice & Delivery Challan"
              />
            }
            label="Invoice & Delivery Challan"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={PromoteYourBusiness}
                onChange={() => setPromoteYourBusiness(!PromoteYourBusiness)}
                name="MATM"
              />
            }
            label="MATM"
          />
        </FormGroup>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={Bills}
                onChange={() => setBills(!Bills)}
                name="Vriddhi Device"
              />
            }
            label="Vriddhi Device"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={Matm}
                onChange={() => setMatm(!Matm)}
                name="100 Flyers"
              />
            }
            label="100 Flyers"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={Aeps}
                onChange={() => setAeps(!Aeps)}
                name="Poster"
              />
            }
            label="Poster"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={ThermalPrinter}
                onChange={() => setThermalPrinter(!ThermalPrinter)}
                name="Bigwayz Board"
              />
            }
            label="Bigwayz Board"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={BigwazBoard}
                onChange={() => setBigwazBoard(!BigwazBoard)}
                name="License"
              />
            }
            label="License"
          />
        </FormGroup>
        <br />
        {/* {pdf === true ? (
          <PictureAsPdfIcon
            color={primaryColor}
            style={{ fontSize: 100, color: secondaryColor }}
          />
        ) : (
          <img src={attachedUrl} className={classes.imageuploaded} />
        )} */}
        {/* <br /> */}
        {/* <Button
          className={classes.buttons}
          variant="contained"
          component="label"
          color={secondaryColor}
        >
          Upload File
          <input
            type="file"
            hidden
            onChange={(e) => uploadImageFunction(e.target.files)}
          />
        </Button> */}
        {/* <br /> */}
        <Button
          onClick={() => addPackage()}
          style={{ backgroundColor: secondaryColor }}
          className={classes.editbutton}
          variant="contained"
        >
          Submit
        </Button>

        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={snackBarOpen}
          onClose={() => setSnackBarOpen(false)}
          message="Success!"
        />
      </Paper>
    </>
  );
}
