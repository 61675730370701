import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import {
  lightGray,
  primaryColor,
  primaryTint,
  secondaryColor,
  whiteColor,
} from "../../../config/constants";
import Avatar from "@material-ui/core/Avatar";
import { Link } from "react-router-dom";
import { firestore, storage } from "../../../config/firebase";
import SearchIcon from "@material-ui/icons/Search";
import { fade } from "@material-ui/core";
import InputBase from "@material-ui/core/InputBase";
import Fuse from "fuse.js";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import Firebase from "firebase";
import axios from "axios";
import Skeleton from "@material-ui/lab/Skeleton";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  title: {
    fontSize: 24,
    color: secondaryColor,
    fontWeight: 700,
    margin: 20,
  },
  background: {
    backgroundColor: "rgba(240, 102, 105, 0.13)",
    padding: 16,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    borderRadius: 8,
    marginLeft: "10px",
    marginRight: "10px",
    marginBottom: "5px",
  },
  background1: {
    backgroundColor: "#ffffff",
    padding: 16,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    borderRadius: 8,
    marginLeft: "10px",
    marginRight: "10px",
  },
  avatartitle: {
    display: "flex",
    width: "20%",
  },
  table: {
    minWidth: 600,
  },
  tableContainer: {
    margin: "1%",
    width: "98%",
  },
  tablehead: {
    fontSize: "15px",
    fontWeight: "800",
    background: secondaryColor,
    color: "white",
  },
  icons: {
    display: "flex",
    justifyContent: "space-around",
  },
  editicon: {
    // marginRight:'30px',
    fill: primaryColor,
    cursor: "pointer",
  },
  deleteicon: {
    fill: secondaryColor,
    cursor: "pointer",
  },
  registeredname: {
    padding: "10px",
    fontSize: 16,
  },
  addbutton: {
    display: "flex",
    float: "right",
    margin: 16,
    width: 150,
    background: secondaryColor,
    color: whiteColor,
    padding: 3,
    textAlign: "center",
    cursor: "pointer",
    textDecoration: "none",
  },
  editbutton: {
    color: whiteColor,
    backgroundColor: secondaryColor,
    borderRadius: 8,
    padding: 8,
    textAlign: "center",
    margin: 5,
    cursor: "pointer",
    maxHeight: 35,
    textDecoration: "none",
  },
  date: {
    marginTop: 10,
    fontSize: 16,
    fontWeight: 300,
  },
  search: {
    position: "relative",
    display: "flex",
    float: "right",
    marginRight: 20,
    marginTop: 20,
    // width:200,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(secondaryColor, 0.15),
    "&:hover": {
      backgroundColor: fade(secondaryColor, 0.25),
    },
    marginLeft: 0,
    width: "200px",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

export default function PromoCodes() {
  const classes = useStyles();
  const [userList, setUserList] = useState([{}]);
  const [searchedUsers, setSearchedUsers] = useState([]);
  const history = useHistory();
  const [Data, setData] = useState([]);
  const [Loading, setLoading] = useState(true);

  useEffect(() => {
    getAllPromoCodes();
  }, []);

  const getAllPromoCodes = () => {
    axios
      .post(
        "https://us-central1-bigwayz-backend.cloudfunctions.net/get_all_promocodes",
        {}
      )
      .then((res) => {
        console.log(res.data);
        setData(res.data);
        setLoading(false);
      });
  };

  const deleteCategory = (item) => {
    console.log("deleting item =", item);
    firestore
      .collection("businessCategories")
      .doc("businessCategories")
      .update(
        {
          categories_array: Firebase.firestore.FieldValue.arrayRemove(item),
        },
        { merge: true }
      );
  };

  function handleClickOpen() {}

  return Loading ? (
    <div
    className="skeleton text-center d-flex justify-content-center"
    style={{
      width: "100%",
      marginTop: "3rem",
      flexDirection:'column',
      padding:30
    }}
  >
    <Skeleton
      style={{
        background: "#efefef",
        // width:'8rem',
        // marginLeft:'5%'
        // margin:'auto'
      }}
      animation="wave"
      variant="text"
      width={'100%'}
      height={64}
    />
     <Skeleton
      style={{
        background: "#efefef",
        // width:'8rem',
        // marginLeft:'5%'
        // margin:'auto'
      }}
      animation="wave"
      variant="text"
      width={'100%'}
      height={64}
    />
     <Skeleton
      style={{
        background: "#efefef",
        // width:'8rem',
        // marginLeft:'5%'
        // margin:'auto'
      }}
      animation="wave"
      variant="text"
      width={'100%'}
      height={64}
    />
     <Skeleton
      style={{
        background: "#efefef",
        // width:'8rem',
        // marginLeft:'5%'
        // margin:'auto'
      }}
      animation="wave"
      variant="text"
      width={'100%'}
      height={64}
    />
     <Skeleton
      style={{
        background: "#efefef",
        // width:'8rem',
        // marginLeft:'5%'
        // margin:'auto'
      }}
      animation="wave"
      variant="text"
      width={'100%'}
      height={64}
    />
     <Skeleton
      style={{
        background: "#efefef",
        // width:'8rem',
        // marginLeft:'5%'
        // margin:'auto'
      }}
      animation="wave"
      variant="text"
      width={'100%'}
      height={64}
    />
     <Skeleton
      style={{
        background: "#efefef",
        // width:'8rem',
        // marginLeft:'5%'
        // margin:'auto'
      }}
      animation="wave"
      variant="text"
      width={'100%'}
      height={64}
    />
     <Skeleton
      style={{
        background: "#efefef",
        // width:'8rem',
        // marginLeft:'5%'
        // margin:'auto'
      }}
      animation="wave"
      variant="text"
      width={'100%'}
      height={64}
    />
     <Skeleton
      style={{
        background: "#efefef",
        // width:'8rem',
        // marginLeft:'5%'
        // margin:'auto'
      }}
      animation="wave"
      variant="text"
      width={'100%'}
      height={64}
    />
     <Skeleton
      style={{
        background: "#efefef",
        // width:'8rem',
        // marginLeft:'5%'
        // margin:'auto'
      }}
      animation="wave"
      variant="text"
      width={'100%'}
      height={64}
    />
     <Skeleton
      style={{
        background: "#efefef",
        // width:'8rem',
        // marginLeft:'5%'
        // margin:'auto'
      }}
      animation="wave"
      variant="text"
      width={'100%'}
      height={64}
    />
     <Skeleton
      style={{
        background: "#efefef",
        // width:'8rem',
        // marginLeft:'5%'
        // margin:'auto'
      }}
      animation="wave"
      variant="text"
      width={'100%'}
      height={64}
    />
     <Skeleton
      style={{
        background: "#efefef",
        // width:'8rem',
        // marginLeft:'5%'
        // margin:'auto'
      }}
      animation="wave"
      variant="text"
      width={'100%'}
      height={64}
    />
  </div>
  ) : (
    <div>
      {/* <Alert severity="info">
                <AlertTitle>Search User Info</AlertTitle>
                The below Search Bar is an — <strong>universal searchbar!</strong> You can search users using their name, username, role, phone, email or current status.
            </Alert> */}
      <Grid container spacing={0}>
        <Grid item xs={6}>
          <Typography className={classes.title}>PromoCodes</Typography>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={1} style={{ marginLeft: "20px", marginTop: "5px" }}>
          <Button
            onClick={() => history.push("/addpromocode")}
            style={{ backgroundColor: primaryColor }}
            className={classes.addbutton}
            variant="contained"
          >
            Add Promocode
          </Button>
        </Grid>
      </Grid>

      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell className={classes.tablehead}>Sr.</TableCell>
              <TableCell align="left" className={classes.tablehead}>
                Title
              </TableCell>
              <TableCell align="left" className={classes.tablehead}>
                Code
              </TableCell>
              <TableCell align="left" className={classes.tablehead}>
                Status
              </TableCell>
              <TableCell align="left" className={classes.tablehead}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Data.map((item, index) => {
              return (
                <>
                  {index % 2 == 0 ? (
                    <TableRow key={item.title}>
                      <TableCell component="th" scope="row">
                        {index + 1}
                      </TableCell>
                      <TableCell align="left">{item.title}</TableCell>
                      <TableCell align="left">{item.code}</TableCell>
                      <TableCell
                        align="left"
                        style={
                          item.status === "active"
                            ? { color: "green" }
                            : { color: primaryColor }
                        }
                      >
                        {item.status}
                      </TableCell>
                      <TableCell align="left" className={classes.icons}>
                        <Link to={{ pathname: "/addCategory", item: item }}>
                          <EditIcon
                            className={classes.editicon}
                            onClick={handleClickOpen}
                          />{" "}
                        </Link>
                        <DeleteIcon
                          onClick={() => {
                            deleteCategory(item);
                          }}
                          className={classes.deleteicon}
                        />
                      </TableCell>
                    </TableRow>
                  ) : (
                    <TableRow
                      key={item.name}
                      style={{ backgroundColor: lightGray }}
                    >
                      <TableCell component="th" scope="row">
                        {index + 1}
                      </TableCell>
                      <TableCell align="left">{item.title}</TableCell>
                      <TableCell align="left">{item.code}</TableCell>
                      <TableCell
                        align="left"
                        style={
                          item.status === "active"
                            ? { color: "green" }
                            : { color: primaryColor }
                        }
                      >
                        {item.status}
                      </TableCell>
                      <TableCell align="left" className={classes.icons}>
                        <Link to={{ pathname: "/addCategory", item: item }}>
                          <EditIcon
                            className={classes.editicon}
                            onClick={handleClickOpen}
                          />{" "}
                        </Link>
                        <DeleteIcon
                          onClick={() => {
                            deleteCategory(item);
                          }}
                          className={classes.deleteicon}
                        />
                      </TableCell>
                    </TableRow>
                  )}
                </>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
