import React,{useState} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { IconButton, Typography,TextField } from '@material-ui/core';
import { primaryColor, secondaryColor, whiteColor } from '../../../config/constants';
import { firestore } from '../../../config/firebase';
import {Link, useLocation} from "react-router-dom";

// icons
import PersonIcon from '@material-ui/icons/Person';
import EditIcon from '@material-ui/icons/Edit';
import PhoneIcon from '@material-ui/icons/Phone';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
      maxHeight:150,
      minHeight:180
    },
    title:{
        fontSize:30,
        color:secondaryColor,
        fontWeight:700,
        margin:20
    },
    addbutton:{
        display:'flex',
        float:'right',
        margin:30,
        fontSize:16,
        borderRadius:8,
        background:secondaryColor,
        color:whiteColor,
        padding:5,
        textAlign:'center',
        cursor:'pointer',
        textDecoration:'none'
    },
    addbuttontitle:{
        textAlign:'center'
    },
    editbutton:{
        color:whiteColor,
        backgroundColor:secondaryColor,
        borderRadius:18,
        padding:8,
        textAlign:'center',
        margin:5,
        cursor:'pointer',
        maxHeight:35,
        textDecoration:'none',
        float:'right',
    },
    userdetailscards:{
        margin:20,
    },
    cardheader:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between'
    },
    avatartitle:{
        display:'flex'
    },
    headertitle:{
        fontSize:18,
        fontWeight:600,
        color:secondaryColor,
        marginLeft:10
    },
    detailscontainer:{
        display:'flex',
        justifyContent:'space-between',
        marginTop:15
    },
    detail:{
        color:primaryColor,
        width:"60%",
        textAlign:'right'
    },
  }));


export default function UserDetails(props) {
    const classes = useStyles();
    const location = useLocation();
    const [phone,setPhone] = useState('')
    const [name,setName] = useState('')
    const [userId ,setUserId] = useState('')
    const [address,setAddress] = useState('')
    const [dob,setDob] = useState('')
    const [gender,setGender] = useState('')
    const [email,setEmail] = useState('')
    const [businessName,setBusineesName] = useState('')
    const [gst,setGst] = useState('')
    const [pan,setPan] = useState('')

    React.useEffect(() => {
     getUser();
    },[]);

    const getUser =() =>{
     console.log(phone)
     firestore.collection('users').doc(location.phone).get().then(snapshot => {
    if(snapshot.data() != undefined){
        setName(snapshot.data().name)
        setUserId(snapshot.data().userId)
        setDob(snapshot.data().dob)
        setGender(snapshot.data().gender)
        setPhone(snapshot.data().phone)
        setAddress(snapshot.data().address)
        setEmail(snapshot.data().email)
        setBusineesName(snapshot.data().businessName)
        setPan(snapshot.data().pan)
        setGst(snapshot.data().gst)
    }
})
    }

    return (
        <div>
        <Grid container spacing={0}>
            <Grid item xs={6}>
            <Typography className={classes.title}>
              {name }
            </Typography>
            </Grid>
            <Grid item xs={6}>
            <div>
             <Link to ="/manageusers" size="small" color="primary"  className={classes.addbutton}>
                    Go Back
            </Link>
            <div  color="primary"  className={classes.addbutton}>
                    Give Admin Access
            </div>
            </div>
            </Grid>
        </Grid>

        <div className={classes.userdetailscards}>
            <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
            <Paper className={classes.paper}>
                <div className={classes.cardheader}>
                    <div className={classes.avatartitle}>
                    <PersonIcon style={{color:secondaryColor}}/>
                    <Typography className={classes.headertitle}>User Details</Typography>
                    </div>
                    <Link to={{pathname:'/edituser', phone:phone}} style={{padding:0}}>
                        <EditIcon style={{color:secondaryColor}}/>
                    </Link>
                </div>
                <div className={classes.detailscontainer}>
                <Typography>User ID :</Typography>
                <Typography className={classes.detail}>{userId}</Typography>
                </div>
                <div className={classes.detailscontainer}>
                <Typography>Date Of Birth:</Typography>
                <Typography  className={classes.detail}>{new Date(dob).getDate() + '/' + (new Date(dob).getMonth()+1) + '/' + new Date(dob).getFullYear()}</Typography>
                </div>
                <div className={classes.detailscontainer}>
                <Typography>Gender:</Typography>
                <Typography  className={classes.detail}>{gender}</Typography>
                </div>
            </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>

            <Paper className={classes.paper}>
                <div className={classes.cardheader}>
                    <div className={classes.avatartitle}>
                    <PhoneIcon style={{color:secondaryColor}}/>
                    <Typography className={classes.headertitle}>Contact Details</Typography>
                    </div>
                    <Link  to={{pathname:'/editcontactdetails', phone:phone}} style={{padding:0}} >
                        <EditIcon style={{color:secondaryColor}}/>
                    </Link>
                </div>
                <div className={classes.detailscontainer}>
                <Typography>Phone Number :</Typography>
                <Typography className={classes.detail}>{phone}</Typography>
                </div>
                <div className={classes.detailscontainer}>
                <Typography>Email Id :</Typography>
                <Typography  className={classes.detail}>{email}</Typography>
                </div>
            </Paper>

            </Grid>
            </Grid>
        </div>

        {/* row 2 */}

        <div className={classes.userdetailscards}>
            <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
            <Paper className={classes.paper}>
                <div className={classes.cardheader}>
                    <div className={classes.avatartitle}>
                    <PersonIcon style={{color:secondaryColor}} />
                    <Typography className={classes.headertitle}>Address Details</Typography>
                    </div>
                    <Link to={{pathname:'/editaddress', phone:phone}} style={{padding:0}}>
                        <EditIcon style={{color:secondaryColor}}/>
                    </Link>
                </div>
                <div className={classes.detailscontainer}>
                <Typography>Full Address :</Typography>
                <Typography className={classes.detail}>{address}</Typography>
                </div>
                {/* <div className={classes.detailscontainer}>
                <Typography>Pin closed:</Typography>
                <Typography  className={classes.detail}>56010101</Typography>
                </div> */}
            </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
            <Paper className={classes.paper}>
                <div className={classes.cardheader}>
                    <div className={classes.avatartitle}>
                    <PhoneIcon style={{color:secondaryColor}} />
                    <Typography className={classes.headertitle}>Bank Details</Typography>
                    </div>
                    <Link to="/editbankdetails" style={{padding:0}}>
                        <EditIcon style={{color:secondaryColor}}/>
                    </Link>
                </div>
                <div className={classes.detailscontainer}>
                <Typography>Account Number :</Typography>
                <Typography className={classes.detail}>7022463389121222112</Typography>
                </div>
                <div className={classes.detailscontainer}>
                <Typography>IFSC Code :</Typography>
                <Typography  className={classes.detail}>Sb12121212</Typography>
                </div>
                <div className={classes.detailscontainer}>
                <Typography>Branch Name :</Typography>
                <Typography  className={classes.detail}>State Bank of India,Delhi,India</Typography>
                </div>
            </Paper>

            </Grid>
            </Grid>
        </div>


        {/* row 3 */}

        <div className={classes.userdetailscards}>
            <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
            <Paper className={classes.paper}>
                <div className={classes.cardheader}>
                    <div className={classes.avatartitle}>
                    <PersonIcon style={{color:secondaryColor}}/>
                    <Typography className={classes.headertitle}>Business Details</Typography>
                    </div>
                    <Link  to={{pathname:'/editbusinessdetails', phone:phone}}style={{padding:0}}>
                        <EditIcon style={{color:secondaryColor}}/>
                    </Link>
                </div>
                <div className={classes.detailscontainer}>
                <Typography>Business Name :</Typography>
                <Typography className={classes.detail}>{businessName}</Typography>
                </div>
                <div className={classes.detailscontainer}>
                <Typography>GST:</Typography>
                <Typography  className={classes.detail}>{gst}</Typography>
                </div>
                <div className={classes.detailscontainer}>
                <Typography>Pan:</Typography>
                <Typography  className={classes.detail}>{pan}</Typography>
                </div>
            </Paper>
            </Grid>
            </Grid>
        </div>




        </div>
    )
}
