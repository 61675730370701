import React,{useState} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {  TextField, Typography,Button } from '@material-ui/core';
import { primaryColor, secondaryColor, whiteColor } from '../../../../config/constants';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {firestore,storage} from '../../../../config/firebase';



const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    title:{
        fontSize:30,
        color:secondaryColor,
        fontWeight:700,
        margin:20
    },
    background:{
        backgroundColor:"rgba(240, 102, 105, 0.13)",
        padding:30,
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between'
    },
    addbutton:{
        display:'flex',
        float:'right',
        margin:30,
        fontSize:16,
        borderRadius:8,
        background:secondaryColor,
        color:whiteColor,
        padding:5,
        textAlign:'center',
        cursor:'pointer',
        textDecoration:'none'
    },
    editbutton:{
        margin:5,
        cursor:'pointer',
        textDecoration:'none',
        margin:20,
        marginLeft:30,
        marginTop:20,
        display:'flex',
        justifyContent:'center'
    },
    formcontainer:{
    },
    formtitle:{
        color:primaryColor,
        fontWeight:600,
        fontSize:16,
        paddingBottom:10,
        paddingTop:10
    },
    forminput:{
        minWidth:"100%",
        borderColor:secondaryColor,
        marginRight:"10%",
        background:whiteColor,
        [theme.breakpoints.only('xs')]: {
           minWidth:'100%'
          },
    },
    imageuploaded:{
        marginBottom:20,
        padding:20,
        width:'90%',
        textAlign:'start',
        display:'flex'
    },
    buttons:{
        marginRight:20,
        backgroundColor:secondaryColor,
        color:whiteColor
    },
    savebutton:{
        width:100,
        color:whiteColor,
        backgroundColor:secondaryColor,
        borderRadius:18,
        padding:8,
        textAlign:'center',
    }
  }));
  

export default function AllType() {
    const classes = useStyles();
    const [uploadedImage, setUploadedImage] = useState();
	const [isFilePicked, setIsFilePicked] = useState(false);
    const [fileName, setFileName] = useState();


    // const handleImageUpload =(event) =>{
    //     const file = URL.createObjectURL(event.target.files[0])
    //     setSelectedFile(file);
	// 	setIsFilePicked(true);
    //     console.log(selectedFile)
    // }

    function uploadImageFunction(files) {
        let file = files[0];
        setFileName(file.name)
        const uploadTask = storage.ref(`/adminImages/${file.name}`).put(file)

        uploadTask.on('state_changed',
            (snapShot) => {
            }, (err) => {
                console.log(err)
            }, () => {
                storage.ref('adminImages').child(file.name).getDownloadURL()
                    .then(fireBaseUrl => {
                        // getting url here
                        setUploadedImage(fireBaseUrl)
                        setIsFilePicked(true)
                        console.log("image uploaded",fireBaseUrl)

                    })
            })
}

    const handleremoveimage =() =>{
        setUploadedImage(null)
        setIsFilePicked(false)
        storage.ref('adminImages').child(fileName).delete()
    }


    const top100Films = [
        { title: 'The Shawshank Redemption', year: 1994 },
        { title: 'The Godfather', year: 1972 },
        { title: 'The Godfather: Part II', year: 1974 },
        { title: 'The Dark Knight', year: 2008 },
        { title: '12 Angry Men', year: 1957 },
        { title: "Schindler's List", year: 1993 },
        { title: 'Pulp Fiction', year: 1994 },
        { title: 'The Lord of the Rings: The Return of the King', year: 2003 },
        { title: 'The Good, the Bad and the Ugly', year: 1966 },
        { title: 'Fight Club', year: 1999 },
        { title: 'The Lord of the Rings: The Fellowship of the Ring', year: 2001 },
        { title: 'Star Wars: Episode V - The Empire Strikes Back', year: 1980 },
      ];
      
    return (
        <div>

        <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                <Typography className={classes.formtitle}>Title</Typography>
                <TextField className={classes.forminput} placeholder="Enter Title" variant="outlined" />
                <Typography className={classes.formtitle}>Description</Typography>
                <TextField className={classes.forminput} placeholder="Enter Description" variant="outlined" multiline={true} rows={3}/>
                </Grid>
                <Grid item xs={12} sm={6}>
                <Typography className={classes.formtitle}>Upload Image</Typography>
                <div style={{display:'flex'}}>
                <Button
                    className={classes.buttons}
                    variant="contained"
                    component="label"
                    color={secondaryColor}
                    >
                    Upload File
                    <input
                        type="file" 
                        hidden
                        onChange={e => uploadImageFunction(e.target.files)}
                    />
                </Button>
            { isFilePicked == true ?
                <Button variant="contained" className={classes.buttons} onClick={handleremoveimage} >
                    Remove
                </Button>
                :
                <></>
            }
                </div>
                <img src={uploadedImage} className={classes.imageuploaded} />
                </Grid>
        </Grid>

            <div size="small" color="primary"  className={classes.editbutton}>
                <div className={classes.savebutton}>
                    Save 
                 </div>
             </div>
        </div>

    )
}
