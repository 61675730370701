import React, {useEffect, useState} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import MainNavBar from '../../components/MainNavBar';
import MainFooter from '../../components/MainFooter';
import { Button, Container, Grid, Paper, Typography } from '@material-ui/core';
import { primaryColor, secondaryColor, whiteColor } from '../../config/webconstants';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import YouTubeIcon from '@material-ui/icons/YouTube';
import contactImage from "../../assets/website/images/contactus.png"
import Telphone from "../../assets/website/images/telephoneicon.png"
import { useHistory } from 'react-router';
import axios from 'axios';
import { te } from 'date-fns/locale';

const API = axios.create({ baseURL: 'https://us-central1-bigwayz-backend.cloudfunctions.net'})

const useStyles = makeStyles(theme =>({
    root: {
        flex:1,
        overflowX:'hidden',
        margin:'10% 2%',
        [theme.breakpoints.down('sm')]: {
            marginTop:'80px'
          },
    },
    container:{
        paddingRight:"0px 10px",
        backgroundColor:primaryColor,
        borderRadius:30
    },
    formconatiner:{
        backgroundColor:primaryColor,
        padding:20,
        borderRadius:30,
        height:'100%',
        borderTopRightRadius:0,
        borderBottomRightRadius:0,
        [theme.breakpoints.down('md')]: {
            borderRadius:20
         },
      
    },
    formtitle:{
        fontSize:25,
        color:whiteColor,
        fontWeight:'600',
        letterSpacing:1,
        fontFamily:"'Myraid',san-serif",
        [theme.breakpoints.down('md')]: {
            fontSize:18
        },
    },
    inputfeild:{
        border:'none',
        borderBottom:'5px solid #B54B4E',
        backgroundColor:'#B54B4E',
        outline:'none',
        backgroundColor:'transparent',
        color:whiteColor,
        margin:20,
        fontSize:22,
        paddingBottom:10,
        width:'95%',
        fontFamily:"'Montserrat',san-serif",
        [theme.breakpoints.down('md')]: {
            fontSize:15,
            margin:5,
         },
    },
    submitcontainer:{
        display:'flex',
        justifyContent:'center',
    },
    submitbutton:{
        margin:'auto'
    },
    submit:{
        background:"linear-gradient(to bottom, #BC4E52 50%,#F1666A)",
        color:whiteColor,
        fontFamily:"'Montserrat',san-serif",
        fontWeight:'800',
        letterSpacing:1,
    },
    contactinfo:{
        padding:'2% 2%',
        background:"linear-gradient(to right, #BC4E52 50%,#F1666A)",
        borderTopRightRadius:30,
        borderBottomRightRadius:30,
        fontFamily:"'Montserrat',san-serif",
        [theme.breakpoints.down('md')]: {
            padding:'15px',
            borderRadius:30,
            marginTop:20,
         },
    },
    contacticon:{
        color:whiteColor,
        fontSize:22,
        margin:'10px 0px',
        fontFamily:"'Montserrat',san-serif",
        [theme.breakpoints.down('md')]: {
            fontSize:22,
        },
    },
    infotext:{
        color:whiteColor,
        fontSize:18,
        padding:"10px 0px",
        fontFamily:"'Montserrat',san-serif",
        [theme.breakpoints.down('md')]: {
            fontSize:14
        },
        
    },
    socialiconcontainer:{
        backgroundColor:primaryColor,
        padding:"10px",
        display:'flex',
        justifyContent:'space-around',
        [theme.breakpoints.down('md')]: {
           borderRadius:300
        },
    },
    socialicon:{
        color:whiteColor,
        cursor:'pointer',
        "&:hover":{
            color:secondaryColor,
        }
    },
    image:{
        height:100,
        width:140,
        marginLeft:'auto',
        objectFit:'cover'
    },
    telephoneicon:{
        height:"100%",
        width:'70%',
        objectFit:'contain'
    },
    clickable:{
        cursor:'pointer',
        textDecoration:'none',
        "&:hover":{
            textDecoration:'underline'
        }
    },
    alinks:{
        textDecoration:'none',
        color:whiteColor,
        "&:hover":{
            textDecoration:'underline',
            color:whiteColor,
        }
        
    }

}))
export default function ContactUs() {
    const classes= useStyles();
    const history = useHistory();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [contactdata, setcontactdata] = useState({name: '', phone:'', email: '', message:'', appLink:"https://bigwayz.in/"});
    const [isent, setissent] = useState(false);

    const changeInput = (event) =>{
        setcontactdata((prevProps) => ({
            ...prevProps,
            [event.target.name]: event.target.value
        }));
    }

    const ContactSubmit = (e) =>{
        e.preventDefault();
        contactdata.appLink = `http://tx3.in/K5Bt/${contactdata.phone}`
        API.post('/website_contact_us', contactdata);
        setissent(true);
        setcontactdata({name: '', phone:'', email: '', message:'', appLink:"https://bigwayz.in/"});
        console.log('sent');
    }


    return (
        <>
        <MainNavBar />
            <div className={classes.root}>

                <Container>
                    <Grid container elevation={2} className={classes.container}>
                        <Grid xs={12} sm={8}>
                            <Paper elevation={3} className={classes.formconatiner}>
                            <Typography className={classes.formtitle}>Contact Us</Typography>
                            <form onSubmit={ContactSubmit}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={4}>
                                    <input 
                                    type="text" 
                                    placeholder="Name" 
                                    placeholderTextColor={whiteColor}
                                    name="name"
                                    value={contactdata.name}
                                    onChange={e => changeInput(e)} 
                                    className={classes.inputfeild}></input>
                                    </Grid>

                                    <Grid item xs={12} sm={4}>
                                    <input 
                                    type="text" 
                                    placeholder="Mobile Number" 
                                    name="phone"
                                    value={contactdata.phone}
                                    onChange={e => changeInput(e)}
                                    placeholderTextColor={whiteColor} 
                                    className={classes.inputfeild}
                                    type="number" pattern="[0-9]*" inputmode="numeric"
                                    ></input>
                                    </Grid>

                                    <Grid item xs={12} sm={4}>
                                    <input 
                                    type="email" 
                                    placeholder="Email (optional)"
                                    name="email"
                                    value={contactdata.email}
                                    onChange={e => changeInput(e)}
                                    placeholderTextColor={whiteColor} 
                                    className={classes.inputfeild}
                                    ></input>
                                    </Grid>

                                    <Grid item xs={12} sm={8}>
                                    <input 
                                    type="text" 
                                    placeholder="Your Message (optional)"
                                    name="message"
                                    value={contactdata.message}
                                    onChange={e => changeInput(e)}
                                    placeholderTextColor={whiteColor} 
                                    className={classes.inputfeild}
                                    ></input>
                                    </Grid>
                                </Grid>
                                <div className={classes.submitcontainer}>
                                    <div className={classes.submitbutton}>
                                    <Button variant="contained" type="submit" className={classes.submit} >SUBMIT</Button>
                                    </div>
                                    <div style={{display:'flex'}}>
                                    <img src={contactImage} alt="img" className={classes.image} />
                                    </div>
                                    {/* <div style={{display:'flex'}}>
                                <img src={contactImage} alt="img" className={classes.image} />
                                </div> */}
                                </div>
                            </form>

                         {isent === true && (<Typography className={classes.formtitle}>Thank you for your interest, We will get in touch with you soon!</Typography>)}   
                            

                            </Paper>
                        </Grid>
                        <Grid xs={12} sm={4} className={classes.contactinfo}>
                            <Grid container>
                                <Grid xs={1} className={classes.clickable}>
                                    <img src={Telphone} alt="telephone" className={classes.telephoneicon} />
                                </Grid>
                                <Grid xs={11} className={classes.clickable}>
                                    <a className={classes.alinks} href="tel:08041496046" >
                                        <Typography className={classes.infotext}>: 080414 96046</Typography></a>
                                </Grid> 
                                <Grid xs={1} className={classes.clickable}>
                                    <PhoneOutlinedIcon className={classes.contacticon}/>
                                </Grid>
                                <Grid xs={11} className={classes.clickable}>
                                    <a className={classes.alinks}  href="tel:9113629361">
                                    <Typography className={classes.infotext}>: +91 91136 29361</Typography> </a>
                                </Grid> 
                                <Grid xs={1} className={classes.clickable}>
                                    <MailOutlineIcon className={classes.contacticon}/>
                                </Grid>
                                <Grid xs={11} className={classes.clickable}>
                                    <a className={classes.alinks}  href = "mailto:info@bigwayz.com">
                                    <Typography className={classes.infotext}>: info@bigwayz.com </Typography> </a>
                                </Grid> 
                                <Grid xs={1}>
                                    <LocationOnOutlinedIcon className={classes.contacticon}/>
                                </Grid>
                                <Grid xs={11}>
                                    <Typography className={classes.infotext}>: 
                                    Inginomicx Technologies Pvt Ltd. 3rd Floor, # 4 & 5. 5th Main, Jayamahal Extension Bangalore, Karnataka – 560046
                                    </Typography>
                                </Grid> 
                            </Grid>
                            <Paper elevation={3} className={classes.socialiconcontainer}>
                                <YouTubeIcon onClick={()=>window.open("https://www.youtube.com/channel/UCQnOpduAlSfaOwVhHYDEJsg", "_blank")} className={classes.socialicon}/>
                                <TwitterIcon onClick={()=>window.open("https://twitter.com/BigwayzOfficial", "_blank")} className={classes.socialicon}/>
                                <FacebookIcon onClick={()=>window.open("https://www.facebook.com/bigwayz.benefits", "_blank")} className={classes.socialicon}/>
                                <InstagramIcon onClick={()=>window.open("https://www.instagram.com/bigwayz_official/", "_blank")}className={classes.socialicon}/>
                                <LinkedInIcon onClick={()=>window.open("https://www.linkedin.com/company/bigwayz", "_blank")} className={classes.socialicon}/>
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        <MainFooter showContact={false}/>
            
        </>
    )
}
