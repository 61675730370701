import React,{useState} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {  TextField, Typography,Button } from '@material-ui/core';
import { primaryColor, secondaryColor, whiteColor } from '../../../config/constants';
import { useTheme } from '@material-ui/core/styles';
import {Link} from "react-router-dom";
import { Input } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    title:{
        fontSize:30,
        color:secondaryColor,
        fontWeight:700,
        margin:20
    },
    background:{
        backgroundColor:"rgba(240, 102, 105, 0.13)",
        padding:30,
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between'
    },
    addbutton:{
        display:'flex',
        float:'right',
        margin:30,
        fontSize:16,
        borderRadius:8,
        background:secondaryColor,
        color:whiteColor,
        padding:5,
        textAlign:'center',
        cursor:'pointer',
        textDecoration:'none'
    },
    editbutton:{
        color:whiteColor,
        backgroundColor:secondaryColor,
        borderRadius:18,
        padding:8,
        textAlign:'center',
        margin:5,
        cursor:'pointer',
        maxHeight:35,
        textDecoration:'none',
        width:100,
        // margin:20
    },
    formcontainer:{
        margin:20
    },
    formtitle:{
        color:primaryColor,
        fontWeight:600,
        fontSize:16,
        paddingBottom:10,
        paddingTop:10
    },
    forminput:{
        minWidth:600,
        borderColor:secondaryColor,
        background:whiteColor,
        [theme.breakpoints.only('xs')]: {
           minWidth:'100%'
          },
    },
    imageuploaded:{
        marginBottom:20,
        padding:20,
        width:'50%',
        textAlign:'start',
        display:'flex'
    },
    buttons:{
        marginLeft:20,
        backgroundColor:secondaryColor,
        color:whiteColor
    }
  }));


export default function EditVideo() {
    const classes = useStyles();
    const [selectedFile, setSelectedFile] = useState([]);
	const [isFilePicked, setIsFilePicked] = useState(false);

    const handleImageUpload =(event) =>{
        var file = URL.createObjectURL(event.target.files[0])
        setSelectedFile(file);
		setIsFilePicked(true);
        console.log(selectedFile)
    }

    const handleremoveimage =() =>{
        const file =[]
        selectedFile()
        setIsFilePicked(false)
    }
    return (
        <div>
            <Grid container spacing={0}>
                <Grid item xs={6}>
                <Typography className={classes.title}>
                    Edit Video
                </Typography>
                </Grid>
                <Grid item xs={6}>
                <Link to ="/videolibrary" size="small" color="primary"  className={classes.addbutton}>
                        Go Back
                </Link>
                </Grid>
            </Grid>

            <div className={classes.formcontainer}>
                <Typography className={classes.formtitle}>Title</Typography>
                <TextField className={classes.forminput} placeholder="Enter Title" variant="outlined" />
                <Typography className={classes.formtitle}>Description</Typography>
                <TextField className={classes.forminput} placeholder="Enter Description" variant="outlined" multiline={true} rows={5}/>
                <Typography className={classes.formtitle}>Tags</Typography>
                <TextField className={classes.forminput} placeholder="Enter Tags" variant="outlined" />
                <Typography className={classes.formtitle}>Upload Image</Typography>
                <div style={{display:'flex'}}>
                <Button
                    className={classes.buttons}
                    variant="contained"
                    component="label"
                    color={secondaryColor}
                    >
                    Upload File
                    <input
                        type="file"
                        hidden
                        onChange={handleImageUpload}
                    />
                </Button>
            { isFilePicked == true ?
                <Button variant="contained" className={classes.buttons} onClick={handleremoveimage} >
                    Remove
                </Button>
                :
                <></>
            }
                </div>
                <img src={selectedFile} className={classes.imageuploaded} />
            </div>
            <div size="small" color="primary"  className={classes.editbutton}>
                    Update
             </div>
        </div>

    )
}
