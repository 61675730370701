import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Typography,
  Button,
  MenuItem,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { Nav, Navbar, Container } from "react-bootstrap";
import { useHistory } from "react-router";
import Logo from "../../assets/website/images/mainlogo.png";
import pdf from "./TermsAndCondition/TermsAndCondition.pdf";
import { useLocation } from "react-router-dom";
import {
  blackColor,
  primaryColor,
  titleSize,
  whiteColor,
  secondaryColor,
  normalSize,
} from "../../config/webconstants";
import "../../App.css";
import { firestore } from "../../config/firebase";

import { MDBCol, MDBContainer, MDBFooter, MDBRow } from "mdbreact";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import CallIcon from "@material-ui/icons/Call";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import YouTubeIcon from "@material-ui/icons/YouTube";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import PlayStoreImage from "../../assets/website/footerimages/playstore.png";
import LogoWhite from "../../assets/website/footerimages/mainlogo_white.png";
import IosImage from "../../assets/website/footerimages/app_store.png";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import Telphone from "../../assets/website/images/telephoneicon.png";
import Lottie from "react-lottie";
import LottieFile from "../../assets/website/businessPage/lf30_editor_i5jrfsov.json";
import VectorImage from "../../assets/website/infinityImages/infinitytitle.png";
import GifImage from "../../assets/website/infinityImages/Card.gif";
import SurakshaVector from "../../assets/website/infinityImages/surakshavector.png";
import SurakshaLogo from "../../assets/website/images/surakshalogo.png";
import InfoIcon from "@material-ui/icons/Info";
import SaveEarnVector from "../../assets/website/infinityImages/saveearn.png";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    overflowX: "hidden",
  },
  logo: {
    height: 70,
    objectFit: "contain",
    [theme.breakpoints.down("sm")]: {
      height: 50,
    },
  },
  NavbarItem: {
    fontSize: titleSize,
    color: blackColor,
    "&:hover": {
      color: blackColor,
    },
    "&.active": {
      borderBottom: `5px solid ${primaryColor}`,
    },
    [theme.breakpoints.down("md")]: {
      "&.active": {
        color: primaryColor,
        borderBottom: `none`,
      },
    },
  },
  logofooter: {
    height: 70,
    width: 200,
    marginTop: 20,
    objectFit: "contain",
  },
  scoialcontainer: {
    marginTop: "10%",
    width: "60%",
    // paddingLeft:'20%',
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      marginTop: "20px",
      padding: "0px 10%",
      width: "100%",
    },
  },

  connecttitle: {
    fontSize: normalSize,
    fontWeight: "600",
    color: whiteColor,
    letterSpacing: 1,
    textAlign: "start",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  socialicons: {
    display: "flex",
    padding: 10,
    backgroundColor: "#DC5D60",
    justifyContent: "space-between",
    borderRadius: 12,
  },
  socialicon: {
    margin: "0px 5px",
    color: whiteColor,
    cursor: "pointer",
    "&:hover": {
      color: secondaryColor,
    },
  },
  horizontalline: {
    padding: "2% 0px",
    padding: 1,
    backgroundColor: whiteColor,
  },
  footersecond: {
    color: whiteColor,
    padding: "0px 2%",
    paddingTop: 15,
    textAlign: "center",
  },
  links: {
    textDecoration: "none",
    textAlign: "start",
    color: whiteColor,
    padding: "20px 0px",
    fontFamily: "Myraid",
    "&:hover": {
      color: whiteColor,
      fontWeight: "600",
    },
  },
  linkcontainer: {
    padding: "10px 0px",
  },
  imagescontainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
  registeredimage: {
    width: "100px",
    height: "100px",
    objectFit: "contain",
    margin: "5px 0px",
    marginRight: "20px",
    [theme.breakpoints.down("md")]: {
      marginRight: "5px",
    },
  },
  footerrow: {
    textAlign: "justify",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  },
  applinks: {
    display: "flex",
    justifyContent: "start",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
  appimage: {
    height: 100,
    width: "45%",
    objectFit: "contain",
    paddingRight: "5%",
    cursor: "pointer",
  },
  telephoneicon: {
    height: "6%",
    width: "6%",
    objectFit: "contain",
    paddingRight: 2,
  },
  clickable: {
    cursor: "pointer",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  mainheader: {
    // backgroundImage: `url(${Background})`,
    background:
      "linear-gradient(90deg, #1675B3 0%, #68B6E8 52.76%, #0F76B9 100%)",
  },
  maincontainer: {
    margin: "5% 5%",
  },
  maincard: {
    padding: "40px 30px",
    borderRadius: 12,
  },
  maintitle: {
    fontSize: titleSize,
    fontWeight: 800,
    fontFamily: "Montserrat",
    [theme.breakpoints.down("sm")]: {
      fontSize: titleSize,
    },
  },
  mainsubtitle: {
    fontSize: normalSize,
    fontWeight: 600,
    fontFamily: "Montserrat",
    marginTop: 40,
    marginBottom: 20,
    fontSize: normalSize,
    [theme.breakpoints.down("sm")]: {
      fontSize: normalSize,
    },
  },
  listitem: {
    fontSize: normalSize,
    fontWeight: 500,
    fontFamily: "Montserrat",
    [theme.breakpoints.down("sm")]: {
      fontSize: normalSize,
    },
  },
  maincardleft: {
    borderRight: "1px solid #cdcdcd",
    paddingRight: "10px",
    [theme.breakpoints.down("sm")]: {
      border: "none",
      paddingRight: "0px",
    },
  },
  maincardright: {
    // padding:'40px'
    display: "flex",
    justifyContent: "center",
    height: 500,
  },
  cardvectorimage: {
    objectFit: "contain",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  },
  gridcontainer: {
    padding: "30px 0px",
  },
  gridleftcontainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px",
  },
  lefttext: {
    textAlign: "center",
    fontSize: "42px",
    fontFamily: "Myraid",
    fontWeight: 600,
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px",
    },
  },
  gifimage: {
    height: 400,
    width: "100%",
    padding: "10px",
    objectFit: "contain",
  },
  surakshalogo: {
    width: 200,
    ObjectFit: "contain",
    marginBottom: 30,
  },
  gridtext: {
    fontSize: normalSize,
    fontFamily: "Montserrat",
    fontWeight: 600,
  },
  gridsubtext: {
    fontSize: normalSize,
    fontFamily: "Montserrat",
    fontWeight: 400,
  },
  earnimagecontainer: {
    padding: 0,
  },
  textfeild: {
    width: "100%",
    margin: "10px 0px",
  },
  formtitle: {
    fontSize: titleSize,
    fontFamily: "Montserrat",
    fontWeight: 600,
    paddingBottom: 20,
  },
  paynowbutton: {
    backgroundColor: "#118C4F",
    color: "#fff",
    fontFamily: "Montserrat",
    fontSize: titleSize,
  },
}));

const EmployeeType = [
  {
    value: "Salaried",
    label: "Salaried",
  },
  {
    value: "Self-Employed",
    label: "Self-Employed",
  },
];

const EmploymentType = [
  {
    value: "Business-Owner",
    label: "Business-Owner",
  },
  {
    value: "Industrial & Manufacturing",
    label: "Industrial & Manufacturing",
  },
  {
    value: "Professional",
    label: "Professional",
  },
  {
    value: "Others",
    label: "Others",
  },
];

const nomineeRelationArray = [
  {
    value: "Son",
    label: "Son",
  },
  {
    value: "Husband",
    label: "Husband",
  },
  {
    value: "Father",
    label: "Father",
  },
  {
    value: "Grand Mother",
    label: "Grand Mother",
  },
  {
    value: "Wife",
    label: "Wife",
  },
  {
    value: "Brother-in-law",
    label: "Brother-in-law",
  },
  {
    value: "Daughter",
    label: "Daughter",
  },
  {
    value: "Sister-in-law",
    label: "Sister-in-law",
  },
  {
    value: "Grand Father",
    label: "Grand Father",
  },
  {
    value: "Mother",
    label: "Mother",
  },
  {
    value: "Brother",
    label: "Brother",
  },
  {
    value: "Sister",
    label: "Sister",
  },
  {
    value: "Mother-in-Law",
    label: "Mother-in-Law",
  },
];

function InfinityPage() {
  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();

  const classes = useStyles();
  const history = useHistory();
  const [employmentype, setEmploymentype] = React.useState("Salaried");
  const [profession, setProfession] = React.useState("Business-Owner");
  const [email, setEmail] = React.useState(query.get("email"));
  const [phone, setPhone] = React.useState("");
  const [nomineeName, setnNomineeName] = React.useState("");
  const [nomineeRelation, setNomineeRelation] = React.useState(
    "Nominee Relation"
  );

  console.log("kkk1", typeof query.get("token"));
  console.log("kkk2", typeof query.get("email"));

  localStorage.setItem("token", query.get("token"));

  const [showText, setShowText] = React.useState(false);

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const Redirect = () => {
    const ApiKey =
      "lYn8YW418LV3bT+U4/XemYSA9MWs+VV83t8oqWRlWO1YLzeaODqwFzo8ZOoR51iRYph9ZdbgzBF/9xfErYa43qGKWnEOr1ljdV4OGDTontI=";

    const API = axios.create({
      baseURL: "https://neokred.bankx.money:9091/",
    });

    API.post(
      `/api/v1/authenticate`,
      {
        email: "george@bigwayz.com",
        password: "Big@1234",
      },
      { headers: { "x-api-key": `${ApiKey}` } }
    )
      .then((res) => {
        if (res.status === 200) {
          console.log("token generated now calling second api", res.data);

          API.get(`/api/v1/client/get/mobileHookUrl`, {
            headers: {
              "api-key": `${ApiKey}`,
              retailerRefId: "BM-9341221872-001",
              Authorization: `Bearer ${res.data.token}`,
            },
          })
            .then((res) => {
              if (res.status === 200) {
                console.log("redirect link generated", res.data);
                window.open(`${res.data.details.liveUrl}`);
              }
            })
            .catch((err) => {
              console.log("error", err);
              if (err.response) {
                console.log("error response", err.response);
              }
            });
        }
      })
      .catch((err) => {
        console.log("error while generating token", err);

        if (err.response) {
          console.log("error response", err.response);
        }
      });
  };

  function openPdf(pathname) {
    window.open(pathname, "Terms & Condition");
  }

  const displayRazorpay = async () => {
    let timestamp = Date.now();
    console.log("test +++++", {
      email: email,
      token: localStorage.getItem("token"),
      nomineeName: nomineeName,
      nomineeRelation: nomineeRelation,
      profession: profession,
      employmentype: employmentype,
      paymentComplete: true,
      createdAt: timestamp,
    });

    if (
      email == null ||
      phone == "" ||
      nomineeName == "" ||
      nomineeRelation == "" ||
      profession == "" ||
      employmentype == ""
    ) {
      alert("Please enter all required fields.");
      return;
    }
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay failed . Please try again");
      return;
    }
    let order_id = Math.floor(Math.random() * 1000000) + 1;
    var options = {
      key: "rzp_live_XjeqqAZEY09PWS", //rzp_live_XjeqqAZEY09PWS, rzp_test_q57iT1DnXDjVxY
      amount: 599 * 100,
      currency: "INR",
      name: "Inginomicx Technologies Pvt Ltd",
      description: "Payment for order",
      image: Logo,
      handler: function(response) {
        //success
        const leadRef = firestore.collection("infinityleads").doc(phone);
        leadRef.set(
          {
            email: email,
            token: localStorage.getItem("token"),
            phone: phone,
            nomineeName: nomineeName,
            nomineeRelation: nomineeRelation,
            profession: profession,
            employmentype: employmentype,
            paymentComplete: true,
            createdAt: timestamp,
          },
          { merge: true }
        );
        setShowText(true);
        // trigger SMS API here
        sendSMS();

        // get api and redirect function
        Redirect();
      },
      prefill: {
        email: email,
        contact: phone,
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LottieFile,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  function LottieAnimation({ lotti, width, height }) {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: lotti,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    return (
      <div>
        <Lottie
          options={defaultOptions}
          height={height}
          width={width}
          style={{ cursor: "default" }}
        />
      </div>
    );
  }

  const sendSMS = () => {
    let axiosData = {
      phone: phone,
    };
    axios
      .post(
        "https://us-central1-bigwayz-backend.cloudfunctions.net/send_sms_https",
        axiosData
      )
      .then((response) => {
        console.log("res == ", response.data);
      })
      .catch((err) => {
        console.log("error == ", err);
      });
  };

  return (
    <div>
      <Navbar
        collapseOnSelect
        expand="md"
        variant="light"
        style={{ backgroundColor: whiteColor }}
        sticky="top"
        className={classes.webnavbar}
      >
        <Container>
          <Navbar.Brand href="/">
            <img src={Logo} alt="imagelogo" className={classes.logo} />
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto"></Nav>
            <Nav.Link className={classes.NavbarItem} href="/aboutus">
              About Us
            </Nav.Link>
            <Nav.Link className={classes.NavbarItem} href="/contactus">
              Help Desk
            </Nav.Link>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* main-start */}

      <div className={classes.maincontainer}>
        <Paper className={classes.maincard} elevation={3}>
          <Grid container>
            <Grid item xs={12} sm={6} className={classes.maincardleft}>
              <Typography className={classes.maintitle}>
                Moneytime Signature Card
              </Typography>
              <Typography className={classes.mainsubtitle}>
                Get a debit card to manage your Business & see your future grow
                with a yes bank powered debit card. Key features are:
              </Typography>
              <ul>
                <li>
                  <Typography className={classes.listitem}>
                    Prepaid Debit/ATM Reloadable Card upto 3 Years Validity
                  </Typography>
                </li>
                <li>
                  <Typography className={classes.listitem}>
                    Onboarding using EKYC process
                  </Typography>
                </li>
                <li>
                  <Typography className={classes.listitem}>
                    Mobile enabled Card linked to a Virtual Account
                  </Typography>
                </li>
                <li>
                  <Typography className={classes.listitem}>
                    Cash withdrawal through ATMs of any Bank
                  </Typography>
                </li>
                <li>
                  <Typography className={classes.listitem}>
                    Accepted for payments at all Rupayor Master Card POS
                  </Typography>
                </li>
                <li>
                  <Typography className={classes.listitem}>
                    Accepted for payments online
                  </Typography>
                </li>
                <li>
                  <Typography className={classes.listitem}>
                    Instant Cash Loading facility
                  </Typography>
                </li>
                <li>
                  <Typography className={classes.listitem}>
                    Card Loading Limits
                  </Typography>
                </li>
              </ul>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.maincardright}>
              <img src={VectorImage} className={classes.cardvectorimage} />
            </Grid>
          </Grid>
        </Paper>

        <Grid container>
          <Grid item xs={12} sm={6} className={classes.gridleftcontainer}>
            <Typography className={classes.lefttext}>
              Get a Yes Bank powered <br />
              Prepaid Card
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.gridleftcontainer}>
            <img src={GifImage} className={classes.gifimage} />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12} sm={6} className={classes.gridleftcontainer}>
            <img src={SurakshaVector} className={classes.gifimage} />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            className={classes.gridleftcontainer}
            style={{ alignItems: "self-end" }}
          >
            <div>
              <img src={SurakshaLogo} className={classes.surakshalogo} />
              <Typography className={classes.gridtext}>
                Whats Covered?
              </Typography>
              <ul style={{ padding: "20px 40px" }}>
                <li>
                  <Typography className={classes.gridtext}>
                    Annual Premium : ₹360 (Excl GST)
                  </Typography>
                </li>
                <li>
                  <Typography className={classes.gridtext}>
                    Sum Insured: ₹10 Lakh
                  </Typography>
                </li>
                <li>
                  <Typography className={classes.gridtext}>
                    Accidental Death: 100% of Sum Insured
                  </Typography>
                </li>
                <li>
                  <Typography className={classes.gridtext}>
                    Add-on benefits include hospitalization expenses,
                    compassionate visit & a daily allowance.
                  </Typography>
                </li>
              </ul>
              <Typography className={classes.gridtext}>
                Don't allow accidents to cripple the family's financial health
                with a comprehensive personal accident plan that covers large
                number of common cases like total disablement, fractures, burn
                damage and much more.
              </Typography>
              <div style={{ display: "flex", padding: "20px 20px" }}>
                <InfoIcon style={{ marginRight: 10, marginTop: 2 }} />
                <Typography className={classes.gridsubtext}>
                  For associates and customers of Bigwayz
                  <br /> (Inginomicx Technologies Pvt Ltd) only.
                </Typography>
              </div>
            </div>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12} sm={6} className={classes.gridleftcontainer}>
            <div>
              <Typography
                className={classes.lefttext}
                style={{ textAlign: "start" }}
              >
                Save and Earn
              </Typography>
              <Typography className={classes.gridtext}>
                from an E-Commerce store with 100+ Brands & 16 categories as
                your inventory for a one stop shopping experience
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.gridleftcontainer}>
            <img src={SaveEarnVector} className={classes.gifimage} />
          </Grid>
        </Grid>

        <Paper className={classes.maincard} elevation={3}>
          <Grid container>
            <Grid item xs={12} sm={6} className={classes.maincardleft}>
              <Typography className={classes.formtitle}>
                Register Now:
              </Typography>
              <TextField
                variant="outlined"
                className={classes.textfeild}
                placeholder="Enter Email Address"
                value={email}
                disabled={query.get("token") !== null ? true : false}
                onChange={(event) => setEmail(event.target.value)}
              />
              <TextField
                variant="outlined"
                className={classes.textfeild}
                placeholder="Enter Phone Number"
                onChange={(event) => setPhone(event.target.value)}
              />
              <Typography
                className={classes.formtitle}
                style={{ paddingTop: 40 }}
              >
                For Personal Accident Care Insurance:
              </Typography>
              <Typography
                className={classes.formtitle}
                style={{ fontSize: 16 }}
              >
                Employee Type:
              </Typography>
              <TextField
                select
                fullWidth
                value={employmentype}
                onChange={(event) => setEmploymentype(event.target.value)}
                variant="outlined"
                placeholder="Enter Employe Type"
                className={classes.formtitle}
              >
                {EmployeeType.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <Typography
                className={classes.formtitle}
                style={{ fontSize: 16 }}
              >
                Employment Type:
              </Typography>
              <TextField
                select
                fullWidth
                value={profession}
                onChange={(event) => setProfession(event.target.value)}
                variant="outlined"
                className={classes.formtitle}
              >
                {EmploymentType.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <Typography
                className={classes.formtitle}
                style={{ fontSize: 16 }}
              >
                Nominee name
              </Typography>
              <TextField
                variant="outlined"
                className={classes.textfeild}
                placeholder="Enter Nominee Name"
                onChange={(event) => setnNomineeName(event.target.value)}
              />
              <Typography
                className={classes.formtitle}
                style={{ fontSize: 16 }}
              >
                Nominee Relation
              </Typography>
              <TextField
                select
                fullWidth
                value={nomineeRelation}
                onChange={(event) => {
                  console.log(event.target.value);
                  setNomineeRelation(event.target.value);
                }}
                variant="outlined"
                placeholder="Enter Employe Type"
                className={classes.formtitle}
              >
                {nomineeRelationArray.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              {/* <TextField
                variant="outlined"
                className={classes.textfeild}
                placeholder="Nominee Relation"
                onChange={(event) => setNomineeRelation(event.target.value)}
                
                  // Thank you for registering with Bigwayz. Your username: {phone}{" "}
                  // & Corporate ID is BIGWAYZ.
              /> */}
              {showText ? (
                <Typography
                  className={classes.formtitle}
                  style={{ fontSize: 16 }}
                >
                  Thank you for showing interest to become part of Bigwayz.
                  Please click on the given link to download the app:
                  <a href="http://tx3.in/MO2a"> http://tx3.in/MO2a </a> Use the
                  Corporate ID: BIGWAYZ to register with Bigwayz.
                </Typography>
              ) : (
                <>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox defaultChecked />}
                      label={<a href={pdf}> Accept Terms & Conditions</a>}
                    />
                  </FormGroup>
                   
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: 40,
                    }}
                  >
                    <Button
                      variant="contained"
                      className={classes.paynowbutton}
                      onClick={() => displayRazorpay()}
                    >
                      Pay Now
                    </Button>
                  </div>
                </>
              )}
            </Grid>
            <Grid item xs={12} sm={6} className={classes.maincardright}>
              <LottieAnimation
                lotti={LottieFile}
                height={"100%"}
                width={"100%"}
              />
            </Grid>
          </Grid>
        </Paper>
      </div>

      {/* main-end */}

      {/* footer */}

      <MDBFooter
        color="stylish-color-dark"
        style={{ backgroundColor: primaryColor }}
        className="page-footer font-small p-4"
      >
        <MDBContainer fluid className="text-center text-md-left">
          <MDBRow style={{ padding: " 0px 5%" }}>
            <MDBCol md="4" className={classes.footerrow}>
              <img src={LogoWhite} alt="logo" className={classes.logofooter} />
              <div className={classes.scoialcontainer}>
                <Typography className={classes.connecttitle}>
                  Connect with us
                </Typography>
                <div className={classes.socialicons}>
                  <a href="https://www.facebook.com/bigwayz.benefits">
                    <FacebookIcon className={classes.socialicon} />
                  </a>
                  <a href="https://www.linkedin.com/company/bigwayz">
                    <LinkedInIcon className={classes.socialicon} />
                  </a>
                  <a href="https://www.instagram.com/bigwayz_official/">
                    <InstagramIcon className={classes.socialicon} />
                  </a>
                  {/* <WhatsAppIcon className={classes.socialicon} /> */}
                  <a href="https://www.youtube.com/channel/UCQnOpduAlSfaOwVhHYDEJsg">
                    <YouTubeIcon className={classes.socialicon} />
                  </a>
                  <a href="https://twitter.com/BigwayzOfficial">
                    <TwitterIcon className={classes.socialicon} />
                  </a>
                </div>
              </div>
            </MDBCol>
            <MDBCol md="4" className={classes.footerrow}>
              <h5
                style={{
                  color: whiteColor,
                  fontFamily: "Myraid",
                  fontWeight: "600",
                }}
                className="text-uppercase mb-4 mt-3 font-weight-bold"
              >
                Important Links
              </h5>
              <ul className="list-unstyled">
                <li className={classes.linkcontainer}>
                  <a className={classes.links} href="/aboutus">
                    About Us
                  </a>
                </li>
                <li className={classes.linkcontainer}>
                  <a className={classes.links} href="/products">
                    Products
                  </a>
                </li>
                <li className={classes.linkcontainer}>
                  <a className={classes.links} href="/calculator">
                    Income Calculator
                  </a>
                </li>
                <li className={classes.linkcontainer}>
                  <a className={classes.links} href="/contactus">
                    Contact Us
                  </a>
                </li>
                <li className={classes.linkcontainer}>
                  <a className={classes.links} href="/privacypolicy">
                    Privacy Policy
                  </a>
                </li>
                <li className={classes.linkcontainer}>
                  <Typography className={classes.connecttitle}>
                    Download our app on
                  </Typography>
                  <div className={classes.applinks}>
                    <img
                      onClick={() => alert("Coming soon!")}
                      src={PlayStoreImage}
                      alt="playstoreimage"
                      className={classes.appimage}
                    />
                    <img
                      onClick={() => alert("Coming soon!")}
                      src={IosImage}
                      alt="playstoreimage"
                      className={classes.appimage}
                    />
                  </div>
                </li>
              </ul>
            </MDBCol>
            <MDBCol
              style={{ color: "#ffffff" }}
              md="4"
              className={classes.footerrow}
            >
              <h5
                style={{
                  color: whiteColor,
                  fontFamily: "Myraid",
                  fontWeight: "600",
                }}
                className="text-uppercase mb-4 mt-3 font-weight-bold"
              >
                Registered With
              </h5>
              <ul className="list-unstyled">
                <li>
                  <p>
                    <CallIcon />
                    {"  "}:
                    <a
                      style={{ color: "#ffffff" }}
                      href="tel:08041496046"
                      className={classes.clickable}
                    >
                      {"  "}08041496046{" "}
                    </a>
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <img
                      src={Telphone}
                      alt="telephone"
                      className={classes.telephoneicon}
                    />
                    {"  "}:{" "}
                    <a
                      style={{ color: "#ffffff" }}
                      href="tel:9113629361"
                      className={classes.clickable}
                    >
                      {"  "} 9113629361{" "}
                    </a>
                  </p>
                </li>
                <li>
                  <p style={{ color: "#ffffff" }}>
                    <MailOutlineIcon /> {"  "}:{" "}
                    <a
                      style={{ color: "#ffffff" }}
                      className={classes.clickable}
                      href="mailto:info@bigwayz.com"
                    >
                      {"  "}info@bigwayz.com
                    </a>
                  </p>
                </li>
                {/*<li>*/}
                {/*    <a href="#!">+91-9587917917</a>*/}
                {/*</li>*/}
                <li>
                  <p href="#!">
                    <LocationOnOutlinedIcon />
                    {"  "} : 3rd Floor, #4 & 5. 5th Main, Jayamahal Extension
                    Bangalore, Karnataka – 560046
                  </p>
                </li>
              </ul>
            </MDBCol>
          </MDBRow>
          <div className={classes.horizontalline}></div>
          <div className={classes.footersecond}>
            Copyright © {new Date().getFullYear()} Inginomicx Technologies
            Private Limited. All Rights Reserved.
          </div>
        </MDBContainer>
      </MDBFooter>
    </div>
  );
}

export default InfinityPage;
