import React,{useEffect,useState} from 'react'
import { Navbar,NavDropdown,Nav,Container ,Dropdown} from 'react-bootstrap'
import Logo from "../assets/website/images/mainlogo.png";
import { makeStyles,useTheme } from '@material-ui/core/styles';
import { blackColor, darkColor, darkGray, primaryColor, primaryTint, secondaryColor, titleSize, whiteColor } from '../config/webconstants';
import { useLocation,useHistory } from 'react-router';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListAltIcon from '@material-ui/icons/ListAlt';
import DnsIcon from '@material-ui/icons/Dns';
import LocalActivityIcon from '@material-ui/icons/LocalActivity';
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import HomeIcon from '@material-ui/icons/Home';
import PeopleIcon from '@material-ui/icons/People';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { Collapse } from '@material-ui/core';

const drawerWidth = "100%";



const useStyles = makeStyles(theme =>({
    logo:{
        height:70,
        objectFit:'contain'
    },
    mobilelogo:{
        height:55,
        objectFit:'contain',
        margin:'auto',
        // position:'absolute',
        width:'80%',
        paddingRight:'10%'
    },
    buttonMob:{
        backgroundColor:primaryColor,
        borderColor:primaryColor,
        color:whiteColor,
        borderRadius:8,
        fontFamily:'Myraid',
        textAlign:'center',
        width: '80px',
        fontWeight:'800',
        "&:hover":{
            backgroundColor:primaryColor
        },
        "&:after":{
            backgroundColor:primaryColor
        },
        [theme.breakpoints.up('md')]: {
            display:'none'
        }
    },
    button:{
        backgroundColor:primaryColor,
        borderColor:primaryColor,
        color:whiteColor,
        borderRadius:8,
        fontFamily:'Myraid',
        width: '80px',
        textAlign:'center',
        marginLeft:10,
        fontWeight:'800',
        "&:hover":{
            backgroundColor:primaryColor
        },
        "&:after":{
            backgroundColor:primaryColor
        },
        [theme.breakpoints.down('md')]: {
            display:'none'
        }
    },
    subcategory:{
        backgroundColor:primaryColor,
        color:whiteColor,
        fontWeight:'600',
        '&.active':{
            backgroundColor:primaryColor,
        },
    },
    buttontext:{
        color:whiteColor,
        fontFamily:'Myraid',
        fontWeight:'800',
        textAlign:'center',
    },
    NavbarItem:{
        fontSize:titleSize,
        '&.active':{
            borderBottom:`5px solid ${primaryColor}`,
        },
        [theme.breakpoints.down('md')]: {
            '&.active':{
                color: primaryColor,
                borderBottom:`none`,
            }
        }
    },
    webnavbar:{
        display:'block',
        [theme.breakpoints.down('sm')]: {
            display:'none'
        }
    },
    mobilenavbar:{
        display:'none',
        [theme.breakpoints.down('sm')]: {
            display:'block'
        }
    },
    appBar: {
        backgroundColor:whiteColor,
        color:blackColor,
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      },
      appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
      menuButton: {
        // marginRight: theme.spacing(2),
      },
      hide: {
        display: 'none',
      },
      drawer: {
        width: drawerWidth,
        flexShrink: 0,
      },
      drawerPaper: {
        width: drawerWidth,
      },
      drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start',
      },
      content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
      },
      contentShift: {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
      },
      logindrawer:{
          margin:'3% 20%',
          width:'60%',
          textAlign:'center',
          color:whiteColor,
          borderRadius:12
        },
        divider:{
            backgroundColor:darkGray
        }
  }));


export default function MainNavBar() {
    const classes = useStyles()
    const [showProducts, setShowProducts] = useState(false);
    const [showOppurtunity,setShowOppurtunity] = useState(false);
    const [showLogin,setShowLogin] = useState(false)
    const location = useLocation();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [openProduct,setOpenProduct] = useState(false)
    const [openOppurtunities,setOppurtunities] = useState(false)
    const [openLogin,setOpenLogin] = useState(false)
    const history = useHistory();


  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleClickitem = () => {

  }


    return (
    
        <>
        <div className={classes.mobilenavbar}>
            <CssBaseline />
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                [classes.appBarShift]: open,
                })}
                elevation={0}
            >
                <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    className={clsx(classes.menuButton, open && classes.hide)}
                >
                    <MenuIcon />
                </IconButton>
                    <img src={Logo} onClick={() => {history.push("/");handleDrawerClose()}} alt="imagelogo" className={classes.mobilelogo} />
                </Toolbar>
            </AppBar>
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={open}
                classes={{
                paper: classes.drawerPaper,
                }}
            >
                <div className={classes.drawerHeader}>
                <IconButton onClick={handleDrawerClose}>
                    {theme.direction === 'ltr' ? 
                    <>
                    <ChevronLeftIcon />
                    <img src={Logo} alt="imagelogo" onClick={() => {history.push("/");handleDrawerClose()}} className={classes.mobilelogo} />
                    </>
                    
                    : <ChevronRightIcon />}
                </IconButton>
                </div>
                <Divider />
                <List>
                    <ListItem  button onClick={()=>{history.push("/");handleDrawerClose()}}>
                    <ListItemIcon> <HomeIcon /></ListItemIcon>
                    <ListItemText primary="Home" />
                    </ListItem>        
                    <Divider classes={{root: classes.divider}} />
                    <ListItem button onClick={()=>setOpenProduct(!openProduct)}>
                    <ListItemIcon>
                        <DnsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Products" />
                        {openProduct? <ExpandLess /> : <ExpandMore />}
                    </ListItem>

                    <Collapse in={openProduct} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem button  onClick={()=>{history.push("/products#finz");handleDrawerClose()}}>
                        <ListItemIcon>
                            {/* <AccountBoxIcon /> */}
                        </ListItemIcon>
                        <ListItemText primary="Digital Banking" />
                        </ListItem>
                    </List>
                    <Divider classes={{root: classes.divider}} />
                    <List component="div" disablePadding>
                        <ListItem button onClick={()=>{history.push("/products#assist");handleDrawerClose()}} >
                        <ListItemIcon>
                            {/* <PeopleAltIcon /> */}
                        </ListItemIcon>
                        <ListItemText primary="E-Commerce" />
                        </ListItem>
                    </List>
                    <Divider classes={{root: classes.divider}} />
                    <List component="div" disablePadding>
                        <ListItem button  onClick={()=>{history.push("/products#suraksha");handleDrawerClose()}}>
                        <ListItemIcon>
                            {/* <PeopleAltIcon /> */}
                        </ListItemIcon>
                        <ListItemText primary="Insurance" />
                        </ListItem>
                    </List>
                    <Divider classes={{root: classes.divider}} />
                    <List component="div" disablePadding>
                        <ListItem button onClick={()=>{history.push("/products#business");handleDrawerClose()}} >
                        <ListItemIcon>
                            {/* <PeopleAltIcon /> */}
                        </ListItemIcon>
                        <ListItemText primary="Promote your Business" />
                        </ListItem>
                    </List>
                    <Divider classes={{root: classes.divider}} />
                    <List component="div" disablePadding>
                        <ListItem button  onClick={()=>{history.push("/products#cards");handleDrawerClose()}}>
                        <ListItemIcon>
                        </ListItemIcon>
                        <ListItemText primary="Prepaid Card" />
                        </ListItem>
                    </List>
                    </Collapse>

                    <Divider classes={{root: classes.divider}} />
    

                    <ListItem button onClick={()=>setOppurtunities(!openOppurtunities)}>
                    <ListItemIcon>
                        <LocalActivityIcon />
                    </ListItemIcon>
                    <ListItemText primary="Opportunities" />
                        {openOppurtunities? <ExpandLess /> : <ExpandMore />}
                    </ListItem>

                    <Collapse in={openOppurtunities} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem button    onClick={()=>{history.push("/entrepreneur");handleDrawerClose()}}>
                        <ListItemIcon>
                            {/* <AccountBoxIcon /> */}
                        </ListItemIcon>
                        <ListItemText primary="Entrepreneur Program" />
                        </ListItem>
                    </List>
                    <Divider classes={{root: classes.divider}} />
                    <List component="div" disablePadding>
                        <ListItem button    onClick={()=>{history.push("/distributor");handleDrawerClose()}}>
                        <ListItemIcon>
                            {/* <AccountBoxIcon /> */}
                        </ListItemIcon>
                        <ListItemText primary="Distributor Program" />
                        </ListItem>
                    </List>
                    <Divider classes={{root: classes.divider}} />
                    <List component="div" disablePadding>
                        <ListItem button    onClick={()=>{history.push("/calculator");handleDrawerClose()}}>
                        <ListItemIcon>
                            {/* <AccountBoxIcon /> */}
                        </ListItemIcon>
                        <ListItemText primary="Income Calculator" />
                        </ListItem>
                    </List>
                    </Collapse>
                    <Divider classes={{root: classes.divider}} />

                    <List component="div" disablePadding>
                        <ListItem button   onClick={()=>{history.push("/corporate");handleDrawerClose()}} >
                        <ListItemIcon>
                        <DonutLargeIcon />
                        </ListItemIcon>
                        <ListItemText primary="Corporate" />
                        </ListItem>
                    </List>
                    <Divider classes={{root: classes.divider}} />
                    <List component="div" disablePadding>
                        <ListItem button   onClick={()=>{history.push("/aboutus");handleDrawerClose()}} >
                        <ListItemIcon>
                        <PeopleIcon />
                        </ListItemIcon>
                        <ListItemText primary="About us" />
                        </ListItem>
                    </List>
                    <Divider classes={{root: classes.divider}} />
                    <List component="div" disablePadding>
                        <ListItem button  onClick={()=>{history.push("/contactus");handleDrawerClose()}}  >
                        <ListItemIcon>
                        <ContactMailIcon />
                        </ListItemIcon>
                        <ListItemText primary="Contact Us" />
                        </ListItem>
                    </List>
                    <Divider classes={{root: classes.divider}} />

                    <ListItem button onClick={()=>setOpenLogin(!openLogin)} className={classes.logindrawer} style={{backgroundColor:primaryColor,padding:0}}>
                    {/* <ListItemIcon>
                        <ListAltIcon />
                    </ListItemIcon> */}
                    <ListItemText primary="Login" />
                        {/* {openLogin? <ExpandLess /> : <ExpandMore />} */}
                    </ListItem>


                    


                    <Collapse in={openLogin} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem   >
                        <ListItemIcon>
                        </ListItemIcon>
                        <ListItemText primary="Finz" onClick={()=>{window.open("https://finserv.bigwayz.com/#/sign-in", "_blank");handleDrawerClose()}}/>
                        </ListItem>
                    </List>
                    <Divider classes={{root: classes.divider}} style={{margin:"0px 15%"}} />

                    <List component="div" disablePadding>
                        <ListItem button >
                        <ListItemIcon>
                        </ListItemIcon>
                        <ListItemText primary="Assist" onClick={()=>{window.open("https://estore.bigwayz.com/", "_blank");handleDrawerClose()}}/>
                        </ListItem>
                    </List>
                    <Divider classes={{root: classes.divider}} style={{margin:"0px 15%"}} />
                    <List component="div" disablePadding>
                        <ListItem button    onClick={()=>{history.push("/suraksha");handleDrawerClose()}}>
                        <ListItemIcon>
                        </ListItemIcon>
                        <ListItemText primary="Suraksha" />
                        </ListItem>
                    </List>
                    <Divider classes={{root: classes.divider}} style={{margin:"0px 15%"}} />
                    <List component="div" disablePadding>
                        <ListItem   button >
                        <ListItemIcon>
                            {/* <AccountBoxIcon /> */}
                        </ListItemIcon>
                        <ListItemText primary="Partner-Login"onClick={()=>{window.open("https://partner.bigwayz.com/", "_blank");handleDrawerClose()}} />
                        </ListItem>
                    </List>
                    </Collapse>

                
                </List>
            </Drawer>
        </div>


        <Navbar collapseOnSelect expand="md" variant="light" style={{backgroundColor:whiteColor}} sticky="top" className={classes.webnavbar}>
            <Container>
                <Navbar.Brand href="/">
                    <img src={Logo} alt="imagelogo" className={classes.logo} />
                </Navbar.Brand>

                <NavDropdown title={ <span className={classes.buttontext}>Login</span>} id="collasible-nav-dropdown" className={classes.buttonMob}  show={showLogin} onClick={()=>setShowLogin(!showLogin)}>
                <NavDropdown.Item className={classes.subcategory} href="https://finserv.bigwayz.com/#/login">Finz</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item className={classes.subcategory} href="https://estore.bigwayz.com/">Assist</NavDropdown.Item>
                </NavDropdown>
                

                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav  className="me-auto">
                        </Nav>

                        <Nav  activeKey={location.pathname}>
                            <Nav.Link  className={classes.NavbarItem} href="/">Home</Nav.Link>
                            <NavDropdown className={classes.NavbarItem} onClick={()=>history.push("/products")} title="Products" id="collasible-nav-dropdown" show={showProducts} onMouseEnter={()=>setShowProducts(!showProducts)} onMouseLeave={()=>setShowProducts(false)}>
                                <NavDropdown.Item className={classes.subcategory} href="/products#finz">Digital Banking</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item className={classes.subcategory} href="/products#assist">E-Commerce</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item className={classes.subcategory} href="/products#suraksha">Insurance</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item className={classes.subcategory} href="/products#business">Promote your Business</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item className={classes.subcategory} href="/products#cards">Prepaid Card</NavDropdown.Item> 
                            </NavDropdown>


                            <NavDropdown className={classes.NavbarItem} title="Opportunities" id="collasible-nav-dropdown"   show={showOppurtunity} onMouseEnter={()=>setShowOppurtunity(!showOppurtunity)} onMouseLeave={()=>setShowOppurtunity(false)}>
                                <NavDropdown.Item className={classes.subcategory} href="/entrepreneur">Entrepreneur Program</NavDropdown.Item>
                                <NavDropdown.Item className={classes.subcategory} href="/distributor">Distributor Program</NavDropdown.Item>
                                <NavDropdown.Item className={classes.subcategory} href="/calculator">Income Calculator</NavDropdown.Item>
                            </NavDropdown>

                            <Nav.Link className={classes.NavbarItem}  href="/corporate">Corporate</Nav.Link>
                            <Nav.Link className={classes.NavbarItem} href="/aboutus">About Us</Nav.Link>
                            <Nav.Link  className={classes.NavbarItem}  href="/contactus">Contact Us</Nav.Link>
                        </Nav>
                </Navbar.Collapse>

                <NavDropdown title={<span className={classes.buttontext}>LOGIN</span>} id="collasible-nav-dropdown" className={classes.button} style={{backgroundColor:primaryColor}} show={showLogin} onMouseEnter={()=>setShowLogin(!showLogin)} onMouseLeave={()=>setShowLogin(false)}>
                    <NavDropdown.Item className={classes.subcategory} href="https://finserv.bigwayz.com/#/sign-in">Finz</NavDropdown.Item>
                    <NavDropdown.Item className={classes.subcategory} href="https://estore.bigwayz.com/">Assist</NavDropdown.Item>
                    <NavDropdown.Item className={classes.subcategory} href="/suraksha">Suraksha</NavDropdown.Item>
                    <NavDropdown.Item className={classes.subcategory} href="https://partner.bigwayz.com/">Partner-Login</NavDropdown.Item>
                </NavDropdown>
            
                </Container>
                </Navbar>

                </>
            
    )
}