import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Typography,TextField, Paper } from '@material-ui/core';
import {blackColor, lightGray, primaryColor, secondaryColor, whiteColor } from '../../../config/constants'; 
import {Link} from "react-router-dom";
import cardImage from "../../../assets/images/cardimage.png"
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import LinkIcon from '@material-ui/icons/Link';


const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    title:{
        fontSize:30,
        color:secondaryColor,
        fontWeight:700,
        margin:20
    },
    background:{
        backgroundColor:"rgba(240, 102, 105, 0.13)",
        padding:30,
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between'
    },
    background1:{
        padding:30,
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between'
    },
    avatartitle:{
        display:'flex',
        width:'20%'
    },
    registeredname:{
        padding:'10px',
        fontSize:18
    },
    addbutton:{
        display:'flex',
        float:'right',
        margin:30,
        fontSize:16,
        borderRadius:8,
        background:secondaryColor,
        color:whiteColor,
        padding:5,
        textAlign:'center',
        cursor:'pointer',
        textDecoration:'none'
    },
    formcontainer:{
        margin:20
    },
    editbutton:{
        color:whiteColor,
        backgroundColor:secondaryColor,
        borderRadius:18,
        padding:8,
        width:100,
        textAlign:'center',
        margin:5,
        cursor:'pointer',
        maxHeight:35,
        textDecoration:'none'
    },
    date:{
        fontSize:16,
        fontWeight:300
    },
    formtitle:{
        color:primaryColor,
        fontWeight:600,
        fontSize:16,
        paddingBottom:10,
        paddingTop:10
    },
    forminput:{
        width:'100%',
        borderColor:secondaryColor,
        background:whiteColor,
        [theme.breakpoints.only('xs')]: {
           minWidth:'100%'
          },
    },
    descriptioncontainer:{
        padding:20,
        borderRadius:12
    },
    forminputdescription:{
        width:'100%',
        borderColor:secondaryColor,
        background:whiteColor,
        marginTop:20,
        [theme.breakpoints.only('xs')]: {
           minWidth:'100%'
          },
    },
    cardimage:{
        width:"100%",
        height:250,
        marginTop:20,
        objectFit:'contain'
    },
    cardcontainer:{
        paddingTop:20,
        paddingBottom:20,
        borderRadius:8,
        marginTop:40,
        width:'90%',
        marginLeft:'5%',
    },
    header:{
        paddingTop:10,
        paddingBottom:10,
        borderBottom:'1px solid #cdcdcd',
        display:'flex',
        justifyContent:'center'
    },
    headertitle:{
        textAlign:'center',
        color:secondaryColor,
        fontWeight:600,
        fontSize:20,
        paddingBottom:10
    },
    innercard:{
        padding:10,
        backgroundColor:'#F2F1F1',
        marginLeft:20,
        marginRight:20,
        marginTop:20,
        borderRadius:12,
    },
    innercardmain:{
        color:blackColor,
        marginBottom:10,
    },
    innercardtitle:{
        color:secondaryColor,
        fontSize:18,
        fontWeight:600
    },
    innercardsubtitle:{
        color:lightGray,
        fontSize:14,
    },
    carddate:{
        color:lightGray,
        fontSize:14,
        marginTop:10,
        marginLeft:20
    },
    carddteails:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        paddingLeft:20,
        paddingRight:20,
        paddingTop:20
    },
    carddteailslast:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        paddingLeft:20,
        paddingRight:20,
        paddingTop:10,
        border:'1px soild black',
        marginTop:20   
    }
  }));
  

export default function CashoutDetails() {
    const classes = useStyles();
    const [value, setValue] = React.useState('Active');

    const handleChange = (event) => {
      setValue(event.target.value);
    };


    return (
        <div>
        <Grid container spacing={0}>
            <Grid item xs={6}>
            <Typography className={classes.title}>
                 Manage Cash out
            </Typography>
            </Grid>
            <Grid item xs={6}>
                <Link to ="/cashout" size="small" color="primary"  className={classes.addbutton}>
                        Go Back
                </Link>
                </Grid>
        </Grid> 
        <div className={classes.formcontainer}> 
        <Grid container spacing={0}>
            <Grid item xs={6}>
                <Typography className={classes.formtitle}>User Name</Typography>
                <TextField className={classes.forminput} placeholder="Enter User Name" variant="outlined" />
                <Typography className={classes.formtitle}>Invoice Number</Typography>
                <TextField className={classes.forminput} placeholder="Enter Invoice Number" variant="outlined" />
                <Typography className={classes.formtitle}>Transaction Amount</Typography>
                <TextField className={classes.forminput} placeholder="Enter Transaction Amount" variant="outlined" />
                <Typography className={classes.formtitle}>Ticket Priority</Typography>
                <div>
                <FormControl component="fieldset">
                <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>
                    <div style={{display:'flex',justifyContent:'space-between'}}>
                    <FormControlLabel value="Active" control={<Radio />} label="Active" />
                    <FormControlLabel value="Pending" control={<Radio />} label="Pending" />
                    <FormControlLabel value="Closed" control={<Radio />} label="Closed" />
                    </div>
                </RadioGroup>
                </FormControl>
                </div>
                <Typography className={classes.formtitle}>Ticket Priority</Typography>
                <div>
                <FormControl component="fieldset">
                <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>
                    <div style={{display:'flex',justifyContent:'space-between'}}>
                    <FormControlLabel value="Active" control={<Radio />} label="Accept" />
                    <FormControlLabel value="Pending" control={<Radio />} label="Reject" />
                    </div>
                </RadioGroup>
                </FormControl>
                </div>
                <div size="small" color="primary"  className={classes.editbutton}>
                    Save 
                </div>
            </Grid>
            <Grid item xs={6}>
                <Paper elevation={1} className={classes.cardcontainer}>
                    <div className={classes.header}>
                        <div>
                            <Typography className={classes.headertitle}>INVOICE</Typography>
                            <Typography>Invoice Number : 12112112</Typography>
                        </div>
                    </div>
                    <div className={classes.innercard}>
                        <div className={classes.innercardmain}>
                            Money Loaded
                        </div>
                        <div className={classes.innercardtitle}>
                           Transaction Related
                        </div>
                        <div className={classes.innercardsubtitle}>
                           Transaction Amt : ₹ 3000
                        </div>
                    </div>
                    <div className={classes.carddate}>
                           Created on Mar 20 2020 
                     </div>
                    <div className={classes.carddteails} style={{marginTop:20}}>
                        <div>
                            Transaction Mode :
                        </div>
                        <div style={{textAlign:'right'}}>
                            Bank Transfer
                        </div>
                    </div>
                    <div className={classes.carddteails} >
                        <div>
                            Transaction Mode :
                        </div>
                        <div style={{textAlign:'right'}}>
                            Bank Transfer
                        </div>
                    </div>
                    <div className={classes.carddteails} >
                        <div>
                            Transaction Mode :
                        </div>
                        <div style={{textAlign:'right'}}>
                            Bank Transfer
                        </div>
                    </div>
                    <div className={classes.carddteails} >
                        <div>
                            Transaction Mode :
                        </div>
                        <div style={{textAlign:'right'}}>
                            Bank Transfer
                        </div>
                    </div>
                    <div className={classes.carddteails} >
                        <div>
                            Transaction Mode :
                        </div>
                        <div style={{textAlign:'right'}}>
                            Bank Transfer
                        </div>
                    </div>
                    <div className={classes.carddteails} >
                        <div>
                            Transaction Mode :
                        </div>
                        <div style={{textAlign:'right'}}>
                            Bank Transfer
                        </div>
                    </div>

                    <div className={classes.carddteailslast} style={{borderTop:'1px solid'}} >
                        <div style={{fontWeight:600,fontSize:20}}>
                            Total :
                        </div >
                        <div style={{textAlign:'right',fontWeight:600,fontSize:20}}>
                            ₹ 1221
                        </div>
                    </div>

                </Paper>
            </Grid>
        </Grid> 
        </div>
        </div>




    )
}
