import React, {useState} from 'react';
import { useHistory } from 'react-router';
import ChatBot from 'react-simple-chatbot';
import { ThemeProvider } from 'styled-components';
import {primaryColor} from '../config/webconstants';
import Man from '../assets/website/images/man.png';

const theme = {
    background: '#f5f8fb',
    // fontFamily: 'Helvetica Neue',
    headerBgColor: primaryColor,
    headerFontColor: '#fff',
    headerFontSize: '15px',
    botBubbleColor: primaryColor,
    botFontColor: '#fff',
    userBubbleColor: '#fff',
    userFontColor: '#4a4a4a',
    height:200,
};

const ExisitingMobileNumber = [1234567890, 1234567899, 1234567892]

function Chatbot() {

    const history = useHistory();

    var languageselected ='english'

    const [language] = useState({
        english:{
            start: 'Hi, Welcome to Bigwayz?',
            Asklanguage:'What is your preferred Language?',
            language:[
                { value: 0, label: 'English', trigger:() => { languageselected = 'english'; return 'AskUserType'}},
                { value: 1, label: 'Hindi',   trigger:() => { languageselected = 'english' ; return 'AskUserType'}},
            ],

            AskUserType:'Are you a new or existing associate of Bigway',
            UserTypeOption:[   
                {value:0, label:'New', trigger:'WelcomeNew'}, 
                {value:1, label:'Exisiting', trigger:'ExistingPhoneAsk'} 
            ],

            ExistingPhoneAsk:'please enter your phone number',
            ExisitingPhoneInvalidMessage1: 'Please enter a valid phone number',
            ExisitingPhoneInvalidMessage2: 'Phone number not exists. If new User then select again',
            ExisitingMessage1:'We will get in touch with you soon.',
            ExisitingMessage2:'We will get in touch with you soon. We suggest you to contact the following number: 9113629361 or contact us at support@bigwayz.com for faster resolution',

            WelcomeNew:'Thank you for showing interest with Bigwayz',
            NewAsk:'What would you like to know',
            NewOption:[   
                {value:0, label:'Product', trigger:() => { history.push('/products'); return 'productMessage'}}, 
                {value:1, label:'Opportunities', trigger:() => { history.push('/entrepreneur'); return 'OpportunitiesMessage'}},
                {value:2, label:'Book a webinar to know more', trigger:'WebinarMessage'},
                {value:3, label:'Contact Us', trigger:() => { history.push('/contactus'); return 'ContactUsEnd'}},
                {value:4, label:'No thank you', trigger:'End'},
            ],

            productMessage: 'Thank you for showing interest. Our team will connect with you shortly regarding the products',

            OpportunitiesMessage: 'Select which Opportunitiy who want to know',
            OpportunitiesOption:[   
                {value:0, label:'Entrepreneur', trigger:() => { history.push('/entrepreneur'); return 'OpportunitiesMessage1'}}, 
                {value:1, label:'Distribution', trigger:() => { history.push('/distributor');  return 'OpportunitiesMessage1'}},
                {value:2, label:'Corporate',    trigger:() => { history.push('/corporate');    return 'OpportunitiesMessage1'}},
            ],

            OpportunitiesMessage1: 'Please share your contact number',
            OpportunitiesMessage2: 'Your Full Name',
            OpportunitiesMessage2InputInvalid: 'Name should be provided',
            OpportunitiesMessage3: 'Email ID',
            OpportunitiesMessage4: 'Pin code',
            OpportunitiesMessage5: 'Current Distribution Business',
            OpportunitiesMessage6: 'Company Name',
            OpportunitiesMessage7: 'Company website',
            
            EntrepreneurMessage: 'Thank you for showing interest. Our team will connect with you shortly regarding the Entrepreneur Program',
            DistributorMessage: 'Thank you for showing interest. Our team will connect with you shortly regarding the Distributor Program',
            CorporateMessage1: 'Greetings from Bigwayz & Thank you for showing interest in our product suite',
            CorporateMessage2: 'Our team will connect with you shortly to know more about your requirements',
            
            WebinarMessage: 'Please share your contact Details for us to reach you',
            WebinarName: 'Your Full Name',
            WebinarPhone: 'Your mobile Number',
            WebinarEndMessage: 'Thank You For Booking for Webinar',

            ContactUsEnd: 'Welcome to  Contact Us Page',

            End: 'Thanks For Visitng Bigwaz', 
            talktous: [{value:0, label:'Talk To us', trigger: 'AskUserType'}, ]

        },

        hindi: {

        }
    })


//function for existing user
    var phone = false;
    function ExisitingPhoneValidation(value){
                let count = 0;
                ExisitingMobileNumber.forEach((mob) => {
                    if(parseInt(mob) === parseInt(value)) {
                        console.log('Function to send message to contact support');
                        count = 1;
                    }
                })
                if(count === 0){
                    phone = false;
                }else{
                    phone = true;
                }
    }

//fucntion to webinar 
    function WebinarFunction(steps){
        const username = steps?.WebinarInputName?.message
        const userphonenumber = steps?.WebinarPhoneInput?.message
        console.log("function is fired off with the following data",username,userphonenumber);
    }

//Opportunities function

function Opportunitiesfunction(id, steps) {
       const fullName = steps?.OpportunitiesMessage2Input?.message
       const mobilenumber = steps?.OpportunitiesMessage1Input?.message
       const email = steps?.OpportunitiesMessage3Input?.message

        if(id == 0)
        {   
            const type = 'Entrepreneur'
            const pincode = steps?.OpportunitiesMessage4Input?.message
            console.log("Enterpreneur function is fired off -> ",type, fullName, mobilenumber, email, pincode);
        }

        else if (id == 1)
        {
            const type = 'Distribution'
            const pincode = steps?.OpportunitiesMessage4Input?.message
            const currentBusinessDistribution = steps?.OpportunitiesMessage5Input?.message
            console.log("Distribution function is fired off -> ",type, fullName, mobilenumber, email, pincode, currentBusinessDistribution );
        }

        else if (id == 2)
        {
            const type = 'Corporate'
            const CompanyName = steps?.OpportunitiesMessage6Input?.message
            const ComapanyWebsite = steps?.OpportunitiesMessage7Input?.message
            console.log("Corporate function is fired off -> ",type, fullName, mobilenumber, email, CompanyName, ComapanyWebsite );
        }
}


    const ChooselanguageStep = [
        {
            id: 'start',
            message: `${language[languageselected].start}`,
            trigger: 'Asklanguage',
        },
        {
            id: 'Asklanguage',
            message: `${language[languageselected].Asklanguage}`,
            trigger: 'language',
        },
        {
            id: 'language',
            options: [...language[languageselected].language]
        },
    ]

    const SelectUserType = [
        {
            id: 'AskUserType',
            message: `${language[languageselected].AskUserType}`,
            trigger: 'UserTypeOption',
        },
        {
            id: 'UserTypeOption',
            options: [ ...language[languageselected].UserTypeOption ],
        },
    ]

    const ExistingUserStep = [
        {
            id: 'ExistingPhoneAsk',
            message: `${language[languageselected].ExistingPhoneAsk}`,
            trigger: 'ExisitingPhone'
        },
        {
            id: 'ExisitingPhone',
            user:true, 
            validator: (value) => {
                if(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(value))
                {
                    ExisitingPhoneValidation(value);
                    return true;
                }
                else{
                    return `${language[languageselected].ExisitingPhoneInvalidMessage1}`;
                }
            },
            trigger:()=> {
                if(phone===false){
                    return 'NotExisitingPhone'
                }else{
                    return 'ExisitingMessage1'
                }
            }
        },
        {
            id: 'NotExisitingPhone',
            message: `${language[languageselected].ExisitingPhoneInvalidMessage2}`,
            trigger: 'UserTypeOption',
        },
        { 
            id: 'ExisitingMessage1',
            message: `${language[languageselected].ExisitingMessage1}`,
            trigger: 'ExisitingMessage2'
        },
        { 
            id: 'ExisitingMessage2',
            message: `${language[languageselected].ExisitingMessage2}`,
            end: true
        }
    ]

    const opportunityStep = [
        {    
            id:'OpportunitiesMessage',
            message: `${language[languageselected].OpportunitiesMessage}`,
            trigger: 'OpportunitiesOption'
        },
        {    
            id:'OpportunitiesOption',
            options: [...language[languageselected].OpportunitiesOption],
        },
        {
            id:'OpportunitiesMessage1',
            message: `${language[languageselected].OpportunitiesMessage1}`,
            trigger: 'OpportunitiesMessage1Input'
        },
        { 
            id:'OpportunitiesMessage1Input',
            user:true, 
            validator: (value) => {
                if(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(value))
                {
                    ExisitingPhoneValidation(value);
                    return true;
                }
                else{
                    return `${language[languageselected].ExisitingPhoneInvalidMessage1}`;
                }
            },
            trigger:()=> {
                if(phone===false){
                    return 'OpportunitiesMessage2'
                }else{
                    return 'ExisitingMessage1'
                }
            }
        },
        {
            id:'OpportunitiesMessage2',
            message: `${language[languageselected].OpportunitiesMessage2}`,
            trigger: 'OpportunitiesMessage2Input'
        },
        {
            id:'OpportunitiesMessage2Input',
            user: true,
            validator: (value) => {
                if(value.length <0 || value === undefined)
                {
                    return `${language[languageselected].OpportunitiesMessage2InputInvalid}`;
                }
                else{
                    return true;
                }
            },
            trigger:'OpportunitiesMessage3'
        },
        {
            id:'OpportunitiesMessage3',
            message: `${language[languageselected].OpportunitiesMessage3}`,
            trigger: 'OpportunitiesMessage3Input'
        },
        {
            id:'OpportunitiesMessage3Input',
            user: true,
            trigger:({steps}) => {
                if(steps.OpportunitiesOption.value === 2)
                {
                    return 'OpportunitiesMessage6'
                }
                else
                {
                    return 'OpportunitiesMessage4'
                }
            }
        },
        {
            id: 'OpportunitiesMessage4',
            message: `${language[languageselected].OpportunitiesMessage4}`,
            trigger: 'OpportunitiesMessage4Input'
        },
        {
            id:'OpportunitiesMessage4Input',
            user: true,
            trigger:({steps}) => {
                if(steps.OpportunitiesOption.value === 1)
                {
                    return 'OpportunitiesMessage5'
                }
                else
                {   
                    //function call
                    Opportunitiesfunction(steps.OpportunitiesOption.value, steps)
                    return 'EntrepreneurMessage'
                }
            }
        },
        {
            id: 'OpportunitiesMessage5',
            message: `${language[languageselected].OpportunitiesMessage5}`,
            trigger: 'OpportunitiesMessage5Input'
        },
        {
            id:'OpportunitiesMessage5Input',
            user: true,
            trigger: ({steps}) => {
                //function call
                Opportunitiesfunction(steps.OpportunitiesOption.value, steps)
                return 'DistributorMessage'
            }
        },
        {
            id: 'OpportunitiesMessage6',
            message: `${language[languageselected].OpportunitiesMessage6}`,
            trigger: 'OpportunitiesMessage6Input'
        },
        {
            id:'OpportunitiesMessage6Input',
            user: true,
            trigger: 'OpportunitiesMessage7'
        },
        {
            id: 'OpportunitiesMessage7',
            message: `${language[languageselected].OpportunitiesMessage7}`,
            trigger: 'OpportunitiesMessage7Input'
        },
        {
            id:'OpportunitiesMessage7Input',
            user: true,
            trigger: ({steps}) =>{
                //function call
                Opportunitiesfunction(steps.OpportunitiesOption.value, steps)
                return 'CorporateMessage1'
            }
        },
        {
            id: 'EntrepreneurMessage',
            message: `${language[languageselected].EntrepreneurMessage}`,
        },
        {
            id: 'DistributorMessage',
            message: `${language[languageselected].DistributorMessage}`,
        
        },
        {
            id: 'CorporateMessage1',
            message: `${language[languageselected].CorporateMessage1}`,
            trigger: 'CorporateMessage2'
        },
        {
            id: 'CorporateMessage2',
            message: `${language[languageselected].CorporateMessage2}`,
        }
    ]

    const WebinarStep = [
    {
        id:'WebinarMessage',
        message: `${language[languageselected].WebinarMessage}`,
        trigger: 'WebinarName'
    },
    {
        id:'WebinarName',
        message: `${language[languageselected].WebinarName}`,
        trigger: 'WebinarInputName',
    },
    {
        id:'WebinarInputName',
        user: true,
        trigger: 'WebinarPhone'
    },
    {
        id:'WebinarPhone',
        message: `${language[languageselected].WebinarPhone}`,
        trigger: 'WebinarPhoneInput'
    },
    {
        id:'WebinarPhoneInput',
        user:true, 
        validator: (value) => {
            if(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(value))
            {
                return true;
            }
            else{
                return `${language[languageselected].ExisitingPhoneInvalidMessage1}`;
            }
        },
        trigger: ({steps}) =>{
            WebinarFunction(steps);
            return 'WebinarEndMessage'
        }
    },
    { 
        id:'WebinarEndMessage',
        message: `${language[languageselected].WebinarEndMessage}`,
        trigger:'NewAsk'
    },
    ]

    const NewUserStep = [
        {
            id: 'WelcomeNew',
            message: `${language[languageselected].WelcomeNew}`,
            trigger: 'NewAsk'
        },
        {
            id: 'NewAsk',
            message: `${language[languageselected].NewAsk}`,
            trigger:'NewOption',
        },
        {
            id:'NewOption',
            options: [...language[languageselected].NewOption]
        },

        //Product
        {
            id:'productMessage',
            message: `${language[languageselected].productMessage}`,
        },
        
        //Opportunities
        ...opportunityStep,

        //Webinar
        ...WebinarStep,

        //ContactUs 
        {
            id: 'ContactUsEnd',
            message: `${language[languageselected].ContactUsEnd}`,
            end: true
        },

        //No thank You 
        {
            id: 'End',
            message: `${language[languageselected].End}`,
            trigger: 'talktous'
        },
        {
            id:'talktous',
            options: [...language[languageselected].talktous],
        }
    ]


    const steps = [
        //Welcome
        ...ChooselanguageStep,
        //selecting User
        ...SelectUserType,
        //Exisiting USer
        ...ExistingUserStep,
        //New User
        ...NewUserStep,
    ]

    return (
        <ThemeProvider theme={theme}>
            <ChatBot botAvatar={Man} style={{height:520}} steps={steps} floating ={true}/>
        </ThemeProvider>
    )
}

export default Chatbot



