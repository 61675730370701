import React, { useState, useEffect } from "react";
import MainNavBar from "../../components/MainNavBar";
import { Container } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import {
  lightGray,
  normalSize,
  primaryColor,
  secondaryColor,
  titleSize,
  whiteColor,
} from "../../config/webconstants";
import {
  Grid,
  Typography,
  Button,
  Box,
  Card,
  Divider,
} from "@material-ui/core";
import Logo from "../../assets/website/images/Bigwayzlogo.png";
import OfferVector from "../../assets/website/images/offervector.png";
import MainFooter from "../../components/MainFooter";
import Banner from "../../components/Banner";
import "aos/dist/aos.css";
import Aos from "aos";
import Amico from "../../assets/website/images/amico.png";
import Rafiki from "../../assets/website/images/paymentcorporate.png";
import InsuranceVector from "../../assets/website/images/rafiki.png";
import Boxes from "../../assets/website/images/boxes.png";
import Cuate from "../../assets/website/images/cuate.png";
import Pana from "../../assets/website/images/pana.png";
import Ecom from "../../assets/website/images/ecom.png";
import corporatecarousel from "../../assets/website/carousel/corporate.png";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

const CardContent = [
  {
    vectorImage: Pana,
    Title: "White Label Solution",
    content: [
      "Bigwayz offers white label solution of web and mobile based applications that facilitate Banking services: DMT, AEPS/Aadhaar Pay, Micro ATM, Mobile, DTH (Direct to home) recharges and Utility bill payments with E-Commerce & Insurance services. Bigwayz ensures that client-specific personalization is made to its applications basis requirements.",
    ],
  },
  {
    vectorImage: Boxes,

    Title: "E-commerce Corporate Programs",
    content: [
      "Buy products online from 100+ brands and more than 16 categories at attractive prices with a highly effective last mile delivery and customer service support system. Customized offers for corporates to run voucher programs and gifting options for employees, customers and trade partners.",
    ],
  },

  {
    vectorImage: Rafiki,
    Title: "Co-branded Prepaid Cards for corporates",
    content: [
      "Create your own Cobranded prepaid cards virtual or physical for digital payments solutions for employee’s expense management, customer engagement programs and dealer trade promotions, thereby increase your branding and customer loyalty.",
    ],
  },
  {
    vectorImage: InsuranceVector,
    Title: "Insurance Solution",
    content: [
      "Provide your employees & customers a comprehensive Health & Non-Life insurance with Suraksha Plus platform and Suraksha Care products. Get insurance quotes instantaneously for Health & Non-Life insurance to compare & give the best plans at competitive prices.",
    ],
  },
  {
    vectorImage: Ecom,
    Title: "Automation Tools",
    content: [
      "Catalogue & manage sales operations and invoicing from a single mobile app. We offer smart mobile marketing tools for promoting your products and supported with a ticketing management system. Now turn customer data into valuable insights for a smarter decision.",
    ],
  },
  {
    vectorImage: Cuate,
    Title: "Fintech Solutions",
    content: [
      "With a dedicated support, avail a wide array of services from digital payment solutions, Micro ATMs, direct money transfers & automated bill payments to save time. With one app for multiple services, service internal and external customers with multiple products & service to gain the advantage you need!",
    ],
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    flexDirection: "column",
    overflowX: "hidden",
    fontFamily: "Myraid",
    [theme.breakpoints.down("sm")]: {
      marginTop: "56px",
    },
  },
  button1: {
    color: secondaryColor,
    background: "transparent",
    margin: "0px 20px",
    width: 200,
    border: "1px solid",
    borderColor: secondaryColor,
    "&:hover": {
      color: secondaryColor,
      borderColor: secondaryColor,
      background: "transparent",
    },
  },
  button2: {
    color: whiteColor,
    borderColor: primaryColor,
    background: primaryColor,
    margin: "0px 20px",
    width: 160,
    "&:hover": {
      color: whiteColor,
      borderColor: primaryColor,
      background: primaryColor,
    },
  },
  carouselcaptions: {
    position: "absolute",
    right: "15%",
    bottom: "8.25rem",
    left: "5%",
    paddingTop: "1.25rem",
    paddingBottom: "1.25rem",
    color: "#fff",
    display: "flex",
  },
  controlicon: {
    fontSize: titleSize,
    color: primaryColor,
  },
  headertitle: {
    fontSize: 30,
    textAlign: "center",
    fontWeight: 600,
    color: "white",
    fontFamily: "Myraid",
  },
  whocontainer: {
    backgroundColor: primaryColor,
    paddingBottom: 50,
    fontFamily: "Myraid",
    [theme.breakpoints.up("md")]: {
      padding: 50,
    },
  },
  whoimage: {
    height: 60,
    width: "100%",
    objectFit: "contain",
  },
  whosubtitle: {
    fontSize: normalSize,
    fontFamily: "Myraid",
    textAlign: "center",
    margin: "20px 0px",
    fontWeight: "600",
  },
  whoicons: {
    height: 50,
    width: "100%",
    objectFit: "contain",
  },
  whoTypo: {
    color: "white",
    marginTop: "25px",
    fontSize: titleSize,
    fontFamily: "Myraid",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0px",
      padding: 20,
    },
  },
  servicebutton1: {
    fontSize: titleSize,
    fontFamily: "Myraid",
    backgroundColor: secondaryColor,
    color: whiteColor,
    fontWeight: 600,
    width: 180,
    margin: "30px 0px",
    border: "none",
    "&:hover": {
      backgroundColor: secondaryColor,
    },
  },
  servicebutton2: {
    fontSize: titleSize,
    backgroundColor: primaryColor,
    color: whiteColor,
    fontWeight: 600,
    width: 180,
    margin: "30px 0px",
    border: "none",
    "&:hover": {
      backgroundColor: primaryColor,
    },
  },
  servicecontainer: {
    margin: "40px 0px",
  },
  servicelogo: {
    height: 60,
    width: 120,
    objectFit: "contain",
  },
  serviceleft: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    fontFamily: "Myraid",
  },
  servicedesc: {
    fontSize: titleSize,
    margin: "30px 0px",
  },
  servicevector: {
    width: "100%",
    height: 350,
    objectFit: "contain",
    fontFamily: "Myraid",
    marinTop: 20,
  },
  buttoncontainer: {
    margin: "20px 20px",
    display: "flex",
    flexDirection: "row",
    fontFamily: "Myraid",
    justifyContent: "space-around",
    width: "95%",
  },
  bigbutton: {
    color: secondaryColor,
    backgroundColor: whiteColor,
    padding: "20px 60px",
    width: "20",
    borderRadius: 8,
    elevation: 3,
    fontSize: titleSize,
    fontWeight: "600",
    letterSpacing: "1px",
  },
  vectoreimage: {
    width: "100%",
    height: 200,
    objectFit: "contain",
  },
  vectortitle: {
    textAlign: "center",
    paddingTop: 20,
    fontSize: titleSize,
    fontWeight: 400,
    color: primaryColor,
    letterSpacing: 1,
    margin: "0px 15%",
  },
  bigbuttoncontainer: {
    margin: "8% 0px",
  },
  cardStyle: {
    backgroundColor: "#efefef",
    padding: "32px",
    borderRadius: "5%",
    height: "550px",
    [theme.breakpoints.down("md")]: {
      height: "750px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "650px",
    },
  },
  cardInnerStyle: {
    // backgroundColor:lightGray,
    padding: "20px",
    borderRadius: "20%",
    marginBottom: "15px",
    [theme.breakpoints.down("md")]: {
      padding: "10px",
      border: "none",
      marginBottom: "10px",
      width: "50%",
      margin: "auto",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
      border: "none",
      marginBottom: "10px",
      width: "90%",
      margin: "auto",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "10px",
      border: "none",
      boxShadow: "none",
      borderRadius: "none",
      marginBottom: "10px",
      textAlign: "center",
      width: "70%",
      margin: "auto",
    },
  },
  cardvector: {
    width: "100%",
    objectFit: "contain",
    height: "200px",
  },
  cardTypography: {
    fontSize: titleSize,
    fontFamily: "Myraid",
    fontWeight: "bold",
    textAlign: "left",
    [theme.breakpoints.down(1480)]: {
      fontSize: "30px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "30px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "40px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "30px",
    },
  },
  CardDiv: {
    margin: "40px 0px",
  },
  cardsubtypography: {
    fontSize: normalSize,
    fontFamily: "Myraid",
    marginTop: "5px",
    fontWeight: "600",
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
    },
  },
  CardGridDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "auto",
  },
  lastheadertitle: {
    fontSize: titleSize,
    textAlign: "center",
    fontWeight: "bold",

    color: primaryColor,
    fontFamily: "Myraid",
    paddingBottom: 20,
    [theme.breakpoints.down("sm")]: {
      fontSize: 22,
      padding: "10px 20px",
    },
  },
  lastcardStyle: {
    backgroundColor: "#efefef",
    padding: 30,
    borderRadius: 20,
    marginBottom: "20px",
  },
  lastgridcontainer: {
    backgroundColor: primaryColor,
    gridGap: "1px",
  },
  lastinnercard: {
    backgroundColor: "#efefef",
    padding: 20,
    boxShadow: "none",
    textAlign: "center",
    width: "calc(50% - 0.5px)",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  lastheader: {
    fontSize: titleSize,
    fontWeight: "600",
    textAlign: "center",
    fontFamily: "Myraid",
    [theme.breakpoints.down("sm")]: {
      fontSize: titleSize,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: titleSize,
    },
  },
}));

const Image = [{ img: corporatecarousel }];

export default function Home() {
  const classes = useStyles();
  useEffect(() => {
    Aos.init({
      duration: 2000,
    });
  }, []);
  return (
    <>
      <MainNavBar />
      <div className={classes.root}>
        <Banner Image={Image} showButtons={false} />

        <div className={classes.whocontainer}>
          {/* <Typography className={classes.headertitle}>Infrastructure for every Business</Typography> */}
          {/* <Typography className={classes.headertitle}>One-time investment, lifetime returns</Typography> */}
          <Container>
            <Typography
              className={classes.whoTypo}
              data-aos="fade-up"
              data-aos-duration="6000"
            >
              Bigwayz provide enterprise solution to corporates, non-banking
              institutions, industries providing customized solutions for cash
              management & payment services, online shopping & voucher programs,
              Prepaid Cards for employee expense management and Insure- tech
              platform for facilitating vehicle, health and medical insurance
              products and services for employees, customers and trade partners.
              We provide solutions to facilitate:
              <ul>
                <li data-aos="fade-up" data-aos-duration="6000">
                  White Label Solution
                </li>
                <li data-aos="fade-up" data-aos-duration="6000">
                  E-Commerce Program for Corporates
                </li>
                <li data-aos="fade-up" data-aos-duration="6000">
                  Co-Branded Prepaid Cards for Corporates
                </li>
                <li data-aos="fade-up" data-aos-duration="6000">
                  Insurance Solution
                </li>
                <li data-aos="fade-up" data-aos-duration="6000">
                  Fintech Solution
                </li>
              </ul>
            </Typography>
          </Container>
        </div>

        <div className={classes.CardDiv}>
          <Container>
            <Typography
              style={{ fontSize: 32 }}
              className={classes.lastheadertitle}
              data-aos="fade-up"
              data-aos-duration="6000"
            >
              WHY CHOOSE BIGWAYZ
            </Typography>
            <Grid container spacing={2}>
              {CardContent?.map((content, index) => (
                <Grid
                  key={index}
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  data-aos="fade-up"
                  data-aos-duration="6000"
                >
                  <Card className={classes.cardStyle}>
                    <Grid
                      container
                      spacing={4}
                      className={classes.CardGridDiv}
                      data-aos="fade-up"
                      data-aos-duration="6000"
                    >
                      <Grid item xs={12} sm={6} md={12} lg={6}>
                        <Card className={classes.cardInnerStyle}>
                          <img
                            src={content?.vectorImage}
                            alt="image"
                            className={classes.cardvector}
                          />
                        </Card>
                      </Grid>
                      <Grid item xs={12} sm={6} md={12} lg={6}>
                        <Typography
                          variant="h3"
                          className={classes.cardTypography}
                        >
                          {content?.Title}
                        </Typography>
                      </Grid>
                    </Grid>
                    {content?.content.map((c, i) => (
                      <Typography key={i} className={classes.cardsubtypography}>
                        {c}
                      </Typography>
                    ))}
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Container>
        </div>

        <div>
          {/* <Typography className={classes.lastheadertitle} data-aos="fade-up" data-aos-duration="6000">
            Technology driven customer first approach to last mile connectivity
            and solution
          </Typography> */}

          {/* <Container>
            <Card className={classes.lastcardStyle}>
              <Grid container className={classes.lastgridcontainer}>
                <Grid item className={classes.lastinnercard} data-aos="fade-up" data-aos-duration="6000">
                  <Typography className={classes.lastheader} >
                    Single Platform Reliability
                  </Typography>

                  <Typography style={{ fontFamily: "Myraid" }}>
                    We constantly add and upgrade latest features to our
                    products & services to keep you equipped with the best tools
                    and make sure your team has the full control over everything
                    at one place.
                  </Typography>
                </Grid>
                <Grid item className={classes.lastinnercard} data-aos="fade-up" data-aos-duration="6000">
                  <Typography className={classes.lastheader}>
                    Support
                  </Typography>

                  <Typography style={{ fontFamily: "Myraid" }}>
                    We help you in every step of the way with our 24*7
                    available, dedicated team of experts. Providing assistance
                    and support whenever you require.
                  </Typography>
                </Grid>
                <Grid item className={classes.lastinnercard} data-aos="fade-up" data-aos-duration="6000">
                  <Typography className={classes.lastheader}>
                    Business Automation
                  </Typography>
                  <Typography style={{ fontFamily: "Myraid" }}>
                    Automate your business from repetitive tasks using our
                    all-in-one SUPER app build with cutting edge technology.
                    From sending payment reminders to bill invoicing and much
                    more, improving efficiency and enabling better customer
                    retargeting.
                  </Typography>
                </Grid>

                <Grid item className={classes.lastinnercard} data-aos="fade-up" data-aos-duration="6000">
                  <Typography className={classes.lastheader}>
                    Personalised Insights & data points
                  </Typography>

                  <Typography style={{ fontFamily: "Myraid" }}>
                    We provide you with the most meaningful analytics and data
                    points about your business in a personalised manner to
                    enable you to make better-informed decisions.
                  </Typography>
                </Grid>

                <Grid item className={classes.lastinnercard} data-aos="fade-up" data-aos-duration="6000">
                  <Typography className={classes.lastheader}>
                    Market Penetration & customer addition
                  </Typography>

                  <Typography style={{ fontFamily: "Myraid" }}>
                    With our help, experience unhindered penetration into new
                    markets and extend your services to millions of new
                    customers spread across the country.
                  </Typography>
                </Grid>

                <Grid item className={classes.lastinnercard} data-aos="fade-up" data-aos-duration="6000">
                  <Typography className={classes.lastheader}>
                    Fully Transparent marketplace Model
                  </Typography>

                  <Typography style={{ fontFamily: "Myraid" }}>
                    We connect customers & sellers digitally in an open and
                    transparent environment, without any hidden costs of
                    business.
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Container> */}
        </div>

        <MainFooter />
      </div>
    </>
  );
}
