import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { TextField, Typography, Button } from "@material-ui/core";
import {
  primaryColor,
  secondaryColor,
  whiteColor,
} from "../../../../config/constants";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { firestore, storage } from "../../../../config/firebase";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    borderRadius:60
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  title: {
    fontSize: 30,
    color: secondaryColor,
    fontWeight: 700,
    margin: 20,
  },
  background: {
    backgroundColor: "#ffffff",
    padding: 30,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  addbutton: {
    display: "flex",
    float: "right",
    margin: 30,
    fontSize: 16,
    borderRadius: 8,
    background: secondaryColor,
    color: whiteColor,
    padding: 5,
    textAlign: "center",
    cursor: "pointer",
    textDecoration: "none",
  },
  editbutton: {
    cursor: "pointer",
    textDecoration: "none",
    marginTop: 20,
    display: "flex",
    justifyContent: "flex-start",
  },
  formcontainer: {},
  formtitle: {
    color: primaryColor,
    fontWeight: 600,
    fontSize: 16,
    paddingBottom: 10,
    paddingTop: 10,
  },
  forminput: {
    minWidth: "100%",
    borderColor: secondaryColor,
    marginRight: "10%",
    background: whiteColor,
    [theme.breakpoints.only("xs")]: {
      minWidth: "100%",
    },
  },
  imageuploaded: {
    marginBottom: 20,
    padding: 20,
    width: "90%",
    textAlign: "start",
    display: "flex",
  },
  buttons: {
    marginRight: 20,
    backgroundColor: secondaryColor,
    color: whiteColor,
  },
  savebutton: {
    width: 100,
    color: whiteColor,
    backgroundColor: secondaryColor,
    borderRadius: 8,
    padding: 8,
    textAlign: "center",
  },
}));

export default function AddNews() {
  const classes = useStyles();
  const [uploadedImage, setUploadedImage] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [fileName, setFileName] = useState();
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [phone, setPhone] = useState("");

  // const handleImageUpload =(event) =>{
  //     const file = URL.createObjectURL(event.target.files[0])
  //     setSelectedFile(file);
  // 	setIsFilePicked(true);
  //     console.log(selectedFile)
  // }

  const sendNotification = () => {
    axios
      .post(
        "https://us-central1-bigwayz-backend.cloudfunctions.net/send_notification_to_phone",
        {
          type: "alert",
          title: title,
          body: body,
          phone: phone,
        }
      )
      .then((response) => {
        console.log(response.data);
        alert('Notiticaiton sent!')
      });
  };

  function uploadImageFunction(files) {
    let file = files[0];
    setFileName(file.name);
    const uploadTask = storage.ref(`/adminImages/${file.name}`).put(file);

    uploadTask.on(
      "state_changed",
      (snapShot) => {},
      (err) => {
        console.log(err);
      },
      () => {
        storage
          .ref("adminImages")
          .child(file.name)
          .getDownloadURL()
          .then((fireBaseUrl) => {
            // getting url here
            setUploadedImage(fireBaseUrl);
            setIsFilePicked(true);
            console.log("image uploaded", fireBaseUrl);
          });
      }
    );
  }

  const handleremoveimage = () => {
    setUploadedImage(null);
    setIsFilePicked(false);
    storage
      .ref("adminImages")
      .child(fileName)
      .delete();
  };

  const usertypes = [
    { title: "All Users" },
    { title: "ECPP - Premium partner" },
    { title: "ECCP - Consultant Partner " },
    { title: "ECBP - Bigwayz Partner" },
    { title: "ECBD - Bigwayz Digipreneur" },
    { title: "ECBM - Bigwayz Merchant" },
    { title: "DCMDT - Master Distributon" },
    { title: "DCDT - Distributor" },
    { title: "DCBR - Bigwayz Retailer" },
    { title: "CCCC - Corporate Consultant" },
    { title: "CCCP - Corporate Partner" },
    { title: "CCCSP - Customer Service Point" },
  ];

  return (
    <div style={{ backgroundColor: whiteColor, padding: 20 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Typography className={classes.formtitle}>Phone Number</Typography>
          <TextField
            className={classes.forminput}
            placeholder="Enter Phone Number"
            variant="outlined"
            multiline={true}
            onChange={(e) => setPhone(e.target.value)}
            rows={1}
          />

          <Typography className={classes.formtitle}>Title</Typography>
          <TextField
            className={classes.forminput}
            placeholder="Enter Title"
            onChange={(e) => setTitle(e.target.value)}
            variant="outlined"
          />
          <Typography className={classes.formtitle}>Description</Typography>
          <TextField
            className={classes.forminput}
            placeholder="Enter Description"
            variant="outlined"
            multiline={true}
            onChange={(e) => setBody(e.target.value)}
            rows={1}
          />
        </Grid>
        {/* <Grid item xs={12} sm={6}>
          <Typography className={classes.formtitle}>Upload Image</Typography>
          <div style={{ display: "flex" }}>
            <Button
              className={classes.buttons}
              variant="contained"
              component="label"
              color={secondaryColor}
            >
              Upload File
              <input
                type="file"
                hidden
                onChange={(e) => uploadImageFunction(e.target.files)}
              />
            </Button>
            {isFilePicked == true ? (
              <Button
                variant="contained"
                className={classes.buttons}
                onClick={handleremoveimage}
              >
                Remove
              </Button>
            ) : (
              <></>
            )}
          </div>
          <img src={uploadedImage} className={classes.imageuploaded} />
        </Grid> */}
      </Grid>

      <div size="small" color="primary" className={classes.editbutton} onClick={() => sendNotification()}>
        <div className={classes.savebutton}>Send</div>
      </div>
    </div>
  );
}
