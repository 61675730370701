import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import {
  darkColor,
  darkGray,
  lightGray,
  primaryColor,
  primaryTint,
  secondaryColor,
  whiteColor,
} from "../../../config/constants";
import Avatar from "@material-ui/core/Avatar";
import Loader from "react-js-loader";
import { Link, useHistory } from "react-router-dom";
import { firestore, storage } from "../../../config/firebase";
import SearchIcon from "@material-ui/icons/Search";
import { fade } from "@material-ui/core";
import InputBase from "@material-ui/core/InputBase";
import Fuse from "fuse.js";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import axios from "axios";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  title: {
    fontSize: 24,
    color: secondaryColor,
    fontWeight: 700,
    margin: 20,
  },
  background: {
    backgroundColor: "rgba(240, 102, 105, 0.13)",
    padding: 16,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    borderRadius: 8,
    marginLeft: "10px",
    marginRight: "10px",
    marginBottom: "5px",
  },
  background1: {
    backgroundColor: "#ffffff",
    padding: 16,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    borderRadius: 8,
    marginLeft: "10px",
    marginRight: "10px",
  },
  avatartitle: {
    display: "flex",
    width: "20%",
  },
  table: {
    minWidth: 600,
  },
  tableContainer: {
    margin: "1%",
    width: "98%",
  },
  tablehead: {
    fontSize: "15px",
    fontWeight: "800",
    background: secondaryColor,
    color: "white",
  },
  icons: {
    display: "flex",
    justifyContent: "space-around",
  },
  addbutton: {
    display: "flex",
    float: "right",
    margin: 16,
    width: 110,
    background: secondaryColor,
    color: whiteColor,
    padding: 3,
    textAlign: "center",
    cursor: "pointer",
    textDecoration: "none",
  },
  editicon: {
    // marginRight:'30px',
    fill: primaryColor,
    cursor: "pointer",
  },
  deleteicon: {
    fill: secondaryColor,
    cursor: "pointer",
  },
  registeredname: {
    padding: "10px",
    fontSize: 16,
  },
  editbutton: {
    color: whiteColor,
    backgroundColor: secondaryColor,
    borderRadius: 8,
    padding: 8,
    textAlign: "center",
    margin: 5,
    cursor: "pointer",
    maxHeight: 35,
    textDecoration: "none",
  },
  date: {
    marginTop: 10,
    fontSize: 16,
    fontWeight: 300,
  },
  search: {
    position: "relative",
    display: "flex",
    float: "right",
    marginRight: 20,
    marginTop: 20,
    // width:200,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(secondaryColor, 0.15),
    "&:hover": {
      backgroundColor: fade(secondaryColor, 0.25),
    },
    marginLeft: 0,
    width: "200px",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

export default function AdminRoles() {
  const classes = useStyles();
  const [userList, setUserList] = useState([{}]);
  const [searchedUsers, setSearchedUsers] = useState([]);
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [deleteTimeStamp, setDeleteTimeStamp] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const handleClickOpen = (id) => {
    setDeleteTimeStamp(id);
    console.log(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setIsLoading(true);
    getAllRoles();
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  const renderStatus = (status) => {
    if (status == "active") {
      return (
        <div
          style={{
            backgroundColor: "green",
            padding: 2,
            color: "#ffffff",
            textAlign: "center",
            borderRadius: 8,
          }}
        >
          active
        </div>
      );
    } else if (status == "inactive") {
      return (
        <div
          style={{
            backgroundColor: "#F6BE00",
            padding: 2,
            color: "#ffffff",
            textAlign: "center",
            borderRadius: 8,
          }}
        >
          in-active
        </div>
      );
    }
  };

  const getAllRoles = () => {
    axios
      .post(
        "https://us-central1-bigwayz-backend.cloudfunctions.net/get_all_admn_roles",
        {}
      )
      .then((res) => {
        console.log(res.data);
        setUserList(res.data);
        console.log("get all users =", res.data);
      });

    // firestore.collection('adminRoles').get().then(snapshot => {
    //     const usersList = snapshot.docs.map(doc =>  {
    //         return doc.data()
    //     })
    //     setUserList(usersList)
    //     console.log('get all users =',usersList)
    // })
  };

  const handleSearch = (text) => {
    console.log("Searching....", text);
    const options = {
      isCaseSensitive: false,
      threshold: 0.3,
      keys: ["name", "status"],
    };

    const fuseCategory = new Fuse(userList, options);
    var temp = fuseCategory.search(text);
    console.log("searched leads ==", temp);
    setSearchedUsers(temp);
  };

  const handleDeleteAdmin = () => {
    console.log(deleteTimeStamp);
    axios
      .post(
        "https://us-central1-bigwayz-backend.cloudfunctions.net/delete_admin_role",
        {
          timestamp: deleteTimeStamp.toString(),
        }
      )
      .then((res) => {
        console.log(res.data);
        setIsLoading(true);
        getAllRoles();
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
        handleClose();
      });
  };

  if (isLoading) {
    return (
      <div style={{ marginTop: 200 }}>
        <Loader
          type="spinner-cub"
          bgColor={darkGray}
          title={"spinner-cub"}
          size={60}
        />
      </div>
    );
  }

  return (
    <div>
      <Grid container spacing={0}>
        <Grid item xs={6}>
          <Typography className={classes.title}>Admin Roles</Typography>
        </Grid>
        <Grid item xs={4}>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search Users"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ "aria-label": "search" }}
              onChange={(e) => {
                handleSearch(e.target.value);
              }}
            />
          </div>
        </Grid>
        <Grid item xs={1} style={{ marginLeft: "20px", marginTop: "5px" }}>
          <Button
            onClick={() => history.push("/addrole")}
            style={{ backgroundColor: primaryColor }}
            className={classes.addbutton}
            variant="contained"
          >
            Add Role
          </Button>
        </Grid>
      </Grid>

      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell className={classes.tablehead}>Sr.</TableCell>
              <TableCell align="left" className={classes.tablehead}>
                Role Name
              </TableCell>
              <TableCell align="left" className={classes.tablehead}>
                Status
              </TableCell>
              <TableCell align="left" className={classes.tablehead}>
                Created on
              </TableCell>
              <TableCell align="left" className={classes.tablehead}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {searchedUsers.length !== 0
              ? searchedUsers.map((item, index) => {
                  return (
                    <>
                      {index % 2 == 0 ? (
                        <TableRow key={item.item.name}>
                          <TableCell component="th" scope="row">
                            {index + 1}
                          </TableCell>
                          <TableCell align="left">{item.item.name}</TableCell>
                          <TableCell align="left">
                            {renderStatus(item.item.status)}
                          </TableCell>
                          <TableCell align="left">
                            {new Date(
                              parseInt(item.item.timestamp, 20)
                            ).getDate() +
                              "/" +
                              (new Date(item.item.timestamp).getMonth() + 1) +
                              "/" +
                              new Date(item.item.timestamp).getFullYear()}
                          </TableCell>

                          <TableCell align="center" className={classes.icons}>
                            <DeleteIcon
                              className={classes.deleteicon}
                              onClick={() =>
                                handleDeleteAdmin(item.item.timestamp)
                              }
                            />
                          </TableCell>
                        </TableRow>
                      ) : (
                        <TableRow key={item.item.name}>
                          <TableCell align="left">{item.item.name}</TableCell>
                          <TableCell align="left">
                            {renderStatus(item.item.status)}
                          </TableCell>
                          <TableCell align="left">
                            {new Date(
                              parseInt(item.item.timestamp, 20)
                            ).getDate() +
                              "/" +
                              (new Date(item.item.timestamp).getMonth() + 1) +
                              "/" +
                              new Date(item.item.timestamp).getFullYear()}
                          </TableCell>

                          <TableCell align="center" className={classes.icons}>
                          
                            <DeleteIcon
                              className={classes.deleteicon}
                              onClick={() =>
                                handleDeleteAdmin(item.item.timestamp)
                              }
                            />
                          </TableCell>
                        </TableRow>
                      )}
                    </>
                  );
                })
              : userList.map((row, index) => {
                  return (
                    <>
                      {index % 2 == 0 ? (
                        <TableRow key={row.name}>
                          <TableCell component="th" scope="row">
                            {index + 1}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ fontFamily: "Myraid", fontWeight: "800" }}
                          >
                            {row.name}
                          </TableCell>
                          <TableCell align="left">
                            {renderStatus(row.status)}
                          </TableCell>
                          <TableCell align="left">
                            {new Date(parseInt(row.timestamp)).getDate() +
                              "/" +
                              (new Date(parseInt(row.timestamp)).getMonth() +
                                1) +
                              "/" +
                              new Date(parseInt(row.timestamp)).getFullYear()}
                          </TableCell>
                          <TableCell align="center" className={classes.icons}>
                          
                            <DeleteIcon
                              className={classes.deleteicon}
                              onClick={() => handleClickOpen(row.timestamp)}
                            />
                          </TableCell>
                        </TableRow>
                      ) : (
                        <TableRow
                          key={row.name}
                          style={{ backgroundColor: lightGray }}
                        >
                          <TableCell component="th" scope="row">
                            {index + 1}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ fontFamily: "Myraid", fontWeight: "800" }}
                          >
                            {row.name}
                          </TableCell>
                          <TableCell align="left">
                            {renderStatus(row.status)}
                          </TableCell>
                          <TableCell align="left">
                            {new Date(parseInt(row.timestamp)).getDate() +
                              "/" +
                              (new Date(parseInt(row.timestamp)).getMonth() +
                                1) +
                              "/" +
                              new Date(parseInt(row.timestamp)).getFullYear()}
                          </TableCell>
                          <TableCell align="center" className={classes.icons}>                          
                            <DeleteIcon
                              className={classes.deleteicon}
                              onClick={() => handleClickOpen(row.timestamp)}
                            />
                          </TableCell>
                        </TableRow>
                      )}
                    </>
                  );
                })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
