import React, {useEffect, useState} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {  TextField, Typography,Button } from '@material-ui/core';
import { primaryColor, secondaryColor, whiteColor } from '../../../config/constants';
import { useTheme } from '@material-ui/core/styles';
import {Link, useHistory, useLocation} from "react-router-dom";
import { Input } from '@material-ui/core';
import Firebase from 'firebase/app';
import {firestore} from "../../../config/firebase";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'left',
        color: theme.palette.text.secondary,
        margin: 20
    },
    title:{
        fontSize:24,
        color:secondaryColor,
        fontWeight:700,
        margin:5,
        marginLeft: 20
    },
    background:{
        backgroundColor:"rgba(240, 102, 105, 0.13)",
        padding:30,
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between'
    },
    addbutton:{
        display:'flex',
        float:'right',
        margin:5,
        marginTop:5,
        width:110,
        background:secondaryColor,
        color:whiteColor,
        padding:3,
        textAlign:'center',
        cursor:'pointer',
        textDecoration:"none"
    },
    editbutton:{
        color:whiteColor,
        backgroundColor:secondaryColor,
        padding:8,
        textAlign:'center',
        marginLeft:20,
        marginTop:10,
        cursor:'pointer',
        maxHeight:35,
        textDecoration:'none',
        width:150,
    },
    formcontainer:{
        margin:20
    },
    formtitle:{
        color:primaryColor,
        fontWeight:600,
        fontSize:16,
        paddingBottom:10,
        paddingTop:10
    },
    forminput:{
        minWidth:600,
        borderColor:secondaryColor,
        background:whiteColor,
        [theme.breakpoints.only('xs')]: {
            minWidth:'100%'
        },
    },
    imageuploaded:{
        marginBottom:20,
        padding:20,
        width:'50%',
        textAlign:'start',
        display:'flex'
    },
    buttons:{
        marginLeft:20,
        backgroundColor:secondaryColor,
        color:whiteColor
    }
}));


export default function EditFAQPage() {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const [question, setQuestion] = React.useState('female');
    const [answer, setAnswer] = React.useState('female');

    useEffect(() => {
        if(location.item != null && location.item != undefined){
            setQuestion(location.item.question)
            setAnswer(location.item.answer)
        }
    },[])

    const addFAQ = () => {
        const faq = {
            question: question,
            answer: answer,
            timestamp: Date.now()
        }
        firestore.collection('faq').doc('faq').set({
            'faq_array': Firebase.firestore.FieldValue.arrayUnion(faq)
        },{merge:true})
        history.push('/editfaq')
    }

    return (
        <>
            <Paper className={classes.paper}>
                <Grid container spacing={0}>
                    <Grid item xs={6}>
                        <Typography className={classes.title}>
                            Edit FAQ
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Button onClick={() => history.push('/editfaq')} style={{backgroundColor: primaryColor}} className={classes.addbutton} variant="contained">Go Back</Button>
                    </Grid>
                </Grid>

                <div className={classes.formcontainer}>
                    <Typography className={classes.formtitle}>Question</Typography>
                    <TextField className={classes.forminput} placeholder={question} variant="outlined" onChange={(e) => setQuestion(e.target.value)} />
                    <Typography className={classes.formtitle}>Answer</Typography>
                    <TextField className={classes.forminput} placeholder={answer} variant="outlined" multiline={true} rows={8} onChange={(e) => setAnswer(e.target.value)} />

                </div>
                <Button onClick={() => addFAQ()} style={{backgroundColor: secondaryColor}} className={classes.editbutton} variant="contained">Add Question</Button>
            </Paper>
        </>

    )
}
