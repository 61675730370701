export const blackColor = '#000000'
export const whiteColor = '#ffffff'
export const primaryColor = '#F1666A'
export const primaryTint = '#b2d5d9'
export const secondaryColor = '#02BACD'
export const lightGray =  '#E2E2E2'
export const darkGray =  '#414141'
export const darkColor = '#670000'
export const lightColor = '#d34b44'
export const secondaryDarkColor = "#0098A8"

export const normalSize = 16;
export const titleSize = 20;

