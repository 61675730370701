import React,{useEffect} from 'react'
import { Grid, makeStyles,  Typography } from '@material-ui/core';
import Image from "../../assets/website/images/404.png"
import MainNavBar from '../../components/MainNavBar';
import MainFooter from '../../components/MainFooter';
import { primaryColor } from '../../config/webconstants';
import { useHistory } from 'react-router';


const useStyles = makeStyles(theme =>({
    root: {
        flex:1,
        flexDirection:'column',
        overflowX:'hidden',
        [theme.breakpoints.down('sm')]: {
            marginTop:'56px'
          },
    },
    image:{
        height:350,
        width:'100%',
        objectFit:'contain'
    },
    container:{
        padding:'5% 15%'
    },
    oops:{
        fontSize:35,
        fontFamily:"Myraid",
        fontWeight:600,
        letterSpacing:1,
        color:primaryColor
    },
    text:{
        fontSize:20,
        fontFamily:"Myraid",
        fontWeight:600,
        padding:"20px 0",
        width:'50%',
    }
}))


export default function Pagenotfound() {
    const classes = useStyles();
    const  history  = useHistory()

    useEffect(() => {
        setTimeout(() => {
            history.push("/")
        }, 5000);
    },[])


    return (
        <div className={classes.root}>
            <MainNavBar />
            <Grid container className={classes.container}>
                <Grid item xs={12} sm={6}>
                    <Typography className={classes.oops}>Oops!!</Typography>
                    <Typography className={classes.text}>We can’t seem to find the page you’re looking for.</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <img src={Image} alt="vector" className={classes.image} />
                </Grid>
            </Grid>
            <MainFooter showContact={false} />
        </div>
    )
}
