import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MainNavBar from "../../components/MainNavBar";
import MainFooter from "../../components/MainFooter";
import { Badge, Container, Grid, Typography } from "@material-ui/core";
// import BannerImage from "../../assets/website/aboutusimages/aboutusbanner.png"
import {
  blackColor,
  darkGray,
  lightGray,
  primaryColor,
  secondaryColor,
  whiteColor,
} from "../../config/webconstants";
import Avatar from "@material-ui/core/Avatar";
import "aos/dist/aos.css";
import Aos from "aos";
import Aboutus1 from "../../assets/website/aboutusimages/aboutsus1.png";
import Aboutus2 from "../../assets/website/aboutusimages/aboutus2.png";
import Aboutus3 from "../../assets/website/aboutusimages/aboutus3.png";
import Aboutus4 from "../../assets/website/aboutusimages/aboutus4.png";
import Aboutus5 from "../../assets/website/aboutusimages/aboutus5.png";
import Aboutus6 from "../../assets/website/aboutusimages/aboutus6.png";
import Aboutus7 from "../../assets/website/aboutusimages/aboutus7.png";
import Aboutus8 from "../../assets/website/aboutusimages/aboutus8.png";
import Aboutus9 from "../../assets/website/aboutusimages/aboutus9.png";
import Retail1 from "../../assets/website/aboutusimages/retail1.png";
import Retail2 from "../../assets/website/aboutusimages/retail2.png";
import BannerImage from "../../assets/website/images/aboutusbanner.png";
import Banner from "../../components/Banner";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    padding: "0px 5%",
    "&, .MuiBadge-anchorOriginBottomRightRectangle": {
      right: "30px",
      bottom: 20,
    },
    [theme.breakpoints.down("md")]: {
      padding: "0px 5px",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "56px",
    },
  },
  banner: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column-reverse",
      alignItems: "center",
    },
  },
  bannerLeft: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "auto",
    textAlign: "start",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginTop: 20,
    },
  },
  bannerright: {
    width: "50%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  bannerimage: {
    width: "100%",
    objectFit: "cover",
    height: 650,
    [theme.breakpoints.down("md")]: {
      width: "100%",
      objectFit: "contain",
      height: 300,
    },
  },
  title1: {
    color: primaryColor,
    fontSize: 30,
    paddingLeft: 30,
    fontWeight: "500",
    fontFamily: "Myraid",
    [theme.breakpoints.down("md")]: {
      fontSize: 20,
      paddingLeft: 5,
    },
  },
  title2: {
    color: secondaryColor,
    fontSize: 60,
    fontWeight: "900",
    fontFamily: "Myraid",
    [theme.breakpoints.down("md")]: {
      fontSize: 25,
    },
  },
  boxcontainer: {
    border: "1px solid",
    borderColor: secondaryColor,
    margin: "0px 5%",
    borderRadius: 8,
    backgroundColor: secondaryColor,
    marginBottom: "3%",
  },
  boxcontainerheader: {
    padding: "10px 30px",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    color: whiteColor,
    fontFamily: "Myraid",
    letterSpacing: 1,
    fontWeight: 700,
  },
  boxcontainerdesc: {
    backgroundColor: whiteColor,
    padding: "10px 2%",
    borderRadius: 8,
    fontFamily: "Myraid",
    fontWeight: "500",
  },
  headertitle: {
    fontSize: 35,
    textAlign: "center",
    fontWeight: 600,
    paddingTop: 50,
    paddingBottom: 20,
    color: primaryColor,
    fontFamily: "Myraid",
  },
  avatarcontainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "4%",
  },
  avatar: {
    width: 200,
    height: 200,
    backgroundColor: secondaryColor,
  },
  customBadge: {
    backgroundColor: lightGray,
    color: blackColor,
  },
  teammembername: {
    padding: "10px 0px",
    fontSize: 20,
    fontWeight: "600",
    fontFamily: "Myraid",
  },
  teammemberdesignation: {
    fontSize: 14,
    padding: "3px 0px",
    letterSpacing: 1,
    fontFamily: "Myraid",
  },
  reatailimage: {
    width: "100%",
    height: 200,
    objectFit: "contain",
    fontFamily: "Myraid",
  },
  retailtext: {
    marginTop: "5%",
    fontFamily: "Myraid",
  },
  retailfactortext: {
    margin: "5% 5%",
    fontFamily: "Myraid",
  },
  aboutusfooter: {
    margin: "5% 5%",
  },
  aboutusfootertext: {
    textAlign: "justify",
    fontSize: 22,
    fontFamily: "Myraid",
    fontWeight: 500,
    letterSpacing: 1,
  },
  aboutusfootertext2: {
    fontSize: 20,
    fontFamily: "Myraid",
    fontWeight: 500,
    letterSpacing: 1,
  },
}));

const Image = [{ img: BannerImage }];

export default function AboutUs() {
  const classes = useStyles();
  useEffect(() => {
    Aos.init({
      duration: 2000,
    });
  }, []);

  const teammembers = [
    {
      image: Aboutus1,
      badge: "ജോർജ്ജ്",
      name: "George Jacob",
      designation: "Chief Executive Officer",
    },
    {
      image: Aboutus4,
      badge: "ಉಮೇಶ",
      name: "Umesh C",
      designation: "Director",
    },

    {
      image: Aboutus3,
      badge: "ಆಮನ್",
      name: "Dr. Amanulla Khan",
      designation: "Director",
    },

    {
      image: Aboutus2,
      badge: "সমরজিৎ",
      name: "Samarjit Banerjea",
      designation: "Director",
    },
    {
      image: Aboutus5,
      badge: "സനൽ",
      name: "Sanal V Menon",
      designation: "Investor",
    },
    {
      image: Aboutus6,
      badge: "अश्विन",
      name: "Ashwin Gunadeep",
      designation: "Technology Lead",
    },
    // {
    //     image : Aboutus7,
    //     badge : "સુશાંત",
    //     name:'Sushant Lodaya',
    //     designation :"Manager – Finance & Operations"
    // },
    // {
    //     image : Aboutus8,
    //     badge : "Amir",
    //     name:'Amir Ali Shaik',
    //     designation :"Assistant Manager – Growth"
    // }
  ];

  const panelmembers = [
    {
      image: Aboutus9,
      badge: "Gunadeep",
      name: "Gunadeep PN",
      designation: "Mentor & Growth Architect",
    },
  ];

  return (
    <>
      <MainNavBar />
      <div className={classes.root}>
        <Banner Image={Image} showButtons={false} />

        <Typography
          className={classes.headertitle}
          data-aos="fade-up"
          data-aos-duration="6000"
        >
          OUR TEAM
        </Typography>

        <Container>
          <Grid
            container
            spacing={1}
            style={{ display: "flex", justifyContent: "center" }}
          >
            {teammembers.map((item, index) => (
              <Grid
                xs={12}
                sm={4}
                className={classes.avatarcontainer}
                data-aos="fade-up"
                data-aos-duration="6000"
              >
                <Badge
                  classes={{ badge: classes.customBadge }}
                  badgeContent={item.badge}
                  color="#e2e2e2"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                >
                  <Avatar
                    alt="Travis Howard"
                    src={item.image}
                    className={classes.avatar}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? primaryColor : secondaryColor,
                    }}
                  />
                </Badge>
                <Typography className={classes.teammembername}>
                  {item.name}
                </Typography>
                <Typography className={classes.teammemberdesignation}>
                  {item.designation}
                </Typography>
              </Grid>
            ))}
          </Grid>

          <Typography
            className={classes.headertitle}
            data-aos="fade-up"
            data-aos-duration="6000"
          >
            Advisory Panel
          </Typography>

          <Grid
            container
            spacing={3}
            style={{ display: "flex", justifyContent: "center" }}
          >
            {panelmembers.map((item, index) => (
              <Grid
                xs={12}
                sm={3}
                className={classes.avatarcontainer}
                data-aos="fade-up"
                data-aos-duration="6000"
              >
                <Badge
                  classes={{ badge: classes.customBadge }}
                  badgeContent={item.badge}
                  color="#e2e2e2"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                >
                  <Avatar
                    alt="Travis Howard"
                    src={item.image}
                    className={classes.avatar}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? primaryColor : secondaryColor,
                    }}
                  />
                </Badge>
                <Typography className={classes.teammembername}>
                  {item.name}
                </Typography>
                <Typography className={classes.teammemberdesignation}>
                  {item.designation}
                </Typography>
              </Grid>
            ))}
          </Grid>

          <Typography
            className={classes.headertitle}
            data-aos="fade-up"
            data-aos-duration="6000"
          >
            INDIAN RETAIL ECOSYSTEM
          </Typography>

          <Grid container style={{ marginTop: "5%" }}>
            <Grid xs={12} sm={4} data-aos="fade-up" data-aos-duration="6000">
              <img src={Retail1} className={classes.reatailimage} />
            </Grid>
            <Grid xs={12} sm={8} data-aos="fade-up" data-aos-duration="6000">
              <Typography className={classes.retailtext}>
                India’s domestic retail economy consists 90% of the unorganized
                sector comprising of Retailers & SME's offering various products
                & services. India’s retail economy is estimated to cross $1200
                billion and the FMCG economy in Semi Urban and rural sector is
                set to reach $150 billion by 2025.
              </Typography>
            </Grid>
            <Grid xs={12} sm={8} data-aos="fade-up" data-aos-duration="6000">
              <Typography className={classes.retailtext}>
                With the rapid rate of internet penetration & digital adoption,
                online retail experience is a high growth opportunity. The
                online consumer base increasingly comes from newer regions and
                factors besides price are emerging as key differentiators for
                retailers.
              </Typography>
            </Grid>
            <Grid xs={12} sm={4} data-aos="fade-up" data-aos-duration="6000">
              <img src={Retail2} className={classes.reatailimage} />
            </Grid>
          </Grid>
        </Container>

        <div
          className={classes.boxcontainer}
          style={{
            marginTop: "5%",
            backgroundColor: primaryColor,
            borderColor: primaryColor,
          }}
        >
          <div
            className={classes.boxcontainerheader}
            data-aos="fade-up"
            data-aos-duration="6000"
          >
            Merchants in the rural landscape face multiple challenges due to
            factors like:
          </div>
          <div
            className={classes.boxcontainerdesc}
            data-aos="fade-up"
            data-aos-duration="6000"
          >
            <ul>
              <li>Reduced Customer Walk-ins</li>
              <li>Limited Capital & Product Availability</li>
              <li>Lack of Customer Data & Infrastructure</li>
              <li>Lack of Knowledge, Skills & Training</li>
              <li>Higher Investment & Competition</li>
              <li>Rise of Online presence of competition.</li>
            </ul>
          </div>
        </div>

        <Typography
          className={classes.retailfactortext}
          data-aos="fade-up"
          data-aos-duration="6000"
        >
          The Retailer-Customer relationship of a retail store in the rural
          landscape is a key factor for the consumer base to define purchase and
          provide a traffic pattern for the retailer's & SME'S to provide
          products & services.
        </Typography>

        <div className={classes.boxcontainer}>
          <div
            className={classes.boxcontainerheader}
            data-aos="fade-up"
            data-aos-duration="6000"
          >
            The challenges faced by rural customers in the context of online
            purchase are:
          </div>
          <div
            className={classes.boxcontainerdesc}
            data-aos="fade-up"
            data-aos-duration="6000"
          >
            <ul>
              <li>
                Trust and comfort of direct touch & feel purchase is absent in
                online purchase.
              </li>
              <li>
                Relationship-based buying is absent in online buying model.
              </li>
              <li>Lack of credit facility.</li>
              <li>
                Incompatibility with App-based purchase and payment model.
              </li>
              <li>Language barriers.</li>
              <li>
                Limited physical visibility of brands in the local market place
                and glut of brands on the online marketplace.
              </li>
            </ul>
          </div>
        </div>

        <div
          className={classes.aboutusfooter}
          data-aos="fade-up"
          data-aos-duration="6000"
        >
          <Typography
            className={classes.aboutusfootertext}
            style={{ color: primaryColor }}
          >
            Bigwayz provides a full-spectrum solution for all SME's making
            choice and solution available across the country to provide products
            & services and a great retail experience. Our Partner offices are
            present at:
          </Typography>
          <Typography
            className={classes.aboutusfootertext2}
            style={{ color: secondaryColor }}
          >
           <ul>
             <li>Karnataka: Shivamogga, Chitradurga, Dharwad, Gulbarga,
            Jamkhandi, Mandya, Mysore</li>
            <li>Andhra Pradesh: Anantapur</li>
            <li>Telengana</li>
            <li>Kerala</li>
            <li>West Bengal</li>
            <li>Many more coming soon...</li>
           </ul>
          </Typography>
        </div>
      </div>
      <MainFooter showContact={false} />
    </>
  );
}
