import React, {useEffect, useState} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {  Paper, TextField, Typography } from '@material-ui/core';
import { primaryColor, secondaryColor, whiteColor } from '../../../config/constants';
import {Link,useLocation} from "react-router-dom";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { firestore } from '../../../config/firebase';
import Button from "@material-ui/core/Button";
import {useHistory} from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import Autocomplete from "@material-ui/lab/Autocomplete";


const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    title:{
        fontSize:24,
        color:secondaryColor,
        fontWeight:700,
        margin:20
    },
    background:{
        backgroundColor:"rgba(240, 102, 105, 0.13)",
        padding:30,
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between'
    },
    addbutton:{
        display:'flex',
        float:'right',
        margin:16,
        width:110,
        background:secondaryColor,
        color:whiteColor,
        padding:3,
        textAlign:'center',
        cursor:'pointer',
        textDecoration:"none"
    },
    editbutton:{
        color:whiteColor,
        backgroundColor:secondaryColor,
        padding:8,
        textAlign:'center',
        marginLeft:20,
        marginTop:10,
        cursor:'pointer',
        maxHeight:35,
        textDecoration:'none',
        width:100,
    },
    formcontainer:{
        marginLeft:20,
        marginRigth:50,
        padding:20,
        width:'90%'
    },
    formtitle:{
        color:primaryColor,
        fontWeight:600,
        fontSize:16,
        paddingBottom:10,
        paddingTop:10
    },
    forminput:{
        width:"100%",
        borderColor:secondaryColor,
        background:whiteColor,
        [theme.breakpoints.only('xs')]: {
           minWidth:'100%'
          },
    },

  }));


export default function AddAdminUser() {
    const classes = useStyles();
    const [value, setValue] = React.useState('female');
    const [userName,setUserName] = React.useState('')
    const [name,setName] = React.useState('')
    const [password,setPassword] = React.useState('')
    const [email,setEmail] = React.useState('')
    const [phone,setPhone] = React.useState('')
    const [allRoles,setAllRoles] = React.useState('')

    const [userRole,setUserRole] = React.useState('')
    const [snackBarOpen, setSnackBarOpen] = React.useState(false);
    const history = useHistory();

    useEffect(() => {
        getAllRoles();
    },[])

    const getAllRoles = () =>{
        firestore.collection('adminRoles').get().then(snapshot => {
            const usersList = snapshot.docs.map(doc =>  {
                return doc.data()
            })
            setAllRoles(usersList)
            console.log('get all users =',usersList)
        })
    }

    const addAdminUser = () => {
        console.log('selected role ==', userRole)
        firestore.collection('adminUsers').doc(userName).set({
            userName: userName,
            password: password,
            userRole: userRole.toLowerCase(),
            name: name,
            email: email,
            phone: phone,
            status: value,
            timestamp: Date.now()
        },{merge: true})
        setSnackBarOpen(true)
        history.push('/adminusers')
    }

    const handleChange = (event) => {
        setValue(event.target.value);
      };

    return (
        <div>
            <Grid container spacing={0}>
                <Grid item xs={6}>
                <Typography className={classes.title}>
                    Add Admin User
                </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Button onClick={() => history.push('/adminusers')} style={{backgroundColor: primaryColor}} className={classes.addbutton} variant="contained">Go Back</Button>
                </Grid>
            </Grid>

            <Paper className={classes.formcontainer}>
                <Grid container spacing={3}>
                  <Grid item xs={5}> 
                <Typography className={classes.formtitle} >UserName</Typography>
                <TextField className={classes.forminput} placeholder="Username" variant="outlined"  onChange={(e)=>setUserName(e.target.value)} />
                </Grid>
                 <Grid item xs={5}>
                <Typography className={classes.formtitle} >Password</Typography>
                <TextField className={classes.forminput} placeholder="Passwrod" variant="outlined"  onChange={(e)=>setPassword(e.target.value)} />
               </Grid>
               <Grid item xs={5}>
               <Typography className={classes.formtitle} >Name</Typography>
                <TextField className={classes.forminput} placeholder="Full Name" variant="outlined"  onChange={(e)=>setName(e.target.value)} />
                </Grid>
                <Grid item xs={5}>
                <Typography className={classes.formtitle} >Email</Typography>
                <TextField className={classes.forminput} placeholder="Email" variant="outlined"  onChange={(e)=>setEmail(e.target.value)} />
                 </Grid>
                   <Grid item xs={5}>
                   <Typography className={classes.formtitle} >Phone</Typography>
                <TextField className={classes.forminput} placeholder="Phone Number" variant="outlined"  onChange={(e)=>setPhone(e.target.value)} />
                   </Grid>
                   <Grid item xs={5}>
                   <Typography className={classes.formtitle} >Role</Typography>
                <Autocomplete
                    className={classes.forminput}
                    id="combo-box-demo"
                    onChange={(event, newValue) => setUserRole(newValue.name)}
                    options={allRoles}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => <TextField  className={classes.forminput}  {...params} label="Select the User Role" variant="outlined" />}
                />
                   </Grid>
                </Grid>
               
                <Typography className={classes.formtitle}>Status</Typography>

                <FormControl component="fieldset">
                <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>
                    <div style={{display:'flex'}}>
                    <FormControlLabel value="active" control={<Radio />} label="Active" />
                    <FormControlLabel value="inactive" control={<Radio   />} label="Inactive" />
                    </div>
                </RadioGroup>
                </FormControl>
                <div>
                <Button onClick={() => addAdminUser()} style={{backgroundColor: secondaryColor}} className={classes.editbutton} variant="contained">Add user</Button>
                </div>
            </Paper>
          
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={snackBarOpen}
                onClose={() => setSnackBarOpen(false)}
                message="User Added Successfully!"

            />

        </div>

    )
}
