import React,{useEffect,useState} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Container, TextField, Typography } from '@material-ui/core';
import {lightGray, primaryColor, primaryTint, secondaryColor, whiteColor} from '../../../config/constants';
import Avatar from '@material-ui/core/Avatar';
import {Link} from "react-router-dom";
import {firestore,storage,} from '../../../config/firebase';
import SearchIcon from '@material-ui/icons/Search';
import {fade} from "@material-ui/core";
import InputBase from '@material-ui/core/InputBase';
import Fuse from 'fuse.js';
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import {useHistory} from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import Firebase from "firebase";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import axios from "axios"
import Snackbar from "@material-ui/core/Snackbar";


import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Skeleton from "@material-ui/lab/Skeleton"




const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    title:{
        fontSize:24,
        color:secondaryColor,
        fontWeight:700,
        margin:20
    },
    background:{
        backgroundColor:"rgba(240, 102, 105, 0.13)",
        padding:16,
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        borderRadius: 8,
        marginLeft: '10px',
        marginRight: '10px',
        marginBottom: '5px'
    },
  
    search: {
        position: 'relative',
        display:'flex',
        float:'right',
        marginRight:20,
        marginTop:20,
        // width:200,
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(secondaryColor, 0.15),
        '&:hover': {
            backgroundColor: fade(secondaryColor, 0.25),
        },
        marginLeft: 0,
        width: '200px',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cardroot: {
        maxWidth: 345,
      },
      media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
      },
      instructions:{
          maxWidth:300,
          whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      },
      expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
        }),
      },
      expandOpen: {
        transform: 'rotate(180deg)',
      },
      editbutton:{
        color:whiteColor,
        backgroundColor:secondaryColor,
        borderRadius:8,
        padding:8,
        textAlign:'center',
        margin:5,
        cursor:'pointer',
        maxHeight:35,
        textDecoration:'none'
    },
}));


export default function Services() {
    const classes = useStyles();
    const history = useHistory();
    const [Data,setData] = useState([])
    const [expanded, setExpanded] = React.useState(false);

    const [Loading, setLoading] = useState(true)

    const handleExpandClick = () => {
      setExpanded(!expanded);
    };
    

    useEffect(() => {
        getAllServices();
    },[]);

    const getAllServices = () =>{
        axios.post('https://us-central1-bigwayz-backend.cloudfunctions.net/get_all_services', {})
        .then(response => {
            console.log(response.data,"get all services")
            setData(response.data)
            setLoading(false)
        });
    }

  

    return   Loading ? (
        <div
        className="skeleton text-center d-flex justify-content-center"
        style={{
          width: "100%",
          marginTop: "3rem",
          flexDirection:'column',
          padding:30
        }}
      >
        <Skeleton
          style={{
            background: "#efefef",
            // width:'8rem',
            // marginLeft:'5%'
            // margin:'auto'
          }}
          animation="wave"
          variant="text"
          width={'100%'}
          height={64}
        />
         <Skeleton
          style={{
            background: "#efefef",
            // width:'8rem',
            // marginLeft:'5%'
            // margin:'auto'
          }}
          animation="wave"
          variant="text"
          width={'100%'}
          height={64}
        />
         <Skeleton
          style={{
            background: "#efefef",
            // width:'8rem',
            // marginLeft:'5%'
            // margin:'auto'
          }}
          animation="wave"
          variant="text"
          width={'100%'}
          height={64}
        />
         <Skeleton
          style={{
            background: "#efefef",
            // width:'8rem',
            // marginLeft:'5%'
            // margin:'auto'
          }}
          animation="wave"
          variant="text"
          width={'100%'}
          height={64}
        />
         <Skeleton
          style={{
            background: "#efefef",
            // width:'8rem',
            // marginLeft:'5%'
            // margin:'auto'
          }}
          animation="wave"
          variant="text"
          width={'100%'}
          height={64}
        />
         <Skeleton
          style={{
            background: "#efefef",
            // width:'8rem',
            // marginLeft:'5%'
            // margin:'auto'
          }}
          animation="wave"
          variant="text"
          width={'100%'}
          height={64}
        />
         <Skeleton
          style={{
            background: "#efefef",
            // width:'8rem',
            // marginLeft:'5%'
            // margin:'auto'
          }}
          animation="wave"
          variant="text"
          width={'100%'}
          height={64}
        />
         <Skeleton
          style={{
            background: "#efefef",
            // width:'8rem',
            // marginLeft:'5%'
            // margin:'auto'
          }}
          animation="wave"
          variant="text"
          width={'100%'}
          height={64}
        />
         <Skeleton
          style={{
            background: "#efefef",
            // width:'8rem',
            // marginLeft:'5%'
            // margin:'auto'
          }}
          animation="wave"
          variant="text"
          width={'100%'}
          height={64}
        />
         <Skeleton
          style={{
            background: "#efefef",
            // width:'8rem',
            // marginLeft:'5%'
            // margin:'auto'
          }}
          animation="wave"
          variant="text"
          width={'100%'}
          height={64}
        />
         <Skeleton
          style={{
            background: "#efefef",
            // width:'8rem',
            // marginLeft:'5%'
            // margin:'auto'
          }}
          animation="wave"
          variant="text"
          width={'100%'}
          height={64}
        />
         <Skeleton
          style={{
            background: "#efefef",
            // width:'8rem',
            // marginLeft:'5%'
            // margin:'auto'
          }}
          animation="wave"
          variant="text"
          width={'100%'}
          height={64}
        />
         <Skeleton
          style={{
            background: "#efefef",
            // width:'8rem',
            // marginLeft:'5%'
            // margin:'auto'
          }}
          animation="wave"
          variant="text"
          width={'100%'}
          height={64}
        />
      </div>
      ) :  (
        <div>
            {/* <Alert severity="info">
                <AlertTitle>Search User Info</AlertTitle>
                The below Search Bar is an — <strong>universal searchbar!</strong> You can search users using their name, username, role, phone, email or current status.
            </Alert> */}
            <Grid container spacing={0}>
                <Grid item xs={6}>
                    <Typography className={classes.title}>
                        Services
                    </Typography>
                </Grid>
                <Grid item xs={4}>

                </Grid>
                {/* <Grid item xs={1} style={{marginLeft: '20px', marginTop: '5px'}}>
                    <Button onClick={handleClickOpen} style={{backgroundColor: primaryColor}} className={classes.addbutton} variant="contained">Add Bank</Button>
                </Grid> */}
            </Grid>

        <Container>
            <Grid container spacing={3}>
                {
                    Data.map(item => 
                        <Grid item xs={12} sm={3}>
                        <Card className={classes.cardroot}>
                        <CardHeader
                            title={item.name?.toUpperCase()}
                            subheader={item.title}
                        />
                        <CardMedia
                            className={classes.media}
                            image={item.image}
                            title="Paella dish"
                        />
                        <CardContent style={{minHeight:'140px',maxHeight:'140px'}}>
                            <Typography variant="body2" color="textSecondary" component="p" className={classes.instructions}>
                                Web App Links : <br></br>{ item.webapp === "" || item.webapp === undefined ? <Typography style={{color:'#551a8b'}}> not available </Typography> : <a style={{wordBreak: 'break-all'}} href="">{item.webapp}</a>}
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p" className={classes.instructions}>
                                Android Links :<br></br> { item.android === "" || item.android === undefined ? <Typography style={{color:'#551a8b'}}> not available </Typography> : <a style={{wordBreak: 'break-all'}} href="">{item.android}</a>}
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p" className={classes.instructions}>
                                Ios Links : <br></br>  {item.ios === "" || item.ios === undefined ? <Typography style={{color:'#551a8b'}}> not available </Typography> : <a style={{wordBreak: 'break-all'}} href="">{item.ios}</a>}
                            </Typography>
                        </CardContent>
                        <CardActions disableSpacing style={{display:'flex',justifyContent:'center'}}>
                            <Button variant="contained" className={classes.editbutton} onClick={()=>history.push({pathname:'/editservices',data:item})}>Edit</Button>
                            <IconButton
                            className={clsx(classes.expand, {
                                [classes.expandOpen]: expanded,
                            })}
                            onClick={handleExpandClick}
                            aria-expanded={expanded}
                            aria-label="show more"
                            >
                            <ExpandMoreIcon />
                            </IconButton>
                        </CardActions>
                        <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardContent>
                    <Typography paragraph>Instructions:</Typography>
                    <Typography paragraph>
                    {item.instructions}
                    </Typography>
                    </CardContent>
                </Collapse>
                        </Card>
                        </Grid>
                )} 
                 </Grid>

            </Container>
        
        </div>

    )
}
