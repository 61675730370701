import React,{useState} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {  TextField, Typography } from '@material-ui/core';
import { primaryColor, secondaryColor, whiteColor } from '../../../config/constants';
import {Link,useLocation} from "react-router-dom";
import { firestore } from '../../../config/firebase';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    title:{
        fontSize:30,
        color:secondaryColor,
        fontWeight:700,
        margin:20
    },
    background:{
        backgroundColor:"rgba(240, 102, 105, 0.13)",
        padding:30,
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between'
    },
    addbutton:{
        display:'flex',
        float:'right',
        margin:30,
        fontSize:16,
        borderRadius:8,
        background:secondaryColor,
        color:whiteColor,
        padding:5,
        textAlign:'center',
        cursor:'pointer',
        textDecoration:'none'
    },
    editbutton:{
        color:whiteColor,
        backgroundColor:secondaryColor,
        borderRadius:18,
        padding:8,
        textAlign:'center',
        margin:5,
        cursor:'pointer',
        maxHeight:35,
        textDecoration:'none',
        width:100,
    },
    formcontainer:{
        margin:20
    },
    formtitle:{
        color:primaryColor,
        fontWeight:600,
        fontSize:16,
        paddingBottom:10,
        paddingTop:10
    },
    forminput:{
        minWidth:600,
        borderColor:secondaryColor,
        background:whiteColor,
        [theme.breakpoints.only('xs')]: {
           minWidth:'100%'
          },
    }
  }));


export default function EditAddress() {
    const classes = useStyles();
    const location = useLocation();
    const [flat,setFlat] = useState('')
    const [locality,setLocality] = useState('')
    const [landmark,setLandmark] = useState('')
    const [streetNumber,setStreetNumber] = useState('')
    const [city,setCity] = useState('')
    const [state,setState] = useState('')
    const [pincode,setPincode] = useState('')

    const handleSubmit =() =>{
        firestore.collection("users").doc(location.phone).update({
            address: '#' + flat +", " + streetNumber +", " + locality +", " + landmark +", " + city +", " + state +", " + pincode ,
        });
        alert('successfully edited')
    }

    return (
        <div>
            <Grid container spacing={0}>
                <Grid item xs={6}>
                <Typography className={classes.title}>
                    Edit Address
                </Typography>
                </Grid>
                <Grid item xs={6}>
                <Link  to={{pathname:'/userdetails', phone:location.phone}} size="small" color="primary"  className={classes.addbutton}>
                        Go Back
                </Link>
                </Grid>
            </Grid>

            <div className={classes.formcontainer}>
                <Typography className={classes.formtitle}>Flat/Building Number</Typography>
                <TextField className={classes.forminput} placeholder="Enter Flat/Building Number" variant="outlined" onChange={(e)=>setFlat(e.target.value)} />
                <Typography className={classes.formtitle}>Street Number</Typography>
                <TextField className={classes.forminput} placeholder="Enter Street Number" variant="outlined" onChange={(e)=>setStreetNumber(e.target.value)} />
                <Typography className={classes.formtitle}>Locality</Typography>
                <TextField className={classes.forminput} placeholder="Enter Locality" variant="outlined" onChange={(e)=>setLocality(e.target.value)} />
                <Typography className={classes.formtitle}>Landmark</Typography>
                <TextField className={classes.forminput} placeholder="Enter Landmark" variant="outlined" onChange={(e)=>setLandmark(e.target.value)} />
                <Typography className={classes.formtitle}>City</Typography>
                <TextField className={classes.forminput} placeholder="Enter City" variant="outlined" onChange={(e)=>setCity(e.target.value)}/>
                <Typography className={classes.formtitle}>State</Typography>
                <TextField className={classes.forminput} placeholder="Enter State" variant="outlined" onChange={(e)=>setState(e.target.value)}/>
                <Typography className={classes.formtitle}>PinCode</Typography>
                <TextField className={classes.forminput} placeholder="Enter PinCode" variant="outlined" onChange={(e)=>setPincode(e.target.value)} />
                <div>

            </div>

            </div>
            <div size="small" color="primary"  className={classes.editbutton} onClick={handleSubmit}>
                    Save
             </div>
        </div>

    )
}
