import React, {useEffect} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {useHistory} from "react-router-dom";
import Logo from '../../assets/images/logo.jpg'
import firebase from "firebase";
import {firestore} from '../../config/firebase';
import Snackbar from "@material-ui/core/Snackbar";
import Grid from "@material-ui/core/Grid";
import sideImage from '../../assets/images/headerImageBigwayz.jpg'
import {primaryColor, secondaryColor} from "../../config/constants";
import homeback from "../../assets/images/bannerHome.jpg"
function Copyright() {
  return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © Inginomicx Technologies Pvt Ltd.\n Designed by '}
        <Link color="inherit" href="https://kraneapps.com/">
          KraneApps.com
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(https://firebasestorage.googleapis.com/v0/b/bigwayz-backend.appspot.com/o/adminImages%2FbannerHome.jpg?alt=media&token=63d4b078-afa9-4477-8963-ed8124859386)',
    // backgroundImage: sideImage,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
        theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
    width: '90px'
  },
  form: {
    width: '80%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: primaryColor
  },
}));

export default function SignInSide() {
  const classes = useStyles();
  const history = useHistory();
  const [userName, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [error, setError] = React.useState('');
  const [snackBarOpen, setSnackBarOpen] = React.useState(false);
  const { innerWidth: width, innerHeight: height } = window;

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('bigwayzUser'))
    console.log('user in dashboard =', user)
    if(user != null && user != undefined){
      history.push('/dashboard')
    }
  })

  //for snackbar
  const handleClose = () => {
    setSnackBarOpen(false)
  };

  const handleLogin = () => {
    console.log('credentials ==', userName, password)
    if(userName == '' || password == ''){
      setSnackBarOpen(true)
      return;
    }
    if(userName !== ''){
    firestore.collection('adminUsers').doc(userName).get().then(snapshot => {
      if(snapshot.data() != undefined){
        // setName(snapshot.data().fullName)
        console.log('user ==', snapshot.data())

        if(password.trim() === snapshot.data().password){
          console.log('login success',)
          localStorage.setItem('bigwayzUser', JSON.stringify(snapshot.data()));
          firestore.collection('adminUsers').doc(userName).set({
            lastLogin: Date.now(),
          }, {merge: true})
          history.push('/dashboard');
        }else{
          setSnackBarOpen(true)
        }
      }
    })
    }
    // history.push('/dashboard');
  }

  return (
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        {/*<Grid item xs={false} sm={4} md={7} className={classes.image}>*/}
        {/*  <img style={{height: height}} src={sideImage}/>*/}
        {/*</Grid>*/}
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <img src={Logo} className={classes.avatar}/>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <form className={classes.form} noValidate>
              <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Username"
                  name="email"
                  autoFocus
                  onChange={(event) => setUsername(event.target.value)}

              />
              <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  onChange={(event) => setPassword(event.target.value)}

              />
              {/* <FormControlLabel
                  control={<Checkbox value="remember" color={primaryColor} />}
                  label="Remember me"
              /> */}
              <Button
                  // type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={() => handleLogin()}
              >
                Sign In
              </Button>
              {/*<Grid container>*/}
              {/*  <Grid item xs>*/}
              {/*    <Link href="#" style={{color: secondaryColor}} variant="body2">*/}
              {/*      Forgot password?*/}
              {/*    </Link>*/}
              {/*  </Grid>*/}
              {/*  <Grid item>*/}
              {/*    <Link href="#" style={{color: secondaryColor}} variant="body2">*/}
              {/*      {"Don't have an account? Sign Up"}*/}
              {/*    </Link>*/}
              {/*  </Grid>*/}
              {/*</Grid>*/}
              <Box mt={5}>
                <Copyright />
              </Box>
            </form>
          </div>
        </Grid>
        <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            open={snackBarOpen}
            onClose={handleClose}
            message="Please check your Phone and Password!"

        />
      </Grid>
  );
}
