import React, { useState, useEffect } from "react";
import MainNavBar from "../../components/MainNavBar";
import { Container } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import {
  lightGray,
  normalSize,
  primaryColor,
  secondaryColor,
  titleSize,
  whiteColor,
} from "../../config/webconstants";
import { Grid, Typography, Button, Box, Card } from "@material-ui/core";
import Logo from "../../assets/website/images/Bigwayzlogo.png";
import BulkVector from "../../assets/website/images/bulkvector.png";
import MainFooter from "../../components/MainFooter";
import Banner from "../../components/Banner";
import "aos/dist/aos.css";
import Aos from "aos";
import Credit from "../../assets/website/images/credit.png";
import CheckBoxes from "../../assets/website/images/checkboxes.png";
import MobileLogin from "../../assets/website/images/mobilelogin.png";
import Motiv from "../../assets/website/images/motiv.png";
import carouselImage from "../../assets/website/carousel/distributor.png";
import DistributorGraph from "../../assets/website/images/distributorimage.png";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    flexDirection: "column",
    overflowX: "hidden",
    [theme.breakpoints.down("sm")]: {
      marginTop: "56px",
    },
  },
  button1: {
    color: secondaryColor,
    background: "transparent",
    margin: "0px 20px",
    width: 200,
    border: "1px solid",
    borderColor: secondaryColor,
    "&:hover": {
      color: secondaryColor,
      borderColor: secondaryColor,
      background: "transparent",
    },
  },
  button2: {
    color: whiteColor,
    borderColor: primaryColor,
    background: primaryColor,
    margin: "0px 20px",
    width: 160,
    "&:hover": {
      color: whiteColor,
      borderColor: primaryColor,
      background: primaryColor,
    },
  },
  carouselcaptions: {
    position: "absolute",
    right: "15%",
    bottom: "8.25rem",
    left: "5%",
    paddingTop: "1.25rem",
    paddingBottom: "1.25rem",
    color: "#fff",
    display: "flex",
  },
  controlicon: {
    fontSize: 50,
    color: primaryColor,
  },
  headertitle: {
    fontSize: 32,
    textAlign: "center",
    fontWeight: 600,
    color: "white",
    fontFamily: "Myraid",
  },
  whocontainer: {
    backgroundColor: primaryColor,
    paddingBottom: 50,
    paddingTop: 50,
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 20,
      paddingTop: 20,
    },
  },
  whoimage: {
    height: 60,
    width: "100%",
    objectFit: "contain",
  },
  whosubtitle: {
    fontSize: titleSize,
    textAlign: "center",
    margin: "20px 0px",
    fontWeight: "600",
    fontFamily: "Myraid",
  },
  whoicons: {
    height: 50,
    width: "100%",
    objectFit: "contain",
  },
  buttoncontainer: {
    margin: "20px 20px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    width: "95%",
  },
  bigbutton: {
    color: secondaryColor,
    backgroundColor: whiteColor,
    padding: "20px 60px",
    width: "20",
    borderRadius: 8,
    elevation: 3,
    fontSize: 20,
    fontWeight: "600",
    letterSpacing: "1px",
    fontFamily: "Myraid",
  },
  vectoreimage: {
    width: "100%",
    height: 200,
    objectFit: "contain",
  },
  vectortitle: {
    textAlign: "center",
    paddingTop: 20,
    fontSize: titleSize,
    fontWeight: 400,
    color: primaryColor,
    letterSpacing: 1,
    margin: "0px 15%",
  },
  bigbuttoncontainer: {
    margin: "8% 0px",
  },

  benefitsHeader: {
    color: primaryColor,
    fontSize: "80px",
    fontFamily: "Myraid",
  },
  befitsbuleHeader: {
    color: secondaryColor,
    fontSize: "70px",
    fontWeight: "solid",
    fontFamily: "Myraid",
  },
  benefitsBulletTypgraphy: {
    color: primaryColor,
    fontSize: titleSize,
    display: "flex",
    alignItems: "center",
    fontFamily: "Myraid",
  },

  distributionmain: {
    margin: "30px auto",
    width: "80%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "95%",
    },
  },
  distributionmainstart: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  distributonImageCard: {
    padding: "20px",
  },
  distributionImage: {
    height: 80,
    width: "100%",
    objectFit: "contain",
  },
  distributionVerticalline: {
    width: "5px",
    height: "200px",
    backgroundColor: "#F1666A",
    [theme.breakpoints.down(1160)]: {
      height: "70px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "50px",
    },
  },
  distributionHorizontalline: {
    width: "66.994%",
    height: "5px",
    backgroundColor: "#F1666A",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  distributionCardflex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    [theme.breakpoints.down(1160)]: {
      flexDirection: "column",
    },
  },
  distributionCarddiv: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  distributionCard: {
    backgroundColor: "#02BACD",
    color: "white",
    padding: "10px",
    textAlign: "center",
    width: "80%",
    borderRadius: "10px",
  },
  distributionsmallTypography: {
    fontSize: titleSize,
    fontFamily: "Myraid",
    [theme.breakpoints.down("sm")]: {
      fontSize: normalSize,
    },
  },
  distributionlargeTypography: {
    fontSize: "40px",
    fontWeight: "bold",
    fontFamily: "Myraid",
    [theme.breakpoints.down("sm")]: {
      fontSize: titleSize,
      fontWeight: "bold",
    },
  },
  DistributorMainTypographyContainer: {
    margin: "auto",
    textAlign: "center",
    fontFamily: "Myraid",
  },
  DistributorMainTypography: {
    fontSize: "50px",
    fontWeight: "bold",
    color: "#02BACD",
    fontFamily: "Myraid",
    [theme.breakpoints.down("md")]: {
      fontSize: "50px",
    },
  },
  DistributorPageContentDiv: {
    width: "80%",
    margin: "auto",
  },
  DistributorPageContentCardMain: {
    display: "flex",
    width: "80%",
    margin: "auto",
    border: "none",
    boxShadow: "none",
    marginTop: "20px",
    marginBottom: "20px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
      alignItems: "center",
      flexDirection: "column",
      paddingBottom: "10px",
      borderBottom: `2px solid #efefef`,
    },
  },
  DistributorPageContentCardMainReverse: {
    display: "flex",
    width: "80%",
    margin: "auto",
    border: "none",
    boxShadow: "none",
    marginTop: "20px",
    marginBottom: "20px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
      alignItems: "center",
      flexDirection: "column-reverse",
      paddingBottom: "10px",
      borderBottom: `2px solid #efefef`,
    },
  },
  DistributorPageContentImageCard: {
    border: "none",
    boxShadow: "none",
    width: "30%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  DistributorPageContentImage: {
    height: "200px",
    width: "100%",
    padding: "20px",
    objectFit: "contain",
    backgroundColor: "#efefef",
    borderRadius: "20px",
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "transparent",
    },
  },
  DistributorPageContentNullCard: {
    height: "200px",
    width: "10%",
    border: "none",
    boxShadow: "none",
    [theme.breakpoints.down("md")]: {
      width: "0%",
      height: 0,
    },
  },
  DistributorContentDivLeft: {
    width: "70%",
    marginLeft: "25px",
    marginTop: "20px",
    fontFamily: "Myraid",
    [theme.breakpoints.down("md")]: {
      width: "95%",
      textAlign: "center",
    },
  },
  DistributorContentDivRight: {
    width: "70%",
    marginRight: "25px",
    textAlign: "right",
    fontFamily: "Myraid",
    [theme.breakpoints.down("md")]: {
      width: "95%",
      textAlign: "center",
    },
  },
  text: {
    fontFamily: "Myraid",
    fontSize: normalSize,
    textAlign: "justify",
    fontWeight: "600",
    marginTop: 15,
    [theme.breakpoints.down("md")]: {
      padding: "12px",
    },
  },
  distributorimage: {
    width: "100%",
  },
}));

const Image = [{ img: carouselImage }];

export default function Enterpreneur() {
  const classes = useStyles();
  useEffect(() => {
    Aos.init({
      duration: 2000,
    });
  }, []);
  return (
    <>
      <MainNavBar />
      <div className={classes.root}>
        <Banner Image={Image} showOneTile={true} />

        <div className={classes.whocontainer}>
          <Typography className={classes.headertitle}>
            More you offer, More you earn!
          </Typography>
          {/* <Typography className={classes.headertitle}>One-time investment, lifetime returns</Typography>
                <Container>
                        <Typography style={{color:'white', fontSize: '18px', marginTop: '25px',fontFamily:"'Montsarry',sans-serif"}}>
                        Bigwayz offers distributors in the Telecom, FMCG, Consumer Durables, Electrical Appliances industry an opportunity to expand their business by utilizing Bigwayz’ vast network of retail stores & partner merchants. Distributors also get additional benefit of having an experienced sales team, all with an initial investment & assured high returns on it.
                        </Typography>
                </Container> */}
        </div>

        <div className={classes.distributionmain}>
          <img src={DistributorGraph} className={classes.distributorimage} />
          {/* <div className={classes.distributionmainstart}>
                        <Card className={classes.distributonImageCard}>
                            <img src={Logo} className={classes.distributionImage}/>
                        </Card>
                        <div className={classes.distributionVerticalline}></div>
                    </div> */}

          {/* <div className={classes.distributionHorizontalline}/>

                        <div className={classes.distributionCardflex}>
                            
                        <div className={classes.distributionCarddiv}>
                            <div className={classes.distributionVerticalline}></div>
                            <Card className={classes.distributionCard}>
                                    <Typography className={classes.distributionsmallTypography}>Over</Typography>
                                    <Typography  className={classes.distributionlargeTypography}>1,00,000</Typography>
                                    <Typography className={classes.distributionsmallTypography}>Distributors</Typography>
                            </Card>
                            
                        </div>
                            
                            <div className={classes.distributionCarddiv}>
                            <div className={classes.distributionVerticalline}></div>
                            <Card className={classes.distributionCard}>
                                    <Typography className={classes.distributionlargeTypography}>10+ Lakhs</Typography>
                                    <Typography className={classes.distributionsmallTypography}>retailers in 17500 </Typography>
                                    <Typography className={classes.distributionsmallTypography}>pincodes across india</Typography>
                            </Card>
                            </div>
                            
                            <div className={classes.distributionCarddiv}>
                            <div className={classes.distributionVerticalline}></div>
                            <Card className={classes.distributionCard}>
                                    <Typography className={classes.distributionsmallTypography}>Earn upto</Typography>
                                    <Typography className={classes.distributionlargeTypography}>60,000 PM</Typography>
                                    <Typography className={classes.distributionsmallTypography}>with Bigwayz</Typography>
                            </Card>
                            </div>
                        </div> */}
        </div>

        <div className={classes.DistributorMainTypographyContainer}>
          <Typography
            className={classes.DistributorMainTypography}
            data-aos="fade-up"
            data-aos-duration="6000"
          >
            PARTNERSHIP BENEFITS
          </Typography>
        </div>

        <div className={classes.DistributorPageContentDiv}></div>

        <Card
          className={classes.DistributorPageContentCardMain}
          data-aos="fade-up"
          data-aos-duration="6000"
        >
          <Card className={classes.DistributorPageContentImageCard}>
            <img src={Credit} className={classes.DistributorPageContentImage} />
          </Card>
          <div className={classes.DistributorContentDivLeft}>
            <Typography className={classes.text}>
              Bigwayz offers distributors in the Telecom, FMCG, Consumer
              Durables, Pharma & other categories the opportunity to power &
              expand to a digital business to get high returns from multiple
              revenue streams.
            </Typography>
          </div>
          <Card className={classes.DistributorPageContentNullCard}></Card>
        </Card>

        <Card
          className={classes.DistributorPageContentCardMainReverse}
          data-aos="fade-up"
          data-aos-duration="6000"
        >
          <Card className={classes.DistributorPageContentNullCard}></Card>

          <div className={classes.DistributorContentDivRight}>
            <Typography className={classes.text} style={{ marginTop: 50 }}>
              Increase network penetration, make more sales & scale your revenue
              to new heights with a virtual inventory of 100+ brands & more than
              16+ categories through Bigwayz E-Commerce platform. Service your
              customers with digital banking & insurance services by providing
              over the counter offerings.
            </Typography>
          </div>

          <Card
            className={classes.DistributorPageContentImageCard}
            data-aos="fade-up"
            data-aos-duration="6000"
          >
            <img
              src={MobileLogin}
              className={classes.DistributorPageContentImage}
            />
          </Card>
        </Card>

        <Card
          className={classes.DistributorPageContentCardMain}
          data-aos="fade-up"
          data-aos-duration="6000"
        >
          <Card className={classes.DistributorPageContentImageCard}>
            <img src={Motiv} className={classes.DistributorPageContentImage} />
          </Card>
          <div className={classes.DistributorContentDivLeft}>
            <Typography className={classes.text}>
              Multiply your sales with focused marketing through your retail
              network using automation tools. Use simple & convenient tools to
              onboard & manage retailers. Earn commissions on every transactions
              from your retailer from one or all products.
            </Typography>
          </div>
          <Card className={classes.DistributorPageContentNullCard}></Card>
        </Card>

        <Card
          className={classes.DistributorPageContentCardMainReverse}
          data-aos="fade-up"
          data-aos-duration="6000"
        >
          <Card className={classes.DistributorPageContentNullCard}></Card>

          <div className={classes.DistributorContentDivRight}>
            <Typography className={classes.text} style={{ marginTop: 50 }}>
              Grow your business with a dynamic application supported with a
              highly trained team & build a highly scalable distribution
              business from your smartphone or laptop to create a sustainable
              revenue stream.
            </Typography>
          </div>

          <Card
            className={classes.DistributorPageContentImageCard}
            data-aos="fade-up"
            data-aos-duration="6000"
          >
            <img
              src={CheckBoxes}
              className={classes.DistributorPageContentImage}
            />
          </Card>
        </Card>

        <MainFooter isDistributor={true} />
      </div>
    </>
  );
}
