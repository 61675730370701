import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { primaryColor } from '../config/webconstants';
import { Card, TextField, Typography, Button } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import axios from 'axios';

const API = axios.create({ baseURL: 'https://us-central1-bigwayz-backend.cloudfunctions.net'})


const useStyles = makeStyles(theme =>({
    ContactMainDiv:{
        background:"linear-gradient(to top, #BC4E52 70%,#F1666A)",
    },
    ContactCardDiv:{
        width: '60%', 
        margin: 'auto', 
        backgroundColor: 'transparent', 
        border: 'none', 
        boxShadow: 'none', 
        padding: '100px',
        [theme.breakpoints.down('md')]: {
            width: '90%',
            padding: '20px',
        }
    },
    ContactTextfieldDiv:{
        display: 'flex', 
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    },
    Input:{
        width: '30%',
        marginRight: '40px',
        '& label':{
            color: 'white', 
            fontSize:'18px'
        },
        '& label.Mui-focused': {
            color: 'black',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'black',
        },
        '&::-webkit-input-placeholder': { color: 'blue' }
        ,
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    InputDown:{
        marginTop:'10px',
        width: '60%',
        marginRight: '40px',
        color: 'white',
        '& label':{
            color: 'white'
        },
        '& label.Mui-focused': {
            color: 'black',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'black',
        },
        [theme.breakpoints.down('md')]: {
            width: '100%'
        }
    },
    inputtext:{
        color: 'white',
        fontFamily:"Myraid",
    },
    ContactButtondiv:{
        width: '30%',
        margin: 'auto',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    ContactButton:{
        marginTop:'40px',
        width: '100%',
        height: '50px',
        backgroundColor: '#EE6569',
        borderRadius: '30px',
        fontSize: '20px',
        fontFamily:"Myraid",
        color: 'white',
        boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.5)',
        '&:hover': {
            backgroundColor: '#EE6569'
        }
    }
}));

function ContactUs({isDistributor}) {
    const classes = useStyles();

    const [contactdata, setcontactdata] = useState({name: '', phone:'', email: '', message:'', appLink:"https://bigwayz.in/"});
    const [isent, setissent] = useState(false);

    const changeInput = (event) =>{
        setcontactdata((prevProps) => ({
            ...prevProps,
            [event.target.name]: event.target.value
        }));
    }

    const ContactSubmit = (e) =>{
        e.preventDefault();
        contactdata.appLink = `http://tx3.in/K5Bt/${contactdata.phone}`
        API.post('/website_contact_us', contactdata).then((response) => {
            console.log(response);
        });
        setissent(true);
        console.log(contactdata);
        setcontactdata({name: '', phone:'', email: '', message:'', appLink:"https://bigwayz.in/"});
        console.log('sent');
    }


    return (
        <div className={classes.ContactMainDiv}>
            <Card className={classes.ContactCardDiv} style={{}}>
                <Typography style={{color: 'white', fontSize: '40px', fontWeight: 'bold'}}>CONTACT US</Typography>
                
                <form onSubmit={ContactSubmit}>
                    <div>
                    <div className={classes.ContactTextfieldDiv} style={{}}>
                    <TextField id="standard-basic" label="Full Name" 
                        className = {classes.Input}
                        InputProps={{ className: classes.inputtext }}
                        name="name"
                        value={contactdata.name}
                        onChange={e => changeInput(e)}
                        required
                    />
                    <TextField id="standard-basic" label="Mobile Number" 
                        className = {classes.Input}
                        InputProps={{ className: classes.inputtext }}
                        name="phone"
                        value={contactdata.phone}
                        onChange={e => changeInput(e)}
                        required
                    />
                    <TextField id="standard-basic" label="Email (optional)" 
                        className = {classes.Input}
                        InputProps={{ className: classes.inputtext }}
                        name="email"
                        value={contactdata.email}
                        onChange={e => changeInput(e)}
        
                    />
                    </div>
                    
                    <TextField id="standard-basic" label={isDistributor == true? "Current Distribution Business": "Your message (optional)"} 
                        className = {classes.InputDown}
                        InputProps={{ className: classes.inputtext }}
                        name="message"
                        value={contactdata.message}
                        onChange={e => changeInput(e)}
                    />
                    </div>
                    
                    <div className={classes.ContactButtondiv}>
                    <Button type="submit" className={classes.ContactButton}>Submit <ChevronRightIcon /></Button>
                    </div>
                </form>

                {isent===true && (<Typography style={{color: 'white', fontSize: '20px', fontWeight: 'bold'}}>Thank you for your interest, We will get in touch with you soon!</Typography>)}
            </Card>
        </div>
    )
}

export default ContactUs
