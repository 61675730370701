import React,{useEffect,useState} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import {darkGray, lightGray, primaryColor, primaryTint, secondaryColor, whiteColor} from '../../../config/constants';
import Avatar from '@material-ui/core/Avatar';
import {Link} from "react-router-dom";
import Loader from "react-js-loader";

import {firestore,storage,} from '../../../config/firebase';
import SearchIcon from '@material-ui/icons/Search';
import {fade} from "@material-ui/core";
import InputBase from '@material-ui/core/InputBase';
import Fuse from 'fuse.js';
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import {useHistory} from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    title:{
        fontSize:24,
        color:secondaryColor,
        fontWeight:700,
        margin:20
    },
    background:{
        backgroundColor:"rgba(240, 102, 105, 0.13)",
        padding:16,
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        borderRadius: 8,
        marginLeft: '10px',
        marginRight: '10px',
        marginBottom: '5px'
    },
    background1:{
        backgroundColor:"#ffffff",
        padding:16,
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        borderRadius: 8,
        marginLeft: '10px',
        marginRight: '10px'
    },
    avatartitle:{
        display:'flex',
        width:'20%'
    },
    table: {
        minWidth: 600,
    },
    tableContainer:{
        margin:'1%',
        width:'98%'
    },
    tablehead:{
        fontSize:'15px',
        fontWeight:'800',
        background: secondaryColor ,
        color:'white'
    },
    icons:{
        display:'flex',
        justifyContent:'flex-end'
    },
    editicon:{
        // marginRight:'30px',
        fill:primaryColor,
        cursor:'pointer',
    },
    deleteicon:{
        fill: secondaryColor,
        cursor:'pointer',
    },
    registeredname:{
        padding:'10px',
        fontSize: 16
    },
    addbutton:{
        display:'flex',
        float:'right',
        margin:16,
        width:110,
        background:secondaryColor,
        color:whiteColor,
        padding:3,
        textAlign:'center',
        cursor:'pointer',
        textDecoration:"none"
    },
    editbutton:{
        color:whiteColor,
        backgroundColor:secondaryColor,
        borderRadius:8,
        padding:8,
        textAlign:'center',
        margin:5,
        cursor:'pointer',
        maxHeight:35,
        textDecoration:'none'
    },
    date:{
        marginTop:10,
        fontSize:16,
        fontWeight:300
    },
    search: {
        position: 'relative',
        display:'flex',
        float:'right',
        marginRight:20,
        marginTop:20,
        // width:200,
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(secondaryColor, 0.15),
        '&:hover': {
            backgroundColor: fade(secondaryColor, 0.25),
        },
        marginLeft: 0,
        width: '200px',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));


export default function AdminUserList() {
    const classes = useStyles();
    const [userList,setUserList] = useState([{}])
    const [searchedUsers,setSearchedUsers] = useState([])
    const history = useHistory();
    const [isLoading,setIsLoading] = useState(true)


    useEffect(() => {
        setIsLoading(true)
        getAllUser();
        setTimeout(() => {
            setIsLoading(false)
        }, 1000);
        
    },[]);

    const getAllUser = () =>{
        firestore.collection('adminUsers').get().then(snapshot => {
            const usersList = snapshot.docs.map(doc =>  {
                return doc.data()
            })
            setUserList(usersList)
            console.log('get all users =',usersList)
        })
    }

    const handleSearch = (text) => {
        console.log("Searching....", text);

        const options = {
            isCaseSensitive: false,
            threshold: 0.3,
            keys: [
                "name",
                "userName",
                "phone",
                "email",
                "userRole",
                "status"
            ]
        };

        const fuseCategory = new Fuse(userList, options);
        var temp = fuseCategory.search(text);
        console.log("searched leads ==",temp);
        setSearchedUsers(temp);
    }

    function timeConverter(UNIX_timestamp){
        let a = new Date(UNIX_timestamp);
        let months = ['01','02','03','04','05','06','07','08','09','10','11','12'];
        let year = a.getFullYear();
        let month = months[a.getMonth()];
        let date = a.getDate();
        let hour = a.getHours();
        let min = a.getMinutes();
        let sec = a.getSeconds();
        let time = hour + ':' + min + ' ' + date + '/' + month  ;
        return time;
    }
    console.log(timeConverter(0));

    function handleClickOpen() {

    }

    if(isLoading){
        return(
            <div style={{marginTop: 200}}>
                <Loader type="spinner-cub" bgColor={darkGray} title={"spinner-cub"} size={60} />
            </div>
        )
    }

    return (
        <div>
            <Alert severity="info">
                <AlertTitle>Search User Info</AlertTitle>
                The below Search Bar is an — <strong>universal searchbar!</strong> You can search users using their name, username, role, phone, email or current status.
            </Alert>
            <Grid container spacing={0}>
                <Grid item xs={6}>
                    <Typography className={classes.title}>
                        Admin Users
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <div className={classes.search}>
                        <div className={classes.searchIcon}>
                            <SearchIcon />
                        </div>
                        <InputBase
                            placeholder="Search Users"
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            inputProps={{ 'aria-label': 'search' }}
                            onChange={(e) => {handleSearch(e.target.value)}}
                        />
                    </div>
                </Grid>
                <Grid item xs={1} style={{marginLeft: '20px', marginTop: '5px'}}>
                    <Button onClick={() => history.push('/addadmin')} style={{backgroundColor: primaryColor}} className={classes.addbutton} variant="contained">Add User</Button>
                </Grid>
            </Grid>

            <TableContainer component={Paper} className={classes.tableContainer}>
                <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tablehead}>Sr.</TableCell>
                            <TableCell align="left" className={classes.tablehead}>Name</TableCell>
                            <TableCell align="left"className={classes.tablehead}>Username</TableCell>
                            <TableCell align="left"className={classes.tablehead}>Role</TableCell>
                            <TableCell align="left"className={classes.tablehead}>Email</TableCell>
                            <TableCell align="left"className={classes.tablehead}>Phone</TableCell>
                            <TableCell align="left"className={classes.tablehead}>Status</TableCell>
                            <TableCell align="left"className={classes.tablehead}>Last Login</TableCell>
                            <TableCell align="left"className={classes.tablehead}>Last Logout</TableCell>
                            <TableCell align="left"className={classes.tablehead}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { searchedUsers.length !== 0?
                            searchedUsers.map((item,index) => {
                                return(
                                    <>
                                        {  index% 2 == 0 ?
                                            <TableRow key={item.item.name}>
                                                <TableCell component="th" scope="row">{index+1}</TableCell>
                                                <TableCell align="left">{item.item.name}</TableCell>
                                                <TableCell align="left">{item.item.userName}</TableCell>
                                                <TableCell align="left">{item.item.userRole}</TableCell>
                                                <TableCell align="left">{item.item.email}</TableCell>
                                                <TableCell align="left">{item.item.phone}</TableCell>
                                                <TableCell align="left">{item.item.status}</TableCell>
                                                <TableCell align="left">{timeConverter(item.item.lastLogin)}</TableCell>
                                                <TableCell align="left">{timeConverter(item.item.lastLogout)}</TableCell>
                                                <TableCell align="left" className={classes.icons}>
                                                    <Link to={{pathname:'/admindetails', userName: item.item.userName}} ><EditIcon className={classes.editicon} onClick={handleClickOpen}/> </Link>
                                                    <DeleteIcon className={classes.deleteicon}/>
                                                </TableCell>
                                            </TableRow>
                                            :
                                            <TableRow key={item.item.name}>
                                                <TableCell component="th" scope="row">{index+1}</TableCell>
                                                <TableCell align="left">{item.item.name}</TableCell>
                                                <TableCell align="left">{item.item.userName}</TableCell>
                                                <TableCell align="left">{item.item.userRole}</TableCell>
                                                <TableCell align="left">{item.item.email}</TableCell>
                                                <TableCell align="left">{item.item.phone}</TableCell>
                                                <TableCell align="left">{item.item.status}</TableCell>
                                                <TableCell align="left">{timeConverter(item.item.lastLogin)}</TableCell>
                                                <TableCell align="left">{timeConverter(item.item.lastLogout)}</TableCell>
                                                <TableCell align="left" className={classes.icons}>
                                                    <Link to={{pathname:'/admindetails', userName: item.item.userName}} ><EditIcon className={classes.editicon} onClick={handleClickOpen}/> </Link>
                                                    <DeleteIcon className={classes.deleteicon}/>
                                                </TableCell>
                                            </TableRow>
                                        }
                                    </>
                                )
                            }):

                            userList.map((item,index) => {
                                return(
                                    <>
                                        {  index% 2 == 0 ?
                                            <TableRow key={item.name}>
                                                <TableCell component="th" scope="row">{index+1}</TableCell>
                                                <TableCell align="left">{item.name}</TableCell>
                                                <TableCell align="left">{item.userName}</TableCell>
                                                <TableCell align="left">{item.userRole}</TableCell>
                                                <TableCell align="left">{item.email}</TableCell>
                                                <TableCell align="left">{item.phone}</TableCell>
                                                <TableCell align="left">{item.status}</TableCell>
                                                <TableCell align="left">{timeConverter(item.lastLogin)}</TableCell>
                                                <TableCell align="left">{timeConverter(item.lastLogout)}</TableCell>
                                                <TableCell align="left" className={classes.icons}>
                                                    <Link to={{pathname:'/admindetails', userName: item.userName}} ><EditIcon className={classes.editicon} onClick={handleClickOpen}/> </Link>
                                                    <DeleteIcon className={classes.deleteicon}/>
                                                </TableCell>
                                            </TableRow>
                                            :
                                            <TableRow key={item.name} style={{backgroundColor: lightGray}}>
                                                <TableCell component="th" scope="row">{index+1}</TableCell>
                                                <TableCell align="left">{item.name}</TableCell>
                                                <TableCell align="left">{item.userName}</TableCell>
                                                <TableCell align="left">{item.userRole}</TableCell>
                                                <TableCell align="left">{item.email}</TableCell>
                                                <TableCell align="left">{item.phone}</TableCell>
                                                <TableCell align="left">{item.status}</TableCell>
                                                <TableCell align="left">{timeConverter(item.lastLogin)}</TableCell>
                                                <TableCell align="left">{timeConverter(item.lastLogout)}</TableCell>
                                                <TableCell align="left" className={classes.icons}>
                                                    <Link to={{pathname:'/admindetails', userName: item.userName}} ><EditIcon className={classes.editicon} onClick={handleClickOpen}/> </Link>
                                                    <DeleteIcon className={classes.deleteicon}/>
                                                </TableCell>
                                            </TableRow>
                                        }
                                    </>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </div>

    )
}
