import React from 'react'
import { ReactComponent as YourSvg } from '../../assets/website/images/Vector.svg';
// import Logo from "../../assets/website/images/Address-rafiki-1.gif"

export default function SVGTry() {
    return (
        <div>
          {/* <img src={Logo} style={{width:200,height:200}}alt="Loading...." /> */}
        </div>
    )
}
