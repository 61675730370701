import React, {useEffect, useState} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {  Checkbox, FormGroup, TextField, Typography } from '@material-ui/core';
import { primaryColor, secondaryColor, whiteColor } from '../../../config/constants';
import {Link,useLocation} from "react-router-dom";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { firestore,storage } from '../../../config/firebase';
import Button from "@material-ui/core/Button";
import {useHistory} from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Firebase from 'firebase/app';
import Paper from "@material-ui/core/Paper";
import InfoIcon from '@material-ui/icons/Info';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import axios from "axios"
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';


const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'left',
      color: theme.palette.text.secondary,
        margin: 20
    },
    title:{
        fontSize:24,
        color:secondaryColor,
        fontWeight:700,
        margin:5,
        marginLeft: 20
    },
    background:{
        backgroundColor:"rgba(240, 102, 105, 0.13)",
        padding:30,
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between'
    },
    addbutton:{
        display:'flex',
        float:'right',
        margin:5,
        marginTop:5,
        width:110,
        background:secondaryColor,
        color:whiteColor,
        padding:3,
        textAlign:'center',
        cursor:'pointer',
        textDecoration:"none"
    },
    editbutton:{
        color:whiteColor,
        backgroundColor:secondaryColor,
        padding:8,
        textAlign:'center',
        marginTop:10,
        cursor:'pointer',
        maxHeight:35,
        textDecoration:'none',
        width:150,
    },
    formcontainer:{
        marginLeft:20,
        marginRigth:20
    },
    formtitle:{
        color:primaryColor,
        fontWeight:600,
        fontSize:16,
        paddingBottom:10,
        paddingTop:10
    },
    forminput:{
        minWidth:600,
        borderColor:secondaryColor,
        background:whiteColor,
        [theme.breakpoints.only('xs')]: {
           minWidth:'100%'
          },
    },
    imageuploaded:{
        marginBottom:20,
        padding:20,
        width:'50%',
        textAlign:'start',
        display:'flex'
    },
    buttons:{
        marginLeft:20,
        backgroundColor:secondaryColor,
        color:whiteColor
    }

  }));


export default function EditService() {
    const classes = useStyles();
    const [value, setValue] = React.useState('');
    const [snackBarOpen, setSnackBarOpen] = React.useState(false);
    const history = useHistory();
    const location = useLocation();
    const [name,setName] = useState(location.data.name)
    const [title,setTitle] = useState(location.data.title)
    const [instruction,setInstruction] = useState(location.data.instructions)
    const [webAppLink,setWebAppLink] = useState(location.data.webapp)
    const [androidLink,setAndroidLink] = useState(location.data.android)
    const [iosLink,setiosLink] = useState(location.data.ios)
    const [uploadedImage, setUploadedImage] = useState();
    const [fileName, setFileName] = useState();
    const [attachedUrl,setAttachedUrl] = useState("")
    const [pdf,setPdf] = useState("")



    function uploadImageFunction(files) {
        let file = files[0];
        setFileName(file.name)
        const uploadTask = storage.ref(`/adminImages/${file.name}`).put(file)

        uploadTask.on('state_changed',
            (snapShot) => {
            }, (err) => {
                console.log(err)
            }, () => {
                storage.ref('adminImages').child(file.name).getDownloadURL()
                    .then(fireBaseUrl => {
                        // getting url here
                        setUploadedImage(fireBaseUrl)
                        console.log("image uploaded",fireBaseUrl)

                    })
            })
}


    useEffect(() => {
        // addCategory();
        console.log(location.data.name)
    },[])

   
    function uploadImageFunction(files) {
        let file = files[0];
        setFileName(file.name)
        console.log("image uploaded",file.name)
        if(file.name.endsWith(".pdf")){
            setPdf(true)
        }
        const uploadTask = storage.ref(`/adminImages/${file.name}`).put(file)

        uploadTask.on('state_changed',
            (snapShot) => {
            }, (err) => {
                console.log(err)
            }, () => {
                storage.ref('adminImages').child(file.name).getDownloadURL()
                    .then(fireBaseUrl => {
                        // getting url here
                        setAttachedUrl(fireBaseUrl)
                        console.log("image uploaded",fireBaseUrl)
                    })
            })
}


    const handleChangeService = () => {
        console.log(name,attachedUrl,webAppLink,iosLink,androidLink,title,instruction)

        axios.post('https://us-central1-bigwayz-backend.cloudfunctions.net/edit_service', {
            "name": name,
            "title": title,
            "instructions": instruction,
            "image": attachedUrl,
            "webapp": webAppLink,
            "android": androidLink,
            "ios": iosLink
        })
        .then(res => console.log(res.data));
        setSnackBarOpen(true)
        setTimeout(() => {
            history.push('/services')
        }, 3000);
    }



    return (
        <>
        <Paper className={classes.paper}>
            <Grid container spacing={0}>
                <Grid item xs={6}>
                <Typography className={classes.title}>
                    Edit Service {location.data.name}
                </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Button onClick={() => history.push('/services')} style={{backgroundColor: primaryColor}} className={classes.addbutton} variant="contained">Go Back</Button>
                </Grid>
            </Grid>

            <Typography className={classes.formtitle} >Name</Typography>
            <TextField className={classes.forminput} placeholder="Enter Name" variant="outlined" value={name}  onChange={(e)=>setName(e.target.value)} />

            <Typography className={classes.formtitle} >title</Typography>
            <TextField className={classes.forminput} placeholder="Enter Title" variant="outlined" value={title}  onChange={(e)=>setTitle(e.target.value)} />

            <Typography className={classes.formtitle} >Web App Link</Typography>
            <TextField className={classes.forminput}  placeholder="Enter Web App Link" variant="outlined" value={webAppLink} onChange={(e)=>setWebAppLink(e.target.value)} />

            <Typography className={classes.formtitle} >Android Link</Typography>
            <TextField className={classes.forminput}  placeholder="Enter Android Link" variant="outlined" value={androidLink}  onChange={(e)=>setAndroidLink(e.target.value)} />

            <Typography className={classes.formtitle} >Ios Link</Typography>
            <TextField className={classes.forminput}  placeholder="Enter Ios Link" variant="outlined" value={iosLink} onChange={(e)=>setiosLink(e.target.value)} />

            <Typography className={classes.formtitle} >Instructions</Typography>
            <TextField className={classes.forminput} placeholder="Enter Instructions" variant="outlined" value={instruction} onChange={(e)=>setInstruction(e.target.value)} multiline rows={4} />
            <br />
        
           { pdf === true ?
            <PictureAsPdfIcon color={primaryColor} style={{fontSize:100,color:secondaryColor}} />
            :

         <img src={attachedUrl} className={classes.imageuploaded} />
         }
            <Button
                    className={classes.buttons}
                    variant="contained"
                    component="label"
                    color={secondaryColor}
                    >
                    Upload Image
                    <input
                        type="file"
                        hidden
                        onChange={e => uploadImageFunction(e.target.files)}
                    />
                </Button>
            <br/>
            <Button onClick={() => handleChangeService()} style={{backgroundColor: secondaryColor}} className={classes.editbutton}  variant="contained">Edit Service</Button>
         
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={snackBarOpen}
                onClose={() => setSnackBarOpen(false)}
                message="Edited Successfully!"
            />
        </Paper>
            {/* <Paper className={classes.paper}>
                Information: These categories will be selected by users during the Registration process in the Bigwayz app, please keep the names simple for the users, and single word categories or category names without a space are preferred.
            </Paper> */}
        </>

    )
}
