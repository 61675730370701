import {Link} from "react-router-dom"
import {makeStyles} from "@material-ui/core/styles"
import React, {useEffect, useState} from "react"
import validator from "validator"
import {firestore, storage} from "../../../config/firebase"
import {useHistory} from "react-router-dom"
import Paper from "@material-ui/core/Paper"
import Snackbar from "@material-ui/core/Snackbar";
import {
  primaryColor,
  secondaryColor,
  whiteColor,
} from "../../../config/constants"
import Button from "@material-ui/core/Button"

const useStyles = makeStyles((theme) => ({
  supportTicketFormBlock: {
    width: "80%",
    margin: "auto",
    marginTop: "2rem",
  },
  validation: {
    background: "red",
    color: "white",
    borderRadius: "10px",
    paddingLeft: "1em",
  },
  success: {
    background: "green",
    color: "white",
    borderRadius: "10px",
    paddingLeft: "1em",
  },
  addbutton: {
    // display:'flex',
    // float:'right',
    margin: 16,
    width: 100,
    background: primaryColor,
    color: whiteColor,
    paddingTop: 3,
    paddingBottom: 3,
    textAlign: "center",
    cursor: "pointer",
    textDecoration: "none",
    borderRadius: "5px",
    border: "transparent",
  },
  imageshowcontainer: {
    display: "flex",
    justifyContent: "center",
    margin: "20px 0px",
  },
  imageshow: {
    width: "200px",
    height: "200px",
    objectFit: "contain",
  },
  backButton: {
    //  display:'inlineBlock',
    float: "right",
    margin: 16,
    width: 100,
    background: primaryColor,
    color: whiteColor,
    paddingTop: 3,
    paddingBottom: 3,
    "&:hover": {
      color: whiteColor,
    },
    textAlign: "center",
    cursor: "pointer",
    textDecoration: "none",
    borderRadius: "5px",
    // border:'transparent'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
    margin: 20,
  },
}))

function CreateSupportTicket() {
  const classes = useStyles()

  const [Category, setCategory] = useState("")

  const [Service, setService] = useState("")

  const [Remark, setRemark] = useState("")

  const [phoneNumber, setphoneNumber] = useState("")

  const [TransactionId, setTransactionId] = useState("")

  const [ValidationError, setValidationError] = useState("")

  const [Categories, setCategories] = useState([])

  const [snackBarOpen, setSnackBarOpen] = React.useState(false);

  const [Services, setServices] = useState([
    "Finz",
    "Assist",
    "Suraksha",
    "Promote Your business",
    "GST invoice",
    "others",
  ])

  const [AttachmentImageLink, setAttachmentImageLink] = useState(null)

  const [
    TicketCreatedSuccessMessage,
    setTicketCreatedSuccessMessage,
  ] = useState("")

  const [Name, setName] = useState("")

  const history = useHistory()

  useEffect(() => {
    console.log("useEffect")

    firestore
      .collection("businessCategories")
      .doc("support_categories")
      .get()
      .then((snapshot) => {
        console.log("support array", snapshot.data().support_array)
        setCategories(snapshot.data().support_array)
        //   setSubCategories(snapshot.data().support_array);
      })
  }, [])

  const makeId = () => {
    var letters = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
    ]
    var IDtext = ""
    var i = 0
    while (i < 10) {
      var letterIndex = Math.floor(Math.random() * letters.length)
      var letter = letters[letterIndex]
      IDtext = IDtext + letter
      i++
    }

    return IDtext
  }

  const CreateAttatchmentImageLink = async (image) => {
    console.log("image", image)

    if (image === (null || undefined)) {
      console.log("null") // return {sucess: false, message: 'Enter Image'};

      return setAttachmentImageLink(null)
    }

    // setRcImageLoader(true)

    const imageId = await makeId()
    const uploadTask = storage.ref(`/attachments/${imageId}`).put(image)

    uploadTask.on(
      "state_changed",
      (snapshot) => {},
      (err) => {
        // return {sucess: false, message: 'Error Occured on Image Upload'}
        setAttachmentImageLink("string")
        console.log("error", err)
      },
      () => {
        const downloadurl = storage
          .ref("attachments")
          .child(imageId)
          .getDownloadURL()
        console.log("success", downloadurl)
        downloadurl
          .then((url) => {
            console.log("url", url)
            setAttachmentImageLink(url)
            // setRcImageLoader(false)
          })
          .catch((err) => {
            console.log("err", err)
          })
      }
    )
  }

  const CreateTicket = (e) => {
    e.preventDefault()
    //    console.log('create support ticket')

    if (Name.replace(/\s/g, "").length === 0) {
      return setValidationError("please add Name")
    }

    if (isNaN(phoneNumber.replace(/\s/g, ""))) {
      console.log("phone number is not a digit")

      return setValidationError("please enter a digit ")
    }

    if (!validator.isMobilePhone(phoneNumber.replace(/\s/g, ""), "en-IN")) {
      console.log("phone number is not valid")

      return setValidationError("please enter a valid phone number")
    }

    if (phoneNumber.replace(/\s/g, "").length !== 10) {
      return setValidationError("please enter 10 digit phoneNumber")
    }

    if (Category === "null" || Category === "") {
      return setValidationError("please select category")
    }

    if (Service === "null" || Service === "") {
      return setValidationError("please select service")
    }

    if (Remark.replace(/\s/g, "").length === 0) {
      return setValidationError("please add remark")
    }

    if (AttachmentImageLink === null) {
      return setValidationError("please add attachment link")
    }

    if (TransactionId.replace(/\s/g, "").length === 0) {
      return setValidationError("please create transaction Id")
    }

    console.log("validation checked calling api ")

    console.log(
      "details",
      Name,
      Service,
      AttachmentImageLink,
      phoneNumber,
      Category,
      TransactionId,
      Remark
    )

    const timestamp = Date.now()
    var letters = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
    ]
    var IDtext = ""
    var i = 0
    while (i < 10) {
      var letterIndex = Math.floor(Math.random() * letters.length)
      var letter = letters[letterIndex]
      IDtext = IDtext + letter
      i++
    }

    const supportRef = firestore.collection("support").doc(IDtext)

    supportRef
      .set(
        {
          name: Name,
          phone: phoneNumber.replace(/\s/g, ""),
          category: Category,
          service: Service,
          attachment: AttachmentImageLink,
          ticketId: IDtext,
          transactionId: TransactionId,
          status: "open",
          createdAt: timestamp.toString(),
          remarks: Remark,
        },
        {merge: true}
      )
      .then(() => {
        console.log("ticket created successfully")

        setValidationError("")
        // setTicketCreatedSuccessMessage("ticket created successfully")
        setSnackBarOpen(true)
        setTimeout(() => {
          history.push("/supportticket")
        }, 3000)
      })
      .catch((error) => {
        // The write failed...
        console.log(error)
      })
  }

  return (
    <Paper className={classes.paper}>
      <Link
        variant="contained"
        className={classes.backButton}
        to={`/supportticket`}
      >
        {" "}
        Go Back{" "}
      </Link>

      <div className={classes.supportTicketFormBlock}>
        {ValidationError && (
          <p className={classes.validation}> {ValidationError} </p>
        )}

        {TicketCreatedSuccessMessage && (
          <p className={classes.success}> {TicketCreatedSuccessMessage} </p>
        )}

        <form>
          <div class="mb-3">
            <label for="exampleInputEmail1" class="form-label">
              {" "}
              Name{" "}
            </label>
            <input
              type="text"
              class="form-control"
              id="Name"
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          <div class="mb-3">
            <label for="exampleInputEmail1" class="form-label">
              {" "}
              Phone Number{" "}
            </label>
            <input
              type="text"
              class="form-control"
              id="Phone"
              onChange={(e) => setphoneNumber(e.target.value)}
            />
          </div>

          <div class="mb-3">
            <select
              class="form-select"
              aria-label="Default select example"
              onClick={(e) => setCategory(e.target.value)}
            >
              <option value="null">Select Category</option>
              {Categories.length > 0 &&
                Categories.map((ER) => (
                  <option key={ER} value={ER}>
                    {" "}
                    {ER}{" "}
                  </option>
                ))}
            </select>
          </div>

          <div class="mb-3">
            <select
              class="form-select"
              aria-label="Default select example"
              onClick={(e) => setService(e.target.value)}
            >
              <option value="null">Select Service</option>
              {Services.length > 0 &&
                Services.map((ER) => (
                  <option key={ER} value={ER}>
                    {" "}
                    {ER}{" "}
                  </option>
                ))}
            </select>
          </div>

          <div class="mb-3">
            <label for="exampleInputEmail1" class="form-label">
              {" "}
              Select Attachment{" "}
            </label>
            <input
              type="file"
              class="form-control"
              id="AttachmentImageLink"
              onChange={(e) => CreateAttatchmentImageLink(e.target.files[0])}
            />
          </div>

          {AttachmentImageLink !== null ? (
            <div className={classes.imageshowcontainer}>
              <img className={classes.imageshow} src={AttachmentImageLink} />
            </div>
          ) : (
            <></>
          )}

          <div class="mb-3">
            <label for="exampleInputEmail1" class="form-label">
              {" "}
              Create Remark{" "}
            </label>
            <input
              type="text"
              class="form-control"
              id="remark"
              onChange={(e) => setRemark(e.target.value)}
            />
          </div>

          <div class="mb-3">
            <label for="exampleInputEmail1" class="form-label">
              {" "}
              Create Transaction id{" "}
            </label>
            <input
              type="text"
              class="form-control"
              onChange={(e) => setTransactionId(e.target.value)}
            />
          </div>

          <Button
            variant="contained"
            type="submit"
            class={classes.addbutton}
            onClick={(e) => CreateTicket(e)}
          >
            Submit
          </Button>
        </form>
      </div>


      <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={snackBarOpen}
      onClose={() => setSnackBarOpen(false)}
      message="Added Successfully!"
  />

    </Paper>
  )
}

export default CreateSupportTicket
