import React from "react";
import "./App.css";
import Login from "./pages/SignIn";
import Dashboard from "./pages/AdminDashboard/index";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages/website/Home";
import "bootstrap/dist/css/bootstrap.min.css";
import SVGTry from "./pages/website/SVGTry";
import Products from "./pages/website/products";
import Calculator from "./pages/website/calculator";
import Corporation from "./pages/website/CorporatePage";
import Enterpreneur from "./pages/website/Enterpreneur";
import Distributor from "./pages/website/Distributor";
import AboutUs from "./pages/website/aboutus";
import ContactUs from "./pages/website/contactus";
import Chatbot from "./components/Chatbot";
import Insurance from "./pages/website/insurance";
import Pagenotfound from "./pages/website/pagenotfound";
import Privacypolicy from "./pages/website/privacypolicy";
import Registration from "./pages/website/Registration";
import { VerticalLinearStepper } from "./pages/website/VerticalStepper";
import BusinessFinance from "./pages/website/businessFinance";
import InfintyPage from "./pages/website/InfintyPage";
import Terms from "./pages/website/terms";

function App() {
  return (
    <Router>
      <div>
        <Switch>
          <Route exact component={Home} path="/" />
          <Route exact component={Login} path="/admin_inginomicxtechnologies_v1.0" />
          <Route exact component={Dashboard} path="/dashboard" />
          <Route exact component={SVGTry} path="/svg" />
          <Route exact component={Products} path="/products" />
          <Route exact component={Calculator} path="/calculator" />
          <Route exact component={Corporation} path="/corporate" />
          <Route exact component={Enterpreneur} path="/entrepreneur" />
          <Route exact component={Distributor} path="/distributor" />
          <Route exact component={AboutUs} path="/aboutus" />
          <Route exact component={ContactUs} path="/contactus" />
          <Route exact component={Insurance} path="/suraksha" />
          <Route exact component={Privacypolicy} path="/privacypolicy" />
          <Route exact component={Terms} path="/TermsAndConditions" />

          {/* <Route exact component={BusinessFinance} path="/infinity1" /> */}
          <Route exact component={InfintyPage} path="/moneytime" />

          <Route exact component={Pagenotfound} path="" />

          <Route exact component={Registration} path="/register" />
          <Route
            exact
            component={VerticalLinearStepper}
            path="/VerticalStepper"
          />
        </Switch>
        {/* <Chatbot /> */}
      </div>
    </Router>
  );
}

export default App;
