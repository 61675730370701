import React,{useState} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {  TextField, Typography,Button } from '@material-ui/core';
import { primaryColor, secondaryColor, whiteColor } from '../../../config/constants';
import { useTheme } from '@material-ui/core/styles';
import {Link} from "react-router-dom";
import { Input } from '@material-ui/core';
import firebase from "firebase";
import {firestore,storage} from '../../../config/firebase';
import {useHistory} from "react-router-dom"



const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    title:{
        fontSize:30,
        color:secondaryColor,
        fontWeight:700,
        margin:20
    },
    background:{
        backgroundColor:"rgba(240, 102, 105, 0.13)",
        padding:30,
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between'
    },
    addbutton:{
        display:'flex',
        float:'right',
        margin:30,
        fontSize:16,
        borderRadius:8,
        background:secondaryColor,
        color:whiteColor,
        padding:5,
        textAlign:'center',
        cursor:'pointer',
        textDecoration:'none'
    },
    editbutton:{
        color:whiteColor,
        backgroundColor:secondaryColor,
        borderRadius:18,
        padding:8,
        textAlign:'center',
        margin:5,
        cursor:'pointer',
        maxHeight:35,
        textDecoration:'none',
        width:100,
    },
    formcontainer:{
        margin:20
    },
    formtitle:{
        color:primaryColor,
        fontWeight:600,
        fontSize:16,
        paddingBottom:10,
        paddingTop:10
    },
    forminput:{
        minWidth:600,
        borderColor:secondaryColor,
        background:whiteColor,
        [theme.breakpoints.only('xs')]: {
           minWidth:'100%'
          },
    },
    imageuploaded:{
        marginBottom:20,
        padding:20,
        width:'50%',
        textAlign:'start',
        display:'flex'
    },
    buttons:{
        marginLeft:20,
        backgroundColor:secondaryColor,
        color:whiteColor
    }
  }));


export default function AddNews() {
    const classes = useStyles();
    const [uploadedImage, setUploadedImage] = useState();
	const [isFilePicked, setIsFilePicked] = useState(false);
    const [fileName, setFileName] = useState();
    const [title,setTitle] = useState('')
    const [description,setDescription] = useState('')
    const [service,setService] = useState('')
    
    const history = useHistory()

    const [Services,setServices] = useState([
' all',
'latest',
 'digitalbanking',
'ecommerce',
'insurance',
    ])

    function uploadImageFunction(files) {
        let file = files[0];
        setFileName(file.name)
        const uploadTask = storage.ref(`/adminImages/${file.name}`).put(file)

        uploadTask.on('state_changed',
            (snapShot) => {
            }, (err) => {
                console.log(err)
            }, () => {
                storage.ref('adminImages').child(file.name).getDownloadURL()
                    .then(fireBaseUrl => {
                        // getting url here
                        setUploadedImage(fireBaseUrl)
                        setIsFilePicked(true)
                        console.log("image uploaded",fireBaseUrl)

                    })
            })
}

    const handleremoveimage =() =>{
        setUploadedImage(null)
        setIsFilePicked(false)
        storage.ref('adminImages').child(fileName).delete()
    }

    const handleAdd = () =>{

        if (service === "null" || service === "") {
       return     alert('please select service')
          }

          if(title.replace(/\s/g, "").length === 0) {

            return     alert('please add title')
          }
      
          if(description.replace(/\s/g, "").length === 0) {

            return     alert('please add description')
          }

          if(!isFilePicked) {

            return     alert('please add image')

          }
      
          console.log('ser',service)

        firestore.collection('newsFeed').doc(Date.now().toString()).set({
                    title: title,
                    description: description,
                    image: uploadedImage,
                    service: service
            },{merge: true}).then((succs)=>{


                alert('added successfully')


                setTimeout(()=>{

                    history.push('/newsfeed')

                },2000)



            })

   
    }
    return (
        <div>
            <Grid container spacing={0}>
                <Grid item xs={6}>
                <Typography className={classes.title}>
                   Add News Feed
                </Typography>
                </Grid>
                <Grid item xs={6}>
                <Link to ="/newsfeed" size="small" color="primary"  className={classes.addbutton}>
                        Go Back
                </Link>
                </Grid>

            </Grid>
            <div className={classes.formcontainer}>
                <Typography className={classes.formtitle}>Title</Typography>
                <TextField className={classes.forminput} placeholder="Enter Title" variant="outlined" onChange={(e)=>setTitle(e.target.value)} />
                <Typography className={classes.formtitle}>Description</Typography>
                <TextField className={classes.forminput} placeholder="Enter Description" variant="outlined" multiline={true} rows={5}  onChange={(e)=>setDescription(e.target.value)} />
            

                <Grid item xs={5} className="mt-2">

                <div class="mb-3">
                <select
                  class="form-select"
                  aria-label="Default select example"
                  onClick={(e) => setService(e.target.value)}
                >
                  <option value="null">Select service</option>
                  {Services.length > 0 &&
                    Services.map((ER) => (
                      <option key={ER} value={ER}>
                        {" "}
                        {ER}{" "}
                      </option>
                    ))}
                </select>
              </div>

              </Grid>

                <Typography className={classes.formtitle}>Upload Image</Typography>
                <div style={{display:'flex'}}>
                <Button
                    className={classes.buttons}
                    variant="contained"
                    component="label"
                    color={secondaryColor}
                    >
                    Upload File
                    <input
                        type="file"
                        hidden
                        onChange={e => uploadImageFunction(e.target.files)}
                    />
                </Button>
            { isFilePicked == true ?
                <Button variant="contained" className={classes.buttons} onClick={handleremoveimage} >
                    Remove
                </Button>
                :
                <></>
            }
                </div>
                <img src={uploadedImage} className={classes.imageuploaded} />
            </div>
            <div size="small" color="primary"  className={classes.editbutton} onClick ={handleAdd}>
                    Save
             </div>
        </div>

    )
}
