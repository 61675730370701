import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MainNavBar from "../../components/MainNavBar";
import MainFooter from "../../components/MainFooter";
import Banner from "../../components/Banner";
import { Container, Grid } from "@material-ui/core";
import "aos/dist/aos.css";
import Aos from "aos";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FinzProduct from "../../assets/website/images/finzproduct.png";
import SurakshaProduct from "../../assets/website/images/surakshaproduct.png";
import AssistProduct from "../../assets/website/images/assistproduct.png";
import PromoteProduct from "../../assets/website/images/promoteproduct.png";
import PrepaidProduct from "../../assets/website/images/prepaidcardsproduct.png";
import {
  primaryColor,
  secondaryColor,
  whiteColor,
} from "../../config/webconstants";
import Product1 from "../../assets/website/carousel/product1.png";
import Product2 from "../../assets/website/carousel/product2.png";
import Product3 from "../../assets/website/carousel/product3.png";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { useLocation, useHistory } from "react-router";
import LogoSlider from "../../components/LogoSlider";
import VectorImage from "../../assets/website/Insurance/hospicash.png";
import HomeAppliancesImage from "../../assets/website/productPageImages/appliances.png";
import BabyProductsImage from "../../assets/website/productPageImages/baby-products.png";
import BackPackImage from "../../assets/website/productPageImages/backpack.png";
import BikeImage from "../../assets/website/productPageImages/bike-helmet.png";
import CosmeticsImage from "../../assets/website/productPageImages/cosmetics.png";
import FashionImage from "../../assets/website/productPageImages/fashion.png";
import FoodImage from "../../assets/website/productPageImages/food.png";
import JewelleryImage from "../../assets/website/productPageImages/jewelry.png";
import PerfumeImage from "../../assets/website/productPageImages/perfume.png";
import PetImage from "../../assets/website/productPageImages/pet-grooming.png";
import Electronics from "../../assets/website/productPageImages/responsive.png";
import ShoesImage from "../../assets/website/productPageImages/sneakers.png";
import StationaryImage from "../../assets/website/productPageImages/stationary.png";
import SunGlassesimage from "../../assets/website/productPageImages/summer.png";
import WatchImage from "../../assets/website/productPageImages/watch.png";
import SportsImage from "../../assets/website/productPageImages/sports.png";
import BbpsImage from "../../assets/website/productPageImages/bbpsbanner.png";
import TravellImage from "../../assets/website/productPageImages/travel banner.png";
import CashDepositImage from "../../assets/website/productPageImages/cashdepositebanner.png";
import AepsImage from "../../assets/website/productPageImages/aepsbanner.png";
import MonetTransfer from "../../assets/website/productPageImages/moneytransferbanner.png";
import { Button } from "react-bootstrap";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    flexDirection: "column",
    overflowX: "hidden",
    "&, .MuiAccordionSummary-expandIcon": {
      right: 10,
      bottom: 0,
      marginTop: "auto",
      color: whiteColor,
    },
    "&, .MuiAccordionSummary-root": {
      padding: 0,
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        flexDirection: "column",
      },
    },
    "&, .MuiAccordionSummary-content": {
      margin: 0,
    },
    "&, .MuiAccordion-rounded:last-child": {
      // borderRadius:20
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "56px",
    },
  },
  accordiancard: {
    margin: "4% 0px",
    scrollMarginTop: "100px",
    scrollPaddingTop: "100px",
  },
  acordianContainer: {
    paddingLeft: 150,
    paddingRight: 150,
    scrollMarginTop: "100px",
    scrollPaddingTop: "100px",
    [theme.breakpoints.down("md")]: {
      paddingLeft: 50,
      paddingRight: 50,
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 10,
      paddingRight: 10,
    },
  },
  InsuranceImage:{
    width: "50%",
    height: 300,
    objectFit: "fill",
    borderRadius: 20,
    margin: "-10px -0px -10px -12px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: 300,
      margin: "0px",
      borderRadius: 0,
    },
  },
  image: {
    width: "50%",
    height: 300,

    objectFit: "cover",
    borderRadius: 20,
    margin: "-10px -0px -10px -12px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: 300,
      margin: "0px",
      borderRadius: 0,
    },
  },
  imageRight: {
    width: "calc(50% + 12px)",
    height: 300,
    objectFit: "cover",
    borderRadius: 20,
    margin: "-20px -20px -10px -12px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: 300,
      margin: "0px",
      borderRadius: 0,
    },
  },
  card1: {
    backgroundColor: primaryColor,
  },
  card2: {
    backgroundColor: secondaryColor,
  },
  accordianleft: {
    padding: "2% 2%",
    color: whiteColor,
    width: "50%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  accordianbottom: {
    padding: "2% 2%",
    color: whiteColor,
  },
  heading: {
    fontWeight: 700,
    fontSize: 20,
    letterSpacing: 1,
    fontFamily: "ABBVoice",
  },
  readmore: {
    fontSize: 14,
    fontWeight: 600,
  },
  subheading: {
    fontSize: 16,
    fontWeight: 600,
    paddingBottom: "20px",
    fontFamily: "Myraid",
    [theme.breakpoints.down("md")]: {
      paddingBottom: "5px",
    },
  },
  cardcontainer: {
    display: "flex",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  cardcontainer2: {
    display: "flex",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column-reverse",
    },
  },
  panelSummary: {
    flexDirection: "row-reverse",
    [theme.breakpoints.down("md")]: {
      flexDirection: "row",
    },
  },
  description: {
    fontSize: 16,
    fontFamily: "Myraid",
    textAlign: "justify",
    [theme.breakpoints.down("md")]: {
      textAlign: "justify",
    },
  },
  accordianexpand: {
    fontSize: 14,
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    padding: "2px",
    borderRadius: "5px",
    border: "none",
    color: "white",
    transform: "rotate(180deg)",
  },
  accordianexpandMore: {
    fontSize: 14,
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    padding: "2px",
    borderRadius: "5px",
    margin: "2px",
    border: "none",
    color: "white",
  },
  vectorimage: {
    height: 100,
    width: "100px",
    margin: "0px 20px",
    objectFit: "cover",
    borderRadius: 20,
    [theme.breakpoints.down("md")]: {
      width: "60%",
      height: "60%",
      alignSelf: "center",
      marginLeft: "15%",
      marginRight: "15%",
      marginBottom: "10px",
    },
  },
  finzcontainer: {
    margin: "4% 0px",
    display: "flex",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  finzcontainersecond: {
    margin: "4% 0px",
    display: "flex",
    flexDirection: "row-reverse",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  finzvector: {
    height: 50,
    width: "100%",
    objectFit: "contain",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      width: "80%",
    },
  },
  finzservicetitle: {
    fontSize: 16,
    fontWeight: 600,
    fontFamily: "Myraid",
    color: whiteColor,
    cursor: "pointer",
    textAlign: "left",
    [theme.breakpoints.down("md")]: {
      fontSize: 14,
    },
  },
  finzservicedesc: {
    fontSize: 12,
    fontFamily: "Myraid",
    color: whiteColor,
    cursor: "pointer",
  },
  surakhsabuttons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "center",
      marginTop: 10,
    },
  },
  surakshabutton: {
    margin: "3% 0px",
    border: "1px solid white",
    borderRadius: 8,
    padding: "10px 40px",
    fontFamily: "Myraid",
    fontSize: 20,
    fontWeight: 700,
    cursor: "pointer",
  },
}));

const Image = [{ img: Product1 }, { img: Product2 }, { img: Product3 }];

export default function Products() {
  const classes = useStyles();
  const [expandData, setExpandData] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    console.log(location);
    if (location.name === "finz") {
      handleTimeout();
      setExpandData([true, false, false, false, false]);
    } else if (location.name === "assist") {
      setExpandData([false, true, false, false, false]);
      handleTimeout();
    } else if (location.name === "suraksha") {
      setExpandData([false, false, true, false, false]);
      handleTimeout();
    } else if (location.name === "business") {
      handleTimeout();
      setExpandData([false, false, false, true, false]);
    } else if (location.name === "cards") {
      handleTimeout();
      setExpandData([false, false, false, false, true]);
    } else {
      handleTimeout();
    }
  }, [location]);

  const handleTimeout = () => {
    const element = document.getElementById(location.hash);
    setTimeout(() => {
      console.log(location.hash);
      window.scrollTo({
        behavior: element ? "smooth" : "auto",
        top: element ? element.offsetTop - 100 : 0,
      });
    }, 100);
  };

  const handleExpandChange = (index) => {
    const list = expandData;
    list[index] = !list[index];
    setExpandData(() => [...list]);
  };

  const finzproducts = [
    {
      image: ShoesImage,
      title: "Shoes",
      subtitle: "subtitle",
    },
    {
      image: FashionImage,
      title: "Clothing & Fashion",
      subtitle: "subtitle2",
    },
    {
      image: CosmeticsImage,
      title: "Beauty & Personal Care",
      subtitle: "subtitle2",
    },
    {
      image: BackPackImage,
      title: "Bags",
      subtitle: "subtitle2",
    },
    {
      image: FoodImage,
      title: "Grocery",
      subtitle: "subtitle2",
    },
    {
      image: WatchImage,
      title: "Watches",
      subtitle: "subtitle2",
    },

    {
      image: PerfumeImage,
      title: "Perfumes & Deodorants",
      subtitle: "subtitle2",
    },
    {
      image: StationaryImage,
      title: "Stationery & Games",
      subtitle: "subtitle2",
    },
    {
      image: BabyProductsImage,
      title: "Baby Products",
      subtitle: "subtitle2",
    },
    {
      image: SportsImage,
      title: "Sports & Fitness",
      subtitle: "subtitle2",
    },
    {
      image: JewelleryImage,
      title: "Jewellery",
      subtitle: "subtitle2",
    },
    {
      image: PetImage,
      title: "Pet Products",
      subtitle: "subtitle2",
    },
    {
      image: SunGlassesimage,
      title: "Sunglass",
      subtitle: "subtitle2",
    },
    {
      image: BikeImage,
      title: "Bike Accessories",
      subtitle: "subtitle2",
    },
    {
      image: Electronics,
      title: "Electronics & Appliances",
      subtitle: "subtitle2",
    },
    {
      image: HomeAppliancesImage,
      title: "Home & Kitchen",
      subtitle: "subtitle2",
    },
  ];

  return (
    <>
      <MainNavBar />
      <div className={classes.root}>
        <Banner Image={Image} showButtons={false} />

        <Container className={classes.acordianContainer}>
          <div id="#finz" className={classes.accordiancard}>
            <Accordion
              className={classes.card1}
              onChange={() => handleExpandChange(0)}
              expanded={expandData[0]}
            >
              <AccordionSummary
                expandIcon={
                  expandData[0] === false ? (
                    <button className={classes.accordianexpandMore}>
                      Click here
                      <ExpandMoreIcon className={classes.expand1} />
                    </button>
                  ) : (
                    <button className={classes.accordianexpand}>
                      Click here
                      <ExpandLessIcon className={classes.expand1} />
                    </button>
                  )
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div className={classes.cardcontainer}>
                  <img src={FinzProduct} className={classes.image} alt="" />
                  <div className={classes.accordianleft}>
                    <Typography className={classes.heading}>
                      BIGWAYZ FINZ
                    </Typography>
                    <Typography className={classes.subheading}>
                      Digital Banking & Financial Services
                    </Typography>
                    <Typography className={classes.description}>
                      Provide Digital Banking Services customized to meet the
                      requirement of every customer across urban & rural India.
                      Bigwayz partners can now offer Cash Deposit, Withdrawals,
                      and Money Transfer through a click away in mobile or
                      laptop.
                    </Typography>
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className={classes.accordianbottom}>
                  <Typography className={classes.description}>
                    <strong>Services we offer:</strong>
                  </Typography>
                  <div className={classes.finzcontainer}>
                    <img
                      src={MonetTransfer}
                      alt=""
                      className={classes.vectorimage}
                    />
                    <Typography className={classes.description}>
                      <strong>Domestic Money Transfer:</strong> Facilitate{" "}
                      <strong>Cash deposit</strong> & <strong>transfer</strong>{" "}
                      of <strong>money</strong> to any bank account through NEFT
                      or IMPS across the country & beyond banking hours.
                    </Typography>
                  </div>
                  <div className={classes.finzcontainersecond}>
                    <img
                      src={AepsImage}
                      alt=""
                      className={classes.vectorimage}
                    />
                    <Typography className={classes.description}>
                      <strong>Aadhaar Enabled Payment System:</strong> Using
                      Aadhaar number and biometric authentication, offer{" "}
                      <strong>Cash Deposit</strong>{" "}
                      <strong>
                        & Cash Withdrawal, Balance Enquiry, Mini Statement,
                        Aadhaar to Aadhaar Fund Transfer & Authentication.
                      </strong>
                    </Typography>
                  </div>

                  <div className={classes.finzcontainer}>
                    <img
                      src={CashDepositImage}
                      alt=""
                      className={classes.vectorimage}
                    />
                    <Typography className={classes.description}>
                      <strong>Micro ATM:</strong> Retail outlets can now become
                      cash points to facilitate deposit of cash into any bank
                      accounts of any bank. Through our secure and robust
                      platform, we enable customers to swipe debit card of any
                      bank on our Micro ATM device to withdraw money from our
                      extensive network of retail outlets & Bigwayz cash points.
                    </Typography>
                  </div>
                  <div className={classes.finzcontainersecond}>
                    <img
                      src={BbpsImage}
                      alt=""
                      className={classes.vectorimage}
                    />
                    <Typography className={classes.description}>
                      <strong>Bharat Bill Pay System (BBPS):</strong> Pay bills
                      like electricity, water, gas, DTH, landline, broadband and
                      postpaid mobile bills & recharge for Fasttag conveniently
                      through our network of partners & retail outlets.
                    </Typography>
                  </div>

                  <div className={classes.finzcontainer}>
                    <img
                      src={TravellImage}
                      alt=""
                      className={classes.vectorimage}
                    />
                    <Typography className={classes.description}>
                      <strong>Travel: </strong>Provide travel & ticketing
                      services to book and buy tickets of flight, bus, railway's
                      and hotel accommodation.
                    </Typography>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>

          <div className={classes.accordiancard} id="#assist">
            <Accordion
              className={classes.card2}
              onChange={() => handleExpandChange(1)}
              expanded={expandData[1]}
            >
              <AccordionSummary
                className={classes.panelSummary}
                expandIcon={
                  expandData[1] === false ? (
                    <button className={classes.accordianexpandMore}>
                      Click here
                      <ExpandMoreIcon className={classes.expand1} />
                    </button>
                  ) : (
                    <button className={classes.accordianexpand}>
                      Click here
                      <ExpandLessIcon className={classes.expand1} />
                    </button>
                  )
                }
                IconButtonProps={{ edge: "center" }}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div className={classes.cardcontainer2}>
                  <div className={classes.accordianleft}>
                    <Typography className={classes.heading}>
                      BIGWAYZ ASSIST
                    </Typography>
                    <Typography className={classes.subheading}>
                      Redefining the shopping experience!
                    </Typography>
                    <Typography className={classes.description}>
                      As a Bigwayz partner, get access to 100+ brands & more
                      than 16 categories as your inventory to offer a one stop
                      shopping experience to new & verified customers without
                      any hassle of inventory & logistics to help grow your
                      business!
                    </Typography>
                  </div>
                  <img
                    src={AssistProduct}
                    className={classes.imageRight}
                    alt=""
                  />
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div className={classes.accordianbottom}>
                    <Typography className={classes.description}>
                      Become part of a trusted & growing business to offer value
                      added services for a superior customer experience across
                      all touchpoints including doorstep delivery, order
                      tracking, payments & customer support. <br /> <br />
                      <strong>Categories:</strong>
                    </Typography>
                  </div>

                  <div>
                    <Grid container spacing={3}>
                      {finzproducts.map((item) => (
                        <Grid item xs={6} sm={3}>
                          <Grid container spacing={2}>
                            <Grid item xs={6} sm={6}>
                              <img
                                src={item.image}
                                alt=""
                                className={classes.finzvector}
                              />
                            </Grid>
                            <Grid item xs={6} sm={6}>
                              <Typography className={classes.finzservicetitle}>
                                {item.title}
                              </Typography>
                              {/* <Typography className={classes.finzservicedesc}>{item.subtitle}</Typography> */}
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </div>

                  <LogoSlider />
                </div>
              </AccordionDetails>
            </Accordion>
          </div>

          {/* 3rd */}
          <div className={classes.accordiancard} id="#suraksha">
            <Accordion
              className={classes.card1}
              onChange={() => handleExpandChange(2)}
              expanded={expandData[2]}
            >
              <AccordionSummary
                expandIcon={
                  expandData[2] === false ? (
                    <button className={classes.accordianexpandMore}>
                      Click here
                      <ExpandMoreIcon className={classes.expand1} />
                    </button>
                  ) : (
                    <button className={classes.accordianexpand}>
                      Click here
                      <ExpandLessIcon className={classes.expand1} />
                    </button>
                  )
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div className={classes.cardcontainer}>
                  <img src={SurakshaProduct} className={classes.InsuranceImage} />
                  <div className={classes.accordianleft}>
                    <Typography className={classes.heading}>
                      BIGWAYZ SURAKSHA
                    </Typography>
                    <Typography className={classes.subheading}>
                      Insure & Secure your customers!
                    </Typography>
                    <Typography className={classes.description}>
                      Become a <strong>POSP</strong> &{" "}
                      <strong>issue insurance policies</strong> easily with
                      minimum paperwork & compare insurance plans from leading
                      insurance companies. Facilitate Insurance services to
                      build a safe & insured future.
                    </Typography>
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className={classes.accordianbottom}>
                  <Typography
                    className={classes.description}
                    style={{ color: whiteColor }}
                  >
                    As a <strong>POSP </strong>using{" "}
                    <strong>Suraksha Plus</strong> platform, provide the best
                    quotes & policy for Health, Two-wheeler, Four wheeler &
                    Commercial vehicle insurance. Protect & take care of your
                    medical expenses with a comprehensive preventive care
                    package brought to you by Suraksha Care for{" "}
                    <strong>
                      Personal Accidental Care, Accidental Hospitalization Care
                      & Hospi Cash -
                    </strong>
                    all specially designed for Bigwayz customers & associates.
                  </Typography>
                  <div className={classes.surakhsabuttons}>
                    <div
                      className={classes.surakshabutton}
                      onClick={() => history.push("/suraksha")}
                    >
                      Suraksha Plus Platform
                    </div>
                    <div
                      className={classes.surakshabutton}
                      onClick={() => history.push("/suraksha")}
                    >
                      Suraksha Care
                    </div>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>

          {/* 4th */}
          <div className={classes.accordiancard} id="#business">
            <Accordion
              className={classes.card2}
              onChange={() => handleExpandChange(3)}
              expanded={expandData[3]}
            >
              <AccordionSummary
                className={classes.panelSummary}
                expandIcon={
                  expandData[3] === false ? (
                    <button className={classes.accordianexpandMore}>
                      Click here
                      <ExpandMoreIcon className={classes.expand1} />
                    </button>
                  ) : (
                    <button className={classes.accordianexpand}>
                      Click here
                      <ExpandLessIcon className={classes.expand1} />
                    </button>
                  )
                }
                IconButtonProps={{ edge: "center" }}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div className={classes.cardcontainer2}>
                  <div className={classes.accordianleft}>
                    <Typography className={classes.heading}>
                      Promote your Business
                    </Typography>
                    {/* <Typography className={classes.subheading}>Start your dream Digital Business today !</Typography> */}
                    <Typography className={classes.description}>
                      Register & start using Bigwayz automation tools to promote
                      your products & services, create & share invoices to
                      manage your Business in a more organized structure.
                    </Typography>
                  </div>
                  <img
                    src={PromoteProduct}
                    className={classes.imageRight}
                    alt=""
                  />
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className={classes.accordianbottom}>
                  <ul>
                    <li>
                      <Typography className={classes.description}>
                        Catalogue, organize & share your product & service
                        offerings to your customers thereby increase your market
                        reach & transform your digital business with unlimited
                        possibilities.
                      </Typography>
                    </li>
                    <li>
                      <Typography className={classes.description}>
                        Create invoices, delivery challan & return invoices to
                        share on whatsapp to give a simple & convenient
                        invoicing solution to customers. Now manage & record
                        your business transactions all in one place.
                      </Typography>
                    </li>
                  </ul>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>

          {/* 5th */}
          <div className={classes.accordiancard} id="#cards">
            <Accordion
              className={classes.card1}
              onChange={() => handleExpandChange(4)}
              expanded={expandData[4]}
            >
              <AccordionSummary
                expandIcon={
                  expandData[4] === false ? (
                    <button className={classes.accordianexpandMore}>
                      Click here
                      <ExpandMoreIcon className={classes.expand1} />
                    </button>
                  ) : (
                    <button className={classes.accordianexpand}>
                      Click here
                      <ExpandLessIcon className={classes.expand1} />
                    </button>
                  )
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div className={classes.cardcontainer}>
                  <img src={PrepaidProduct} className={classes.image} />
                  <div className={classes.accordianleft}>
                    <Typography className={classes.heading}>
                      Prepaid Card
                    </Typography>
                    {/* <Typography className={classes.subheading}>Your one stop banking solutions partner!</Typography> */}
                    <Typography className={classes.description}>
                      Promote Prepaid cards with a virtual account for customers
                      to encourage cashless payments & personalized offerings to
                      suit the requirement of managing personal finances in a
                      planned manner. The card can be preloaded with a balance &
                      used for purchases, cash withdrawals at ATM's & online
                      shopping to get attractive rewards.
                    </Typography>
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className={classes.accordianbottom}>
                  <p className={classes.description}>Key features are:</p>
                  <ul>
                    <li>
                      <Typography className={classes.description}>
                        Prepaid Debit/ATM Reloadable Card upto 3 Years Validity
                      </Typography>
                    </li>
                    <li>
                      <Typography className={classes.description}>
                        Zero Balance Prepaid Card
                      </Typography>
                    </li>
                    <li>
                      <Typography className={classes.description}>
                        Instant Activation on submission of KYC documents
                      </Typography>
                    </li>
                    <li>
                      <Typography className={classes.description}>
                        Mobile enabled Card linked to a Virtual Account
                      </Typography>
                    </li>
                    <li>
                      <Typography className={classes.description}>
                        Instant Cash Loading facility
                      </Typography>
                    </li>
                    <li>
                      <Typography className={classes.description}>
                        Cash withdrawal through ATMs of any Bank in India
                      </Typography>
                    </li>
                    <li>
                      <Typography className={classes.description}>
                        Accepted for payments at all Rupay, Master Card POS &
                        online.
                      </Typography>
                    </li>
                    <li>
                      <Typography className={classes.description}>
                        Reloadable Card through app anytime & instantly.
                      </Typography>
                    </li>
                    <li>
                      <Typography className={classes.description}>
                        Fully Secured with all Bank level security features
                      </Typography>
                    </li>
                    <li>
                      <Typography className={classes.description}>
                        Exciting loyalty programs through Bigwayz Assist & other
                        major brands.
                      </Typography>
                    </li>
                  </ul>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        </Container>
      </div>
      <MainFooter />
    </>
  );
}
