import React, {useEffect} from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import Fuse from 'fuse.js';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import {useHistory} from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ListItemText from "@material-ui/core/ListItemText";
import PeopleIcon from "@material-ui/icons/People";
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import TextField from "@material-ui/core/TextField";
// import Title from "./Title";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {primaryColor} from "../../config/constants";
import {firestore} from "../../config/firebase";
import ManageUsers from "./RegisteredUsers/ManageUsers";

// import {Title} from "@material-ui/icons";

function Title(props) {
    return (
        <Typography component="h2" variant="h6" color="primary" gutterBottom>
            {props.children}
        </Typography>
    );
}
//
// Title.propTypes = {
//     children: PropTypes.node,
// };


function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {/* XX{'Copyright © Bigwayz Designed by '}
            <Link color="inherit" href="https://kraneapps.com/">
                KraneApps.com
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'} */}
        </Typography>
    );
}


// Generate Order Data
function createData(type, name, phone, email,city, timestamp) {
    return { type, name, phone, email,city, timestamp };
}

const drawerWidth = 240;

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24,
        backgroundColor: primaryColor// keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));

export default function BusineesCategories() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const [openDialog, setOpenDialog] = React.useState(false);

    const [pattern, setPattern] = React.useState('');

    const history = useHistory();
    const [allLeads, setAllLeads] = React.useState([]);
    const [searchLeads, setSearchLeads] = React.useState([]);


    const handleClose = () => {
        setOpenDialog(false);
    };

    const handleSearch = () => {
        console.log("Searching....");

        const options = {
            isCaseSensitive: false,
            threshold: 0.3,
            keys: [
                "type",
                "name",
                "city",
                "email",
                "phone"
            ]
        };

        const fuseCategory = new Fuse(allLeads, options);
        const toSearch = pattern;
        var temp = fuseCategory.search(toSearch);
        console.log("searched leads ==",temp);
        setSearchLeads(temp);
        setOpenDialog(true);
    }


    // const handleSearch = (e) => {
    //     console.log("Searching....");
    //     //Categories
    //     const CategoryOptions = {
    //         isCaseSensitive: false,
    //         threshold: 0.3,
    //         keys: [
    //             "id",
    //             "name"
    //         ]
    //     };
    //
    //     const ProductOptions = {
    //         isCaseSensitive: false,
    //         threshold: 0.3,
    //         keys: [
    //             "product_name",
    //             "category_id"
    //         ]
    //     };
    //     const fuseCategory = new Fuse(categories, CategoryOptions);
    //     const pattern = searchTerm;
    //     var temp = fuseCategory.search(pattern);
    //     console.log("Subset category: ",temp);
    //     setSearchCategories(temp);
    //
    //     const fuseProduct = new Fuse(products, ProductOptions);
    //     temp = fuseProduct.search(pattern);
    //     console.log("subset Product", temp[0]);
    //     setSearchProducts(temp);
    // }

    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);


    return (
        <div className={classes.root}>
            <CssBaseline />
            <main className={classes.content}>
                <Container maxWidth="lg" className={classes.container}>

                    <Grid container spacing={3}>
                        {/*<Grid item xs={12} md={4} lg={4}>*/}
                        {/*    <Paper className={fixedHeightPaper}>*/}
                        {/*        <Title>Entrepreneur Channel</Title>*/}
                        {/*        <Typography color="textSecondary" sx={{ flex: 1 }}>*/}
                        {/*            as on {new Date().getDate()}/{new Date().getMonth()+1}/{new Date().getFullYear()}*/}
                        {/*        </Typography>*/}
                        {/*    <Grid container spacing={1} style={{marginTop: '10px'}}>*/}
                        {/*        <Grid item xs={4}>*/}
                        {/*                <Typography component="p" variant="h4">*/}
                        {/*                    100*/}
                        {/*                </Typography>*/}
                        {/*                <Typography color="textSecondary" sx={{ flex: 1 }}>*/}
                        {/*                    Registered Users*/}
                        {/*                </Typography>*/}
                        {/*        </Grid>*/}
                        {/*        <Grid item xs={4}>*/}
                        {/*            <Typography component="p" variant="h4">*/}
                        {/*                100*/}
                        {/*            </Typography>*/}
                        {/*            <Typography color="textSecondary" sx={{ flex: 1 }}>*/}
                        {/*                Active Users*/}
                        {/*            </Typography>*/}
                        {/*        </Grid>*/}
                        {/*        <Grid item xs={4}>*/}
                        {/*            <Typography component="p" variant="h4">*/}
                        {/*                100*/}
                        {/*            </Typography>*/}
                        {/*            <Typography color="textSecondary" sx={{ flex: 1 }}>*/}
                        {/*                Inactive Users*/}
                        {/*            </Typography>*/}
                        {/*        </Grid>*/}
                        {/*    </Grid>*/}

                        {/*</Paper>*/}
                        {/*</Grid>*/}
                        {/*<Grid item xs={12} md={4} lg={4}>*/}
                        {/*    <Paper className={fixedHeightPaper}>*/}
                        {/*        <Title>Distribution Channel</Title>*/}
                        {/*        <Typography color="textSecondary" sx={{ flex: 1 }}>*/}
                        {/*            as on {new Date().getDate()}/{new Date().getMonth()+1}/{new Date().getFullYear()}*/}
                        {/*        </Typography>*/}
                        {/*        <Grid container spacing={1} style={{marginTop: '10px'}}>*/}
                        {/*            <Grid item xs={4}>*/}
                        {/*                <Typography component="p" variant="h4">*/}
                        {/*                    100*/}
                        {/*                </Typography>*/}
                        {/*                <Typography color="textSecondary" sx={{ flex: 1 }}>*/}
                        {/*                    Registered Users*/}
                        {/*                </Typography>*/}
                        {/*            </Grid>*/}
                        {/*            <Grid item xs={4}>*/}
                        {/*                <Typography component="p" variant="h4">*/}
                        {/*                    100*/}
                        {/*                </Typography>*/}
                        {/*                <Typography color="textSecondary" sx={{ flex: 1 }}>*/}
                        {/*                    Active Users*/}
                        {/*                </Typography>*/}
                        {/*            </Grid>*/}
                        {/*            <Grid item xs={4}>*/}
                        {/*                <Typography component="p" variant="h4">*/}
                        {/*                    100*/}
                        {/*                </Typography>*/}
                        {/*                <Typography color="textSecondary" sx={{ flex: 1 }}>*/}
                        {/*                    Inactive Users*/}
                        {/*                </Typography>*/}
                        {/*            </Grid>*/}
                        {/*        </Grid>*/}

                        {/*    </Paper>*/}
                        {/*</Grid>*/}

                        {/*<Grid item xs={12} md={4} lg={4}>*/}
                        {/*    <Paper className={fixedHeightPaper}>*/}
                        {/*        <Title>Corporate Channel</Title>*/}
                        {/*        <Typography color="textSecondary" sx={{ flex: 1 }}>*/}
                        {/*            as on {new Date().getDate()}/{new Date().getMonth()+1}/{new Date().getFullYear()}*/}
                        {/*        </Typography>*/}
                        {/*        <Grid container spacing={1} style={{marginTop: '10px'}}>*/}
                        {/*            <Grid item xs={4}>*/}
                        {/*                <Typography component="p" variant="h4">*/}
                        {/*                    100*/}
                        {/*                </Typography>*/}
                        {/*                <Typography color="textSecondary" sx={{ flex: 1 }}>*/}
                        {/*                    Registered Users*/}
                        {/*                </Typography>*/}
                        {/*            </Grid>*/}
                        {/*            <Grid item xs={4}>*/}
                        {/*                <Typography component="p" variant="h4">*/}
                        {/*                    100*/}
                        {/*                </Typography>*/}
                        {/*                <Typography color="textSecondary" sx={{ flex: 1 }}>*/}
                        {/*                    Active Users*/}
                        {/*                </Typography>*/}
                        {/*            </Grid>*/}
                        {/*            <Grid item xs={4}>*/}
                        {/*                <Typography component="p" variant="h4">*/}
                        {/*                    100*/}
                        {/*                </Typography>*/}
                        {/*                <Typography color="textSecondary" sx={{ flex: 1 }}>*/}
                        {/*                    Inactive Users*/}
                        {/*                </Typography>*/}
                        {/*            </Grid>*/}
                        {/*        </Grid>*/}

                        {/*    </Paper>*/}
                        {/*</Grid>*/}
                        {/* Recent Orders */}
                        <Grid item xs={12}>
                            {/*<Paper className={classes.paper}>*/}
                            {/*    /!*<Orders />*!/*/}
                            {/*</Paper>*/}
                        </Grid>
                        <Grid item xs={12}>
                                <ManageUsers/>
                        </Grid>
                    </Grid>
                    <Box pt={4}>
                        <Copyright />
                    </Box>
                </Container>
            </main>
        </div>
    );
}

