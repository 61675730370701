import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import {primaryColor, secondaryColor, whiteColor } from '../../../config/constants'; 
import {Link} from "react-router-dom";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    title:{
        fontSize:30,
        color:secondaryColor,
        fontWeight:700,
        margin:20
    },
    background:{
        backgroundColor:"rgba(240, 102, 105, 0.13)",
        padding:30,
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between'
    },
    background1:{
        padding:30,
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between'
    },
    avatartitle:{
        display:'flex',
        width:'20%'
    },
    registeredname:{
        padding:'10px',
        fontSize:18
    },
    editbutton:{
        color:whiteColor,
        backgroundColor:secondaryColor,
        borderRadius:18,
        padding:8,
        textAlign:'center',
        margin:5,
        cursor:'pointer',
        maxHeight:35,
        textDecoration:'none'
    },
    date:{
        fontSize:16,
        fontWeight:300
    },

  }));
  

export default function KYC() {
    const classes = useStyles();
    const [value, setValue] = React.useState('female');

    const handleChange = (event) => {
      setValue(event.target.value);
    };


    return (
        <div>
        <Grid container spacing={0}>
            <Grid item xs={6}>
            <Typography className={classes.title}>
                Manage KYC
            </Typography>
            </Grid>
            <Grid item xs={6}>
            </Grid>
        </Grid>
            <div className={classes.background}>
                <div className={classes.avatartitle}>
                <div className={classes.registeredname}>1. User Name</div>
                </div>
                <div className={classes.date} >
                <FormControl component="fieldset">
                <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>
                    <FormControlLabel value="female" control={<Radio />} label="Approved" />
                </RadioGroup>
                </FormControl>
                </div>
                <Link to ="/kycdetails" size="small" color="primary"  className={classes.editbutton}>
                    Manage Details
                </Link>
            </div>
            <div className={classes.background1}>
            <div className={classes.avatartitle}>
                <div className={classes.registeredname}>2.User Name </div>
                </div>
            <div className={classes.date}>
            <FormControl component="fieldset">
                <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>
                    <FormControlLabel value="female" control={<Radio />} label="Not Approved" />
                </RadioGroup>
            </FormControl>
                </div>
                <Link to ="/kycdetails" size="small" color="primary"  className={classes.editbutton}>
                    Manage Details
                </Link>
            </div>
            
            
        </div>




    )
}
