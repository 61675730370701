import { Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { Container } from "react-bootstrap";
import MainFooter from "../../components/MainFooter";
import MainNavBar from "../../components/MainNavBar";
import { primaryColor } from "../../config/webconstants";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "5% 0px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "56px",
    },
  },
  title: {
    fontSize: 20,
    color: primaryColor,
    fontWeight: 800,
    fontFamily: "Myraid",
    padding: "20px 0",
    textAlign: "center",
  },
  desc: {
    fontSize: 18,
    fontWeight: 600,
    fontFamily: "Myraid",
    textAlign: "justify",
  },
}));

export default function Terms() {
  const classes = useStyles();
  return (
    <>
      <MainNavBar />
      <div className={classes.root}>
        <Container>
          <Grid container>
            <Grid item xs={12} sm={12}>
              <Typography className={classes.title}>
                Terms & Conditions
              </Typography>
              <Typography className={classes.desc}>
                Inginomicx Technologies Pvt Ltd (“ITPL”) is the licensed owner
                of the brand Bigwayz Assist and the website bigwayz.com (”The
                Site”). These terms and conditions (“User Terms”/ “Terms”) apply
                to your use of the Platform. This document is an electronic
                record in terms of the Information Technology Act, 2000 and
                rules thereunder as applicable and the provisions pertaining to
                electronic records in various statutes as amended by the
                Information Technology Act, 2000. This electronic record is
                generated by a computer system and does not require any physical
                or digital signatures. As a visitor to the Site/ Customer, you
                are advised to please read the Term & Conditions carefully. By
                accessing the services provided by the Site you agree to the
                terms provided in this Terms & Conditions document. YOUR
                AGREEMENT TO THESE USER TERMS SHALL OPERATE AS A BINDING
                AGREEMENT BETWEEN YOU AND THE COMPANY IN RESPECT OF THE USE OF
                THE PLATFORM AND THE SERVICES. Your acceptance of the User Terms
                shall be deemed to include your acceptance of the Privacy Policy
                available at Privacy Policy. If there is any conflict between
                these User Terms and any other applicable terms, in relation to
                the Platform or the Services, these User Terms shall prevail.
                The Agreement (defined hereunder) is applicable to any Users
                when they install, download or even merely visit or access any
                part of the Platform or utilise the Services. The Agreement
                between User/you and Company is effective on the date on which
                the application is downloaded/website is accessed and/or the
                date on which terms of Agreement are updated, which are accepted
                by you before accessing any of the Services, creating a legally
                binding arrangement between you and the Company.
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Typography className={classes.title}>
                Personal Information
              </Typography>
              <Typography className={classes.desc}>
                As part of the registration process on the Site, ITPL may
                collect the following personally identifiable information about
                you: Name including first and last name, alternate email
                address, mobile phone number and contact details, Postal code,
                Demographic profile (like your age, gender, occupation,
                education, address etc.) and information about the pages on the
                site you visit/access, the links you click on the site, the
                number of times you access the page and any such browsing
                information.
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Typography className={classes.title}>
                Eligibility, Account and Registration Obligations
              </Typography>
              <Typography className={classes.desc}>
                Services of the Site would be available to only select
                geographies in India. Persons who are "incompetent to contract"
                within the meaning of the Indian Contract Act, 1872 including
                un-discharged insolvents etc. are not eligible to use the Site.
                If you are a minor i.e. under the age of 18 years but at least
                13 years of age you may use the Site only under the supervision
                of a parent or legal guardian who agrees to be bound by these
                Terms of Use. If your age is below 18 years your parents or
                legal guardians can transact on behalf of you if they are
                registered users. You are prohibited from purchasing any
                material which is for adult consumption and the sale of which to
                minors is prohibited. You can use your account created on the
                Platform to access the Services. You are sole responsible for
                all activities that occur under such account created by you,
                including the transactions made using such account created by
                you; the Company shall not be liable for any such activity
                undertaken from your account in any manner whatsoever. The
                details of the information that We collect and the manner in
                which We use such information is detailed in the Privacy Policy,
                which forms part of these Terms and shall stand incorporated
                herein by reference. You are required to enter a valid phone
                number while registering on Platform. By such registration, you
                consent to be contacted by Company via phone calls, SMS
                notifications, instant messages or other such means of
                communication inter alia for Services, subscription, promotional
                updates etc. You declare that any and all information/details
                shared by you are true and correct to the best of your
                knowledge. In the event there is any change in such information,
                you will promptly inform the Company and update the information
                in your my account details. You agree that if you provide any
                information that is untrue, inaccurate, not current or
                incomplete or We have reasonable grounds to suspect that such
                information is untrue, inaccurate, not current or incomplete, or
                not in accordance with these User Terms. If you use the
                Platform, You are solely responsible for all activities that
                occur under the account crested on the Platform and all
                transactions made by you thereunder and the Company shall not be
                liable for any such activity in any manner. You are responsible
                for maintaining the confidentiality of your account, password,
                user identification codes and OTPs, if any and for restricting
                access to your computer/any device to prevent unauthorised
                access to your account. You agree to accept responsibility for
                all activities that occur under your account and/or password.
                You should take all necessary steps to ensure that the password
                is kept confidential and secure and should inform Us immediately
                if you have any reason to believe that your password has become
                known to anyone else, or if the password is being, or is likely
                to be, used in an unauthorised manner. You must comply with all
                the Applicable Laws when using the Services and You are not
                permitted to use the Services under false pretences or in
                violation of Applicable Laws. You hereby confirm that the
                consent/ instruction and/ or approval so provided by you on the
                Platform will be valid, effective and binding on you without any
                further enquiry or due diligence as to the identity of the
                person purporting to give such consent/ instruction and/or
                approval. In case of any dispute pertaining to your use of the
                Platform and the Service on behalf of anybody else,
                impersonation of someone else and/ or using information that
                belongs to someone else, you alone shall be solely responsible.
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Typography className={classes.title}>
                License & Site access
              </Typography>
              <Typography className={classes.desc}>
                ITPL grants you a limited sub-license to access and make
                personal use of this site and not to download (other than page
                caching) or modify it, or any portion of it, except with express
                written consent of ITPL. This site or any portion of this site
                may not be reproduced, duplicated, copied, sold, resold,
                visited, or otherwise exploited for any commercial purpose
                without express written consent of ITPL. You may not frame or
                utilize framing techniques to enclose any trademark, logo, or
                other proprietary information (including images, text, page
                layout, or form) of the Site or of ITPL and its affiliates
                without express written consent. You may not use any meta tags
                or any other "hidden text" utilizing the Site’s or ITPL’s name
                or trademarks without the express written consent of ITPL. Any
                unauthorized use terminates the permission or license granted by
                ITPL. We shall ensure that our Platform is eligible for easy
                access to all our customers. However, we do not guarantee that
                the Platform, or any content on it, will always be available or
                be uninterrupted. Access to the Platform may be suspended,
                withdrawn, discontinued or changed without notice to you. We
                will not be liable to you if, for any reason, the Platform or
                the Services are unavailable at any time or for any period. You
                are responsible for making all arrangements necessary for you to
                have access to the Platform. You are also responsible for
                ensuring that all persons who access the Platform through your
                internet connection, are aware of these User Terms and other
                applicable terms and conditions, and that they comply with them.
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Typography className={classes.title}>
                Scope of Services and Limitations
              </Typography>

              <Typography className={classes.desc}>
                The Services provided by the Company are available and valid
                only for use in India. The Services offered by the Company
                through the Platform is merely a technology platform to
                facilitate and enable Users to avail the Services facilitated
                herein. Any data that you submit to us through the Platform
                shall be governed by the Our Privacy Policy. You understand that
                the Company reserves the right to vary, alter or modify the User
                Terms and the terms relating to the Services, at any time and at
                our sole discretion. Policies related to returns/ exchanges,
                penalties, refunds, cancellation will be updated by us from time
                to time, without any explicit permission from you. We shall
                inform you from time to time regarding such updates and you are
                required to be aware of same. Using the Services once does not
                entitle automatic eligibility to avail similar products in the
                future. You understand, agree and acknowledge that we will have
                the right to send you reminders electronically or by post and/or
                SMS and/or push notifications, messages through social media
                such as WhatsApp to Your registered phone number and/or your
                e-mail. You agree and undertake not to revoke the aforesaid
                consent to receive SMS, messages, calls, e-mail and other
                notifications through any manner whatsoever. You further
                acknowledge and confirm that you are aware of the nature of
                telecommunications/internet services and that an email
                transmission may not be received properly. You further
                acknowledge that any default from you relating to the Services
                obtained from us and/or violation of the User Terms may attract
                legal charges against you, and the Company may at its sole
                discretion pursue such civil or criminal action. You agree to
                assume and bear all the risks involved in connection with your
                obligations to be fulfilled in order to avail the Services and
                undertake that that the Company shall not be responsible in any
                manner. You hereby accept and agree that we reserve the right to
                withdraw, discontinue or stop the provision of the Services, at
                any time, at our sole discretion, in which case, your use of the
                Services shall automatically stand terminated.
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Typography className={classes.title}>
                Specific Terms Relating To the Services
              </Typography>
              <Typography className={classes.desc}>
                Products and Services: You may place order through the Platform
                based on the products showcased and availability of the same.
                You understand and agree that the Company shall only be able to
                provide you with the products based on the availability. The
                Company shall take all reasonable efforts to make sure that the
                Platform shall reflect accurate information relating to the
                Services including but not limited to the product availability,
                delivery availability, delivery time and such other information.
                However, there might be certain instances where a part of the
                Services, including the products may not be available. You
                understand and agree that such events may not be deliberate, and
                the Company shall not be held liable in any manner whatsoever as
                these events are beyond reasonable control of the Company.
                Pricing: We aim to ensure that prices of all products are
                correct and up-to-date. However, from time to time, prices for
                certain products may not be current or may be inaccurate on
                account of technical issues, typographical errors or incorrect
                product information provided to us by a supplier. In each such
                case, notwithstanding anything to the contrary, We reserve the
                right to cancel the order without any further liability or
                notice to You at our sole discretion which will be conclusive
                and binding. Subject to the foregoing, the price mentioned at
                the time of ordering a product shall be the price charged at the
                time of delivery, provided that no product listed on the
                Platform will be sold at a price higher than its MRP (Maximum
                Retail Price). Payment Terms: You agree that the debit/credit
                card or such other payment related details provided by you for
                use of the purchase of Services is correct and accurate and that
                you will not use a debit/credit card or such other payment
                modes, that is not lawfully owned by you or the use of which is
                not authorized by the lawful owner thereof. You may pay for
                Services by using a debit/credit card or through online banking
                account, wallets or such other payment modes offered by the
                Company on the Platform. Further, you warrant, agree and confirm
                that when you initiate a payment transaction and/or issue an
                online payment instruction and provide your card/bank details/
                or such other payment related details: (a) You are authorizing
                debit of the nominated card/bank account/wallet/such other modes
                you have chosen, for the payment of amount selected by you along
                with the applicable fees/taxes; (b) You are responsible to
                ensure sufficient credit/amount is available on the nominated
                card/bank account/wallet at the time of making the payment to
                permit the payment of the dues payable or the bill(s) selected
                by you inclusive of the applicable fees/taxes. Damaged and
                defective goods: We will not be responsible for damaged or
                defective goods or part thereof in transit or for any
                discrepancy between the goods delivered and the goods ordered
                unless You notify Us by written or email notice of a claim to
                the Company within 24 (twenty-four) hours of receiving such
                damaged or defective goods. In the event of damage of goods or
                part thereof in transit or for any discrepancy between the goods
                delivered and the goods ordered, you must hold the goods and
                make them available for inspection or collection by us or our
                representatives on request. Our total liability under any
                warranty for defective or damaged goods supplied by Us, or in
                relation to Services, based on the inspection and proof of the
                genuineness of such damage or defect of the goods is limited at
                Our sole discretion which will be conclusive and binding, to
                either (a) replacing the defective or damaged goods; or (b)
                refunding the price of the defective or damaged goods. We are
                not under any obligation to accept any returns of products/goods
                where the defect or damage was caused by you or the defect or
                fault occurred after dispatch of the products/goods. For the
                purpose of these Terms, (a) Damaged shall mean unforeseen and
                accidental physical loss, breakage, destruction of or damage to
                tangible property including the loss of use thereof at any time
                resulting from nuisance or interference with any right of way,
                light, air or water; and (b) Defect shall mean an error, that
                prevents the required usage or which substantially diminishes
                the possibilities or use of the products with the following
                specifications: (i) a defect is substantial, if it cannot be
                cured despite repeated attempts such that the product cannot be
                used at all; (ii) it is not a defect, if the User or a third
                party make modifications or repairs to the product without the
                Company’s permission unless the User can prove that the defect
                occurred independently from the modification or repairs.
                Delivery: You are solely responsible to provide us with accurate
                information regarding the delivery address. Once the order is
                placed on the Platform with a particular delivery address and
                the same is confirmed by the Company for delivery, you may not
                change the address. We recommend you to contact the support
                services for the change in delivery address or cancellation of
                the order. You understand and agree that for all the orders we
                will charge a delivery fee of INR 50/- (Indian Rupees Fifty
                only).
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Typography className={classes.title}>
                Cancellation And Refund
              </Typography>
              <Typography className={classes.desc}>
                We reserve the right to cancel any order if: (a) the address
                provided by You is either wrong or falls outside the delivery
                zone; (b) there is lack of information, direction or
                authorization from You at the time of delivery; and/or (c) some
                or all the items ordered by You are unavailable at the time the
                order is being processed. If we suspect any fraudulent
                transaction by any customer or any transaction which defies the
                terms & conditions of using the website, we at our sole
                discretion could cancel such orders. We will maintain a negative
                list of all fraudulent transactions and customers and would deny
                access to them or cancel any orders placed by them. You cannot
                cancel any orders when the order is in process. You can cancel
                your order for a product at no cost any time before the products
                are dispatched from our end and We send the dispatch
                confirmation e-mail/notification relating to that product
                confirming that the product has been dispatched for delivery.
                Unfortunately, an order cannot be cancelled once the item has
                been dispatched for delivery and/or the same is delivered to
                you, in which case you will have to return the items to Us after
                delivery by following Our Return Policy. Kindly refer to the
                Return Policy. In case You want to cancel the order at the time
                of delivery of products, ie., at the point of delivery, You may
                do so through the Platform; then the same will be marked as
                cancelled at the same time by the delivery associate. Once We
                receive the products from You at our address, We will conduct a
                quality check. Once the products have passed the quality check
                based on your specifications and criteria for cancellation, We
                will initiate the process of refund within 7 (Seven) business
                days. You understand and agree that: (a) We have the authority
                to make the final decision on refund amount and when it is
                generated; (b) refunds will happen through the same mode by
                which the payment was made, however, You may have the option to
                request for a change to credit/ reward points, which will be
                approved by the Company; (c) If your refund doesn’t appear in
                Your Account, and the processing time for Your payment method
                has passed, You may contact Us for further assistance.
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Typography className={classes.title}>Return Policy</Typography>
              <Typography className={classes.desc}>
                You may call Our customer service representative at +91
                91136929361 or at support@bigwayz.com and We shall arrange for
                the return pick-up within XXX (X) business days. You are
                required to keep a copy of the invoice highlighting the items to
                be returned. Once We receive the products at our address, You
                will receive an email confirmation regarding the same. If you
                wish to exchange the products, you must submit the following at
                the time of exchange: (a) Product Return Form; (b) Reason for
                Return; (c) Copy of Invoice; (d) Copy of Customer Order Receipt;
                (e) Products to be returned. Please note that the return
                shipping cost will be borne by you in case you wish to return
                the item for the reason “I no longer need / wish to keep the
                item”. We will take the returned product back with us and issue
                a credit note for the value of the return products which will be
                credited to your account on the Site. This can be used to pay
                your subsequent shopping bills. You Agree and Confirm 1. That in
                the event that a non-delivery occurs on account of a mistake by
                you (i.e. wrong name or address or any other wrong information)
                any extra cost incurred by ITPL for redelivery shall be claimed
                from you. 2. The Certain items are non-returnable, an icon on
                the item description page would specify that the item can’t be
                returned; 3. the product must be returned in the original
                condition they were received in with all the accompanying
                accessories, if any; 4. Replacement/ refund would only be
                initiated once the return is received and been inspected for
                defect/ damage; 5. Items that you wish to return must be in new
                and unopened condition with all the original packing, tags,
                inbox literature, and warranty/ guarantee card, freebies and
                accessories.
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Typography className={classes.title}>
                Intellectual Property Rights
              </Typography>
              <Typography className={classes.desc}>
                We are the licensed owners of the brand ‘Bigwayz Assist’ and the
                Platform, with license from Inginomicx Technologies Private
                Limited , a private company limited by shares incorporated under
                the Companies Act, 2013, having its registered office at -3rd
                Floor, # 4 & 5. 5th Main, Jayamahal Extension Bangalore,
                Karnataka – 560046, India, (“ITPL”). All content included on the
                Platform, such as text, graphics, names, design marks, slogans,
                logos, service marks, button icons, images, illustrations, audio
                clips, video clips, digital downloads, data compilations,
                software and any such intellectual property (“Marks”), is Our
                property, or the property of ITPL, its affiliates or content
                suppliers, and is protected by Indian and international
                intellectual property law, including copyright, authors’ rights
                and database rights laws. Users are prohibited from using any
                Marks for any purpose without the written permission of the
                Company or such third party which may own the Marks; No
                trademark or service mark license is granted in connection with
                the materials contained on the Platform. Access to the Platform
                does not confer and shall not be considered as conferring upon
                anyone any license of any of Ours or ITPL’s or any third party’s
                Marks. All content on the Platform is solely for your personal,
                non-commercial consumption. You must not copy, reproduce,
                republish, upload, post, transmit or distribute such material in
                any way, including by email or other electronic means and
                whether directly or indirectly and you must not assist any other
                person to do so. Modification of the materials, use of the
                materials on any other website or networked computer environment
                or use of the materials for any purpose other than personal,
                non-commercial consumption is a violation of the copyrights,
                trademarks and other proprietary rights, and is prohibited. For
                the purposes of this clause, any use for which you receive any
                remuneration, whether in money or otherwise (i.e. in kind), is a
                commercial use. Reference on the Platform to any names, marks,
                products or services of third parties or hypertext links to
                third party sites or information are provided solely as a
                convenience to you and do not in any way constitute or imply Our
                or ITPLs endorsement or recommendation, information, product or
                service. We or ITPL are not responsible for the content of any
                third party sites and do not make any representations regarding
                the content or accuracy of material on such sites. If you decide
                to link to any such third party websites, you do so at your own
                risk. If you have comments regarding the Services and/or the
                Platform or ideas on how to improve it, please contact our
                customer service. Please note that by doing so, you hereby
                irrevocably assign to the Company, and shall assign to the
                Company, all rights, title and interests in and to all ideas and
                suggestions and any and all worldwide intellectual property
                rights associated therewith. You agree to perform such acts and
                execute such documents as may be reasonably necessary to perfect
                the foregoing rights. You may not use the Site for any of the
                following purposes: 1. Disseminating any unlawful, harassing,
                libellous, abusive, threatening, harmful, vulgar, obscene, or
                otherwise objectionable material. 2. Transmitting material that
                encourages conduct that constitutes a criminal offence or
                results in civil liability or otherwise breaches any relevant
                laws, regulations or code of practice. 3. Gaining unauthorized
                access to other computer systems. 4. Interfering with any other
                person's use or enjoyment of the Site. 5. Breaching any
                applicable laws; 6. Interfering or disrupting networks or web
                sites connected to the Site. 7. Making, transmitting or storing
                electronic copies of materials protected by copyright without
                the permission of the owner.
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Typography className={classes.title}>Product Colours</Typography>
              <Typography className={classes.desc}>
                We have made every effort to display the colours of our products
                that appear on the Website as accurately as possible. However,
                as the actual colours you see will depend on your monitor, we
                cannot guarantee that your monitor's display of any colour will
                be accurate.
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Typography className={classes.title}>
                Modification of Terms & Conditions of Service
              </Typography>
              <Typography className={classes.desc}>
                ITPL may at any time modify the Terms & Conditions of Use of the
                Website without any prior notification to you. You can access
                the latest version of these Terms & Conditions at any given time
                on the Site. You should regularly review the Terms & Conditions
                on the Site. In the event the modified Terms & Conditions is not
                acceptable to you, you should discontinue using the Service.
                However, if you continue to use the Service you shall be deemed
                to have agreed to accept and abide by the modified Terms &
                Conditions of Use of this Site.
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Typography className={classes.title}>
                Governing Law and Jurisdiction
              </Typography>
              <Typography className={classes.desc}>
                This User Agreement shall be construed in accordance with the
                applicable laws of India. The Courts at Bangalore shall have
                exclusive jurisdiction in any proceedings arising out of this
                agreement. Any dispute or difference either in interpretation or
                otherwise, of any terms of this User Agreement between the
                parties hereto, the same shall be referred to an independent
                arbitrator who will be appointed by ITPL and his decision shall
                be final and binding on the parties hereto. The above
                arbitration shall be in accordance with the Arbitration and
                Conciliation Act, 1996 as amended from time to time. The
                arbitration shall be held in Bangalore. The High Court of
                judicature at Bangalore alone shall have the jurisdiction and
                the Laws of India shall apply.
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Typography className={classes.title}>
                Reviews, Feedback, Submissions
              </Typography>
              <Typography className={classes.desc}>
                All reviews, comments, feedback, postcards, suggestions, ideas,
                and other submissions disclosed, submitted or offered to the
                Site on or by this Site or otherwise disclosed, submitted or
                offered in connection with your use of this Site (collectively,
                the "Comments") shall be and remain the property of ITPL. Such
                disclosure, submission or offer of any Comments shall constitute
                an assignment to ITPL of all worldwide rights, titles and
                interests in all copyrights and other intellectual properties in
                the Comments. Thus, ITPL owns exclusively all such rights,
                titles and interests and shall not be limited in any way in its
                use, commercial or otherwise, of any Comments. ITPL will be
                entitled to use, reproduce, disclose, modify, adapt, create
                derivative works from, publish, display and distribute any
                Comments you submit for any purpose whatsoever, without
                restriction and without compensating you in any way. ITPL is and
                shall be under no obligation (1) to maintain any Comments in
                confidence; (2) to pay you any compensation for any Comments; or
                (3) to respond to any Comments. You agree that any Comments
                submitted by you to the Site will not violate this policy or any
                right of any third party, including copyright, trademark,
                privacy or other personal or proprietary right(s), and will not
                cause injury to any person or entity. You further agree that no
                Comments submitted by you to the Website will be or contain
                libelous or otherwise unlawful, threatening, abusive or obscene
                material, or contain software viruses, political campaigning,
                commercial solicitation, chain letters, mass mailings or any
                form of "spam". Please note all reviews submitted by customers
                are subject to checks and moderation by our content Team. ITPL
                reserves the rights to moderate as well as publish/not publish
                the reviews. ITPL reserves the rights to solicit or withhold
                reviews and ratings with no liability. ITPL reserve the right to
                accept, reject, moderate, monitor & edit or remove any comment.
                You grant ITPL the right to use the name that you submit in
                connection with any Comments. You agree not to use a false email
                address, impersonate any person or entity, or otherwise mislead
                as to the origin of any Comments you submit. You are and shall
                remain solely responsible for the content of any Comments you
                make, and you agree to indemnify ITPL and its affiliates for all
                claims resulting from any Comments you submit. ITPL and its
                affiliates take no responsibility and assume no liability for
                any Comments submitted by you or any third party.
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Typography className={classes.title}>
                Copyright & Trademark
              </Typography>
              <Typography className={classes.desc}>
                <br />
                1. ITPL and its suppliers and licensors expressly reserve all
                intellectual property rights in all text, programs, products,
                processes, technology, content and other materials, which appear
                on this Site.
                <br />
                2. Access to this Website does not confer and shall not be
                considered as conferring upon anyone any license under any of
                ITPL or any third party's intellectual property rights.
                <br />
                3. All rights, including copyright, in this website are owned by
                or licensed to ITPL. Any use of this website or its contents,
                including copying or storing it or them in whole or part, other
                than for your own personal, non-commercial use is prohibited
                without the permission of ITPL .
                <br />
                4. You may not modify, distribute or re-post anything on this
                website for any purpose. The names and logos and all related
                product and service names, design marks and slogans are the
                trademarks or service marks of ITPL, its affiliates, its
                partners or its suppliers.
                <br />
                5. All other marks are the property of their respective
                companies. No trademark or service mark license is granted in
                connection with the materials contained on this Site. Access to
                this Site does not authorize anyone to use any name, logo or
                mark in any manner.
                <br />
                6. References on this Site to any names, marks, products or
                services of third parties or hypertext links to third party
                sites or information are provided solely as a convenience to you
                and do not in any way constitute or imply ITPL endorsement,
                sponsorship or recommendation of the third party, information,
                product or service ITPL is not responsible for the content of
                any third party sites and does not make any representations
                regarding the content or accuracy of material on such sites.
                <br />
                7. If you decide to link to any such third party websites, you
                do so entirely at your own risk. All materials, including
                images, text, illustrations, designs, icons, photographs,
                programs, music clips or downloads, video clips and written and
                other materials that are part of this Website (collectively, the
                "Contents") are intended solely for personal, non-commercial
                use.
                <br />
                8. You may download or copy the Contents and other downloadable
                materials displayed on the Website for your personal use only.
                No right, title or interest in any downloaded materials or
                software is transferred to you as a result of any such
                downloading or copying.
                <br />
                9. You may not reproduce (except as noted above), publish,
                transmit, distribute, display, modify, create derivative works
                from, sell or participate in any sale of or exploit in any way,
                in whole or in part, any of the Contents, the Website or any
                related software.
                <br />
                10. All software used on this Website is the property of ITPL or
                its licensees and suppliers and protected by Indian and
                international copyright laws. The Contents and software on this
                Website may be used only as a shopping resource.
                <br />
                11. Any other use, including the reproduction, modification,
                distribution, transmission, republication, display, or
                performance, of the Contents on this Website is strictly
                prohibited. Unless otherwise noted, all Contents are copyrights,
                trademarks, trade dress and/or other intellectual property
                owned, controlled or licensed by ITPL, one of its affiliates or
                by third parties who have licensed their materials to ITPL and
                are protected by Indian and international copyright laws.
                <br />
                12. The compilation (meaning the collection, arrangement, and
                assembly) of all Contents on this Website is the exclusive
                property of ITPL and is also protected by Indian and
                international copyright laws.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography className={classes.title}>
                Objectionable Material
              </Typography>
              <Typography className={classes.desc}>
                You understand that by using this Site or any services provided
                on the Site, you may encounter Content that may be deemed by
                some to be offensive, indecent, or objectionable, which Content
                may or may not be identified as such. You agree to use the Site
                and any service at your sole risk and that to the fullest extent
                permitted under applicable law, ITPL and its affiliates shall
                have no liability to you for Content that may be deemed
                offensive, indecent, or objectionable to you.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography className={classes.title}>Indemnity</Typography>
              <Typography className={classes.desc}>
                You agree to defend, indemnify and hold harmless ITPL, its
                employees, directors, officers, agents and their successors and
                assigns from and against any and all claims, liabilities,
                damages, losses, costs and expenses, including attorney's fees,
                caused by or arising out of claims based upon your actions or
                inactions, which may result in any loss or liability to ITPL or
                any third party including but not limited to breach of any
                warranties, representations or undertakings or in relation to
                the non-fulfilment of any of your obligations under this User
                Agreement or arising out of the your violation of any applicable
                laws, regulations including but not limited to Intellectual
                Property Rights, payment of statutory dues and taxes, claim of
                libel, defamation, violation of rights of privacy or publicity,
                loss of service by other subscribers and infringement of
                intellectual property or other rights. This clause shall survive
                the expiry or termination of this User Agreement.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography className={classes.title}>Termination</Typography>
              <Typography className={classes.desc}>
                This User Agreement is effective unless and until terminated by
                either you or ITPL. You may terminate this User Agreement at any
                time, provided that you discontinue any further use of this
                Site. ITPL may terminate this User Agreement at any time and may
                do so immediately without notice, and accordingly deny you
                access to the Site, Such termination will be without any
                liability to ITPL. Upon any termination of the User Agreement by
                either you or ITPL, you must promptly destroy all materials
                downloaded or otherwise obtained from this Site, as well as all
                copies of such materials, whether made under the User Agreement
                or otherwise. ITPL's right to any Comments shall survive any
                termination of this User Agreement. Any such termination of the
                User Agreement shall not cancel your obligation to pay for the
                product already ordered from the Website or affect any liability
                that may have arisen under the User Agreement.
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Typography className={classes.title}>Grievance</Typography>
              <Typography className={classes.desc}>
                If you have any concerns or grievances with respect to the
                Services or any content hosted, transmitted, published, updated
                or shared on the Platform, You may contact the grievance officer
                at support@bigwayz.com. We will analyse the query and take such
                action as we deem appropriate under the circumstances.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </div>
      <MainFooter showContact={false} />
    </>
  );
}
