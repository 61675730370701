import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { IconButton, Typography, Popover } from "@material-ui/core";
import {
  lightGray,
  primaryColor,
  primaryTint,
  secondaryColor,
  whiteColor,
} from "../../../config/constants";
import { Link } from "react-router-dom";
import { firestore, storage } from "../../../config/firebase";
import { fade } from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Firebase from "firebase";
import axios from "axios";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  title: {
    fontSize: 24,
    color: secondaryColor,
    fontWeight: 700,
    margin: 20,
  },
  background: {
    backgroundColor: "rgba(240, 102, 105, 0.13)",
    padding: 16,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    borderRadius: 8,
    marginLeft: "10px",
    marginRight: "10px",
    marginBottom: "5px",
  },
  background1: {
    backgroundColor: "#ffffff",
    padding: 16,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    borderRadius: 8,
    marginLeft: "10px",
    marginRight: "10px",
  },
  avatartitle: {
    display: "flex",
    width: "20%",
  },
  table: {
    minWidth: 600,
  },
  tableContainer: {
    margin: "1%",
    width: "98%",
  },
  tablehead: {
    fontSize: "15px",
    fontWeight: "800",
    background: secondaryColor,
    color: "white",
  },
  icons: {
    display: "flex",
    justifyContent: "space-around",
  },
  editicon: {
    // marginRight:'30px',
    fill: primaryColor,
    cursor: "pointer",
  },
  deleteicon: {
    fill: secondaryColor,
    cursor: "pointer",
  },
  registeredname: {
    padding: "10px",
    fontSize: 16,
  },
  addbutton: {
    display: "flex",
    float: "right",
    margin: 16,
    width: 150,
    background: secondaryColor,
    color: whiteColor,
    padding: 3,
    textAlign: "center",
    cursor: "pointer",
    textDecoration: "none",
  },
  editbutton: {
    color: whiteColor,
    backgroundColor: secondaryColor,
    borderRadius: 8,
    padding: 8,
    textAlign: "center",
    margin: 5,
    cursor: "pointer",
    maxHeight: 35,
    textDecoration: "none",
  },
  date: {
    marginTop: 10,
    fontSize: 16,
    fontWeight: 300,
  },
  search: {
    position: "relative",
    display: "flex",
    float: "right",
    marginRight: 20,
    marginTop: 20,
    // width:200,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(secondaryColor, 0.15),
    "&:hover": {
      backgroundColor: fade(secondaryColor, 0.25),
    },
    marginLeft: 0,
    width: "200px",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

export default function Cashout() {
  const classes = useStyles();
  const [userList, setUserList] = useState([{}]);
  const [searchedUsers, setSearchedUsers] = useState([]);
  const history = useHistory();
  const [data, setData] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = useState(false);

  const [Loading, setLoading] = useState(true);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    console.log(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    getAllCategories();
  }, []);

  const getAllCategories = () => {
    firestore
      .collection("cashout")
      .get()
      .then((data) => {
        let dummyArray = [];
        data.forEach((doc) => {
          dummyArray.push(doc.data());
        });
        setData(dummyArray.reverse());
        setLoading(false);
      });
  };

  const renderStatus = (status) => {
    if (status == "success") {
      return (
        <div
          style={{
            backgroundColor: "green",
            padding: 2,
            color: "#ffffff",
            textAlign: "center",
            borderRadius: 8,
          }}
        >
          success
        </div>
      );
    } else if (status == "pending") {
      return (
        <div
          style={{
            backgroundColor: "#F6BE00",
            padding: 2,
            color: "#ffffff",
            textAlign: "center",
            borderRadius: 8,
          }}
        >
          pending
        </div>
      );
    }
  };

  return Loading ? (
    <div
    className="skeleton text-center d-flex justify-content-center"
    style={{
      width: "100%",
      marginTop: "3rem",
      flexDirection:'column',
      padding:30
    }}
  >
    <Skeleton
      style={{
        background: "#efefef",
        // width:'8rem',
        // marginLeft:'5%'
        // margin:'auto'
      }}
      animation="wave"
      variant="text"
      width={'100%'}
      height={64}
    />
     <Skeleton
      style={{
        background: "#efefef",
        // width:'8rem',
        // marginLeft:'5%'
        // margin:'auto'
      }}
      animation="wave"
      variant="text"
      width={'100%'}
      height={64}
    />
     <Skeleton
      style={{
        background: "#efefef",
        // width:'8rem',
        // marginLeft:'5%'
        // margin:'auto'
      }}
      animation="wave"
      variant="text"
      width={'100%'}
      height={64}
    />
     <Skeleton
      style={{
        background: "#efefef",
        // width:'8rem',
        // marginLeft:'5%'
        // margin:'auto'
      }}
      animation="wave"
      variant="text"
      width={'100%'}
      height={64}
    />
     <Skeleton
      style={{
        background: "#efefef",
        // width:'8rem',
        // marginLeft:'5%'
        // margin:'auto'
      }}
      animation="wave"
      variant="text"
      width={'100%'}
      height={64}
    />
     <Skeleton
      style={{
        background: "#efefef",
        // width:'8rem',
        // marginLeft:'5%'
        // margin:'auto'
      }}
      animation="wave"
      variant="text"
      width={'100%'}
      height={64}
    />
     <Skeleton
      style={{
        background: "#efefef",
        // width:'8rem',
        // marginLeft:'5%'
        // margin:'auto'
      }}
      animation="wave"
      variant="text"
      width={'100%'}
      height={64}
    />
     <Skeleton
      style={{
        background: "#efefef",
        // width:'8rem',
        // marginLeft:'5%'
        // margin:'auto'
      }}
      animation="wave"
      variant="text"
      width={'100%'}
      height={64}
    />
     <Skeleton
      style={{
        background: "#efefef",
        // width:'8rem',
        // marginLeft:'5%'
        // margin:'auto'
      }}
      animation="wave"
      variant="text"
      width={'100%'}
      height={64}
    />
     <Skeleton
      style={{
        background: "#efefef",
        // width:'8rem',
        // marginLeft:'5%'
        // margin:'auto'
      }}
      animation="wave"
      variant="text"
      width={'100%'}
      height={64}
    />
     <Skeleton
      style={{
        background: "#efefef",
        // width:'8rem',
        // marginLeft:'5%'
        // margin:'auto'
      }}
      animation="wave"
      variant="text"
      width={'100%'}
      height={64}
    />
     <Skeleton
      style={{
        background: "#efefef",
        // width:'8rem',
        // marginLeft:'5%'
        // margin:'auto'
      }}
      animation="wave"
      variant="text"
      width={'100%'}
      height={64}
    />
     <Skeleton
      style={{
        background: "#efefef",
        // width:'8rem',
        // marginLeft:'5%'
        // margin:'auto'
      }}
      animation="wave"
      variant="text"
      width={'100%'}
      height={64}
    />
  </div>
  ) : (
    <div>
      {/* <Alert severity="info">
                <AlertTitle>Search User Info</AlertTitle>
                The below Search Bar is an — <strong>universal searchbar!</strong> You can search users using their name, username, role, phone, email or current status.
            </Alert> */}
      <Grid container spacing={0}>
        <Grid item xs={6}>
          <Typography className={classes.title}>Cashout Requests</Typography>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid
          item
          xs={1}
          style={{ marginLeft: "20px", marginTop: "5px" }}
        ></Grid>
      </Grid>

      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell className={classes.tablehead}>Sr.</TableCell>
              <TableCell align="" className={classes.tablehead}>
                Transaction ID
              </TableCell>
              <TableCell align="" className={classes.tablehead}>
                Phone
              </TableCell>
              <TableCell align="left" className={classes.tablehead}>
                Text
              </TableCell>
              <TableCell align="left" className={classes.tablehead}>
                Status
              </TableCell>
              <TableCell align="left" className={classes.tablehead}>
                Amount
              </TableCell>
              <TableCell align="left" className={classes.tablehead}>
                Created On
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item, index) => {
              return (
                <>
                  {index % 2 == 0 ? (
                    <TableRow key={item.name}>
                      <TableCell component="th" scope="row">
                        {index + 1}
                      </TableCell>
                      <TableCell align="left" style={{ fontWeight: "800" }}>
                        {item.transactionId}
                      </TableCell>
                      <TableCell align="left">{item.phone}</TableCell>
                      <TableCell align="left">{item.text}</TableCell>
                      <TableCell align="left">
                        {renderStatus(item.status)}
                      </TableCell>
                      <TableCell align="left">{item.amount}</TableCell>
                      <TableCell align="left">
                        {new Date(item.createdAt).getDate() +
                          "/" +
                          (new Date(item.createdAt).getMonth() + 1) +
                          "/" +
                          new Date(item.createdAt).getFullYear()}
                      </TableCell>
                    </TableRow>
                  ) : (
                    <TableRow
                      key={item.name}
                      style={{ backgroundColor: lightGray }}
                    >
                      <TableCell component="th" scope="row">
                        {index + 1}
                      </TableCell>
                      <TableCell align="left" style={{ fontWeight: "800" }}>
                        {item.transactionId}
                      </TableCell>
                      <TableCell align="left">{item.phone}</TableCell>
                      <TableCell align="left">{item.text}</TableCell>
                      <TableCell align="left">
                        {renderStatus(item.status)}
                      </TableCell>
                      <TableCell align="left">{item.amount}</TableCell>
                      <TableCell align="left">
                        {new Date(item.createdAt).getDate() +
                          "/" +
                          (new Date(item.createdAt).getMonth() + 1) +
                          "/" +
                          new Date(item.createdAt).getFullYear()}
                      </TableCell>
                    </TableRow>
                  )}
                </>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
