import React, {useEffect, useState} from "react"
import {makeStyles} from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import {Checkbox, FormGroup, TextField, Typography} from "@material-ui/core"
import {
  primaryColor,
  secondaryColor,
  whiteColor,
} from "../../../config/constants"
import {Link, useLocation} from "react-router-dom"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormControl from "@material-ui/core/FormControl"
import {firestore, storage} from "../../../config/firebase"
import Button from "@material-ui/core/Button"
import {useHistory} from "react-router-dom"
import Snackbar from "@material-ui/core/Snackbar"
import Autocomplete from "@material-ui/lab/Autocomplete"
import Firebase from "firebase/app"
import Paper from "@material-ui/core/Paper"
import InfoIcon from "@material-ui/icons/Info"
import InputLabel from "@material-ui/core/InputLabel"
import Select from "@material-ui/core/Select"
import axios from "axios"
import reactRouterDom from "react-router-dom"

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
    margin: 20,
  },
  title: {
    fontSize: 24,
    color: secondaryColor,
    fontWeight: 700,
    margin: 5,
    marginLeft: 20,
  },
  background: {
    backgroundColor: "rgba(240, 102, 105, 0.13)",
    padding: 30,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  addbutton: {
    display: "flex",
    float: "right",
    margin: 5,
    marginTop: 5,
    width: 110,
    background: secondaryColor,
    color: whiteColor,
    padding: 3,
    textAlign: "center",
    cursor: "pointer",
    textDecoration: "none",
  },
  validation: {
    background: "red",
    color: "white",
    borderRadius: "10px",
    paddingLeft: "1em",
  },
  success: {
    background: "green",
    color: "white",
    borderRadius: "10px",
    paddingLeft: "1em",
  },
  editbutton: {
    color: whiteColor,
    backgroundColor: secondaryColor,
    padding: 8,
    textAlign: "center",
    marginTop: 10,
    cursor: "pointer",
    maxHeight: 35,
    textDecoration: "none",
    width: 150,
  },
  formcontainer: {
    marginLeft: 20,
    marginRigth: 20,
  },
  formtitle: {
    color: primaryColor,
    fontWeight: 600,
    fontSize: 16,
    paddingBottom: 10,
    paddingTop: 10,
  },
  forminput: {
    minWidth: 600,
    borderColor: secondaryColor,
    background: whiteColor,
    [theme.breakpoints.only("xs")]: {
      minWidth: "100%",
    },
  },
  imageuploaded: {
    marginBottom: 20,
    padding: 20,
    width: "50%",
    textAlign: "start",
    display: "flex",
  },
  buttons: {
    marginLeft: 20,
    backgroundColor: secondaryColor,
    color: whiteColor,
  },
}))

export default function AddDiscount() {
  const classes = useStyles()
  const [value, setValue] = React.useState("percentage")
  const [snackBarOpen, setSnackBarOpen] = React.useState(false)
  const history = useHistory()
  const location = useLocation()
  const [code, setCode] = useState("")
  const [price, setPrice] = useState("")
  const [title, setTitle] = useState("")
  const [Description, setDescription] = useState("")
  const [Image, setImage] = useState("")
  const [ValidationErrorMessage, setValidationErrorMessage] = useState("")
  const [
    CouponCreatedSuccessMessage,
    setCouponCreatedSuccessMessage,
  ] = useState("")

  useEffect(() => {
    // addCategory();
    if (location.item != null && location.item != undefined) {
      console.log("got category from locaiton =", location.item)
      // setUserName(location.item.name)
      // setValue(location.item.value)
    }
  }, [])

  const addDiscount = () => {
    if (code.replace(/\s/g, "").length === 0) {
      // setAddEmployeeSuccessMessage('')
      return setValidationErrorMessage("code is required")
    }

    if (price.replace(/\s/g, "").length === 0) {
      return setValidationErrorMessage("value is required")
    }

    if (title.replace(/\s/g, "").length === 0) {
      // setAddEmployeeSuccessMessage('')
      return setValidationErrorMessage("title is required")
    }

    if (Description.replace(/\s/g, "").length === 0) {
      return setValidationErrorMessage("description is required")
    }

    console.log(code.toUpperCase(), title, value, price, Description)

    console.log("validation successfull")

    const couponRef = firestore
      .collection("discountCoupons")
      .doc(code.toUpperCase())

    couponRef
      //   .firestore()
      //   .collection("discountCoupons")
      .get()
      .then((docSnapshot) => {
        if (docSnapshot.exists) {
          //   res.json("Code already exists!");

          console.log("code already exists please create new one")
          return setValidationErrorMessage(
            "code already exists please create new one"
          )
          //   return false;
        } else {
          couponRef
            .set({
              title: title,
              description: Description,
              type: value, // can be amount or percentage
              value: price,
              image: Image ? Image : "",
              code: code.toUpperCase(),
              status: "active",
            })
            .then((res) => {
              console.log("coupon created successfully", res)
              setSnackBarOpen(true)
              setValidationErrorMessage("")
              //   setCouponCreatedSuccessMessage('coupon created successfully')
              setTimeout(() => {
                history.push("/discountcoupon")
              }, 3000)
            })
          // handle success
        }
      })
      .catch((error) => {
        console.log("error", error)
        return false
      })

    // axios.post('https://us-central1-bigwayz-backend.cloudfunctions.net/create_discount_coupon', {
    //     "code": code.toUpperCase(),
    //     "type": value,
    //     "value": price,
    //     "title": title,
    //     "description": Description,
    //     "image": ""
    // })
    // .then(res => console.log(res.data));

    // setSnackBarOpen(true)

    // setTimeout(() => {
    //      history.push('/discountcoupon')
    // }, 3000);
  }

  return (
    <>
      <Paper className={classes.paper}>
        {ValidationErrorMessage && (
          <p className={classes.validation}> {ValidationErrorMessage} </p>
        )}

        {CouponCreatedSuccessMessage && (
          <p className={classes.success}> {CouponCreatedSuccessMessage} </p>
        )}

        <Grid container spacing={0}>
          <Grid item xs={6}>
            <Typography className={classes.title}>
              Add Discount Coupons
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Button
              onClick={() => history.push("/discountcoupon")}
              style={{backgroundColor: primaryColor}}
              className={classes.addbutton}
              variant="contained"
            >
              Go Back
            </Button>
          </Grid>
        </Grid>
        <Typography className={classes.formtitle}>Code</Typography>

        <TextField
          className={classes.forminput}
          placeholder="Enter Code"
          variant="outlined"
          onChange={(e) => setCode(e.target.value)}
        />

        <Typography className={classes.formtitle}>Type</Typography>
        <FormControl variant="outlined" className={classes.forminput}>
          <InputLabel htmlFor="outlined-age-native-simple">Type</InputLabel>
          <Select
            native
            value={value}
            onChange={(e) => {
              setValue(e.target.value)
            }}
            label="Age"
            inputProps={{
              name: "age",
              id: "outlined-age-native-simple",
            }}
          >
            <option value="percentage">percentage</option>
            <option value="amount">amount</option>
          </Select>
        </FormControl>

        <Typography className={classes.formtitle}>Value</Typography>
        {value === "amount" ? (
          <TextField
            className={classes.forminput}
            placeholder="Enter amount"
            inputProps={{type: "number"}}
            variant="outlined"
            onChange={(e) => setPrice(e.target.value)}
          />
        ) : (
          <TextField
            className={classes.forminput}
            maxLength={2}
            placeholder="Enter Percentage"
            inputProps={{type: "number"}}
            variant="outlined"
            onChange={(e) => setPrice(e.target.value)}
            inputProps={{maxLength: 2}}
          />
        )}

        <Typography className={classes.formtitle}>Title</Typography>
        <TextField
          className={classes.forminput}
          placeholder="Enter Title"
          variant="outlined"
          onChange={(e) => setTitle(e.target.value)}
        />

        <Typography className={classes.formtitle}>Description</Typography>
        <TextField
          className={classes.forminput}
          placeholder="Enter Description"
          variant="outlined"
          onChange={(e) => setDescription(e.target.value)}
        />

        <br />
        <Button
          onClick={() => addDiscount()}
          style={{backgroundColor: secondaryColor}}
          className={classes.editbutton}
          variant="contained"
        >
          Add Coupon{" "}
        </Button>

        <Snackbar
          anchorOrigin={{vertical: "bottom", horizontal: "center"}}
          open={snackBarOpen}
          onClose={() => setSnackBarOpen(false)}
          message="Added Successfully!"
        />
      </Paper>
      {/* <Paper className={classes.paper}>
                Information: These categories will be selected by users during the Registration process in the Bigwayz app, please keep the names simple for the users, and single word categories or category names without a space are preferred.
            </Paper> */}
    </>
  )
}
