import React from "react";
import { MDBCol, MDBContainer, MDBFooter, MDBRow } from "mdbreact";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import CallIcon from "@material-ui/icons/Call";
import { makeStyles } from "@material-ui/core/styles";
import {
  primaryColor,
  secondaryColor,
  whiteColor,
} from "../config/webconstants";
import { Typography } from "@material-ui/core";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import YouTubeIcon from "@material-ui/icons/YouTube";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import ContactUs from "./ContactUs";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import PlayStoreImage from "../assets/website/footerimages/playstore.png";
import RegisteredImage from "../assets/website/footerimages/registered1.png";
import RegisteredImage2 from "../assets/website/footerimages/registered2.png";
import LogoWhite from "../assets/website/footerimages/mainlogo_white.png";
import IosImage from "../assets/website/footerimages/app_store.png";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import Telphone from "../assets/website/images/telephoneicon.png";

const useStyles = makeStyles((theme) => ({
  logo: {
    height: 50,
    width: 200,
    marginTop: 20,
    objectFit: "contain",
  },
  scoialcontainer: {
    marginTop: "10%",
    width: "60%",
    // paddingLeft:'20%',
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      marginTop: "20px",
      padding: "0px 10%",
      width: "100%",
    },
  },

  connecttitle: {
    fontSize: 16,
    fontWeight: "600",
    color: whiteColor,
    letterSpacing: 1,
    textAlign: "start",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  socialicons: {
    display: "flex",
    padding: 10,
    backgroundColor: "#DC5D60",
    justifyContent: "space-between",
    borderRadius: 12,
  },
  socialicon: {
    margin: "0px 5px",
    color: whiteColor,
    cursor: "pointer",
    "&:hover": {
      color: secondaryColor,
    },
  },
  horizontalline: {
    padding: "2% 0px",
    padding: 1,
    backgroundColor: whiteColor,
  },
  footersecond: {
    color: whiteColor,
    padding: "0px 2%",
    paddingTop: 15,
    textAlign: "center",
  },
  links: {
    textDecoration: "none",
    textAlign: "start",
    color: whiteColor,
    padding: "20px 0px",
    fontFamily: "Myraid",
    "&:hover": {
      color: whiteColor,
      fontWeight: "600",
    },
  },
  linkcontainer: {
    padding: "10px 0px",
  },
  imagescontainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
  registeredimage: {
    width: "100px",
    height: "100px",
    objectFit: "contain",
    margin: "5px 0px",
    marginRight: "20px",
    [theme.breakpoints.down("md")]: {
      marginRight: "5px",
    },
  },
  footerrow: {
    textAlign: "justify",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  },
  applinks: {
    display: "flex",
    justifyContent: "start",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
  appimage: {
    height: 100,
    width: "45%",
    objectFit: "contain",
    paddingRight: "5%",
    cursor: "pointer",
  },
  telephoneicon: {
    height: "6%",
    width: "6%",
    objectFit: "contain",
    paddingRight: 2,
  },
  clickable: {
    cursor: "pointer",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

function MainFooter({ showContact, isDistributor }) {
  const classes = useStyles();

  return (
    <div>
      {showContact === false ? (
        <></>
      ) : (
        <ContactUs isDistributor={isDistributor} />
      )}

      <MDBFooter
        color="stylish-color-dark"
        style={{ backgroundColor: primaryColor }}
        className="page-footer font-small p-4"
      >
        <MDBContainer fluid className="text-center text-md-left">
          <MDBRow style={{ padding: " 0px 5%" }}>
            <MDBCol md="4" className={classes.footerrow}>
              <img src={LogoWhite} alt="logo" className={classes.logo} />
              <div className={classes.scoialcontainer}>
                <Typography className={classes.connecttitle}>
                  Connect with us
                </Typography>
                <div className={classes.socialicons}>
                  <a href="https://www.facebook.com/bigwayz.benefits">
                    <FacebookIcon className={classes.socialicon} />
                  </a>
                  <a href="https://www.linkedin.com/company/bigwayz">
                    <LinkedInIcon className={classes.socialicon} />
                  </a>
                  <a href="https://www.instagram.com/bigwayz_official/">
                    <InstagramIcon className={classes.socialicon} />
                  </a>
                  {/* <WhatsAppIcon className={classes.socialicon} /> */}
                  <a href="https://www.youtube.com/channel/UCQnOpduAlSfaOwVhHYDEJsg">
                    <YouTubeIcon className={classes.socialicon} />
                  </a>
                  <a href="https://twitter.com/BigwayzOfficial">
                    <TwitterIcon className={classes.socialicon} />
                  </a>
                </div>
              </div>
            </MDBCol>
            <MDBCol md="4" className={classes.footerrow}>
              <h5
                style={{
                  color: whiteColor,
                  fontFamily: "Myraid",
                  fontWeight: "600",
                }}
                className="text-uppercase mb-4 mt-3 font-weight-bold"
              >
                Important Links
              </h5>
              <ul className="list-unstyled">
                <li className={classes.linkcontainer}>
                  <a className={classes.links} href="/privacypolicy">
                    Privacy Policy
                  </a>
                </li>
                <li className={classes.linkcontainer}>
                  <a className={classes.links} href="/TermsAndConditions">
                    Terms & Conditions
                  </a>
                </li>
                <li className={classes.linkcontainer}>
                  <a className={classes.links} href="/calculator">
                    Income Calculator
                  </a>
                </li>
                <li className={classes.linkcontainer}>
                  <a className={classes.links} href="/products">
                    Products
                  </a>
                </li>
                <li className={classes.linkcontainer}>
                  <a className={classes.links} href="/entrepreneur">
                    Entrepreneur Program
                  </a>
                </li>

                <li className={classes.linkcontainer}>
                  <a className={classes.links} href="/distributor">
                    Distributor Program
                  </a>
                </li>
                <li className={classes.linkcontainer}>
                  <a className={classes.links} href="/corporate">
                    Corporate
                  </a>
                </li>

                <li className={classes.linkcontainer}>
                  <Typography className={classes.connecttitle}>
                    Download our app on
                  </Typography>
                  <div className={classes.applinks}>
                    <img
                      onClick={() =>
                        window.open(
                          "https://play.google.com/store/apps/details?id=com.bigwayz"
                        )
                      }
                      src={PlayStoreImage}
                      alt="playstoreimage"
                      className={classes.appimage}
                    />
                    <img
                      onClick={() => alert("Coming soon!")}
                      src={IosImage}
                      alt="playstoreimage"
                      className={classes.appimage}
                    />
                  </div>
                </li>
              </ul>
            </MDBCol>
            <MDBCol
              style={{ color: "#ffffff" }}
              md="4"
              className={classes.footerrow}
            >
              <h5
                style={{
                  color: whiteColor,
                  fontFamily: "Myraid",
                  fontWeight: "600",
                }}
                className="text-uppercase mb-4 mt-3 font-weight-bold"
              >
                Registered With
              </h5>
              <div className={classes.imagescontainer}>
                <img
                  src={RegisteredImage}
                  alt="registeredimage"
                  className={classes.registeredimage}
                />
                <img
                  src={RegisteredImage2}
                  alt="registeredimage"
                  className={classes.registeredimage}
                />
              </div>
              <ul className="list-unstyled">
                <li>
                  <p>
                    {" "}
                    <img
                      src={Telphone}
                      alt="telephone"
                      className={classes.telephoneicon}
                    />
                    {"  "}:
                    <a
                      style={{ color: "#ffffff" }}
                      href="tel:08041496046"
                      className={classes.clickable}
                    >
                      {"  "}08041496046{" "}
                    </a>
                  </p>
                </li>
                <li>
                  <p>
                    <CallIcon />
                    {"  "}:{" "}
                    <a
                      style={{ color: "#ffffff" }}
                      href="tel:9113629361"
                      className={classes.clickable}
                    >
                      {"  "} 9113629361{" "}
                    </a>
                  </p>
                </li>
                <li>
                  <p style={{ color: "#ffffff" }}>
                    <MailOutlineIcon /> {"  "}:{" "}
                    <a
                      style={{ color: "#ffffff" }}
                      className={classes.clickable}
                      href="mailto:info@bigwayz.com"
                    >
                      {"  "}info@bigwayz.com
                    </a>
                  </p>
                </li>
                {/*<li>*/}
                {/*    <a href="#!">+91-9587917917</a>*/}
                {/*</li>*/}
                <li>
                  <p href="#!">
                    <LocationOnOutlinedIcon />
                    {"  "} : Inginomicx Technologies Pvt ltd
                    <br />
                    3rd Floor, Padmashree building, 5th Main Rd,
                    <br /> Jayamahal Extension, Bangalore, Karnataka 560046
                    <br /> <br /> CIN: U93090KA2019PTC129904
                  </p>
                </li>
              </ul>
            </MDBCol>
          </MDBRow>
          <div className={classes.horizontalline}></div>
          <div className={classes.footersecond}>
            Copyright © {new Date().getFullYear()} Inginomicx Technologies
            Private Limited. All Rights Reserved.
          </div>
        </MDBContainer>
      </MDBFooter>
    </div>
  );
}

export default MainFooter;
