import React from 'react'
import { Carousel, Container } from 'react-bootstrap'
import BGImage from "../assets/website/images/bg.jpg"
import { makeStyles } from '@material-ui/core/styles';
import { lightGray, primaryColor, secondaryColor, whiteColor } from '../config/webconstants';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Grid, Typography,Button, Box } from '@material-ui/core';


const useStyles = makeStyles(theme =>({
    root: {
        flex:1,
        flexDirection:'column',
        overflowX:'hidden'
    },
    carouselcaptions:{
        position: 'absolute',
        right: '15%',
        left: '5%',
        color: '#fff',
        display:'flex'
    },
    controlicon:{
        fontSize:50,
        color:primaryColor,
        display: 'none'
    },
}))

export default function Slider({Image}) {
    const classes = useStyles()
    return (
        <Carousel 
        indicators={false}
        nextIcon={<ChevronRightIcon className={classes.controlicon}/>} nextLabel=""
        prevIcon={<ChevronLeftIcon className={classes.controlicon}/>} prevLabel=""
        >
            {Image?.map((item,index)=>(
                <Carousel.Item>
                    {/* <Typography style={{marginBottom:'5px', fontSize:'20px', fontWeight:'bold'}}>{item?.title}</Typography> */}
                    <img
                        className="d-block w-100"
                        src={item?.img}
                        style={{width:'100%', height:'300px' , objectFit:'contain'}}
                        alt="First slide"
                    />
            </Carousel.Item>
            ))}

            </Carousel>
    )
}
