import React,{useState} from 'react';
import validator from 'validator';
import PhoneIcon from '@material-ui/icons/Phone';
import {makeStyles, TextField, Typography } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import DateRangeIcon from '@material-ui/icons/DateRange';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Checkbox from '@material-ui/core/Checkbox';
import NativeSelect from '@material-ui/core/NativeSelect';
import FormControl from '@material-ui/core/FormControl';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { DropzoneArea, DropzoneDialog } from "material-ui-dropzone";
import MainNavBar from '../../components/MainNavBar';
import MainFooter from '../../components/MainFooter';
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import {Firebase, storage} from '../../config/firebase'
import LockIcon from '@material-ui/icons/Lock';
import axios from 'axios';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';




const API = axios.create({ baseURL: 'https://us-central1-bigwayz-backend.cloudfunctions.net'})

const IndiaState = [ "Andhra Pradesh","Arunachal Pradesh","Assam","Bihar","Chhattisgarh","Goa","Gujarat","Haryana","Himachal Pradesh","Jammu and Kashmir",
"Jharkhand","Karnataka","Kerala","Madhya Pradesh","Maharashtra","Manipur","Meghalaya","Mizoram","Nagaland","Odisha","Punjab","Rajasthan","Sikkim",
"Tamil Nadu","Telangana","Tripura","Uttarakhand","Uttar Pradesh","West Bengal","Andaman and Nicobar Islands","Chandigarh","Dadra and Nagar Haveli","Daman and Diu","Delhi","Lakshadweep","Puducherry"]

const useStyles = makeStyles(theme =>({
    container:{
        width: '50%',
        margin: '50px auto',
        minHeight: '80vh',
        paddingBottom: '50px',
        [theme.breakpoints.down('sm')]: {
            width:'95%'
        },
    },
    container1:{
        width: '50%',
    },
    mobform:{
        display: 'flex', 
        flexDirection: 'column',
        
    },
    containerDivTextfield:{
        display: 'flex', 
        border: '1px solid #31C7D7', 
        borderRadius: '5px', 
        marginTop:'10px',
    },
    containerDivTextfield2:{
        display: 'flex', 
        border: '1px solid #31C7D7', 
        borderRadius: '5px', 
        marginTop:'10px',
        width:'100%'
    },
    IconDiv:{
        color: '#31C7D7', 
        borderRight:'1px solid #31C7D7', 
        minWidth: '50px', 
        maxWidth: '50px', 
        display:'flex', 
        justifyContent: 'center',
        alignItems: 'center'
    },
    Textfield2:{
        border: 'none',
        marginLeft:'10px', 
        padding: '5px', 
        width:'100%'
    },
    Textfield:{
        border: 'none',
        marginLeft:'10px', 
        padding: '5px', 
        width:'100%'
    },
    containerDivSubmit:{
        width:'100%',
        [theme.breakpoints.down('sm')]: {
            width:'100%'
        },
    },
    containerDivSubmit2:{
        width:'100%',
        
    },
    SubmitbtnEdit:{
        backgroundColor:'#31C7D7', 
        color:'white',
        border: 'none',  
        borderRadius: '5px', 
        width:'10%', 
        marginTop:'10px', 
        marginLeft: 'auto',
        float: 'right', 
        height: '40px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    Submitbtn:{
        backgroundColor:'#31C7D7', 
        color:'white',
        border: 'none',  
        borderRadius: '5px', 
        width:'50%', 
        marginTop:'10px', 
        marginLeft: 'auto',
        float: 'right', 
        height: '40px'
    },
    TogglebtnTrue:{
        backgroundColor:'#31C7D7', 
        color:'white',
        border: 'none',
        borderRadius: '5px', 
        margin:'5px', 
        padding:'5px', 
        minWidth: '60px',
        textAlign:'center'
    },
    TogglebtnFalse:{
        backgroundColor:'white',
        border: '1px solid  #31C7D7',
        borderRadius: '5px', 
        margin:'5px', 
        padding:'5px', 
        minWidth: '60px',
        textAlign:'center'
    },
    GenderTypoDiv:{
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'space-between', 
        width:'100%'
    },
    GenderTypo:{
        border: 'none',
        marginLeft:'10px', 
        width:'85%', 
        color: '#696969'
    },
    WhatsappNotDiv:{
        display: 'flex', 
        flexDirection: 'column',
        border: '1px solid #31C7D7', 
        borderRadius: '5px',
        marginTop:'10px', 
        padding:'10px 25px',
    },
    WhatsappNotcheckDiv:{
        display: 'flex', 
        alignItems: 'center', 
        width:'100%'
    },
    WhatsappNotcheckTypo:{
        border: 'none',
        marginRight:'20px', 
        color: '#696969'
    },
    WhatsappNotAlternativeTypo:{
        border: 'none',
        marginTop:'20px', 
        color: '#696969'
    },
    WhatsappNotAlternativeDiv:{
        display: 'flex', 
        border: '1px solid #31C7D7', 
        borderRadius: '5px', 
        marginTop:'5px'
    },
    containerBusiness:{
        marginTop: '100px', 
        width:'50%',
        [theme.breakpoints.down('sm')]: {
            width:'100%'
        },
    },
    containerPersonal:{
        width:'50%',
        [theme.breakpoints.down('sm')]: {
            width:'100%'
        },
    },
    containerMob:{
        display:'flex',
        [theme.breakpoints.down('sm')]: {
            flexDirection:'column',
            alignItems: 'center'
        },
    },
    containerMobForm:{
        minWidth: '50%',
        [theme.breakpoints.down('sm')]: {
            width:'100%'
        },
    },
    root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
        background:'#D2691E'
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
    icon: {
        color: "#31C7D7 !important"
    }
}))

const theme = createMuiTheme({
    overrides: {
        MuiDropzoneArea: {
        root:{
            color:'#31C7D7',
            borderColor: '#31C7D7'
            },
        text:{
            color:'#31C7D7'
        },
        icon:{
            color:'#31C7D7'
        }
    }
    }
});


function VerifyPhoneNUM({mobileNumber, onChange, handleNext}) {
    const classes = useStyles()
    const [otpnumber, setotpnumber] = useState(null);
    const [otpoption, setotpoption] = useState(false);
    const [verified, setverified] = useState(false);

    const otpnumberInputChange = (e) => {
        if(e.target.value?.length <= 0){
            setotpnumber(e.target.value);
        }
        else if(validator.isNumeric(e.target.value) && e.target.value.length < 6){
            setotpnumber(e.target.value);
        }
        else if(validator.isNumeric(e.target.value) && e.target.value?.length === 6){
            setotpnumber(e.target.value);
        }
    }

    function configureCaptcha (){
        window.recaptchaVerifier = new Firebase.auth.RecaptchaVerifier('recaptcha-container', {
            size: 'invisible',
            callback: function (response) {
                console.log("recature resolved")
                onSignInSubmit();
            }
        });
    
    }

    const onSignInSubmit = (e) => {
        e.preventDefault()
        configureCaptcha()
        const phoneNumber = "+91" + mobileNumber
        console.log(phoneNumber)
        const appVerifier = window.recaptchaVerifier;
        Firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier)
            .then((confirmationResult) => {
                window.confirmationResult = confirmationResult;
                console.log("OTP has been sent")
                setotpoption(true);
            }).catch((error) => {
                console.log(error);
                console.log("SMS not sent")
            });
    }

    const onSubmitOTP = (e) =>{
        e.preventDefault()
        const code = otpnumber;
        console.log(code)
        window.confirmationResult.confirm(code).then((result) => {
            const user = result.user;
            const data = {phone: mobileNumber};
            console.log('Submitted')
            API.post('/create_user_step1', data).then((result) => {
                if(result?.data?.result === 'success')
                {
                    handleNext();
                }
            })
        }).catch((error) => {
        });
    }

    return (
        <div className={classes.containerMob} >

        <form  style={{minWidth: '50%'}} className={classes.mobform} onSubmit={onSignInSubmit}>
            <div id="recaptcha-container"></div>
            <div className={classes.containerDivTextfield2}>
            <div className={classes.IconDiv}><PhoneIcon /></div>
            <TextField className={classes.Textfield}
                placeholder="Mobile Number" 
                name="mobileNumber"
                value={mobileNumber} 
                onChange={onChange}
                InputProps={{
                    disableUnderline: true,
                }}
                required />
            </div>
            {otpoption===false && (
                <div className={classes.containerDivSubmit}>
                    <button className={classes.Submitbtn} type="submit" >Submit</button>
                </div>
            )}
        </form>


        {otpoption === true && (
            <div>
                {verified === false ?(
                    <form style={{display: 'flex'}} onSubmit={onSubmitOTP}>
                    <div style={{display: 'flex', border: '1px solid #31C7D7', borderRadius: '5px', width: '250px', marginTop:'10px', padding: '5px', marginLeft:'50px',height: '40px'}}>
                        <TextField
                            style={{border: 'none',marginLeft:'10px', width:'100%'}}
                            placeholder="OTP Verify" 
                            name="otpnum" 
                            value={otpnumber} 
                            onChange={(e) => otpnumberInputChange(e)}
                            InputProps={{
                                disableUnderline: true,
                            }}
                            required  />
                    </div>
                        <button style={{ backgroundColor:'#31C7D7',color:'white',border: 'none',  borderRadius: '5px', width:'50%', marginTop:'10px',float: 'right', height: '40px', marginLeft: '10px'}} type="submit" >Verify</button>
                    </form>
                ) : (
                    <div style={{color:'#31C7D7', marginTop:'10px', marginLeft: '10px'}}><CheckCircleIcon />Verified</div>
                )}
            </div>
            
        )}
        </div>
    )
}

function Personaldetails({mobileNumber,handleNext}){
    const classes = useStyles();
    const [personaldetails, setpersonaldetails] = useState({
        firstName:'',
        lastName:'',
        dob: '',
        gender: 'Male',
        email: '',
        receiveWhatsAppNotification: 'N',
        whatsAppNumber: '',
    });
    const [registeredCheck, setRegisteredCheck] = useState(true); 

    const changeInput = (event) =>{
        setpersonaldetails((prevProps) => ({
            ...prevProps,
            [event.target.name]: event.target.value
        }));
    }

    const persondetailsSubmit = (event) =>{
        event.preventDefault();

        if(personaldetails.receiveWhatsAppNotification === 'Y')
        {
            if(registeredCheck === true){
                personaldetails.whatsAppNumber = mobileNumber;
            }
            else{
                if(!validator.isMobilePhone(personaldetails.whatsAppNumber, ['en-IN']))
                {
                console.log(personaldetails.whatsAppNumber);
                console.log('Inavlid Mobile Number');
                return;
                }
            }
        }else{
            personaldetails.whatsAppNumber = ''
        }

        const data = {   phone: mobileNumber, 
            firstName: personaldetails.firstName, 
            lastName: personaldetails.lastName, 
            dob: personaldetails.dob, 
            gender: personaldetails.gender, 
            email: personaldetails.email, 
            receiveWhatsAppNotification: personaldetails.receiveWhatsAppNotification,
            whatsAppNumber: personaldetails.whatsAppNumber
        }

        

        API.post('/create_user_step2', data).then((result) => {
            if(result?.data?.result === 'success')
                {
                    handleNext();
                }
        })
    }

    return (
        <form className={classes.containerPersonal} onSubmit={persondetailsSubmit}>
            <Typography style={{fontSize: '20px', marginBottom: '20px'}}>Personal Information</Typography>
        <div className={classes.containerDivTextfield}>
            <div className={classes.IconDiv}><PersonIcon /></div>
            <TextField className={classes.Textfield}
            placeholder="First Name" 
            name="firstName"
            pattern="[A-Za-z]"
            value={personaldetails.firstName} 
            onChange={(e) => changeInput(e)}
            InputProps={{
                disableUnderline: true,
            }}
            required  />
        </div>

        <div className={classes.containerDivTextfield}>
            <div className={classes.IconDiv}><DateRangeIcon /></div>
            <TextField className={classes.Textfield}
            type="date"
            placeholder="Date of Birth" 
            name="dob"
            value={personaldetails.dob} 
            onChange={(e) => changeInput(e)}
            InputProps={{
                disableUnderline: true,
            }}
            required  />
        </div>

        <div className={classes.containerDivTextfield}>
            <div className={classes.IconDiv}><AlternateEmailIcon /></div>
            <TextField className={classes.Textfield}
            placeholder="E-Mail" 
            name="email"
            value={personaldetails.email} 
            onChange={(e) => changeInput(e)}
            InputProps={{
                disableUnderline: true,
            }}
            required  />
        </div>

        <div className={classes.containerDivTextfield}>
            <div className={classes.IconDiv}><PhoneIcon /></div>
            <div className={classes.GenderTypoDiv}>
                <Typography className={classes.GenderTypo}>Gender</Typography>
                <button type="button" className={personaldetails.gender === 'Male' ? classes.TogglebtnTrue : classes.TogglebtnFalse}  
                name="gender"  value="Male" onClick = {(e) => changeInput(e)}>Male</button>
                <button type="button" className={personaldetails.gender === 'Female' ? classes.TogglebtnTrue : classes.TogglebtnFalse}
                    name="gender"  value="Female" onClick = {(e) => changeInput(e)}>Female</button>
                <button type="button" className={personaldetails.gender === 'Others' ? classes.TogglebtnTrue : classes.TogglebtnFalse}
                    name="gender"  value="Others" onClick = {(e) => changeInput(e)}>Others</button>
            </div>
        </div>

        <div className={classes.containerDivTextfield}>
            <div className={classes.IconDiv}><NotificationsIcon  /></div>
            <div className={classes.GenderTypoDiv}>
                <Typography className={classes.GenderTypo}>Receive Whatsapp Notifications</Typography>
                <button type="button" className={personaldetails.receiveWhatsAppNotification === 'Y' ? classes.TogglebtnTrue : classes.TogglebtnFalse} name="receiveWhatsAppNotification" value="Y" onClick = {(e) => changeInput(e)}>Yes</button>
                <button type="button" className={personaldetails.receiveWhatsAppNotification === 'N' ? classes.TogglebtnTrue : classes.TogglebtnFalse} name="receiveWhatsAppNotification" value="N" onClick = {(e) => changeInput(e)}>No</button>
            </div>
        </div>
        
        {personaldetails.receiveWhatsAppNotification === 'Y' && (
                <div className={classes.WhatsappNotDiv}>
                <div className={classes.WhatsappNotcheckDiv}>
                    <Typography className={classes.WhatsappNotcheckTypo}>Registered Number is my Whatsapp Number</Typography>
                    <Checkbox style={{color:'#31C7D7'}} color="primary" checked={registeredCheck} onClick={() => setRegisteredCheck(!registeredCheck)}/>
                </div>
                {registeredCheck === false && (
                    <div>
                        <Typography className={classes.WhatsappNotAlternativeTypo}>Alternate Whatsapp Number</Typography>
                        <div className={classes.WhatsappNotAlternativeDiv}>
                            <TextField className={classes.Textfield}
                            type='number'
                            placeholder="Enter Whatsapp Number" 
                            name="whatsAppNumber"
                            value={personaldetails.whatsAppNumber} 
                            onChange={(e) =>
                                {if(e.target.value?.length < 11){
                                    changeInput(e)
                                }}
                            }
                            InputProps={{
                                disableUnderline: true,
                            }}
                            required  />
                        </div>
                    </div>
                )}
            </div>
        )}
        
        <div className={classes.containerDivSubmit2}>
        <button className={classes.Submitbtn} type="submit">Submit</button>
        </div>
    
    </form>
    )
}


function BusinessDetails({mobileNumber, handleNext}){
    const classes = useStyles();
    const [businessdetails, setbusinessdetails] = useState({
        gstin: '',
        gstImage: null,
        registeredBusiness: 'N',
        businessName: '',
        businessAddress: '',
        businessPinCode: '',
        businessCity: '',
        businessState: '',
        businessPan: '',
        businessPanImage: null,
        homeAddress: '',
        homePincode: '',
        homeCity: '',
        homeState: '',
        businessCategory: ''
    })
    const [isgst, setisgst] = useState(false);


    const businessChangeInput = (event) =>{
        setbusinessdetails((prevProps) => ({
            ...prevProps,
            [event.target.name]: event.target.value
        }));
    }
    
    const onDropzoneAreaChange = async(files) => {

        if(files[0]!==undefined){
            const imageType = files[0].type.substring(6).toLowerCase()
            const uploadTask = await storage.ref(`registrationImage/${mobileNumber}/gst.${imageType}`).put(files[0]);
            const gstdownloadurl = await storage.ref(`registrationImage/${mobileNumber}/gst.${imageType}`).getDownloadURL();
            businessdetails.gstImage = gstdownloadurl;
        }
    };

    const onDropzoneAreaChangePan = async(files) => {

        if(files[0]!==undefined) {
            const imageType = files[0].type.substring(6).toLowerCase()
            const uploadTask = await storage.ref(`registrationImage/${mobileNumber}/pan.${imageType}`).put(files[0]);
            const pandownloadurl = await storage.ref(`registrationImage/${mobileNumber}/pan.${imageType}`).getDownloadURL();
            businessdetails.businessPanImage = pandownloadurl
        }
        
        
    }

    const businessdetailsSubmit = async(event) =>{
        event.preventDefault();
        
        //PanImage
        if(businessdetails.registeredBusiness === 'Y')
        {   
                const data = {
                    phone : mobileNumber,
                    gstin: businessdetails.gstin,
                    gstImage: businessdetails.gstImage,
                    registeredBusiness: businessdetails.registeredBusiness,
                    businessName: businessdetails.businessName,
                    businessAddress: businessdetails.businessAddress,
                    businessPinCode: businessdetails.businessPinCode,
                    businessCity: businessdetails.businessCity,
                    businessState: businessdetails.businessState,
                    businessPan: businessdetails.businessPan,
                    businessPanImage: businessdetails.businessPanImage,
                    homeAddress: '',
                    homePincode: '',
                    homeCity: '',
                    homeState: '',
                    businessCategory: ''
                }
                 console.log(data);
             API.post('/create_user_step3', data).then((result) => {
                if(result?.data?.result === 'success')
                {
                    handleNext();
                }
                })
        }else{
            const data = {
                phone : mobileNumber,
                gstin: businessdetails.gstin,
                gstImage: businessdetails.gstImage,
                registeredBusiness: businessdetails.registeredBusiness,
                businessName: '',
                businessAddress: '',
                businessPinCode: '',
                businessCity: '',
                businessState: '',
                businessPan: '',
                businessPanImage: null,
                homeAddress: businessdetails.homeAddress,
                homePincode: businessdetails.homePincode,
                homeCity: businessdetails.homeCity,
                homeState: businessdetails.homeState,
                businessCategory: businessdetails.businessCategory
            }
            console.log(data);
            API.post('/create_user_step3', data).then((result) => {
                if(result?.data?.result === 'success')
                {
                    handleNext();
                }
            })
        }
        
    }

    return (
        <form className={classes.containerBusiness} onSubmit={businessdetailsSubmit}>
        <Typography style={{fontSize: '20px', marginBottom: '20px'}}>Business Information</Typography>
        <Typography>For GST Tax Credit :</Typography>
        <div style={{display:'flex', alignItems: 'center'}}>
            <Typography>Do you have GSTIN</Typography>
            <button type="button" className={isgst ? classes.TogglebtnTrue : classes.TogglebtnFalse} onClick = {(e) => setisgst(true)} >Yes</button>
            <button type="button" className={!isgst ? classes.TogglebtnTrue : classes.TogglebtnFalse}name="gender"  value="Male" onClick = {(e) => setisgst(false)} >No</button>
        </div>

        {isgst === true && (
            <div>
                <div className={classes.containerDivTextfield}>
                    <TextField className={classes.Textfield}
                    placeholder="GSTIN"
                    name="gstin"
                    value={businessdetails.gstin}
                    onChange={e => businessChangeInput(e)}
                    InputProps={{
                        disableUnderline: true,
                    }}/>
                </div>

                <div style={{margin:'20px 0px'}}>
                <MuiThemeProvider theme={theme}>
                    <DropzoneArea
                    acceptedFiles={["image/*"]}
                    filesLimit={1}
                    maxFileSize={1048576} //1 MB
                    showFileNames={true}
                    dropzoneText={"Drag and drop an image here or click GST Image"}
                    onChange={onDropzoneAreaChange}
                    />
                </MuiThemeProvider>
                </div>
            </div>
        )}
        
        
        <div style={{display:'flex', alignItems: 'center'}}>
            <Typography>Registered Business Name</Typography>
            <button type="button" className={businessdetails.registeredBusiness === 'Y' ? classes.TogglebtnTrue : classes.TogglebtnFalse} name="registeredBusiness" value="Y" onClick = {e => businessChangeInput(e)} >Yes</button>
            <button type="button" className={businessdetails.registeredBusiness === 'N' ? classes.TogglebtnTrue : classes.TogglebtnFalse} name="registeredBusiness" value="N" onClick = {e => businessChangeInput(e)} >No</button>
        </div>
        
        {businessdetails.registeredBusiness === 'Y' ? (
            <div>
                <div className={classes.containerDivTextfield}>
                    <TextField className={classes.Textfield}
                    placeholder="Business Name"
                    name="businessName"
                    value={businessdetails.businessName}
                    onChange={e => businessChangeInput(e)}
                    InputProps={{
                        disableUnderline: true,
                    }}/>
                </div>

                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div style={{display: 'flex', border: '1px solid #31C7D7', borderRadius: '5px', marginTop:'10px',width:'49%'}}>
                        <TextField className={classes.Textfield}
                        type="number"
                        placeholder="Pin code"
                        name="businessPinCode"
                        value={businessdetails.businessPinCode}
                        onChange={e => {
                            if(e.target.value?.length < 7)
                            {
                                businessChangeInput(e)}}
                            }
                        InputProps={{
                            disableUnderline: true,
                        }}/>
                    </div>

                    <div style={{display: 'flex', border: '1px solid #31C7D7', borderRadius: '5px', marginTop:'10px',width:'49%'}}>
                        <TextField className={classes.Textfield}
                        placeholder="City"
                        name="businessCity"
                        value={businessdetails.businessCity}
                        onChange={e => businessChangeInput(e)}
                        InputProps={{
                            disableUnderline: true,
                        }}/>
                    </div>
                </div>

                <div className={classes.containerDivTextfield}>
                    <FormControl style={{width:'100%', marginLeft:'15px', padding: '5px'}}>
                        <NativeSelect
                        name="businessState"
                        value={businessdetails.businessState}
                        inputProps={{ disableUnderline: true }}
                        onChange={businessChangeInput}
                        disableUnderline
                        >
                            <option value='' disabled>State</option>
                            {IndiaState.map((item,index) => (
                                <option key={index} value={item}>{item}</option>
                            ))}
                        </NativeSelect>
                    </FormControl>
                </div>

                <div className={classes.containerDivTextfield}>
                    <TextField className={classes.Textfield}
                    placeholder="Business PAN"
                    name="businessPan"
                    value={businessdetails.businessPan}
                    onChange={e => businessChangeInput(e)} 
                    InputProps={{
                        disableUnderline: true,
                    }}/>
                </div>

            <Typography >Provide Business Name as per registered Business PAN.If you don’t have a business PAN, click: “No” & Proceed.</Typography>
             
            <div style={{margin:'20px 0px'}}>
                <MuiThemeProvider theme={theme}>
                    <DropzoneArea
                    acceptedFiles={["image/*"]}
                    filesLimit={1}
                    maxFileSize={1048576} //1 MB
                    showFileNames={true}
                    dropzoneText={"Drag and drop an image here or click GST Image"}
                    onChange={onDropzoneAreaChangePan}
                    />
                </MuiThemeProvider>
            </div>

            </div>
        ) : (
            <div>
                <div className={classes.containerDivTextfield}>
                    <TextField className={classes.Textfield}
                    placeholder="Home Address"
                    name="homeAddress"
                    value={businessdetails.homeAddress}
                    onChange={e => businessChangeInput(e)} 
                    InputProps={{
                        disableUnderline: true,
                    }}/>
                </div>

                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div style={{display: 'flex', border: '1px solid #31C7D7', borderRadius: '5px', marginTop:'10px',width:'49%'}}>
                        <TextField className={classes.Textfield}
                        placeholder="Pin code"
                        name="homePincode"
                        value={businessdetails.homePincode}
                        onChange={e => {
                            if(e.target.value?.length < 7)
                            {
                                businessChangeInput(e)
                            }
                        }} 
                        InputProps={{
                            disableUnderline: true,
                        }}/>
                    </div>

                    <div style={{display: 'flex', border: '1px solid #31C7D7', borderRadius: '5px', marginTop:'10px',width:'49%'}}>
                        <TextField className={classes.Textfield}
                        placeholder="City"
                        name="homeCity"
                        value={businessdetails.homeCity} 
                        onChange={e => businessChangeInput(e)} 
                        InputProps={{
                            disableUnderline: true,
                        }}/>
                    </div>
                </div>

                <div className={classes.containerDivTextfield}>
                    <FormControl style={{width:'100%', marginLeft:'15px', padding: '5px'}}>
                        <NativeSelect
                        name="homeState"
                        value={businessdetails.homeState}
                        inputProps={{ disableUnderline: true }}
                        onChange={businessChangeInput}
                        disableUnderline
                        >
                            <option value='' disabled>State</option>
                            {IndiaState.map((item,index) => (
                                <option key={index} value={item}>{item}</option>
                            ))}
                        </NativeSelect>
                    </FormControl>
                </div>

                <div className={classes.containerDivTextfield}>
                    <TextField className={classes.Textfield}
                    placeholder="Business Category"
                    name="businessCategory"
                    value={businessdetails.businessCategory}
                    onChange={e => businessChangeInput(e)} 
                    InputProps={{
                        disableUnderline: true,
                    }}/>
                </div>
                
            </div>
        )}

        <div className={classes.containerDivSubmit2}>
            <button className={classes.Submitbtn} type="submit" >Submit</button>
        </div>
    </form>
    )
}

function DocDetails({mobileNumber, handleNext}){
    const classes = useStyles();
    const [docdetails, setdocdetails] = useState({
        userPhoto: null,
        adhaarNumber: '',
        adhaarFrontImage: null,
        adhaarBackImage: null,
        panNumber: '',
        panImage: null,
        signaturePhoto: null,
    });

    const [userphoto, setuserphoto] = useState(null);
    const [adhaarFrontImage, setadhaarFrontImage] = useState(null);
    const [adhaarBackImage, setadhaarBackImage] = useState(null);
    const [panImage, setpanImage] = useState(null);
    const [signaturePhoto, setsignaturePhoto] = useState(null);

    const [open,setOpen] = useState([false, false, false, false, false,])

    const docChangeInput = (event) =>{
        setdocdetails((prevProps) => ({
            ...prevProps,
            [event.target.name]: event.target.value
        }));
    }

    const Openhandler = (event, index, value) =>{
        console.log('clicked')
        const list = open;
        list[index] = value;
        setOpen(() => [...list]);
    }

    
    const onDropzoneChange = async(event, files) => {
        
        if(event === 'userPhoto'){
            setuserphoto(files[0]);
            console.log(files[0]);
            // const imageType = files[0].name.split('.').pop();
            // const uploadTask1 = await storage.ref(`registrationImage/${mobileNumber}/userPhoto.${imageType}`).put(userphoto);
            // const userdownloadurl = await storage.ref(`registrationImage/${mobileNumber}/userPhoto.${imageType}`).getDownloadURL();
            // docdetails.userPhoto = userdownloadurl;

            if(files[0]!==undefined){
                const imageType = files[0].type.substring(6).toLowerCase()
                const uploadTask = await storage.ref(`registrationImage/${mobileNumber}/userPhoto.${imageType}`).put(files[0]);
                const gstdownloadurl = await storage.ref(`registrationImage/${mobileNumber}/userPhoto.${imageType}`).getDownloadURL();
                docdetails.userPhoto  = gstdownloadurl;
                console.log('do1->',gstdownloadurl)
            }
            
        }
        if(event === 'AddharFront')
        {
            setadhaarFrontImage(files[0]);
            // const imageType = files[0].name.split('.').pop();
            // const uploadTask2 = await storage.ref(`registrationImage/${mobileNumber}/AddharFront.${imageType}`).put(adhaarFrontImage);
            // const adharfrontdownloadurl = await storage.ref(`registrationImage/${mobileNumber}/AddharFront.${imageType}`).getDownloadURL();
            // docdetails.adhaarFrontImage = adharfrontdownloadurl;
            if(files[0]!==undefined){
                const imageType = files[0].type.substring(6).toLowerCase()
                const uploadTask = await storage.ref(`registrationImage/${mobileNumber}/AddharFront.${imageType}`).put(files[0]);
                const gstdownloadurl = await storage.ref(`registrationImage/${mobileNumber}/AddharFront.${imageType}`).getDownloadURL();
                docdetails.adhaarFrontImage  = gstdownloadurl;
                console.log('do2->',gstdownloadurl)
            }
        }
        if(event === 'adhaarBackImage')
        {
            setadhaarBackImage(files[0]);
            const imageType = files[0].name.split('.').pop();
            const uploadTask3 = await storage.ref(`registrationImage/${mobileNumber}/adhaarBackImage.${imageType}`).put(adhaarBackImage);
            const adharbackdownloadurl = await storage.ref(`registrationImage/${mobileNumber}/adhaarBackImage.${imageType}`).getDownloadURL();
            docdetails.adhaarBackImage = adharbackdownloadurl
        }
        if(event === 'panImage')
        {
            setpanImage(files[0]);
            const imageType = files[0].name.split('.').pop();
            const uploadTask4 = await storage.ref(`registrationImage/${mobileNumber}/panImage.${imageType}`).put(panImage);
            const pancarddownloadurl = await storage.ref(`registrationImage/${mobileNumber}/panImage.${imageType}`).getDownloadURL();
            docdetails.panImage = pancarddownloadurl
        }
        if(event === 'signaturePhoto')
        {
            setsignaturePhoto(files[0]);
            const imageType = files[0].name.split('.').pop();
            const uploadTask5 = await storage.ref(`registrationImage/${mobileNumber}/signaturePhoto.${imageType}`).put(signaturePhoto);
            const signdownloadurl = await storage.ref(`registrationImage/${mobileNumber}/signaturePhoto.${imageType}`).getDownloadURL();
            docdetails.signaturePhoto = signdownloadurl
        }
    };
    

    console.log(docdetails);

    const docsubmit = async(e) =>{
        e.preventDefault();

        if(userphoto !== null && adhaarFrontImage !==null && adhaarBackImage!==null && panImage!==null && signaturePhoto!==null){

            const data = {
                phone: mobileNumber,
                userPhoto: docdetails.userPhoto,
                adhaarNumber: docdetails.adhaarNumber,
                adhaarFrontImage: docdetails.adhaarFrontImage,
                adhaarBackImage: docdetails.adhaarBackImage,
                panNumber: docdetails.panNumber,
                panImage: docdetails.panImage,
                signaturePhoto: docdetails.signaturePhoto,
            }
            console.log(data);
            API.post('/create_user_step4', data).then((result) => {
                // if(result?.data?.result === 'success')
                // {
                //     handleNext();
                // }
            })
        }else{
            console.log('fill all the fields')
        }
    }

    return (
        <form onSubmit={docsubmit}>
            <div className={classes.containerBusiness} >
            <Typography style={{fontSize: '20px', marginBottom: '20px'}}>Upload Documents</Typography>
            </div>

            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px'}}>
                <Typography style={{fontSize: '20px', marginBottom: '20px'}}>Upload User Image</Typography>
                <div>
                    <button type="button" style={{color:'white', padding: '5px', border: 'none', borderRadius: '5px', backgroundColor: '#31C7D7'}} variant="contained" color="primary" onClick={(e) => Openhandler(e,0,true)}>
                        {userphoto === null ? 'Add Photo' : 'Again Upload Photo'}
                    </button>
                    <DropzoneDialog
                    acceptedFiles={['image/*']}
                    cancelButtonText={"cancel"}
                    submitButtonText={"submit"}
                    filesLimit={1}
                    maxFileSize={5000000}
                    open={open[0]}
                    onClose={(e) => Openhandler(e,0,false)}
                    onSave={(files) => {
                        setOpen((e) => Openhandler(e,0,false));
                        onDropzoneChange("userPhoto", files)
                    }}
                    showPreviews={true}
                    showFileNamesInPreview={true}
                    />
                </div>
                {userphoto !==null && userphoto !== undefined ? (<img style={{width: "150px", height: "150px"}} src={URL.createObjectURL(userphoto)} alt="userphoto"/>):(<div style={{width: "150px", height: "150px", backgroundColor: "lightgrey"}}></div>)}
            </div>

            <div style={{borderTop:'2px solid #31C7D7', margin:'10px 0px'}}></div>

            <Typography style={{fontSize: '20px'}}>Adhar Card Section</Typography>

            <div style={{width:'50%'}} className={classes.containerDivTextfield}>
                    <TextField className={classes.Textfield} 
                    placeholder="Enter Addhar Card Number"
                    name="adhaarNumber"
                    type="number"
                    value={docdetails.adhaarNumber}
                    onChange={(e) => docChangeInput(e)}
                    InputProps={{
                        disableUnderline: true,
                    }} required/>
                </div>

            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px'}}>
                <Typography style={{fontSize: '20px', marginBottom: '20px'}}>Upload Addhar Front</Typography>
                <div>
                    <button type="button" style={{color:'white', padding: '5px', border: 'none', borderRadius: '5px', backgroundColor: '#31C7D7'}} variant="contained" color="primary" onClick={(e) => Openhandler(e,1,true)}>
                        {adhaarFrontImage === null ? 'Add  Photo' : 'Again Upload Photo'}
                    </button>
                    <DropzoneDialog
                    acceptedFiles={['image/*']}
                    cancelButtonText={"cancel"}
                    submitButtonText={"submit"}
                    filesLimit={1}
                    maxFileSize={5000000}
                    open={open[1]}
                    onClose={(e) => Openhandler(e,1,false)}
                    onSave={(files) => {
                        setOpen((e) => Openhandler(e,1,false));
                        onDropzoneChange("AddharFront", files)
                    }}
                    showPreviews={true}
                    showFileNamesInPreview={true}
                    />
                </div>
                {adhaarFrontImage !==null && adhaarFrontImage !== undefined ? (<img style={{width: "150px", height: "150px"}} src={URL.createObjectURL(adhaarFrontImage)} alt="AddharFrontphoto"/>) : (<div style={{width: "150px", height: "150px", backgroundColor: "lightgrey"}}></div>)}
            </div>
            
            
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px'}}>
                <Typography style={{fontSize: '20px', marginBottom: '20px'}}>Upload Addhar Back</Typography>
                <div>
                    <button type="button" style={{color:'white', padding: '5px', border: 'none', borderRadius: '5px', backgroundColor: '#31C7D7'}} variant="contained" color="primary" onClick={(e) => Openhandler(e,2,true)}>
                        {adhaarBackImage === null ? 'Add  Photo' : 'Again Upload Photo'}
                    </button>
                    <DropzoneDialog
                    acceptedFiles={['image/*']}
                    cancelButtonText={"cancel"}
                    submitButtonText={"submit"}
                    filesLimit={1}
                    maxFileSize={5000000}
                    open={open[2]}
                    onClose={(e) => Openhandler(e,2,false)}
                    onSave={(files) => {
                        setOpen((e) => Openhandler(e,2,false));
                        onDropzoneChange("adhaarBackImage", files)
                    }}
                    showPreviews={true}
                    showFileNamesInPreview={true}
                    />
                </div>
                {adhaarBackImage !==null && adhaarBackImage !== undefined ? (<img style={{width: "150px", height: "150px"}} src={URL.createObjectURL(adhaarBackImage)} alt="adhaarBackImage"/>) : (<div style={{width: "150px", height: "150px", backgroundColor: "lightgrey"}}></div>)}
            </div>

            <div style={{borderTop:'2px solid #31C7D7', margin:'10px 0px'}}></div>

            <Typography style={{fontSize: '20px'}}>Pan Card Section</Typography>

            <div style={{width:'50%'}} className={classes.containerDivTextfield}>
                <TextField className={classes.Textfield} 
                placeholder="Enter Pan Card Number"
                name="panNumber"
                value={docdetails.panNumber}
                onChange={(e) => docChangeInput(e)}
                InputProps={{
                    disableUnderline: true,
                }} required/>
            </div>

            
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px'}}>
                <Typography style={{fontSize: '20px', marginBottom: '20px'}}>Upload Pancard Image</Typography>
                <div>
                    <button type="button" style={{color:'white', padding: '5px', border: 'none', borderRadius: '5px', backgroundColor: '#31C7D7'}} variant="contained" color="primary" onClick={(e) => Openhandler(e,3,true)}>
                        {panImage === null ? 'Add Photo' : 'Again Upload Photo'}
                    </button>
                    <DropzoneDialog
                    acceptedFiles={['image/*']}
                    cancelButtonText={"cancel"}
                    submitButtonText={"submit"}
                    filesLimit={1}
                    maxFileSize={5000000}
                    open={open[3]}
                    onClose={(e) => Openhandler(e,3,false)}
                    onSave={(files) => {
                        setOpen((e) => Openhandler(e,3,false));
                        onDropzoneChange("panImage", files)
                    }}
                    showPreviews={true}
                    showFileNamesInPreview={true}
                    />
                </div>
                {panImage !==null && panImage !== undefined ? (<img style={{width: "150px", height: "150px"}} src={URL.createObjectURL(panImage)} alt="panImage"/>) : (<div style={{width: "150px", height: "150px", backgroundColor: "lightgrey"}}></div>)}
            </div>

            <div style={{borderTop:'2px solid #31C7D7', margin:'10px 0px'}}></div>
            <Typography style={{fontSize: '20px'}}>Signature Section</Typography>
            
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px'}}>
                <Typography style={{fontSize: '20px', marginBottom: '20px'}}>Upload Signature</Typography>
                <div>
                    <button type="button" style={{color:'white', padding: '5px', border: 'none', borderRadius: '5px', backgroundColor: '#31C7D7'}} variant="contained" color="primary" onClick={(e) => Openhandler(e,4,true)}>
                        {signaturePhoto === null ? 'Add Photo' : 'Again Upload Photo'}
                    </button>
                    <DropzoneDialog
                    acceptedFiles={['image/*']}
                    cancelButtonText={"cancel"}
                    submitButtonText={"submit"}
                    filesLimit={1}
                    maxFileSize={5000000}
                    open={open[4]}
                    onClose={(e) => Openhandler(e,4,false)}
                    onSave={(files) => {
                        setOpen((e) => Openhandler(e,4,false));
                        onDropzoneChange("signaturePhoto", files)
                    }}
                    showPreviews={true}
                    showFileNamesInPreview={true}
                    />
                </div>
                {signaturePhoto !==null && signaturePhoto !== undefined ? (<img style={{width: "150px", height: "150px"}} src={URL.createObjectURL(signaturePhoto)} alt="signaturePhoto"/>) : (<div style={{width: "150px", height: "150px", backgroundColor: "lightgrey"}}></div>)}
            </div>

            
        <div className={classes.containerDivSubmit2}>
            <button className={classes.Submitbtn} type="submit" >Submit</button>
        </div>
        </form>
    )
}


function PasswordStep({mobileNumber, handleNext}){
    const classes = useStyles();
    const [password, setpassword] = useState('');
    const [confirmPassword, setconfirmPassword] = useState('');

    const passwordStepSubmit = (e) => {
        e.preventDefault();
        if(password !== confirmPassword)
        {
            console.log('Password doesnot match');
            return;
        }else{
            if(password?.length < 6)
            {
                console.log('Passsword length should be of 6 length')
            }else{
                const data = {phone: mobileNumber, password};
                console.log(data);
                API.post('/create_user_step4', data).then((result) => {
                    if(result?.data?.result === 'success')
                    {
                        handleNext();
                    }
                })
            }
        }
    }
    return(
    <form className={classes.containerBusiness} onSubmit={passwordStepSubmit}>
            <div className={classes.containerDivTextfield2}>
            <div className={classes.IconDiv}><LockIcon/></div>
            <TextField className={classes.Textfield}
                type="password"
                placeholder="Enter password" 
                name="mobileNumber"
                value={password}
                onChange = {e => setpassword(e.target.value)}
                InputProps={{
                    disableUnderline: true,
                }}
                required />
            </div>

            <div className={classes.containerDivTextfield2}>
            <div className={classes.IconDiv}><LockIcon /></div>
            <TextField className={classes.Textfield}
                type="password"
                placeholder="Confirm password" 
                name="mobileNumber"
                value={confirmPassword}
                onChange={e=> setconfirmPassword(e.target.value)}
                InputProps={{
                    disableUnderline: true,
                }}
                required />
            </div>

            <div className={classes.containerDivSubmit2}>
            <button className={classes.Submitbtn} type="submit" >Submit</button>
        </div>
    </form>
    )
}

function getSteps() {
    return ['Mobile Verification', 'Personal Details', 'Business Details', 'Documnet Uploadation', 'Password Updation'];
}

function getStepContent(step, mobileNumber,mobileNumberInputChange,handleNext) {
    switch (step) {
    case 0:
        return <VerifyPhoneNUM  mobileNumber={mobileNumber} onChange={mobileNumberInputChange} handleNext={handleNext}/>
    case 1:
        return <Personaldetails mobileNumber={mobileNumber} handleNext={handleNext}/>
    case 2:
        return <BusinessDetails mobileNumber={mobileNumber} handleNext={handleNext}/>
    case 3:
        return <DocDetails mobileNumber={mobileNumber} handleNext={handleNext}/>
    case 4:
        return <PasswordStep mobileNumber={mobileNumber} handleNext={handleNext}/>
    default:
        return 'Unknown step';
    }
}


function Registration() {
    const classes = useStyles()
    const [mobileNumber , setMobileNumber] = useState(null);

    const [activeStep, setActiveStep] = useState(0);
    const steps = getSteps();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };



    const mobileNumberInputChange = (e) => {
        if(e.target.name === 'mobileNumber'){
            if(e.target.value?.length <= 0)
                setMobileNumber(e.target.value);
            else if(validator.isNumeric(e.target.value) && e.target.value.length < 10){
                    setMobileNumber(e.target.value);
                }
            else if(validator.isNumeric(e.target.value) && e.target.value?.length === 10){
                    setMobileNumber(e.target.value);
            }    
        }
    }


    return (
        <>
        <MainNavBar />
        <div className={classes.container}>
        <div className={classes.root}>
            <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((label, index) => (
                    <Step key={label} >
                        <StepLabel StepIconProps={{ classes: { root: classes.icon } }} >{label}</StepLabel>
                        <StepContent>
                            <div>{getStepContent(index, mobileNumber, mobileNumberInputChange, handleNext)}</div>
                        </StepContent>
                    </Step>
                ))}
            </Stepper>

            {activeStep === steps.length && (
                <Paper square elevation={0} className={classes.resetContainer}>
                    <Typography>All steps completed - you&apos;re finished</Typography>
                </Paper>
            )}
        </div>

        </div>
        <MainFooter />
        </>
    )
}

export default Registration
