import React,{useEffect} from 'react'
import { Carousel, Container } from 'react-bootstrap'
import BGImage from "../assets/website/images/bg.jpg"
import { makeStyles } from '@material-ui/core/styles';
import { blackColor, lightGray, primaryColor, secondaryColor, whiteColor } from '../config/webconstants';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Grid, Typography,Button, Box } from '@material-ui/core';
import { useHistory } from 'react-router';
import { ImageSearch } from '@material-ui/icons';


const useStyles = makeStyles(theme =>({
    root: {
        flex:1,
        flexDirection:'column',
        overflowX:'hidden'
    },
    button1:{
        color:blackColor,
        background:'transparent',
        margin:'0px 0px',
        width:220,
        height: 60,
        border:'1px solid',
        borderColor:blackColor,
        fontWeight:'800',
        fontFamily: 'Montserrat',
        "&:hover":{
            color:secondaryColor,
            borderColor:secondaryColor,
            background:'transparent',
        },
    },
    button2:{
        color:whiteColor,
        borderColor:primaryColor,
        background:primaryColor,
        margin:'0px 20px',
        width:220,
        height: 60,
        fontWeight:'800',
        fontFamily: 'Montserrat',
        "&:hover":{
            color:whiteColor,
            borderColor:primaryColor,
            background:primaryColor,
        },
        
    },
    button2right:{
        color:whiteColor,
        borderColor:whiteColor,
        background:primaryColor,
        margin:'0px 20px',
        width:220,
        border:'1px solid',
        height: 60,
        fontWeight:'800',
        fontFamily: 'Montserrat',
        "&:hover":{
            color:whiteColor,
            borderColor:whiteColor,
            background:primaryColor,
        },
    
    },

    carouselcaptions:{
        position: 'absolute',
        right: '15%',
        bottom: '9.25rem',
        left: '10%',
        paddingTop: '1.25rem',
        paddingBottom: '1.25rem',
        color: '#fff',
        display:'flex',
        [theme.breakpoints.down('md')]:{
            bottom: '6.25rem',
            display:'flex',
        },
        [theme.breakpoints.down('sm')]:{
            display:'none',
            bottom: '2.25rem',

        },
        [theme.breakpoints.down('xs')]:{
            display:'none',
            bottom: '0.4rem',
        },

    },
    carouselcaptionsRight:{
        position: 'absolute',
        right: '5%',
        bottom: '5.25rem',
        left: '60%',
        paddingTop: '1.25rem',
        paddingBottom: '1.25rem',
        color: '#fff',
        display:'flex',
        [theme.breakpoints.down('md')]:{
            bottom: '6.25rem',
            display:'flex',
        },
        [theme.breakpoints.down('sm')]:{
            display:'none',
            bottom: '2.25rem',

        },
        [theme.breakpoints.down('xs')]:{
            display:'none',
            bottom: '0.4rem',
        },
    },
    controlicon:{
        fontSize:50,
        color:primaryColor,
        display: 'none'
    },
}))

export default function Banner({Image,showButtons,showOneTile}) {
    const classes = useStyles();
    const history = useHistory();
    const [indicator,setIndicator] = React.useState(false)


    console.log(Image)
    useEffect(() => {
      console.log(Image.length)
      if(ImageSearch.length>=2){
          setIndicator(true)
      }
    },)

    return (
        <Carousel 
        indicators={true}
        controls={false}
        interval={5000}
        fade
        // nextIcon={<ChevronRightIcon className={classes.controlicon}/>} nextLabel=""
        // prevIcon={<ChevronLeftIcon className={classes.controlicon}/>} prevLabel=""
        >
            {Image?.map((item,index)=>(
                <Carousel.Item key={index}>
                    <img
                        className="d-block w-100"
                        src={item?.img}
                        alt="First slide"
                    />
                    <Carousel.Caption className={item.buttons ==="right"? classes.carouselcaptionsRight : classes.carouselcaptions}>
                        {
                            showButtons === false ? null :
                            <>
                            {showOneTile == true?null:
                              <Button variant="primary" className={classes.button1} onClick={() => history.push('/products')}>Products</Button>
                            }
                            <Button variant="primary" className={item.buttons ==="right"? classes.button2right : classes.button2} onClick={() => {history.push("/contactus")}}>Join Bigwayz</Button>
                            </>

                        }
                      
                    </Carousel.Caption>
            </Carousel.Item>
            ))}
            </Carousel>
    )
}
