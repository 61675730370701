import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import axios from "axios";

import {
  lightGray,
  primaryColor,
  primaryTint,
  secondaryColor,
  whiteColor,
} from "../../../config/constants";
import Avatar from "@material-ui/core/Avatar";
import VisibilityIcon from "@material-ui/icons//Visibility";
import { Link } from "react-router-dom";
import moment from "moment";
import { CSVLink, CSVDownload } from "react-csv";
import { firestore, storage } from "../../../config/firebase";
import SearchIcon from "@material-ui/icons/Search";
import { fade } from "@material-ui/core";
import InputBase from "@material-ui/core/InputBase";
import Fuse from "fuse.js";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { ListGroup, Modal } from "react-bootstrap";
import Skeleton from "@material-ui/lab/Skeleton";
import { Alert, AlertTitle } from "@material-ui/lab";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

import {
  IconButton,
  Popover,
  Checkbox,
  FormGroup,
  TextField,
  Dialog,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  title: {
    fontSize: 24,
    color: secondaryColor,
    fontWeight: 700,
    margin: 20,
    fontFamily: "Myraid",
    fontWeight: "800",
  },
  ActionButtonActivateDeactivate: {
    backgroundColor: secondaryColor,
    color: whiteColor,
    paddingLeft: "5px",
    paddingRight: "5px",
    width: "auto",
    color: "#ffffff",
    textAlign: "center",
    borderRadius: 8,
    cursor: "pointer",
  },
  ActionButton: {
    backgroundColor: secondaryColor,
    color: whiteColor,
    paddingLeft: "5px",
    paddingRight: "5px",
    width: "4.1rem",
    color: "#ffffff",
    textAlign: "center",
    borderRadius: 8,
    cursor: "pointer",
    // backgroundColor: "#303c54",
    // padding: 2,
    // color: "#ffffff",
    // textAlign: "center",
    // borderRadius: 8,
    // cursor: "pointer",
    // fontWeight: "500",
    // borderRadius: "15px",
    // border: "transparent",
    /* position: absolute;
  right: 0; */
    // paddingLeft: "0.4em",
    // paddingRight: "0.4em",
    // paddingTop: "0.2em",
    // paddingBottom: "0.2em",
  },
  background: {
    backgroundColor: "rgba(240, 102, 105, 0.13)",
    padding: 16,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    borderRadius: 8,
    marginLeft: "10px",
    marginRight: "10px",
    marginBottom: "5px",
  },
  background1: {
    backgroundColor: "#ffffff",
    padding: 16,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    borderRadius: 8,
    marginLeft: "10px",
    marginRight: "10px",
  },
  avatartitle: {
    display: "flex",
    width: "20%",
  },
  table: {
    minWidth: 600,
  },
  tableContainer: {
    margin: "1%",
    width: "98%",
  },
  tablehead: {
    fontSize: "15px",
    fontWeight: "800",
    background: secondaryColor,
    color: "white",
  },
  icons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  editicon: {
    // marginRight:'30px',
    fill: primaryColor,
    cursor: "pointer",
  },
  deleteicon: {
    fill: secondaryColor,
    cursor: "pointer",
  },
  registeredname: {
    padding: "10px",
    fontSize: 16,
  },
  editbutton: {
    color: whiteColor,
    backgroundColor: secondaryColor,
    borderRadius: 8,
    padding: 8,
    textAlign: "center",
    margin: 5,
    cursor: "pointer",
    maxHeight: 35,
    textDecoration: "none",
  },
  date: {
    marginTop: 10,
    fontSize: 16,
    fontWeight: 300,
  },
  search: {
    position: "relative",
    display: "flex",
    float: "right",
    marginRight: 20,
    marginTop: 20,
    // width:200,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(secondaryColor, 0.15),
    "&:hover": {
      backgroundColor: fade(secondaryColor, 0.25),
    },
    marginLeft: 0,
    width: "200px",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

export default function ManageUsers() {
  const classes = useStyles();
  const [userList, setUserList] = useState([]);
  const [userListFormattedData, setuserListFormattedData] = useState([]);

  const [searchedUsers, setSearchedUsers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});

  const [Loading, setLoading] = useState(true);

  const [ActiveUser, setActiveUser] = useState(null);

  const [OpenSurakshaDailog, setOpenSurakshaDailog] = useState(false);

  const [OpenFinzDailog, setOpenFinzDailog] = useState(false);

  const [OpenActivationDailog, setOpenActivationDailog] = useState(false);

  const [OpenAssistDailog, setOpenAssistDailog] = useState(false);

  const [OpenDeleteUserDailog, setOpenDeleteUserDailog] = useState(false);

  const [Role, setRole] = React.useState("CRM");
  const [searchTerm, setSearchTerm] = React.useState("");


  const CloseSurakshaDailog = () => {
    setRole("CRM");
    setOpenSurakshaDailog(false);
  };

  const CloseFinzDailog = () => {
    setOpenFinzDailog(false);
  };

  const CloseActivationDailog = () => {
    setOpenActivationDailog(false);
  };

  const CloseAssistDailog = () => {
    setOpenAssistDailog(false);
  };

  const CloseDeleteUserDailog = () => {
    setOpenDeleteUserDailog(false);
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("allusers"));
    if (!!user && user != []) {
      console.log("fetching data from local", user);

      user.forEach((item) => {
        if (item.status == "passwordCreated" && !!item.packageId) {
          item.payment = "paymentDone";
        }
      });

      setUserList(user);

      const FD = user.map((EI) => {
        return {
          DateofRegistration:
            new Date(EI.joined).getDate() +
            "/" +
            (new Date(EI.joined).getMonth() + 1) +
            "/" +
            new Date(EI.joined).getFullYear(),
          DateofLogin: EI.lastlogin,
          Name: EI.firstName
            ? EI.firstName
            : "N/A" + " " + EI.lastName
            ? EI.lastName
            : "",
          Role: EI.role,
          Email: EI.email,
          Phone: EI.phone,
          Gender: EI.gender,
          HomeCity: EI.homeCity,
          HomePincode: EI.homePincode,
          RegistrationStatus: EI.status == "step1"?"Verified Phone Number": EI.status == "step2"?"Personal Details":EI.status == "step3"?"Business Details":EI.status == "step4"?"KYC Upload":EI.status == "passwordCreated"?"Registration Complete":"Payment Done" ,
          IsUserActive: EI.active ? "yes" : "no",
          IsFinzActive: EI.finzActive ? "yes" : "no",
          IsAssistActive: EI.assistActive ? "yes" : "no",
          IsSurakshsaActive: EI.surakshaActive ? "yes" : "no",

          Dateofbirth: EI.dob,
          WhatsappNumber: EI.whatsAppNumber,

          // business
          BusinessName: EI.businessName,
          BusinessAddress: EI.businessAddress,
          BusinessCategory: EI.businessCategory,
          BusinessCity: EI.businessCity,
          BusinessState: EI.businessState,
          BusinessPan: EI.businessPan,
          BusinessPincode: EI.businessPinCode,
          GSTIN: EI.gstin,

          //  home
          HomeState: EI.homeState,
          HomeAddress: EI.homeAddress,
          OnboardedBy: EI.onboardedBy,

          // KYC
          UserPhoto: EI.userPhoto,
          AadhaarFrontImage: EI.adhaarFrontImage,
          AadhaarBackImage: EI.adhaarBackImage,
          PanCardImage: EI.panImage,
          Signature: EI.signaturePhoto,
          Education: EI.educationQualification,

          // bank
          BankName: EI.bankName,
          BankAccountHolderName: EI.nameAsPerBank,
          IFSC: EI.ifsc,
          AccountNumber: EI.accountNumber,
          TypeofAccount: EI.typeOfAccount,
          BankEmail: EI.bankEmail,
        };
      });

      console.log("ssssssss", FD);
      setuserListFormattedData(FD);
      setLoading(false);
    }
    // getAllUser();
  }, []);

  const getAllUser = () => {
    console.log("downloading all users data from firebase");
    let tempArray = {};
    firestore
      .collection("users")
      .orderBy("joined", "desc")
      .get()
      .then((snapshot) => {
        const usersList = snapshot.docs.map((doc) => {
          return doc.data();
        });

        console.log("data", usersList);
        setUserList(usersList);

        // formatting user data

        const FD = usersList.map((EI) => {
          return {
            DateofRegistration:
              new Date(EI.joined).getDate() +
              "/" +
              (new Date(EI.joined).getMonth() + 1) +
              "/" +
              new Date(EI.joined).getFullYear(),
            DateofLogin: EI.lastlogin,
            Name: EI.firstName
              ? EI.firstName
              : "N/A" + " " + EI.lastName
              ? EI.lastName
              : "",
            Role: EI.role,
            Email: EI.email,
            Phone: EI.phone,
            Gender: EI.gender,
            HomeCity: EI.homeCity,
            HomePincode: EI.homePincode,
            RegistrationStatus: EI.status,
            IsUserActive: EI.active ? "yes" : "no",
            IsFinzActive: EI.finzActive ? "yes" : "no",
            IsAssistActive: EI.assistActive ? "yes" : "no",
            IsSurakshsaActive: EI.surakshaActive ? "yes" : "no",

            Dateofbirth: EI.dob,
            WhatsappNumber: EI.whatsAppNumber,

            // business
            BusinessName: EI.businessName,
            BusinessAddress: EI.businessAddress,
            BusinessCategory: EI.businessCategory,
            BusinessCity: EI.businessCity,
            BusinessState: EI.businessState,
            BusinessPan: EI.businessPan,
            BusinessPincode: EI.businessPinCode,
            GSTIN: EI.gstin,

            //  home
            HomeState: EI.homeState,
            HomeAddress: EI.homeAddress,
            OnboardedBy: EI.onboardedBy,

            // KYC
            UserPhoto: EI.userPhoto,
            AadhaarFrontImage: EI.adhaarFrontImage,
            AadhaarBackImage: EI.adhaarBackImage,
            PanCardImage: EI.panImage,
            Signature: EI.signaturePhoto,
            Education: EI.educationQualification,

            // bank
            BankName: EI.bankName,
            BankAccountHolderName: EI.nameAsPerBank,
            IFSC: EI.ifsc,
            AccountNumber: EI.accountNumber,
            TypeofAccount: EI.typeOfAccount,
            BankEmail: EI.bankEmail,
          };
        });
        console.log("dd", FD);
        setuserListFormattedData(FD);
        localStorage.setItem("allusers", JSON.stringify(usersList));
        setLoading(false);
        // console.log("get all users =", usersList);
      });
  };

  let headers = [
    { label: "Date of Registration", key: "DateofRegistration" },
    { label: "Date of Login", key: "DateofLogin" },
    { label: "Role", key: "Role" },
    { label: "Phone", key: "Phone" },
    { label: "Full Name", key: "Name" },
    { label: "Gender", key: "Gender" },
    { label: "Email", key: "Email" },
    { label: "Whatsapp Number", key: "WhatsappNumber" },
    { label: "GSTIN", key: "GSTIN" },
    { label: "Date ofbirth", key: "Dateofbirth" },
    { label: "Home Address", key: "HomeAddress" },
    { label: "Home City", key: "HomeCity" },
    { label: "Home State", key: "HomeState" },
    { label: "Home Pincode", key: "HomePincode" },
    { label: "Registration Status", key: "RegistrationStatus" },
    { label: "OnBoardedBy", key: "onboardedBy" },

    { label: "IsUserActive", key: "IsUserActive" },
    { label: "IsFinzActive", key: "IsFinzActive" },
    { label: "IsAssistActive", key: "IsFinzActive" },
    { label: "IsSurakshsaActive", key: "IsSurakshsaActive" },

    // business
    { label: "Business Name", key: "BusinessName" },
    { label: "Business Category", key: "BusinessCategory" },
    { label: "Business Pincode", key: "BusinessPinCode" },
    { label: "Business Address", key: "BusinessAddress" },
    { label: "Business City", key: "BusinessCity" },
    { label: "Business State", key: "BusinessState" },
    { label: "Business Pan", key: "BusinessPan" },

    // bank
    { label: "Bank Name", key: "BankName" },
    { label: "Bank Account Holder Name", key: "BankAccountHolderName" },
    { label: "IFSC", key: "IFSC" },
    { label: "Account Number", key: "AccountNumber" },
    { label: "Type of Account", key: "TypeofAccount" },
    { label: "Bank Email", key: "BankEmail" },

    // KYC
    { label: "User Photo", key: "UserPhoto" },
    { label: "Aadhaar Front Image", key: "AadhaarFrontImage" },
    { label: "Aadhaar Back Image", key: "AadhaarBackImage" },
    { label: "Pan Card Image", key: "PanCardImage" },
    { label: "Signature", key: "Signature" },
    { label: "Education", key: "Education" },
  ];

  const handleSearch = (text) => {
    // console.log("Searching....", text);

    const options = {
      isCaseSensitive: false,
      threshold: 0.3,
      keys: [
        "firstName",
        "lastName",
        "phone",
        "email",
        "businessName",
        "status",
        "gender",
        "homePincode",
        "packageId",
        "homeCity",
        "role",
        "payment",
      ],
    };

    const fuseCategory = new Fuse(userList, options);
    var temp = fuseCategory.search(text);
    console.log("searched leads ==", temp);
    let dummy = [];
    temp.forEach((item) => {
      dummy.push(item.item);
    });
    setSearchedUsers(dummy);
  };

  const check = (status) => {
    if (status === "step1") {
      return "Verified Phone Number";
    }
    if (status === "step2") {
      return "Personal Details";
    }
    if (status === "step3") {
      return "Business Details";
    }
    if (status === "step4") {
      return "KYC Upload";
    }
    if (status === "step5") {
      return "Registration Complete";
    }
    if (status === "step6") {
      return "Payment Done";
    }
  };

  const renderStatus = (status, packageId) => {
    // console.log("render status", packageId);

    if (status == "passwordCreated" && !!packageId) {
      return (
        <div
          style={{
            backgroundColor: "green",
            padding: 4,
            color: "#ffffff",
            textAlign: "center",
            borderRadius: 8,
          }}
        >
          Payment done
        </div>
      );
    }

    if (status == "passwordCreated" || status == "registrationComplete") {
      return (
        <div
          style={{
            backgroundColor: "green",
            padding: 4,
            color: "#ffffff",
            textAlign: "center",
            borderRadius: 8,
          }}
        >
         Registration Complete
        </div>
      );
    } else {
      return (
        <div
          style={{
            backgroundColor: "#F6BE00",
            padding: 2,
            color: "#ffffff",
            textAlign: "center",
            borderRadius: 8,
          }}
        >
          {check(status)}
        </div>
      );
    }
  };

  const Assist = () => {
    console.log("calling assist ");

    const UserData = [
      {
        mobile: ActiveUser.phone,
        firstname: ActiveUser.firstName,
        lastname: ActiveUser.lastName ? ActiveUser.lastName : "",
        email: ActiveUser.email,
        crm_id: 1,
        shop: "Bigwayz",
      },
    ];

    axios
      .post(
        "https://estore.bigwayz.com/modules/stockupdateapi/api/bigwayz-onboard-merchant.php",
        UserData
      )
      .then((succs) => {
        console.log("succs-----------------------------++++", succs);

        if (succs.data[0].status === "success") {
          AddAssistKeyAndItsValueTrue(succs);
        }

        if (!succs.data[0].errors[0].status) {
          CloseAssistDailog();

          return alert(`${succs.data[0].errors[0]}`);
        }
      })
      .catch((err) => {
        if (err.response !== undefined) {
          console.log("error", err.response);
          alert("something went wrong see console");

          CloseAssistDailog();
        }
      });
  };

  const SurakshaApprove = () => {
    console.log("apprve suraksha");

    firestore
      .collection("ssousers")
      .doc(ActiveUser.phone)
      .get()
      .then((snapshot) => {
        if (snapshot.exists) {
          CloseSurakshaDailog();
          alert(
            `user ${ActiveUser.firstName +
              " " +
              ActiveUser.lastName} is already suraksha approved`
          );
        } else {
          firestore
            .collection("ssousers")
            .doc(ActiveUser.phone)
            .set({
              name: ActiveUser.firstName + " " + ActiveUser.lastName,
              email: ActiveUser.email,
              phone: ActiveUser.phone,
              role: Role === "CRM" ? "C" : "P",
            })
            .then((succs) => {
              console.log("seeing success", succs);

              AddSurakshaKeyAndItsValueTrue();
            });
        }
      });
  };

  // const testing=()=>{
  //   console.log('hhhhggggggggggggggh')
  //   firestore
  //     .collection("users")
  //     .doc("7566840283")
  //     .set(
  //       {
  //         surakshaActive: false,
  //       },
  //       { merge: true }
  //     )
  //     .then((succs) => {
  //       console.log(`user finz activated`);
  //       getAllUser();
  //     });
  // }

  const FinzActivate = () => {
    firestore
      .collection("users")
      .doc(ActiveUser.phone)
      .set(
        {
          finzActive: true,
        },
        { merge: true }
      )
      .then((succs) => {
        console.log(`user finz activated`);

        CloseFinzDailog();
        alert(
          `user ${ActiveUser.firstName +
            " " +
            ActiveUser.lastName}  finz approved successfully`
        );

        getAllUser();
      });
  };

  const Activation = () => {
    firestore
      .collection("users")
      .doc(ActiveUser.phone)
      .set(
        {
          active: !ActiveUser.active,
        },
        { merge: true }
      )
      .then((succs) => {
        console.log(`user activated /deactivated`);

        CloseActivationDailog();
        getAllUser();
      });
  };

  const AddAssistKeyAndItsValueTrue = (succs) => {
    console.log("hitting add assist key function");

    firestore
      .collection("users")
      .doc(ActiveUser.phone)
      .set(
        {
          assistActive: true,
        },
        { merge: true }
      )
      .then(() => {
        // console.log(`user assist activated`);

        CloseAssistDailog();

        alert(`${succs.data[0].message}`);

        getAllUser();
      });
  };

  const AddSurakshaKeyAndItsValueTrue = () => {
    console.log("hitting add suraksha key function");

    firestore
      .collection("users")
      .doc(ActiveUser.phone)
      .set(
        {
          surakshaActive: true,
        },
        { merge: true }
      )
      .then((succs) => {
        console.log(`user suraksha activated`);

        CloseSurakshaDailog();

        alert(
          `user ${ActiveUser.firstName +
            " " +
            ActiveUser.lastName}  suraksha approved successfully`
        );

        getAllUser();
      });
  };

  const deleteUser = () => {
    console.log("deleting user ....", ActiveUser.phone);
    firestore
      .collection("users")
      .doc(ActiveUser.phone)
      .delete()
      .then(() => {
        CloseDeleteUserDailog();
        getAllUser();
        return true;
      })
      .catch((error) => {
        alert("Something went wrong");
        return false;
      });
  };

  const handleClickOpen = () => {};
  let mapList = searchedUsers.length > 0 ? searchedUsers : userList || [];
  let time = Date.now();
  return Loading ? (
    <div
      className="skeleton text-center d-flex justify-content-center"
      style={{
        width: "100%",
        marginTop: "3rem",
        flexDirection: "column",
      }}
    >
      <Button onClick={() => getAllUser()} variant="outlined">
        refresh
      </Button>
      <Skeleton
        style={{
          background: "#efefef",
          // width:'8rem',
          // marginLeft:'5%'
          // margin:'auto'
        }}
        animation="wave"
        variant="text"
        width={"100%"}
        height={64}
      />
      <Skeleton
        style={{
          background: "#efefef",
          // width:'8rem',
          // marginLeft:'5%'
          // margin:'auto'
        }}
        animation="wave"
        variant="text"
        width={"100%"}
        height={64}
      />
      <Skeleton
        style={{
          background: "#efefef",
          // width:'8rem',
          // marginLeft:'5%'
          // margin:'auto'
        }}
        animation="wave"
        variant="text"
        width={"100%"}
        height={64}
      />
      <Skeleton
        style={{
          background: "#efefef",
          // width:'8rem',
          // marginLeft:'5%'
          // margin:'auto'
        }}
        animation="wave"
        variant="text"
        width={"100%"}
        height={64}
      />
      <Skeleton
        style={{
          background: "#efefef",
          // width:'8rem',
          // marginLeft:'5%'
          // margin:'auto'
        }}
        animation="wave"
        variant="text"
        width={"100%"}
        height={64}
      />
      <Skeleton
        style={{
          background: "#efefef",
          // width:'8rem',
          // marginLeft:'5%'
          // margin:'auto'
        }}
        animation="wave"
        variant="text"
        width={"100%"}
        height={64}
      />
      <Skeleton
        style={{
          background: "#efefef",
          // width:'8rem',
          // marginLeft:'5%'
          // margin:'auto'
        }}
        animation="wave"
        variant="text"
        width={"100%"}
        height={64}
      />
      <Skeleton
        style={{
          background: "#efefef",
          // width:'8rem',
          // marginLeft:'5%'
          // margin:'auto'
        }}
        animation="wave"
        variant="text"
        width={"100%"}
        height={64}
      />
      <Skeleton
        style={{
          background: "#efefef",
          // width:'8rem',
          // marginLeft:'5%'
          // margin:'auto'
        }}
        animation="wave"
        variant="text"
        width={"100%"}
        height={64}
      />
      <Skeleton
        style={{
          background: "#efefef",
          // width:'8rem',
          // marginLeft:'5%'
          // margin:'auto'
        }}
        animation="wave"
        variant="text"
        width={"100%"}
        height={64}
      />
      <Skeleton
        style={{
          background: "#efefef",
          // width:'8rem',
          // marginLeft:'5%'
          // margin:'auto'
        }}
        animation="wave"
        variant="text"
        width={"100%"}
        height={64}
      />
      <Skeleton
        style={{
          background: "#efefef",
          // width:'8rem',
          // marginLeft:'5%'
          // margin:'auto'
        }}
        animation="wave"
        variant="text"
        width={"100%"}
        height={64}
      />
      <Skeleton
        style={{
          background: "#efefef",
          // width:'8rem',
          // marginLeft:'5%'
          // margin:'auto'
        }}
        animation="wave"
        variant="text"
        width={"100%"}
        height={64}
      />
    </div>
  ) : (
    <div>
      <Dialog className={classes.myDailogBox} open={OpenFinzDailog}>
        <DialogContent>
          Do you really want to finz approve user{" "}
          {ActiveUser?.firstName + " " + ActiveUser?.lastName}
        </DialogContent>

        <DialogActions>
          <Button onClick={() => CloseFinzDailog()}> Close</Button>
          <Button onClick={() => FinzActivate()}> yes </Button>
        </DialogActions>
      </Dialog>

      <Dialog className={classes.myDailogBox} open={OpenDeleteUserDailog}>
        <DialogContent>
          Do you really want to delete user{" "}
          {ActiveUser?.firstName + " " + ActiveUser?.lastName}
        </DialogContent>

        <DialogActions>
          <Button onClick={() => CloseDeleteUserDailog()}> Close</Button>
          <Button onClick={() => deleteUser()}> yes </Button>
        </DialogActions>
      </Dialog>

      <Dialog className={classes.myDailogBox} open={OpenAssistDailog}>
        <DialogContent>
          Do you really want to assist user{" "}
          {ActiveUser?.firstName + " " + ActiveUser?.lastName}
        </DialogContent>

        <DialogActions>
          <Button onClick={() => CloseAssistDailog()}> Close</Button>
          <Button onClick={() => Assist()}> yes </Button>
        </DialogActions>
      </Dialog>

      <Dialog className={classes.myDailogBox} open={OpenSurakshaDailog}>
        <DialogContent>
          <FormControl component="fieldset">
            <FormLabel component="legend">Please Select Role </FormLabel>
            <RadioGroup
              row
              aria-label="gender"
              name="gender1 row-radio-buttons-group"
              value={Role}
              onChange={(e) => setRole(e.target.value)}
            >
              <FormControlLabel value="CRM" control={<Radio />} label="CRM" />
              <FormControlLabel value="POSP" control={<Radio />} label="POSP" />
            </RadioGroup>
          </FormControl>

          <div className="mt-2">
            Do you really want to suraksha approve user{" "}
            {ActiveUser?.firstName + " " + ActiveUser?.lastName}
          </div>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => CloseSurakshaDailog()}>Close</Button>
          <Button onClick={() => SurakshaApprove()}> yes </Button>
        </DialogActions>
      </Dialog>

      <Dialog className={classes.myDailogBox} open={OpenActivationDailog}>
        <DialogContent>
          Do you really want to {ActiveUser?.active ? "deactivate" : "activate"}{" "}
          user {ActiveUser?.firstName + " " + ActiveUser?.lastName}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => CloseActivationDailog()}>Close</Button>
          <Button onClick={() => Activation()}> yes </Button>
        </DialogActions>
      </Dialog>

      <Alert severity="info">
        <AlertTitle>All Registered Users</AlertTitle>
        Please use the Refresh button carefeully, one refresh can cost —{" "}
        <strong>more than 10K operations in cloud.</strong>
      </Alert>
      <Grid container spacing={0}>
        <Grid item xs={4}>
          <Typography className={classes.title}>Registered Users</Typography>
        </Grid>
        <Grid item xs={6}>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder=""
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ "aria-label": "search" }}
              onChange={(e) => {
                let data = e.target.value
                let previousData = ''
                if(data && data.length === 3 && data.length > previousData.length){
                  setSearchTerm(data)
                }
                previousData = data || '';
              }}
            />
             <Button
            onClick={() => handleSearch(searchTerm)}
            style={{height:24, marginTop:5}}
            // variant="outlined"
          >
            Search
          </Button>
          </div>
        </Grid>
        <Grid item xs={2}>
          <Button
            onClick={() => getAllUser()}
            variant="outlined"
            style={{ marginTop: 20 }}
          >
            Refresh
          </Button>
          <CSVLink
            style={{
              backgroundColor: secondaryColor,
              color: whiteColor,
              padding: 8,
              borderRadius: 8,
              marginTop: 30,
              position: "relative",
              display: "flex",
              float: "right",
              marginRight: 20,
              marginTop: 20,
              textDecoration: "none",
            }}
            filename={moment(time).format("MMMM Do YYYY, h:mm:ss a") + ".csv"}
            data={userListFormattedData}
            headers={headers}
          >
            Download
          </CSVLink>
        </Grid>
      </Grid>

      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell className={classes.tablehead}>Sr.</TableCell>
              <TableCell align="left" className={classes.tablehead}>
                Date Of Registration
              </TableCell>
              <TableCell align="left" className={classes.tablehead}>
                Date of Login
              </TableCell>
              <TableCell align="left" className={classes.tablehead}>
                Name
              </TableCell>
              <TableCell align="left" className={classes.tablehead}>
                Role
              </TableCell>
              <TableCell align="left" className={classes.tablehead}>
                Email
              </TableCell>
              <TableCell align="left" className={classes.tablehead}>
                Phone{" "}
              </TableCell>

              <TableCell align="left" className={classes.tablehead}>
                Gender
              </TableCell>
              <TableCell align="left" className={classes.tablehead}>
                City
              </TableCell>
              <TableCell align="left" className={classes.tablehead}>
                Pincode
              </TableCell>

              <TableCell align="left" className={classes.tablehead}>
                Status
              </TableCell>

              <TableCell align="left" className={classes.tablehead}>
                IsUserActive
              </TableCell>

              <TableCell align="left" className={classes.tablehead}>
                Finz
              </TableCell>
              <TableCell align="left" className={classes.tablehead}>
                Assist
              </TableCell>
              <TableCell align="left" className={classes.tablehead}>
                Suraksha
              </TableCell>
              <TableCell align="left" className={classes.tablehead}>
                Activation / Deactivation
              </TableCell>
              <TableCell align="left" className={classes.tablehead}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody
            style={{
              position: "relative",
            }}
          >
            {mapList.map((row, index) => {
              return (
                <>
                  <TableRow
                    key={row.name}
                    style={{
                      backgroundColor: index % 2 == 0 ? whiteColor : lightGray,
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {mapList.length - index}
                    </TableCell>

                    <TableCell align="left">
                      {" "}
                      {new Date(row.joined).getDate() +
                        "/" +
                        (new Date(row.joined).getMonth() + 1) +
                        "/" +
                        new Date(row.joined).getFullYear()}
                    </TableCell>
                    <TableCell align="left">
                      {row.lastlogin ? row.lastlogin : ""}
                    </TableCell>
                    <TableCell align="left">
                      {row.firstName
                        ? row.firstName
                        : "N/A" + " " + row.lastName
                        ? row.lastName
                        : ""}
                    </TableCell>
                    <TableCell align="left">{row.role}</TableCell>

                    <TableCell align="left">{row.email}</TableCell>
                    <TableCell align="left">{row.phone}</TableCell>
                    <TableCell align="left">{row.gender}</TableCell>
                    <TableCell align="left">{row.homeCity}</TableCell>
                    <TableCell align="left">{row.homePincode}</TableCell>
                    <TableCell align="left">
                      {renderStatus(row.status, row.packageId)}
                    </TableCell>
                    <TableCell align="center">
                      {row.active ? "yes" : "no"}
                    </TableCell>
                    <TableCell align="center">
                      {row.finzActive ? (
                        <span
                          style={{
                            backgroundColor: "green",
                            padding: 4,
                            color: "#ffffff",
                            textAlign: "center",
                            borderRadius: 8,
                          }}
                        >
                          {" "}
                          active{" "}
                        </span>
                      ) : (
                        <div
                          onClick={() => {
                            setOpenFinzDailog(true);
                            setActiveUser(row);
                          }}
                          className={classes.ActionButton}
                        >
                          {" "}
                          activate{" "}
                        </div>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {" "}
                      {row.assistActive ? (
                        <span
                          style={{
                            backgroundColor: "green",
                            padding: 4,
                            color: "#ffffff",
                            textAlign: "center",
                            borderRadius: 8,
                          }}
                        >
                          {" "}
                          active{" "}
                        </span>
                      ) : (
                        <div
                          className={classes.ActionButton}
                          onClick={() => {
                            setOpenAssistDailog(true);
                            setActiveUser(row);
                          }}
                        >
                          {" "}
                          approve{" "}
                        </div>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {" "}
                      {row.surakshaActive ? (
                        <span
                          style={{
                            backgroundColor: "green",
                            padding: 4,
                            color: "#ffffff",
                            textAlign: "center",
                            borderRadius: 8,
                          }}
                        >
                          {" "}
                          active{" "}
                        </span>
                      ) : (
                        <div
                          className={classes.ActionButton}
                          onClick={() => {
                            setOpenSurakshaDailog(true);
                            setActiveUser(row);
                          }}
                        >
                          {" "}
                          approve{" "}
                        </div>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      <div
                        className={classes.ActionButtonActivateDeactivate}
                        onClick={() => {
                          setOpenActivationDailog(true);
                          setActiveUser(row);
                        }}
                      >
                        {" "}
                        {row.active ? "deactivate" : "activate"}{" "}
                      </div>
                    </TableCell>

                    <TableCell align="left" className={classes.icons}>
                      <Link
                        to={{ pathname: "/specificUser", userName: row.phone }}
                      >
                        <VisibilityIcon
                          onClick={() => {
                            setSelectedItem(row);
                          }}
                          className={classes.deleteicon}
                        />
                      </Link>

                      <DeleteIcon
                        onClick={() => {
                          setActiveUser(row);
                          setOpenDeleteUserDailog(true);
                        }}
                        className={classes.deleteicon}
                      />
                    </TableCell>
                  </TableRow>
                </>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
