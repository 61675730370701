import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Typography,TextField, Paper } from '@material-ui/core';
import {lightGray, primaryColor, secondaryColor, whiteColor } from '../../../config/constants'; 
import {Link} from "react-router-dom";
import cardImage from "../../../assets/images/cardimage.png"
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';


const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    title:{
        fontSize:30,
        color:secondaryColor,
        fontWeight:700,
        margin:20
    },
    registeredname:{
        padding:'10px',
        fontSize:18
    },
    addbutton:{
        display:'flex',
        float:'right',
        margin:30,
        fontSize:16,
        borderRadius:8,
        background:secondaryColor,
        color:whiteColor,
        padding:5,
        textAlign:'center',
        cursor:'pointer',
        textDecoration:'none'
    },
    formcontainer:{
        margin:20
    },
    editbutton:{
        color:whiteColor,
        backgroundColor:secondaryColor,
        borderRadius:18,
        padding:8,
        width:100,
        textAlign:'center',
        margin:5,
        cursor:'pointer',
        maxHeight:35,
        textDecoration:'none'
    },
    date:{
        fontSize:16,
        fontWeight:300
    },
    formtitle:{
        color:primaryColor,
        fontWeight:600,
        fontSize:16,
        paddingBottom:10,
        paddingTop:10
    },
    forminput:{
        width:'100%',
        borderColor:secondaryColor,
        background:whiteColor,
        [theme.breakpoints.only('xs')]: {
           minWidth:'100%'
          },
    },
    cardImage:{
        width:'100%',
        height:200,
        objectFit:'contain',
        marginTop:10
    }
    
  }));
  

export default function KYCDetails() {
    const classes = useStyles();
    const [value, setValue] = React.useState('Active');

    const handleChange = (event) => {
      setValue(event.target.value);
    };


    return (
        <div>
        <Grid container spacing={0}>
            <Grid item xs={6}>
            <Typography className={classes.title}>
                 KYC  Management
            </Typography>
            </Grid>
            <Grid item xs={6}>
                <Link to ="/kyc" size="small" color="primary"  className={classes.addbutton}>
                        Go Back
                </Link>
                </Grid>
        </Grid> 
        <div className={classes.formcontainer}> 
        <Grid container spacing={0}>
            <Grid item xs={6}>
            <Typography className={classes.formtitle}>Aadhar  Number</Typography>
               <Typography className ={classes.forminfo}>122211111111111</Typography>
            <Typography className={classes.formtitle}>PAN  Number</Typography>
            <Typography className ={classes.forminfo}>1222111111sb11111</Typography>
            <Typography className={classes.formtitle}>Address</Typography>
            <Typography className ={classes.forminfo}>No.6, 2nd main , RR nagar, Navi Mumbai,Mumbai,50393200 smnsnnsjsnjddjj</Typography>
                <Typography className={classes.formtitle}>Update Status</Typography>
                <div>
                <FormControl component="fieldset">
                <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>
                    <div style={{display:'flex',justifyContent:'space-between'}}>
                    <FormControlLabel value="Active" control={<Radio />} label="Approved" />
                    <FormControlLabel value="Pending" control={<Radio />} label="Not Apporved" />
                    </div>
                </RadioGroup>
                </FormControl>
                </div>
                <div size="small" color="primary"  className={classes.editbutton}>
                    Save 
                </div>
            </Grid>
            <Grid item xs={6}>
               <img src = {cardImage} className={classes.cardImage} />
               <img src = {cardImage} className={classes.cardImage} />
            </Grid>
            
        </Grid> 
        </div>
        </div>




    )
}
