import { Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react'
import { Nav, Navbar,Container } from 'react-bootstrap'
import { useHistory } from 'react-router';
import Logo from "../../assets/website/images/mainlogo.png";
import { blackColor, primaryColor, titleSize, whiteColor ,secondaryColor} from '../../config/webconstants';
import "../../App.css"


import {MDBCol, MDBContainer, MDBFooter, MDBRow} from "mdbreact";
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import CallIcon from '@material-ui/icons/Call';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import YouTubeIcon from '@material-ui/icons/YouTube';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import PlayStoreImage from "../../assets/website/footerimages/playstore.png"
import LogoWhite from "../../assets/website/footerimages/mainlogo_white.png"
import IosImage from "../../assets/website/footerimages/app_store.png"
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import Telphone from "../../assets/website/images/telephoneicon.png"
import Lottie from "react-lottie";
import LottieFile from "../../assets/website/businessPage/lf30_editor_i5jrfsov.json"
import VectorImage from "../../assets/website/businessPage/businessvector.png"

const useStyles = makeStyles(theme =>({
    root:{
        flex:1,
        overflowX:'hidden'
    },
    logo:{
        height:70,
        objectFit:'contain',
        [theme.breakpoints.down('sm')]: {
            height:50
        }
    },
    NavbarItem:{
        fontSize:titleSize,
        color:blackColor,
        "&:hover":{
            color:blackColor,
        },
        '&.active':{
            borderBottom:`5px solid ${primaryColor}`,
        },
        [theme.breakpoints.down('md')]: {
            '&.active':{
                color: primaryColor,
                borderBottom:`none`,
            }
        }
    },
    logofooter:{
        height:70,
        width:200,
        marginTop: 20,
        objectFit:'contain'
    },
    scoialcontainer:{
        marginTop:"10%",
        width:'60%',
        // paddingLeft:'20%',
        justifyContent:'space-between',
        [theme.breakpoints.down('sm')]: {
            marginTop:'20px',
            padding:'0px 10%',
            width:'100%'
        },
    },

    connecttitle:{
        fontSize:16,
        fontWeight:'600',
        color:whiteColor,
        letterSpacing:1,
        textAlign:'start',
        [theme.breakpoints.down('sm')]: {
            textAlign:'center',
        },
    },
    socialicons:{
        display:'flex',
        padding:10,
        backgroundColor:"#DC5D60",
        justifyContent:'space-between',
        borderRadius:12,
      
    },
    socialicon:{
        margin:'0px 5px',
        color:whiteColor,
        cursor:'pointer',
        "&:hover":{
            color:secondaryColor
        }
    },
    horizontalline:{
        padding:'2% 0px',
        padding:1,
        backgroundColor:whiteColor  
    },
    footersecond:{
        color:whiteColor,
        padding:'0px 2%',
        paddingTop:15,
        textAlign:'center'
    },
    links:{
        textDecoration:'none',
        textAlign:'start',
        color:whiteColor,
        padding:"20px 0px",
        fontFamily:"Myraid",
        "&:hover":{
            color:whiteColor,
            fontWeight:'600'
        }
    },
    linkcontainer:{
        padding:"10px 0px"
    },
    imagescontainer:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'start',
        [theme.breakpoints.down('md')]: {
            justifyContent:'center',
        },
    },
    registeredimage:{
        width:'100px',
        height:'100px',
        objectFit:'contain',
        margin:'5px 0px',
        marginRight:'20px',
        [theme.breakpoints.down('md')]: {
            marginRight:'5px',
        },
    },
    footerrow:{
        textAlign:'justify',
        [theme.breakpoints.down('md')]: {
            textAlign:'center',
        },
    },
    applinks:{
        display:'flex',
        justifyContent:'start',
        [theme.breakpoints.down('md')]: {
            justifyContent:'center',
        },
    },
    appimage:{
        height:100,
        width:'45%',
        objectFit:'contain',
        paddingRight:'5%',
        cursor:'pointer',
    },
    telephoneicon:{
        height:"6%",
        width:'6%',
        objectFit:'contain',
        paddingRight:2
    },
    clickable:{
        cursor:'pointer',
        textDecoration:'none',
        "&:hover":{
            textDecoration:'underline'
        }
    },
    mainheader:{
        // backgroundImage: `url(${Background})`,
        background:"linear-gradient(90deg, #1675B3 0%, #68B6E8 52.76%, #0F76B9 100%)",
    },
    gridcontainer:{
        padding:'5% 5%'
    },
    maintitle:{
        fontFamily:'Montserrat',
        color:whiteColor,
        fontSize:30,
        fontWeight:700,
        textShadow:'2px 0px #8c8c8c'
    },
    maintitle2:{
        fontFamily:'Montserrat',
        fontSize:30,
        fontWeight:700,
    },
    mainsubtitle:{
        fontFamily:'Montserrat',
        color:whiteColor,
        fontSize:24,
        fontWeight:600,
        padding:'10% 0px',
        textShadow:'2px 0px #8c8c8c'
    },
    mainsubtitle2:{
        fontFamily:'Montserrat',
        fontSize:24,
        fontWeight:600,
        padding:'10% 0px',
    },
    list:{
        fontFamily:'Montserrat',
        color:whiteColor,
        fontSize:18,
        fontWeight:600,
        textShadow:'2px 0px #8c8c8c'
    },
    list2:{
        fontFamily:'Montserrat',
        fontSize:18,
        fontWeight:600,
    },
    gridcontainer2:{
        padding:'5% 5%'
    },
    vectorimage:{
        margin:'5%',
        width:'90%',
        height:500,
        objectFit:'contain'
    },
}))


function BusinessFinance() {
    const classes = useStyles()
    const history = useHistory()


    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: LottieFile,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      };


      function LottieAnimation({ lotti, width, height }) {
        const defaultOptions = {
          loop: true,
          autoplay: true,
          animationData: lotti,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
          },
        };
      
        return (
          <div>
            <Lottie options={defaultOptions} height={height} width={width} style={{cursor:'default'}}/>
          </div>
        );
      };

    return (
        <div >
               <Navbar collapseOnSelect expand="md" variant="light" style={{backgroundColor:whiteColor}} sticky="top" className={classes.webnavbar}>
                <Container>
                <Navbar.Brand href="/">
                    <img src={Logo} alt="imagelogo" className={classes.logo} />
                </Navbar.Brand>
                
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav  className="me-auto">
                        </Nav>
                            <Nav.Link className={classes.NavbarItem} href="/aboutus">About Us</Nav.Link>
                            <Nav.Link  className={classes.NavbarItem}  href="/contactus">Help Desk</Nav.Link>
                </Navbar.Collapse>            
                </Container>
                </Navbar>



            {/* main */}
            <div className={classes.root}>
            <div className={classes.mainheader}>
            <Grid container className={classes.gridcontainer} spacing={2} >
                <Grid item xs={12} sm={6}>
                    <Typography className={classes.maintitle}>MANAGE YOUR BUSINESS FINANCES BETTER</Typography>
                    <Typography className={classes.mainsubtitle}>Get rewarded with exciting offers & cashback & see your future grow. Get a debit card that doesn't require a bank account. Key features are:</Typography>
                    <ul>
                        <li style={{color:whiteColor}}><Typography className={classes.list}>Prepaid Debit/ATM Reloadable Card upto 3 Years Validity</Typography> </li>
                        <li style={{color:whiteColor}}><Typography className={classes.list}>Onboarding using EKYC process</Typography> </li>
                        <li style={{color:whiteColor}}><Typography className={classes.list}>Mobile enabled Card linked to a Virtual Account </Typography> </li>
                        <li style={{color:whiteColor}}><Typography className={classes.list}> Cash withdrawal through ATMs of any Bank</Typography> </li>
                        <li style={{color:whiteColor}}><Typography className={classes.list}>Accepted for payments at all Rupay or Master Card POS</Typography> </li>
                        <li style={{color:whiteColor}}><Typography className={classes.list}>Accepted for payments online</Typography> </li>
                        <li style={{color:whiteColor}}><Typography className={classes.list}>Instant Cash Loading facility</Typography> </li>
                        <li style={{color:whiteColor}}><Typography className={classes.list}>Card Loading Limits</Typography> </li>

                    </ul>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <LottieAnimation lotti={LottieFile} height={"80%"} width={"90%"} />
                </Grid>
            </Grid>
            </div>

            <Grid container className={classes.gridcontainer2} spacing={2} >
                <Grid item xs={12} sm={6}>
                    <Typography className={classes.maintitle2}>Manage your business finances better & get rewarded</Typography>
                    <Typography className={classes.mainsubtitle2}>with exciting offers & cashback & see your future grow. Get a debit card that doesn't require a bank account. Key features are:</Typography>
                    <ul>
                        <li ><Typography className={classes.list2}>Prepaid Debit/ATM Reloadable Card upto 3 Years Validity</Typography> </li>
                        <li ><Typography className={classes.list2}>Onboarding using EKYC process</Typography> </li>
                        <li ><Typography className={classes.list2}>Mobile enabled Card linked to a Virtual Account </Typography> </li>
                        <li ><Typography className={classes.list2}> Cash withdrawal through ATMs of any Bank</Typography> </li>
                        <li ><Typography className={classes.list2}>Accepted for payments at all Rupay or Master Card POS</Typography> </li>
                        <li ><Typography className={classes.list2}>Accepted for payments online</Typography> </li>
                        <li ><Typography className={classes.list2}>Instant Cash Loading facility</Typography> </li>
                        <li ><Typography className={classes.list2}>Card Loading Limits</Typography> </li>

                    </ul>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <img src={VectorImage} alt="" className={classes.vectorimage}/>
                </Grid>
            </Grid>

            </div>


            {/* footer */}

            <MDBFooter color="stylish-color-dark" style={{backgroundColor: primaryColor}} className="page-footer font-small p-4">
            <MDBContainer fluid className="text-center text-md-left">
                <MDBRow  style={{padding:' 0px 5%'}}>
                    <MDBCol md="4" className={classes.footerrow}>
                        <img src={LogoWhite} alt="logo" className={classes.logofooter}/>
                        <div className={classes.scoialcontainer}>
                            <Typography className={classes.connecttitle}>Connect with us</Typography>
                            <div className={classes.socialicons}>
                            <a href="https://www.facebook.com/bigwayz.benefits"><FacebookIcon className={classes.socialicon} /></a>
                            <a href="https://www.linkedin.com/company/bigwayz"><LinkedInIcon className={classes.socialicon} /></a>
                            <a href="https://www.instagram.com/bigwayz_official/"><InstagramIcon className={classes.socialicon} /></a>
                            {/* <WhatsAppIcon className={classes.socialicon} /> */}
                            <a href="https://www.youtube.com/channel/UCQnOpduAlSfaOwVhHYDEJsg"><YouTubeIcon className={classes.socialicon} /></a>
                            <a href="https://twitter.com/BigwayzOfficial"><TwitterIcon className={classes.socialicon}/></a>
                            </div>
                        </div>
                    </MDBCol>
                    <MDBCol md="4" className={classes.footerrow}>
                        <h5 style={{color:whiteColor,fontFamily:"Myraid",fontWeight:'600'}} className="text-uppercase mb-4 mt-3 font-weight-bold">
                            Important Links
                        </h5>
                        <ul  className="list-unstyled">
        
                            <li className={classes.linkcontainer}>
                                <a className={classes.links} href="/aboutus">About Us</a>
                            </li>
                            <li className={classes.linkcontainer}>
                                <a className={classes.links} href="/products">Products</a>
                            </li>
                            <li className={classes.linkcontainer}>
                                <a className={classes.links} href="/calculator">Income Calculator</a>
                            </li>
                            <li className={classes.linkcontainer}>
                                <a className={classes.links} href="/contactus">Contact Us</a>
                            </li>
                            <li className={classes.linkcontainer}>
                                <a className={classes.links} href="/privacypolicy">Privacy Policy</a>
                            </li>
                            <li className={classes.linkcontainer}>
                                <Typography className={classes.connecttitle}>Download our app on</Typography>
                                <div className={classes.applinks}>
                                    <img onClick={() => alert('Coming soon!')} src={PlayStoreImage} alt="playstoreimage" className={classes.appimage}/>
                                    <img onClick={() => alert('Coming soon!')}  src={IosImage} alt="playstoreimage" className={classes.appimage}/>
                                </div>
                            </li>
                        
                        </ul>
                    </MDBCol>
                    <MDBCol style={{color: '#ffffff'}} md="4" className={classes.footerrow}>
                        <h5 style={{color:whiteColor,fontFamily:"Myraid",fontWeight:'600'}}  className="text-uppercase mb-4 mt-3 font-weight-bold">
                            Registered With
                        </h5>
                        <ul className="list-unstyled">
                        <li>
                                <p ><CallIcon/>{'  '}:<a style={{color: '#ffffff'}} href="tel:08041496046"  className={classes.clickable}>{'  '}08041496046 </a></p>
                            </li>
                            <li>
                           <p > <img src={Telphone} alt="telephone" className={classes.telephoneicon} />{'  '}: <a style={{color: '#ffffff'}} href="tel:9113629361"  className={classes.clickable}>{'  '} 9113629361 </a></p>
                            </li>
                            <li>
                                <p style={{color: '#ffffff'}}><MailOutlineIcon/> {'  '}: <a style={{color: '#ffffff'}}  className={classes.clickable} href = "mailto:info@bigwayz.com">
                                    {'  '}info@bigwayz.com</a></p>

                            </li>
                            {/*<li>*/}
                            {/*    <a href="#!">+91-9587917917</a>*/}
                            {/*</li>*/}
                            <li>
                                <p href="#!">
                                    <LocationOnOutlinedIcon/>
                                    {'  '} : 3rd Floor, #4 & 5. 5th Main, Jayamahal Extension Bangalore, Karnataka – 560046</p>
                            </li>
                        </ul>
                    </MDBCol>
                </MDBRow>
                <div className={classes.horizontalline}></div>
                <div className={classes.footersecond}>
                Copyright © {new Date().getFullYear()} Inginomicx Technologies Private Limited. All Rights Reserved.
                </div>
            </MDBContainer>
        </MDBFooter>
            
        </div>
    )
}

export default BusinessFinance
