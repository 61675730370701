import React, { useState, useEffect } from "react";
import MainNavBar from "../../components/MainNavBar";
import { Container } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import {
  lightGray,
  normalSize,
  primaryColor,
  secondaryColor,
  titleSize,
  whiteColor,
} from "../../config/webconstants";
import { Grid, Typography, Button } from "@material-ui/core";
import Logo from "../../assets/website/images/mainlogo.png";
import FinzLogo from "../../assets/website/images/finzlogo.png";
import SurakshaLogo from "../../assets/website/images/surakshalogo.png";
import AssistLogo from "../../assets/website/images/assistlogo2.png";
import FinzVector from "../../assets/website/images/finzvector.png";
import AssistVector from "../../assets/website/images/AssistVector.png";
import SurakshaVector from "../../assets/website/images/surakshavector.png";
import BSVector from "../../assets/website/images/busineessolvector.png";
import StoreVector from "../../assets/website/images/storevector.png";
import BulkVector from "../../assets/website/images/bulkvector.png";
import OfferVector from "../../assets/website/images/offervector.png";
import MainFooter from "../../components/MainFooter";
import Banner from "../../components/Banner";
import StarupIndia from "../../assets/website/images/logo1.png";
import MaskGroup from "../../assets/website/images/logo2.png";
import MicrosoftAzureLogo from "../../assets/website/images/logo3.png";
import GoogleCloudLogo from "../../assets/website/images/GoogleCloudLogo.png";
import PhoneImage from "../../assets/website/images/whatphone.png";
import ScreenShot from "../../assets/website/images/screenshot.png";
import AcUnitIcon from "@material-ui/icons/AcUnit";
import Ellipse from "../../assets/website/images/Ellipse.png";
import "aos/dist/aos.css";
import Aos from "aos";
import FinzPhone from "../../assets/website/images/homescreenshot.png";
import SurkshaPhone from "../../assets/website/images/surakshaphone.png";
import EcommercePhone from "../../assets/website/images/productscreenshot.png";
import BusinessPhone from "../../assets/website/images/businessphone.png";
import vendor from "../../assets/website/carousel/vendor.png";
import farmer from "../../assets/website/carousel/farmer carousel.png";
import medic from "../../assets/website/carousel/medic.png";
import lady from "../../assets/website/carousel/lady.png";
import Slider from "../../components/Slider";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import Fade from "@material-ui/core/Fade";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    flexDirection: "column",
    overflowX: "hidden",
    fontFamily: "ABBVoice",
    [theme.breakpoints.down("sm")]: {
      marginTop: "56px",
    },
  },
  headertitle: {
    fontSize: 40,
    textAlign: "center",
    fontWeight: 600,
    paddingTop: 50,
    paddingBottom: 20,
    color: primaryColor,
    fontFamily: "ABBVoice",
    [theme.breakpoints.down("sm")]: {
      fontSize: 25,
      paddingTop: 20,
    },
  },
  whowerarecontent: {
    fontSize: titleSize + 2,
    fontFamily: "ABBVoice",
    fontWeight: "600",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
      textAlign: "justify",
    },
  },
  whocontainer: {
    backgroundColor: lightGray,
    paddingBottom: 50,
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 5,
    },
  },
  whoimage: {
    height: 95,
    width: "100%",
    objectFit: "contain",
    [theme.breakpoints.down("sm")]: {
      height: 55,
      width: "100%",
    },
  },
  whosubtitle: {
    fontSize: titleSize,
    textAlign: "center",
    margin: "20px 0px",
    fontFamily: "ABBVoice",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  whoicons: {
    height: 150,
    width: "100%",
    objectFit: "contain",
    alignItems: "center",
    alignSelf: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  whoicons2: {
    height: 70,
    width: "100%",
    objectFit: "contain",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  whoImageDiv: {
    display: "none",
  },
  whoTypoDiv: {
    [theme.breakpoints.up("md")]: {
      padding: 20,
    },
  },
  whoTyposmall: {
    marginTop: "5px",
    fontSize: titleSize,
    fontFamily: "Myraid",
    display: "flex",
    fontWeight: "500",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      textAlign: "justify",
    },
  },
  SliderDiv: {
    width: "50%",
    margin: "10px auto",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      width: "80%",
      margin: "0px 10%",
    },
  },
  servicebutton1: {
    fontSize: 16,
    backgroundColor: secondaryColor,
    color: whiteColor,
    fontWeight: 600,
    width: 180,
    margin: "30px 0px",
    border: "none",
    fontFamily: "Myraid",
    "&:hover": {
      backgroundColor: secondaryColor,
    },
    [theme.breakpoints.down("sm")]: {
      margin: "5px 0px",
    },
  },
  servicebutton2: {
    fontSize: 16,
    backgroundColor: primaryColor,
    color: whiteColor,
    fontWeight: 600,
    width: 180,
    margin: "30px 0px",
    border: "none",
    fontFamily: "Myraid",
    "&:hover": {
      backgroundColor: primaryColor,
    },
    [theme.breakpoints.down("sm")]: {
      margin: "5px 0px",
    },
  },
  servicecontainer: {
    margin: "40px 0px",
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
      margin: "0px 0px",
    },
  },
  servicelogo: {
    height: 60,
    width: 140,
    objectFit: "contain",
  },

  serviceleft: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
  },
  servicedesc: {
    fontSize: normalSize + 2,
    margin: "20px 0px",
    textAlign: "justify",
    fontFamily: "Myraid",
    [theme.breakpoints.down("sm")]: {
      padding: "0px 10px",
      width: "90%",
    },
  },
  servicevector: {
    width: "100%",
    height: 350,
    objectFit: "contain",
    marginTop: 50,
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      height: 200,
      marginTop: 0,
    },
  },
  buttoncontainer: {
    margin: "20px 20px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    width: "95%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      width: "90%",
    },
  },
  bigbutton: {
    color: secondaryColor,
    backgroundColor: whiteColor,
    padding: "20px 60px",
    width: "20",
    borderRadius: 8,
    elevation: 3,
    fontSize: 20,
    fontWeight: "600",
    letterSpacing: "1px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 30px",
      marginTop: 10,
      fontSize: 15,
    },
  },
  vectoreimage: {
    width: "100%",
    height: 200,
    objectFit: "contain",
  },
  vectortitle: {
    textAlign: "center",
    paddingTop: 20,
    fontSize: 20,
    fontWeight: 700,
    color: primaryColor,
    letterSpacing: 1,
    margin: "0px 15%",
    fontFamily: "Myraid",
  },
  bigbuttoncontainer: {
    margin: "8% 0px",
    [theme.breakpoints.down("sm")]: {
      margin: "0% 0px",
    },
  },
  whatcontainer: {
    padding: "0px 8%",
    [theme.breakpoints.down("sm")]: {
      padding: "0px 5px",
    },
  },
  phoneimage: {
    height: "600px",
    width: "100%",
    objectFit: "contain",
    zIndex: 2,
    [theme.breakpoints.down("sm")]: {
      height: "250px",
    },
  },
  ArrayImagesDiv: {
    height: "630px",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      height: "250px",
    },
  },
  strip1: {
    backgroundColor: secondaryColor,
    color: whiteColor,
    display: "flex",
    justifyContent: "start",
    position: "absolute",
    top: "25%",
    right: "50%",
    zIndex: 0,
    textAlign: "left",
    width: "40%",
    padding: "14px 0px",
    paddingLeft: 10,
    borderRadius: "12px",
    fontWeight: "600",
    cursor: "pointer",
    fontSize: titleSize,
    color: whiteColor,
    fontWeight: "600",
    fontFamily: "Myraid",
    transition: "padding 1s easin",
    transition: "0.6s",
    "&:hover": {
      fontWeight: "900",
      fontSize: 22,
    },
    [theme.breakpoints.down("sm")]: {
      padding: "8px 5px",
      fontSize: 8,
      width: "50%",
      "&:hover": {
        fontWeight: "900",
        fontSize: 10,
      },
    },
  },
  strip2: {
    backgroundColor: primaryColor,
    color: whiteColor,
    display: "flex",
    justifyContent: "flex-end",
    position: "absolute",
    top: "25%",
    left: "50%",
    zIndex: 0,
    textAlign: "left",
    width: "40%",
    padding: "14px 0px",
    paddingRight: 10,
    borderRadius: "12px",
    fontWeight: "600",
    cursor: "pointer",
    fontSize: titleSize,
    color: whiteColor,
    fontWeight: "600",
    fontFamily: "Myraid",
    transition: "padding 1s easin",
    transition: "0.6s",
    "&:hover": {
      fontWeight: "900",
      fontSize: 22,
    },
    [theme.breakpoints.down("sm")]: {
      padding: "8px 5px",
      fontSize: 8,
      width: "50%",
      "&:hover": {
        fontWeight: "900",
        fontSize: 10,
      },
    },
  },
  strip3: {
    top: "50%",
    // width: '50%'
  },
  screenshotcarousel: {
    zIndex: 3,
    objectFit: "contain",
    height: "600px",
    width: "40%",
    position: "absolute",
    left: "31%",
    animation: "fadeIn ease 5s infinte",
    [theme.breakpoints.down("sm")]: {
      height: "250px",
    },
  },
  finozmobile: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  finozdesktop: {
    display: "block",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  hoverCarousel: {},
  "@keyframes fadeIn": {
    "0%": {
      opacity: "0.1",
    },
    "100%": {
      opacity: "1",
    },
  },
}));

const Image = [
  { img: farmer, buttons: "right" },
  { img: vendor },
  ,
  { img: medic },
  ,
  { img: lady },
];
const SliderImage = [
  { img: StarupIndia, title: "Recoginzed By" },
  { img: MaskGroup, title: "Registrated with" },
  { img: MicrosoftAzureLogo, title: "Powered By" },
];

export default function Home() {
  const classes = useStyles();


  const arrayImages = [FinzPhone, SurkshaPhone, BusinessPhone, EcommercePhone];

  const [ishoverImage, setishoverImage] = useState(false);
  const [counter, setcounter] = useState(0);
  const [hoverImage, setHoverImage] = useState(arrayImages[0]);
  const history = useHistory();
  const hoverImageFunction = (name, index) => {
    if (name === "hover") {
      setishoverImage(true);
      setHoverImage(arrayImages[index]);
    } else {
      setishoverImage(false);
    }
  };

  const ChangeImage = () => {
    if (counter < 3) {
      setcounter(counter + 1);
    } else {
      setcounter(0);
    }
  };

  useEffect(() => {
    Aos.init({
      duration: 2000,
    });
    // setInterval(() => {
    //     ChangeImage()
    // }, 3000);
  }, []);

  return (
    <>
      <MainNavBar />
      <div className={classes.root}>
        <Banner
          Image={Image}
          showbutton1={true}
          showbutton2={true}
          makeBlack={true}
        />

        <div className={classes.whocontainer}>
          <Typography
            className={classes.headertitle}
            data-aos="fade-up"
            data-aos-duration="6000"
          >
            WHO WE ARE
          </Typography>
          <Container>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <Typography
                  className={classes.whowerarecontent}
                  data-aos="fade-up"
                  data-aos-duration="6000"
                >
                  Bigwayz is a one-stop business solution to enable Retailers &
                  SME’s benefit from the fast growing digital economy in India.{" "}
                </Typography>

                <div className={classes.whoTypoDiv}>
                  <ul>
                    <li>
                      <Typography
                        className={classes.whoTyposmall}
                        data-aos="fade-up"
                        data-aos-duration="6000"
                      >
                        Be part of the fastest growing Branchless Banking
                        network to provide Digital & Financial services in your
                        area to service customers.
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        className={classes.whoTyposmall}
                        data-aos="fade-up"
                        data-aos-duration="6000"
                      >
                        Power your Business to sell from 100+ brands & more than
                        16 categories as your inventory offering your customer,
                        products at attractive prices & doorstep delivery
                        services.
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        className={classes.whoTyposmall}
                        data-aos="fade-up"
                        data-aos-duration="6000"
                      >
                        Tech enabled platform to provide over the counter health
                        & vehicle Insurance services from leading insurance
                        companies.
                      </Typography>
                    </li>
                  </ul>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                data-aos="fade-up"
                data-aos-duration="6000"
              >
                <img src={StoreVector} className={classes.vectoreimage} />
                <Typography className={classes.vectortitle}>
                  Increase <br /> Customer Walk-Ins
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                data-aos="fade-up"
                data-aos-duration="6000"
              >
                <img src={OfferVector} className={classes.vectoreimage} />
                <Typography className={classes.vectortitle}>
                  More Products,
                  <br /> More Profits
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                data-aos="fade-up"
                data-aos-duration="6000"
              >
                <img src={BulkVector} className={classes.vectoreimage} />
                <Typography className={classes.vectortitle}>
                  Promote Your Store <br />
                  Digitally
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </div>

        {/* <Container> */}
        <Typography
          className={classes.headertitle}
          data-aos="fade-up"
          data-aos-duration="6000"
        >
          WHAT WE OFFER
        </Typography>
        <div className={classes.whatcontainer}>
          <div
            className={classes.ArrayImagesDiv}
            data-aos="fade-up"
            data-aos-duration="6000"
          >
            <img src={Ellipse} className={classes.phoneimage} alt="" />
            {/* screenshots */}
            {ishoverImage === true ? (
              <Fade in={ishoverImage === true} timeout={1500}>
                <img
                  id="imagehover"
                  src={hoverImage}
                  className={`${classes.screenshotcarousel} ${ishoverImage ===
                    true && classes.hoverCarousel}`}
                  id="#images"
                  alt=""
                />
              </Fade>
            ) : (
              <img
                src={arrayImages[counter]}
                className={classes.screenshotcarousel}
                alt=""
              />
            )}

            <div
              className={classes.strip1}
              onMouseEnter={() => hoverImageFunction("hover", 0)}
              onMouseLeave={() => hoverImageFunction("nothover", -1)}
            >
              Digital Banking{" "}
            </div>

            <div
              className={`${classes.strip1} ${classes.strip3}`}
              onMouseEnter={() => hoverImageFunction("hover", 1)}
              onMouseLeave={() => hoverImageFunction("nothover", -1)}
            >
              Insure-Tech
            </div>

            <div
              className={classes.strip2}
              onMouseEnter={() => hoverImageFunction("hover", 2)}
              onMouseLeave={() => hoverImageFunction("nothover", -1)}
            >
              E-Commerce
            </div>

            <div
              className={`${classes.strip2} ${classes.strip3}`}
              onMouseEnter={() => hoverImageFunction("hover", 3)}
              onMouseLeave={() => hoverImageFunction("nothover", -1)}
            >
              Promote Your Business{" "}
            </div>
          </div>

          <Grid container spacing={4} className={classes.servicecontainer}>
            <Grid
              item
              xs={12}
              sm={5}
              className={classes.serviceleft}
              data-aos="fade-up"
              data-aos-duration="6000"
            >
              <Button
                className={classes.servicebutton1}
                onClick={() =>
                  history.push({
                    pathname: "/products",
                    hash: "#finz",
                    name: "finz",
                  })
                }
              >
                {" "}
                Digital Banking
              </Button>
              <img src={FinzLogo} alt="image" className={classes.servicelogo} />

              <Grid container spacing={1} style={{ margin: "20px 0px" }}>
                <Grid item xs={12} sm={6} className={classes.finozmobile}>
                  <ul>
                    <li>AEPS </li>
                    <li>Micro ATM</li>
                    <li>Bill Payments </li>
                    <li>Aadhaar Pay</li>
                    <li>Direct Money Transfer</li>
                    <li>Mobile & DTH Recharges</li>
                  </ul>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.finozdesktop}>
                  <ul>
                    <li>AEPS </li>
                    <li>Micro ATM</li>
                    <li>Bill Payments </li>
                  </ul>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.finozdesktop}>
                  <ul>
                    <li>Aadhaar Pay</li>
                    <li>Direct Money Transfer</li>
                    <li>Mobile & DTH Recharges</li>
                  </ul>
                </Grid>
              </Grid>
              <Typography
                className={[classes.servicedesc, { margin: "5px 0px" }]}
              >
                Transform your Business to a preferred & digitally enabled
                service provider in your area for customers.
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={7}
              data-aos="fade-up"
              data-aos-duration="6000"
            >
              <img
                // src={FinzVector}
                src={FinzVector}
                alt="vector"
                className={classes.servicevector}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={5}
              className={classes.serviceleft}
              data-aos="fade-up"
              data-aos-duration="6000"
            >
              <Button
                className={classes.servicebutton2}
                onClick={() =>
                  history.push({
                    pathname: "/products",
                    hash: "#assist",
                    name: "assist",
                  })
                }
              >
                E - Commerce
              </Button>
              <img
                src={AssistLogo}
                alt="image"
                className={classes.servicelogo}
              />
              <Typography className={classes.servicedesc}>
                Sell products from Bigwayz Assist to facilitate seamless
                e-commerce & attract new customers with a fast & responsive
                digital experience. Get access to 100+ brands & more than 16
                categories as your inventory offering your customer, products at
                attractive prices secured with a highly effective last mile
                delivery, order tracking & support system.
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={7}
              data-aos="fade-up"
              data-aos-duration="6000"
            >
              <img
                src={SurakshaVector}
                alt="vector"
                className={classes.servicevector}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={5}
              className={classes.serviceleft}
              data-aos="fade-up"
              data-aos-duration="6000"
            >
              <Button
                className={classes.servicebutton2}
                onClick={() =>
                  history.push({
                    pathname: "/products",
                    hash: "#suraksha",
                    name: "suraksha",
                  })
                }
              >
                Insurance
              </Button>
              <img
                src={SurakshaLogo}
                alt="image"
                className={classes.servicelogo}
              />
              <Typography className={classes.servicedesc}>
                Now Insure & secure your customers in a digital & hassle free
                way. Become a POSP & offer insurance solutions from leading
                insurance companies to customers at best prices & options
                instantaneously.
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={7}
              data-aos="fade-up"
              data-aos-duration="6000"
            >
              <img
                src={BSVector}
                alt="vector"
                className={classes.servicevector}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={5}
              className={classes.serviceleft}
              data-aos="fade-up"
              data-aos-duration="6000"
            >
              <Button
                style={{ width: "200px", marginBottom: 10 }}
                className={classes.servicebutton1}
                onClick={() =>
                  history.push({
                    pathname: "/products",
                    hash: "#business",
                    name: "business",
                  })
                }
              >
                Business Solutions
              </Button>
              {/* <img src={FinzLogo} alt="image" className={classes.servicelogo} /> */}
              {/* <Typography className={classes.servicedesc} style={{fontWeight:'600'}}>
                            Your all-in-one business solutions
                            </Typography> */}
              <Typography className={classes.servicedesc}>
                Get access to a range of products & services to secure & empower
                your business.
                <ul>
                  <li>
                    Accounting solution to create invoices & get a complete
                    overview to manage your Business transactions & share
                    invoice with customers instantaneously.
                  </li>
                  <li>
                    Get a Prepaid card to manage your transactions & join the
                    digital revolution to pay anytime, anywhere with a virtual
                    account to make your financial transactions simple & secure.
                  </li>
                </ul>
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={7}
              data-aos="fade-up"
              data-aos-duration="6000"
            >
              <img
                src={AssistVector}
                alt="vector"
                className={classes.servicevector}
              />
            </Grid>
          </Grid>
        </div>
        {/* </Container> */}

        <Container>
          <Typography
            className={classes.headertitle}
            data-aos="fade-up"
            data-aos-duration="6000"
          >
            Join Bigwayz for an instant business opportunity
          </Typography>
          <div
            className={classes.buttoncontainer}
            data-aos="fade-up"
            data-aos-duration="6000"
          >
            <Button
              onClick={() => history.push("/products")}
              variant="contained"
              className={classes.bigbutton}
            >
              RETAILER
            </Button>
            <Button
              onClick={() => history.push("/entrepreneur")}
              variant="contained"
              className={classes.bigbutton}
            >
              ENTREPRENEUR
            </Button>
            <Button
              onClick={() => history.push("/distributor")}
              variant="contained"
              className={classes.bigbutton}
            >
              DISTRIBUTOR
            </Button>
          </div>

          <Grid container spacing={3} className={classes.bigbuttoncontainer}>
            <Grid
              item
              xs={12}
              sm={12}
              data-aos="fade-up"
              data-aos-duration="6000"
            >
              <div className={classes.SliderDiv}>
                <Slider Image={SliderImage} />
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              data-aos="fade-up"
              data-aos-duration="6000"
            >
              <img src={StarupIndia} alt="image" className={classes.whoicons} />
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              data-aos="fade-up"
              data-aos-duration="6000"
            >
              <img src={MaskGroup} alt="image" className={classes.whoicons} />
            </Grid>
            <Grid
              item
              xs={12}
              sm={5}
              data-aos="fade-up"
              data-aos-duration="6000"
            >
              <img
                src={MicrosoftAzureLogo}
                alt="image"
                className={classes.whoicons}
              />
            </Grid>
          </Grid>
        </Container>

        <MainFooter showContact={false} />
      </div>
    </>
  );
}
