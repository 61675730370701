import React, {useEffect, useState} from "react"
import {makeStyles} from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import {CSVLink, CSVDownload} from "react-csv"
import {IconButton, Typography, Popover} from "@material-ui/core"

import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
  Dialog,
} from "@material-ui/core"

import {
  lightGray,
  primaryColor,
  primaryTint,
  secondaryColor,
  whiteColor,
} from "../../../config/constants"
import moment from "moment"
import {Link} from "react-router-dom"
import {firestore, storage} from "../../../config/firebase"
import {fade} from "@material-ui/core"
import TableContainer from "@material-ui/core/TableContainer"
import Paper from "@material-ui/core/Paper"
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import TableBody from "@material-ui/core/TableBody"
import EditIcon from "@material-ui/icons/Edit"
import DeleteIcon from "@material-ui/icons/Delete"
import Button from "@material-ui/core/Button"
import {useHistory} from "react-router-dom"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import Firebase from "firebase"
import axios from "axios"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"

import Skeleton from "@material-ui/lab/Skeleton"
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  title: {
    fontSize: 24,
    color: secondaryColor,
    fontWeight: 700,
    margin: 20,
  },
  background: {
    backgroundColor: "rgba(240, 102, 105, 0.13)",
    padding: 16,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    borderRadius: 8,
    marginLeft: "10px",
    marginRight: "10px",
    marginBottom: "5px",
  },
  validation: {
    marginTop: "0.6em",
    marginLeft: "0.2em",
    marginRight: "0.2em",
    background: "red",
    color: "white",
    borderRadius: "10px",
    paddingLeft: "1em",
  },
  background1: {
    backgroundColor: "#ffffff",
    padding: 16,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    borderRadius: 8,
    marginLeft: "10px",
    marginRight: "10px",
  },
  avatartitle: {
    display: "flex",
    width: "20%",
  },
  table: {
    minWidth: 600,
  },
  tableContainer: {
    margin: "1%",
    width: "98%",
  },
  tablehead: {
    fontSize: "15px",
    fontWeight: "800",
    background: secondaryColor,
    color: "white",
  },
  icons: {
    display: "flex",
    justifyContent: "space-around",
  },
  editicon: {
    // marginRight:'30px',
    fill: primaryColor,
    cursor: "pointer",
  },
  deleteicon: {
    fill: secondaryColor,
    cursor: "pointer",
  },
  registeredname: {
    padding: "10px",
    fontSize: 16,
  },
  addbutton: {
    display: "flex",
    float: "right",
    margin: 16,
    width: 150,
    background: secondaryColor,
    color: whiteColor,
    padding: 3,
    textAlign: "center",
    cursor: "pointer",
    textDecoration: "none",
  },
  ButtonAlign: {
    marginTop: 2,
    marginRight: 2,
  },

  editbutton: {
    color: whiteColor,
    backgroundColor: secondaryColor,
    borderRadius: 8,
    padding: 8,
    textAlign: "center",
    margin: 5,
    cursor: "pointer",
    maxHeight: 35,
    textDecoration: "none",
  },
  date: {
    marginTop: 10,
    fontSize: 16,
    fontWeight: 300,
  },
  search: {
    position: "relative",
    display: "flex",
    float: "right",
    marginRight: 20,
    marginTop: 20,
    // width:200,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(secondaryColor, 0.15),
    "&:hover": {
      backgroundColor: fade(secondaryColor, 0.25),
    },
    marginLeft: 0,
    width: "200px",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  ActionButton: {
    background: "#303c54",
    color: "#ffffff",
    fontWeight: "500",
    borderRadius: "15px",
    border: "transparent",
    /* position: absolute;
  right: 0; */
    paddingLeft: "0.9em",
    paddingRight: "0.9em",
    paddingTop: "0.3em",
    paddingBottom: "0.3em",
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}))

export default function DiscountCoupons() {
  const classes = useStyles()
  const [userList, setUserList] = useState([{}])
  const [searchedUsers, setSearchedUsers] = useState([])
  const history = useHistory()
  const [data, setData] = useState([])
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [open, setOpen] = useState(false)

  const [PhoneNo, setPhoneNo] = useState(null)
  const [TicketId, setTicketId] = useState(null)
  const [ChangeStatus, setChangeStatus] = useState(null)

  const [Reload, setReload] = useState(false)

  const [Loading, setLoading] = useState(true)

  const [open2, setOpen2] = useState(false)

  const [open3, setOpen3] = useState(false)

  const [Remark, setRemark] = useState("")

  const [BZRemark, setBZRemark] = useState("")

  const [Active, setActive] = useState(null)

  const [ValidationError, setValidationError] = useState("")



  const AddRemark = () => {
    if (Remark.replace(/\s/g, "").length === 0) {
      return setValidationError("remark can not be empty")
    }

    firestore
      .collection("support")
      .doc(Active)
      .set(
        {
          remarks: Remark,
        },
        {merge: true}
      )
      .then((succ) => {
        console.log("remark added")

        getAllCategories()
        handleClose2()
      })
      .catch((err) => {
        console.log("error", err)
      })
  }

  const AddBigwayzRemark = () => {
    if (BZRemark.replace(/\s/g, "").length === 0) {
      return setValidationError("remark can not be empty")
    }

    firestore
      .collection("support")
      .doc(Active)
      .set(
        {
          BigwayzRemarks: BZRemark,
        },
        {merge: true}
      )
      .then((succ) => {
        console.log("remark added")

        getAllCategories()
        handleClose3()
      })
      .catch((err) => {
        console.log("error", err)
      })
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
    console.log(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setTicketId(null)
    setPhoneNo(null)
  }

  const handleClose2 = () => {
    setValidationError("")
    setRemark("")
    setOpen2(false)
  }

  const handleClose3 = () => {
    setValidationError("")
    setBZRemark("")
    setOpen3(false)
  }


  const handleClickOpen2 = () => {
    setOpen2(true)
  }

  const handleClickOpen3 = () => {
    setOpen3(true)
  }

  useEffect(() => {
    console.log("ran")
    getAllCategories()
  }, [Reload])

  const getAllCategories = () => {
    firestore
      .collection("support")
      .orderBy("createdAt", "desc")
      .get()
      .then((data) => {
        let dummyArray = []
        data.forEach((doc) => {
          dummyArray.push(doc.data())
        })

        console.log("my data ", dummyArray)
        setData(dummyArray)
        setLoading(false)
      })
  }

  let headers = [
    {label: "Category", key: "category"},
    {label: "Name", key: "name"},
    {label: "Phone", key: "phone"},
    {label: "Remarks", key: "remarks"},
    {label: "Status", key: "status"},
    {label: "TicketId", key: "ticketId"},
    {label: "Service", key: "service"},
    {label: "BigwayzRemarks", key: "BigwayzRemarks"},
    {label: "TransactionId", key: "transactionId"},
  ]

  const handleChangeStatus = (phone, id, status) => {
    console.log(phone, id, status)
    axios
      .post(
        "https://us-central1-bigwayz-backend.cloudfunctions.net/change_support_status",
        {
          phone: phone,
          ticketId: id,
          status: status,
        }
      )
      .then((res) => {
        console.log(res.data)
        handleClose()
        setReload(!Reload)
      })
    // getAllCategories()
  }

  const renderStatus = (status) => {
    if (status == "open") {
      return (
        <div
          style={{
            backgroundColor: "green",
            padding: 2,
            color: "#ffffff",
            textAlign: "center",
            borderRadius: 8,
          }}
        >
          open
        </div>
      )
    } else if (status == "progress") {
      return (
        <div
          style={{
            backgroundColor: "#F6BE00",
            padding: 2,
            color: "#ffffff",
            textAlign: "center",
            borderRadius: 8,
          }}
        >
          in-progress
        </div>
      )
    } else if (status == "close") {
      return (
        <div
          style={{
            backgroundColor: "grey",
            padding: 2,
            color: "#ffffff",
            textAlign: "center",
            borderRadius: 8,
          }}
        >
          closed
        </div>
      )
    }
  }

  let time = Date.now()

  return Loading ? (
    <div
    className="skeleton text-center d-flex justify-content-center"
    style={{
      width: "100%",
      marginTop: "3rem",
      flexDirection:'column',
      padding:30
    }}
  >
    <Skeleton
      style={{
        background: "#efefef",
        // width:'8rem',
        // marginLeft:'5%'
        // margin:'auto'
      }}
      animation="wave"
      variant="text"
      width={'100%'}
      height={64}
    />
     <Skeleton
      style={{
        background: "#efefef",
        // width:'8rem',
        // marginLeft:'5%'
        // margin:'auto'
      }}
      animation="wave"
      variant="text"
      width={'100%'}
      height={64}
    />
     <Skeleton
      style={{
        background: "#efefef",
        // width:'8rem',
        // marginLeft:'5%'
        // margin:'auto'
      }}
      animation="wave"
      variant="text"
      width={'100%'}
      height={64}
    />
     <Skeleton
      style={{
        background: "#efefef",
        // width:'8rem',
        // marginLeft:'5%'
        // margin:'auto'
      }}
      animation="wave"
      variant="text"
      width={'100%'}
      height={64}
    />
     <Skeleton
      style={{
        background: "#efefef",
        // width:'8rem',
        // marginLeft:'5%'
        // margin:'auto'
      }}
      animation="wave"
      variant="text"
      width={'100%'}
      height={64}
    />
     <Skeleton
      style={{
        background: "#efefef",
        // width:'8rem',
        // marginLeft:'5%'
        // margin:'auto'
      }}
      animation="wave"
      variant="text"
      width={'100%'}
      height={64}
    />
     <Skeleton
      style={{
        background: "#efefef",
        // width:'8rem',
        // marginLeft:'5%'
        // margin:'auto'
      }}
      animation="wave"
      variant="text"
      width={'100%'}
      height={64}
    />
     <Skeleton
      style={{
        background: "#efefef",
        // width:'8rem',
        // marginLeft:'5%'
        // margin:'auto'
      }}
      animation="wave"
      variant="text"
      width={'100%'}
      height={64}
    />
     <Skeleton
      style={{
        background: "#efefef",
        // width:'8rem',
        // marginLeft:'5%'
        // margin:'auto'
      }}
      animation="wave"
      variant="text"
      width={'100%'}
      height={64}
    />
     <Skeleton
      style={{
        background: "#efefef",
        // width:'8rem',
        // marginLeft:'5%'
        // margin:'auto'
      }}
      animation="wave"
      variant="text"
      width={'100%'}
      height={64}
    />
     <Skeleton
      style={{
        background: "#efefef",
        // width:'8rem',
        // marginLeft:'5%'
        // margin:'auto'
      }}
      animation="wave"
      variant="text"
      width={'100%'}
      height={64}
    />
     <Skeleton
      style={{
        background: "#efefef",
        // width:'8rem',
        // marginLeft:'5%'
        // margin:'auto'
      }}
      animation="wave"
      variant="text"
      width={'100%'}
      height={64}
    />
     <Skeleton
      style={{
        background: "#efefef",
        // width:'8rem',
        // marginLeft:'5%'
        // margin:'auto'
      }}
      animation="wave"
      variant="text"
      width={'100%'}
      height={64}
    />
  </div>
  ) : (
    <div>
      {/* <Alert severity="info">
                <AlertTitle>Search User Info</AlertTitle>
                The below Search Bar is an — <strong>universal searchbar!</strong> You can search users using their name, username, role, phone, email or current status.
            </Alert> */}

      <Dialog className={classes.myDailogBox} open={open2}>
        {ValidationError && (
          <p className={classes.validation}> {ValidationError} </p>
        )}

        <DialogTitle>Add Remark</DialogTitle>

        <DialogContent>
          <TextField
            margin="dense"
            id="remark"
            label=""
            type="text"
            fullWidth
            onChange={(e) => setRemark(e.target.value)}
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose2()}>Close</Button>
          <Button onClick={() => AddRemark()}> Add </Button>
        </DialogActions>
      </Dialog>

      <Dialog className={classes.myDailogBox} open={open3}>
        {ValidationError && (
          <p className={classes.validation}> {ValidationError} </p>
        )}

        <DialogTitle>Add Remark</DialogTitle>

        <DialogContent>
          <TextField
            margin="dense"
            id="remark"
            label=""
            type="text"
            fullWidth
            onChange={(e) => setBZRemark(e.target.value)}
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose3()}>Close</Button>
          <Button onClick={() => AddBigwayzRemark()}> Add </Button>
        </DialogActions>
      </Dialog>

      <Grid container spacing={0}>
        <Grid item xs={6}>
          <Typography className={classes.title}>Support Tickets</Typography>
        </Grid>

        <Grid item xs={6}>
          <CSVLink
            style={{
              backgroundColor: secondaryColor,
              color: whiteColor,
              padding: 8,
              borderRadius: 8,
              marginTop: 30,
              position: "relative",
              display: "flex",
              float: "right",
              marginRight: 20,
              marginTop: 20,
              textDecoration: "none",
            }}
            filename={moment(time).format("MMMM Do YYYY, h:mm:ss a") + ".csv"}
            data={data}
            headers={headers}
          >
            Download
          </CSVLink>

          <Link
            style={{
              backgroundColor: secondaryColor,
              color: whiteColor,
              padding: 8,
              borderRadius: 8,
              marginTop: 30,
              position: "relative",
              display: "flex",
              float: "right",
              marginRight: 20,
              marginTop: 20,
              textDecoration: "none",
            }}
            variant="contained"
            to={`/createSupportTicket`}
            className=""
          >
            {" "}
            Create Ticket
          </Link>
        </Grid>

        {/* <Grid item xs={1} style={{ marginLeft: "20px", marginTop: "5px" }}>
          <Button
            onClick={() => history.push("/supportticketdetail")}
            style={{ backgroundColor: primaryColor }}
            className={classes.addbutton}
            variant="contained"
          >
            Manage
          </Button>
        </Grid> */}
      </Grid>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleChangeStatus(PhoneNo, TicketId, "open")}>
          Open
        </MenuItem>
        <MenuItem
          onClick={() => handleChangeStatus(PhoneNo, TicketId, "progress")}
        >
          In-progress
        </MenuItem>
        <MenuItem
          onClick={() => handleChangeStatus(PhoneNo, TicketId, "close")}
        >
          Close
        </MenuItem>
      </Menu>

      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell className={classes.tablehead}>Sr.</TableCell>
              <TableCell align="left" className={classes.tablehead}>
              Created On
            </TableCell>
              <TableCell align="" className={classes.tablehead}>
                Ticket ID
              </TableCell>
              <TableCell align="" className={classes.tablehead}>
                Name
              </TableCell>
              <TableCell align="" className={classes.tablehead}>
                Phone
              </TableCell>
              <TableCell align="left" className={classes.tablehead}>
                Service
              </TableCell>
              <TableCell align="left" className={classes.tablehead}>
                Category
              </TableCell>
              <TableCell align="center" className={classes.tablehead}>
                Attachment
              </TableCell>
              <TableCell align="left" className={classes.tablehead}>
              Transaction ID
            </TableCell>
              <TableCell align="left" className={classes.tablehead}>
                Remarks
              </TableCell>
              <TableCell align="left" className={classes.tablehead}>
                BigwayzRemarks
              </TableCell>
    
    
              <TableCell align="left" className={classes.tablehead}>
                Status
              </TableCell>
              <TableCell align="left" className={classes.tablehead}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((item, index) => {
              return (
                <>
                  <TableRow
                    key={item.name}
                    style={{
                      backgroundColor: index % 2 == 0 ? lightGray : "#ffffff",
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell align="left">
                    {/* {item.createdAt} */}
                    {moment(parseInt(item?.createdAt)).format("L")}
                    {/* {moment(parseInt(item?.createdAt)).subtract(10, 'days').calendar()} */}
                    {/* {moment(item.createdAt).format("MMMM Do YYYY, h:mm:ss a")} */}
                  </TableCell>
                    <TableCell align="left">{item.ticketId}</TableCell>
                    <TableCell align="left">{item.name}</TableCell>
                    <TableCell align="left">{item.phone}</TableCell>
                    <TableCell align="left">{item.service}</TableCell>
                    <TableCell align="left">{item.category}</TableCell>
                    <TableCell align="left">
                      {" "}
                      <img
                        style={{width: "100%", height: "100%"}}
                        src={item.attachment}
                      />{" "}
                    </TableCell>
                    <TableCell align="left">{item.transactionId}</TableCell>
                    <TableCell align="left">
                      {" "}
                      {item.remarks ? (
                        item.remarks
                      ) : (
                        <button
                          onClick={() => {
                            handleClickOpen2()
                            setActive(item.ticketId)
                          }}
                          className={classes.ActionButton}
                        >
                          {" "}
                          Add{" "}
                        </button>
                      )}{" "}
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      {item.BigwayzRemarks ? (
                        item.BigwayzRemarks
                      ) : (
                        <button
                          onClick={() => {
                            handleClickOpen3()
                            setActive(item.ticketId)
                          }}
                          className={classes.ActionButton}
                        >
                          {" "}
                          Add{" "}
                        </button>
                      )}{" "}
                    </TableCell>

         


                    <TableCell align="left" style={{color: "#8c8c8c"}}>
                      {renderStatus(item.status)}
                    </TableCell>
                    <TableCell>
                      <div
                        onClick={(e) => {
                          handleClick(e)
                          setTicketId(item.ticketId)
                          setPhoneNo(item.phone)
                        }}
                        style={{
                          backgroundColor: "#F6BE00",
                          padding: 2,
                          color: "#ffffff",
                          textAlign: "center",
                          borderRadius: 8,
                          cursor: "pointer",
                        }}
                      >
                        Edit
                      </div>
                    </TableCell>
                  </TableRow>
                </>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}
