import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Accezory from '../assets/website/Brand/Accezory.png';
import Adidas from '../assets/website/Brand/Adidas.png';
import AmericanTourister from '../assets/website/Brand/AmericanTourister.png';
import aristocrat from '../assets/website/Brand/aristocrat.png';
import ASICSs from '../assets/website/Brand/ASICS.png';
import Bagaholics from '../assets/website/Brand/Bagaholics.png';
import Bagsrus from '../assets/website/Brand/Bagsrus.png';
import Baidyanath from '../assets/website/Brand/Baidyanath.png';
import bblunt from '../assets/website/Brand/bblunt.png';
import BritishKnights from '../assets/website/Brand/BritishKnights.png';
import BUWCHh from '../assets/website/Brand/BUWCH.png';
import CalvinKlein from '../assets/website/Brand/CalvinKlein.png';
import CanineCreek from '../assets/website/Brand/CanineCreek.png';
import Caprese from '../assets/website/Brand/Caprese.png';
import chaiSafari from '../assets/website/Brand/chaiSafari.png';
import chloe from '../assets/website/Brand/chloe.png';

import Cinthol from '../assets/website/Brand/Cinthol.png';
import citizen from '../assets/website/Brand/citizen.png';
import ClassicFashion from '../assets/website/Brand/ClassicFashion.png';
import Clovia from '../assets/website/Brand/Clovia.png';
import DIFFERENCEOFOPINIONs from '../assets/website/Brand/DIFFERENCEOFOPINION.png';
import Dillinger from '../assets/website/Brand/Dillinger.png';
import drools from '../assets/website/Brand/drools.png';
import fastrack from '../assets/website/Brand/fastrack.png';
import fcuk from '../assets/website/Brand/fcuk.png';
import Fila from '../assets/website/Brand/Fila.png';
import Franks from '../assets/website/Brand/Franks.png';
import garnier from '../assets/website/Brand/garnier.png';
import godrej from '../assets/website/Brand/godrej.png';
import Gonemad from '../assets/website/Brand/Gonemad.png';
import Hidegear from '../assets/website/Brand/Hidegear.png';
import Hidekraft from '../assets/website/Brand/Hidekraft.png';

import Hidesign from '../assets/website/Brand/Hidesign.png';
import HighValley from '../assets/website/Brand/HighValley.png';
import Himalaya from '../assets/website/Brand/Himalaya.png';
import Horra from '../assets/website/Brand/Horra.png';
import Huntsman from '../assets/website/Brand/Huntsman.png';
import iknow from '../assets/website/Brand/iknow.png';
import Indiamigo from '../assets/website/Brand/Indiamigo.png';
import Kohinoor from '../assets/website/Brand/Kohinoor.png';
import krosshorn from '../assets/website/Brand/krosshorn.png';
import lactocalamine from '../assets/website/Brand/lactocalamine.png';
import Layerr from '../assets/website/Brand/Layerr.png';
import Leaderachi from '../assets/website/Brand/Leaderachi.png';
import LeatherWorld from '../assets/website/Brand/LeatherWorld.png';
import lee from '../assets/website/Brand/lee.png';
import LetsBite from '../assets/website/Brand/LetsBite.png';
import Linoperros from '../assets/website/Brand/Linoperros.png';

import Littels from '../assets/website/Brand/Littels.png';
import Nike from '../assets/website/Brand/Nike.png';
import nykaa from '../assets/website/Brand/nykaa.png';
import peperone from '../assets/website/Brand/peperone.png';
import piramal from '../assets/website/Brand/piramal.png';
import plush from '../assets/website/Brand/plush.png';
import polycrol from '../assets/website/Brand/polycrol.png';
import priority from '../assets/website/Brand/priority.png';
import Protekt from '../assets/website/Brand/Protekt.png';
import puma from '../assets/website/Brand/puma.png';
import purepet from '../assets/website/Brand/purepet.png';
import Reebok from '../assets/website/Brand/Reebok.png';
// import RoysterCallus from '../assets/website/Brand/RoysterCallus.png';
import Saba from '../assets/website/Brand/Saba.png';
import skybags from '../assets/website/Brand/skybags.png';
import Smilykiddos from '../assets/website/Brand/Smilykiddos.png';

// import supradyn from '../assets/website/Brand/supradyn.png';
import SwissMilitary from '../assets/website/Brand/SwissMilitary.png';
// import tetmosol from '../assets/website/Brand/tetmosol.png';
import Titan from '../assets/website/Brand/Titan.png';
// import TommyHilfiger from '../assets/website/Brand/TommyHilfiger.png';
// import Unitedcolorofbenetton from '../assets/website/Brand/Unitedcolorofbenetton.png';
import UnitedColors from '../assets/website/Brand/UnitedColors.png';
import UrbanBuddha from '../assets/website/Brand/UrbanBuddha.png';
import Vans from '../assets/website/Brand/Vans.png';
import VIPz from '../assets/website/Brand/VIP.png';
import Vishisht from '../assets/website/Brand/Vishisht.png';
// import VLCCs from '../assets/website/Brand/VLCC.png';
import wildcraft from '../assets/website/Brand/wildcraft.png';
import wildhorn from '../assets/website/Brand/wildhorn.png';
import woodland from '../assets/website/Brand/woodland.png';
import Wrangler from '../assets/website/Brand/Wrangler.png';
import zoff from '../assets/website/Brand/zoff.png';


const Logos = [Accezory, Adidas, AmericanTourister, aristocrat, ASICSs, Bagaholics, Bagsrus,Baidyanath,bblunt,BritishKnights,BUWCHh,CalvinKlein,CanineCreek,Caprese,chaiSafari,chloe,Cinthol,citizen,ClassicFashion,Clovia,DIFFERENCEOFOPINIONs,Dillinger,drools,fastrack,fcuk,Fila,Franks,garnier,godrej,Gonemad,Hidegear,Hidekraft,Hidesign,HighValley,Himalaya,Horra,Huntsman,iknow,Indiamigo,Kohinoor,krosshorn,lactocalamine,Layerr ,Leaderachi,LeatherWorld,lee,LetsBite,Linoperros,Littels,Nike,nykaa,peperone,piramal,plush,polycrol,priority,Protekt,puma,purepet,Reebok,Saba,skybags,Smilykiddos,SwissMilitary,Titan,UnitedColors,UrbanBuddha,Vans,VIPz,Vishisht,wildcraft,wildhorn,woodland,Wrangler,zoff]


const useStyles = makeStyles(theme =>({
    LogoSliderContainer:{
        height:'100px',
        position:'relative',
        overflow:'hidden',
        padding: '20px', 
        marginTop:'30px'
    },
    LogoSliderImg:{
        margin: '0 0.8em',
        height: '100px'
    },
    '@keyframes bannermove': {
        '0%': {
            transform: 'translate(0, 0)',
        },
        '100%': {
            transform: 'translate(-50%, 0)',
        }
    },
    logobanner: {
        backgroundColor:'#ffffff',
        position:'absolute', 
        top:'0px',
        left:'0px',
        overflow:'hidden',
        whiteSpace: 'nowrap',
        animation: `$bannermove 30s linear infinite`
    }
}))


function LogoSlider() {
    const classes = useStyles();

    return (
        <div className={classes.LogoSliderContainer}>
        <div className={classes.logobanner}>
            {Logos.map((logo,index)=> (
                <img className={classes.LogoSliderImg} src={logo} alt="" />
            ))}
            
        </div>
    </div>
    )
}

export default LogoSlider;
