import React, {useEffect, useState} from "react"

import {CSVLink, CSVDownload} from "react-csv"
import Grid from "@material-ui/core/Grid"
import moment from "moment"
import {
  lightGray,
  primaryColor,
  primaryTint,
  secondaryColor,
  whiteColor,
} from "../../../config/constants"
import SearchIcon from "@material-ui/icons/Search"

import {
  IconButton,
  Popover,
  Checkbox,
  FormControlLabel,
  FormGroup,
  makeStyles,
  TextField,
  Typography,
  Dialog,
} from "@material-ui/core"

import {Link} from "react-router-dom"
import {firestore, storage} from "../../../config/firebase"
import {fade} from "@material-ui/core"
import TableContainer from "@material-ui/core/TableContainer"
import Paper from "@material-ui/core/Paper"
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import TableBody from "@material-ui/core/TableBody"
import EditIcon from "@material-ui/icons/Edit"
import DeleteIcon from "@material-ui/icons/Delete"
import Button from "@material-ui/core/Button"
import Fuse from "fuse.js"
import InputBase from "@material-ui/core/InputBase"
import {useHistory} from "react-router-dom"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import Firebase from "firebase"
import axios from "axios"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import {Alert, AlertTitle} from "@material-ui/lab"
import Skeleton from "@material-ui/lab/Skeleton"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  title: {
    fontSize: 24,
    color: secondaryColor,
    fontWeight: 700,
    margin: 20,
    fontFamily: "Myraid",
    fontWeight: "800",
  },
  validation: {
    marginTop: "0.6em",
    marginLeft: "0.2em",
    marginRight: "0.2em",
    background: "red",
    color: "white",
    borderRadius: "10px",
    paddingLeft: "1em",
  },
  green: {
    backgroundColor: "green",
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 3,
    paddingRight: 3,
    color: "#ffffff",
    textAlign: "center",
    borderRadius: 8,
  },
  background: {
    backgroundColor: "rgba(240, 102, 105, 0.13)",
    padding: 16,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    borderRadius: 8,
    marginLeft: "10px",
    marginRight: "10px",
    marginBottom: "5px",
  },
  background1: {
    backgroundColor: "#ffffff",
    padding: 16,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    borderRadius: 8,
    marginLeft: "10px",
    marginRight: "10px",
  },
  avatartitle: {
    display: "flex",
    width: "20%",
  },
  table: {
    minWidth: 600,
  },
  tableContainer: {
    margin: "1%",
    width: "98%",
  },
  tablehead: {
    fontSize: "15px",
    fontWeight: "800",
    background: secondaryColor,
    color: "white",
  },
  ActionButton: {
    background: "#303c54",
    color: "#ffffff",
    fontWeight: "500",
    borderRadius: "15px",
    border: "transparent",
    /* position: absolute;
right: 0; */
    paddingLeft: "0.4em",
    paddingRight: "0.4em",
    paddingTop: "0.3em",
    paddingBottom: "0.3em",
  },
  icons: {
    display: "flex",
    justifyContent: "space-around",
  },
  editicon: {
    // marginRight:'30px',
    fill: primaryColor,
    cursor: "pointer",
  },
  deleteicon: {
    fill: secondaryColor,
    cursor: "pointer",
  },
  registeredname: {
    padding: "10px",
    fontSize: 16,
  },
  addbutton: {
    display: "flex",
    float: "right",
    margin: 16,
    width: 150,
    background: secondaryColor,
    color: whiteColor,
    padding: 3,
    textAlign: "center",
    cursor: "pointer",
    textDecoration: "none",
  },
  editbutton: {
    color: whiteColor,
    backgroundColor: secondaryColor,
    borderRadius: 8,
    padding: 8,
    textAlign: "center",
    margin: 5,
    cursor: "pointer",
    maxHeight: 35,
    textDecoration: "none",
  },
  date: {
    marginTop: 10,
    fontSize: 16,
    fontWeight: 300,
  },
  search: {
    position: "relative",
    display: "flex",
    float: "right",
    marginRight: 20,
    marginTop: 20,
    // width:200,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(secondaryColor, 0.15),
    "&:hover": {
      backgroundColor: fade(secondaryColor, 0.25),
    },
    marginLeft: 0,
    width: "200px",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}))

export default function Transactions() {
  const classes = useStyles()
  const history = useHistory()
  const [data, setData] = useState([])
  const [DummyData, setDummyData] = useState([])

  const [anchorEl, setAnchorEl] = React.useState(null)

  const [Loading, setLoading] = useState(true)

  const [Active, setActive] = useState(null)

  const [ApproveId, setApproveId] = useState("")

  const [Remark, setRemark] = useState("")

  const [open1, setOpen1] = useState(false)

  const [open2, setOpen2] = useState(false)

  const [ValidationError, setValidationError] = useState("")

  const handleSearch = (text) => {
    console.log("Searching....", text)
    let dummyData = data
    const options = {
      isCaseSensitive: false,
      threshold: 0.3,
      keys: [
        "transactionId",
        "status",
        "text",
        "phone",
        "remark",
        "amount",
        "name",
      ],
    }
    const fuseCategory = new Fuse(data, options)
    var temp = fuseCategory.search(text)

    console.log("searched leads ==", temp)

    let dummyArray = []

    if (temp.length < 1) {
      console.log("entered")

      // dummyArray = data

      setData(DummyData)
    } else {
      temp.forEach((obj) => {
        dummyArray.push(obj.item)
      })
      console.log("seeing", dummyArray)

      setData(dummyArray)
    }
  }

  let headers = [
    {label: "Amount", key: "amount"},
    {label: "PackageId", key: "packageId"},
    {label: "Phone", key: "phone"},
    {label: "status", key: "status"},
    {label: "TransactionId", key: "transactionId"},
    {label: "Text", key: "text"},
    {label: "Type", key: "type"},
  ]

  const handleClickOpen1 = () => {
    setOpen1(true)
  }

  const handleClickOpen2 = () => {
    setOpen2(true)
  }

  const handleClose1 = () => {
    setOpen1(false)
  }

  const handleClose2 = () => {
    setValidationError("")
    setRemark("")
    setOpen2(false)
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
    console.log(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    getAllCategories()
  }, [])

  const getAllCategories = () => {
    firestore
      .collection("transactions")
      .orderBy("createdAt", "desc")
      .get()
      .then((data) => {
        let dummyArray = []

        data.forEach((doc) => {
          dummyArray.push(doc.data())
        })

        console.log("dummyArray", dummyArray.length)

        const filteredData = dummyArray.filter((ET) => {
          return ET.transactionId !== undefined
        })

        console.log("filtered data", filteredData.length)

        console.log(filteredData)
        setData(filteredData.reverse())
        setDummyData(filteredData.reverse())
        setLoading(false)
      })
  }

  const renderStatus = (status) => {
    if (status == "success") {
      return (
        <div
          style={{
            backgroundColor: "green",
            padding: 2,
            color: "#ffffff",
            textAlign: "center",
            borderRadius: 8,
          }}
        >
          success
        </div>
      )
    } else if (status == "pending") {
      return (
        <div
          style={{
            backgroundColor: "#F6BE00",
            padding: 2,
            color: "#ffffff",
            textAlign: "center",
            borderRadius: 8,
          }}
        >
          pending
        </div>
      )
    }
  }

  const AddRemark = () => {
    if (Remark.replace(/\s/g, "").length === 0) {
      return setValidationError("remark can not be empty")
    }

    firestore
      .collection("transactions")
      .doc(Active)
      .set(
        {
          remark: Remark,
        },
        {merge: true}
      )
      .then((succs) => {
        console.log("remark added")

        getAllCategories()
        handleClose2()
      })
      .catch((err) => {
        console.log("error", err)
      })
  }

  const Approve = () => {
    // console.log('id',Id)

    firestore
      .collection("transactions")
      .doc(ApproveId)
      .set(
        {
          verified: true,
        },
        {merge: true}
      )
      .then((succs) => {
        console.log("approved")
        getAllCategories()
        handleClose1()
      })
      .catch((err) => {
        console.log("error", err)
      })
  }

  let time = Date.now()

  return Loading ? (
    <div
      className="skeleton text-center d-flex justify-content-center"
      style={{
        width: "100%",
        marginTop: "3rem",
        flexDirection: "column",
        padding: 30,
      }}
    >
      <Skeleton
        style={{
          background: "#efefef",
          // width:'8rem',
          // marginLeft:'5%'
          // margin:'auto'
        }}
        animation="wave"
        variant="text"
        width={"100%"}
        height={64}
      />
      <Skeleton
        style={{
          background: "#efefef",
          // width:'8rem',
          // marginLeft:'5%'
          // margin:'auto'
        }}
        animation="wave"
        variant="text"
        width={"100%"}
        height={64}
      />
      <Skeleton
        style={{
          background: "#efefef",
          // width:'8rem',
          // marginLeft:'5%'
          // margin:'auto'
        }}
        animation="wave"
        variant="text"
        width={"100%"}
        height={64}
      />
      <Skeleton
        style={{
          background: "#efefef",
          // width:'8rem',
          // marginLeft:'5%'
          // margin:'auto'
        }}
        animation="wave"
        variant="text"
        width={"100%"}
        height={64}
      />
      <Skeleton
        style={{
          background: "#efefef",
          // width:'8rem',
          // marginLeft:'5%'
          // margin:'auto'
        }}
        animation="wave"
        variant="text"
        width={"100%"}
        height={64}
      />
      <Skeleton
        style={{
          background: "#efefef",
          // width:'8rem',
          // marginLeft:'5%'
          // margin:'auto'
        }}
        animation="wave"
        variant="text"
        width={"100%"}
        height={64}
      />
      <Skeleton
        style={{
          background: "#efefef",
          // width:'8rem',
          // marginLeft:'5%'
          // margin:'auto'
        }}
        animation="wave"
        variant="text"
        width={"100%"}
        height={64}
      />
      <Skeleton
        style={{
          background: "#efefef",
          // width:'8rem',
          // marginLeft:'5%'
          // margin:'auto'
        }}
        animation="wave"
        variant="text"
        width={"100%"}
        height={64}
      />
      <Skeleton
        style={{
          background: "#efefef",
          // width:'8rem',
          // marginLeft:'5%'
          // margin:'auto'
        }}
        animation="wave"
        variant="text"
        width={"100%"}
        height={64}
      />
      <Skeleton
        style={{
          background: "#efefef",
          // width:'8rem',
          // marginLeft:'5%'
          // margin:'auto'
        }}
        animation="wave"
        variant="text"
        width={"100%"}
        height={64}
      />
      <Skeleton
        style={{
          background: "#efefef",
          // width:'8rem',
          // marginLeft:'5%'
          // margin:'auto'
        }}
        animation="wave"
        variant="text"
        width={"100%"}
        height={64}
      />
      <Skeleton
        style={{
          background: "#efefef",
          // width:'8rem',
          // marginLeft:'5%'
          // margin:'auto'
        }}
        animation="wave"
        variant="text"
        width={"100%"}
        height={64}
      />
      <Skeleton
        style={{
          background: "#efefef",
          // width:'8rem',
          // marginLeft:'5%'
          // margin:'auto'
        }}
        animation="wave"
        variant="text"
        width={"100%"}
        height={64}
      />
    </div>
  ) : (
    <div>
      <Dialog className={classes.myDailogBox} open={open1}>
        <DialogContent>
          Do you really want to approve transaction {ApproveId}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose1()}>Close</Button>
          <Button onClick={() => Approve()}> yes </Button>
        </DialogActions>
      </Dialog>

      <Dialog className={classes.myDailogBox} open={open2}>
        {ValidationError && (
          <p className={classes.validation}> {ValidationError} </p>
        )}

        <DialogTitle>Add Remark</DialogTitle>

        <DialogContent>
          <TextField
            margin="dense"
            id="remark"
            label=""
            type="text"
            fullWidth
            onChange={(e) => setRemark(e.target.value)}
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose2()}>Close</Button>
          <Button onClick={() => AddRemark()}> Add </Button>
        </DialogActions>
      </Dialog>

      <Alert severity="info">
        <AlertTitle>All Transactions</AlertTitle>
        The below table shows — <strong>all transactions</strong> done by all
        registered users. Search bar can be used to filter records based on
        phone, amount, transaction type, transaction status and transaction
        text.
      </Alert>
      <Grid container spacing={0}>
        <Grid item xs={6}>
          <Typography className={classes.title}>Transactions</Typography>
        </Grid>
        <Grid item xs={6}>
          <CSVLink
            style={{
              backgroundColor: secondaryColor,
              color: whiteColor,
              padding: 8,
              borderRadius: 8,
              marginTop: 30,
              position: "relative",
              display: "flex",
              float: "right",
              marginRight: 20,
              marginTop: 20,
              textDecoration: "none",
            }}
            filename={moment(time).format("MMMM Do YYYY, h:mm:ss a") + ".csv"}
            data={data}
            headers={headers}
          >
            Download
          </CSVLink>

          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="search here... "
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{"aria-label": "search"}}
              onChange={(e) => {
                handleSearch(e.target.value)
              }}
            />
          </div>
        </Grid>
      </Grid>

      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell className={classes.tablehead}>Sr.</TableCell>
              <TableCell align="" className={classes.tablehead}>
                Transaction ID
              </TableCell>
              <TableCell align="" className={classes.tablehead}>
                Phone
              </TableCell>
              <TableCell align="left" className={classes.tablehead}>
                Text
              </TableCell>
              <TableCell align="left" className={classes.tablehead}>
                Status
              </TableCell>
              <TableCell align="left" className={classes.tablehead}>
                Amount
              </TableCell>
              <TableCell align="left" className={classes.tablehead}>
                Created On
              </TableCell>
              <TableCell align="center" className={classes.tablehead}>
                Approve
              </TableCell>
              <TableCell align="left" className={classes.tablehead}>
                Remark
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item, index) => {
              return (
                <>
                  {index % 2 == 0 ? (
                    <TableRow key={item.name}>
                      <TableCell component="th" scope="row">
                        {index + 1}
                      </TableCell>
                      <TableCell align="left" style={{fontWeight: "800"}}>
                        {item.transactionId}
                      </TableCell>
                      <TableCell align="left">{item.phone}</TableCell>
                      <TableCell align="left">{item.text}</TableCell>
                      <TableCell align="left">
                        {renderStatus(item.status)}
                      </TableCell>
                      <TableCell align="left">{item.amount}</TableCell>
                      <TableCell align="left">
                        {new Date(item.createdAt).getDate() +
                          "/" +
                          (new Date(item.createdAt).getMonth() + 1) +
                          "/" +
                          new Date(item.createdAt).getFullYear()}
                      </TableCell>
                      <TableCell align="center">
                        {item.verified ? (
                          <span className={classes.green} style={{padding: 5}}>
                            {" "}
                            verified{" "}
                          </span>
                        ) : (
                          <button
                            onClick={() => {
                              handleClickOpen1()
                              setApproveId(item.transactionId)
                            }}
                            className={classes.ActionButton}
                          >
                            {" "}
                            Approve{" "}
                          </button>
                        )}
                      </TableCell>

                      <TableCell align="left">
                        {item.remark ? (
                          item.remark
                        ) : (
                          <button
                            onClick={() => {
                              handleClickOpen2()
                              setActive(item.transactionId)
                            }}
                            className={classes.ActionButton}
                          >
                            Add remark
                          </button>
                        )}
                      </TableCell>
                    </TableRow>
                  ) : (
                    <TableRow
                      key={item.name}
                      style={{backgroundColor: lightGray}}
                    >
                      <TableCell component="th" scope="row">
                        {index + 1}
                      </TableCell>
                      <TableCell align="left" style={{fontWeight: "800"}}>
                        {item.transactionId}
                      </TableCell>
                      <TableCell align="left">{item.phone}</TableCell>
                      <TableCell align="left">{item.text}</TableCell>
                      <TableCell align="left">
                        {renderStatus(item.status)}
                      </TableCell>
                      <TableCell align="left">{item.amount}</TableCell>
                      <TableCell align="left">
                        {new Date(item.createdAt).getDate() +
                          "/" +
                          (new Date(item.createdAt).getMonth() + 1) +
                          "/" +
                          new Date(item.createdAt).getFullYear()}
                      </TableCell>
                      <TableCell align="center">
                        {item.verified ? (
                          <span className={classes.green} style={{padding: 5}}>
                            {" "}
                            verified{" "}
                          </span>
                        ) : (
                          <button
                            onClick={() => {
                              handleClickOpen1()
                              setApproveId(item.transactionId)
                            }}
                            className={classes.ActionButton}
                          >
                            {" "}
                            Approve{" "}
                          </button>
                        )}
                      </TableCell>

                      <TableCell align="left">
                        {item.remark ? (
                          item.remark
                        ) : (
                          <button
                            onClick={() => {
                              handleClickOpen2()
                              setActive(item.transactionId)
                            }}
                            className={classes.ActionButton}
                          >
                            Add remark
                          </button>
                        )}
                      </TableCell>
                    </TableRow>
                  )}
                </>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}
