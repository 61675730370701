import { Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { Container } from "react-bootstrap";
import MainFooter from "../../components/MainFooter";
import MainNavBar from "../../components/MainNavBar";
import { primaryColor } from "../../config/webconstants";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "5% 0px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "56px",
    },
  },
  title: {
    fontSize: 20,
    color: primaryColor,
    fontWeight: 800,
    fontFamily: "Myraid",
    padding: "20px 0",
    textAlign: "center",
  },
  desc: {
    fontSize: 18,
    fontWeight: 600,
    fontFamily: "Myraid",
    textAlign: "justify",
  },
}));

export default function Privacypolicy() {
  const classes = useStyles();
  return (
    <>
      <MainNavBar />
      <div className={classes.root}>
        <Container>
          <Grid container>
            <Grid item xs={12} sm={12}>
              <Typography className={classes.title}>Privacy Policy</Typography>
              <Typography className={classes.desc}>
                Inginomicx Technologies Private Limited (Bigwayz) operates the
                Website www.bigwayz.com, which provides the SERVICE.
                <br />
                <br />
                This page is used to inform website visitors regarding our
                policies with the collection, use, and disclosure of Personal
                Information if anyone decided to use our Service, the Website
                www.bigwayz.com. If you choose to use our Service, then you
                agree to the collection and use of information in relation with
                this policy. The Personal Information that we collect are used
                for providing and improving the Service. We will not use or
                share your information with anyone except as described in this
                Privacy Policy. The terms used in this Privacy Policy have the
                same meanings as in our Terms and Conditions, which is
                accessible at www.bigwayz.com, unless otherwise defined in this
                Privacy Policy.
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Typography className={classes.title}>
                Information Collection and Use
              </Typography>
              <Typography className={classes.desc}>
                For a better experience while using our Service, we may require
                you to provide us with certain personally identifiable
                information, including but not limited to your name, phone
                number, and postal address. The information that we collect will
                be used to contact or identify you.
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Typography className={classes.title}>Log Data</Typography>
              <Typography className={classes.desc}>
                We want to inform you that whenever you visit our Service, we
                collect information that your browser sends to us that is called
                Log Data. This Log Data may include information such as your
                computer's Internet Protocol ("IP") address, browser version,
                pages of our Service that you visit, the time and date of your
                visit, the time spent on those pages, and other statistics.
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Typography className={classes.title}>Cookies</Typography>
              <Typography className={classes.desc}>
                Cookies are files with small amount of data that is commonly
                used as anonymous unique identifier. These are sent to your
                browser from the website that you visit and are stored on your
                computer's hard drive. Our website uses these "cookies" to
                collect information and to improve our Service. You have the
                option to either accept or refuse these cookies and know when a
                cookie is being sent to your computer. If you choose to refuse
                our cookies, you may not be able to use some portions of our
                Service.
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Typography className={classes.title}>
                Service Providers
              </Typography>
              <Typography className={classes.desc}>
                We may employ third-party companies and individuals due to the
                following reasons:
              </Typography>
              <br />
              <ul>
                <li>
                  <Typography className={classes.desc}>
                    To facilitate our Service;
                  </Typography>
                </li>
                <li>
                  <Typography className={classes.desc}>
                    To provide the Service on our behalf;
                  </Typography>
                </li>
                <li>
                  <Typography className={classes.desc}>
                    To perform Service-related services; or
                  </Typography>
                </li>
                <li>
                  <Typography className={classes.desc}>
                    To assist us in analyzing how our Service is used.
                  </Typography>
                </li>
              </ul>
              <br />
              <Typography className={classes.desc}>
                We want to inform our Service users that these third parties
                have access to your Personal Information. The reason is to
                perform the tasks assigned to them on our behalf. However, they
                are obligated not to disclose or use the information for any
                other purpose.
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Typography className={classes.title}>Security</Typography>
              <Typography className={classes.desc}>
                We value your trust in providing us your Personal Information,
                thus we are striving to use commercially acceptable means of
                protecting it. But remember that no method of transmission over
                the internet, or method of electronic storage is 100% secure and
                reliable, and we cannot guarantee its absolute security.
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Typography className={classes.title}>
                Links to Other Sites
              </Typography>
              <Typography className={classes.desc}>
                Our Service may contain links to other sites. If you click on a
                third-party link, you will be directed to that site. Note that
                these external sites are not operated by us. Therefore, we
                strongly advise you to review the Privacy Policy of these
                websites. We have no control over, and assume no responsibility
                for the content, privacy policies, or practices of any
                third-party sites or services.
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Typography className={classes.title}>
                Children's Privacy
              </Typography>
              <Typography className={classes.desc}>
                Our Services do not address anyone under the age of 18. We do
                not knowingly collect personal identifiable information from
                children under 18. In the case we discover that a child under 18
                has provided us with personal information, we immediately delete
                this from our servers. If you are a parent or guardian and you
                are aware that your child has provided us with personal
                information, please contact us so that we will be able to do
                necessary actions.
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Typography className={classes.title}>Disclaimer</Typography>
              <Typography className={classes.title}>
                For RS Symbol (₹) Use:
              </Typography>
              <Typography className={classes.desc}>
                Inginomicx Technologies Pvt Ltd. (‘Bigwayz’) hereby acknowledges
                that India Rupee symbol (₹), as imbedded in our artistic logo,
                was conceptualized and designed by Mr. Udaya Kumar and adopted
                by Government of India on 15th July, 2010 as National Symbol of
                India. Inginomicx Technologies Pvt Ltd, it’s Group / subsidiary
                companies, promoters, directors, employees or other partners /
                associates do not claim any proprietary rights or copyrights in
                or over symbol per se.
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Typography className={classes.title}>Disclaimer</Typography>
              <Typography className={classes.title}>
                For Unauthorized & Fraudulent Use Of Company Name & Marks:
              </Typography>
              <Typography className={classes.desc}>
                Inginomicx Technologies Pvt Ltd. (‘Bigwayz’) wishes to draw the
                attention of our customers and partners to the prevalence of
                fraudulent activity being perpetuated by fraudsters who aim to
                deceive and defraud our customers/partners by unauthorized use
                of Inginomicx Technologies Pvt Ltd. (‘Bigwayz’) name and
                trademarks on their websites and emails in an attempt to solicit
                fees from interested partners for enrolling them into program
                and illegitimately making wrongful gains. This type of fraud may
                be via email, letters, text messages, facsimile or by using a
                website purporting to be that of Inginomicx Technologies Pvt
                Ltd. (‘Bigwayz’). Our customers/partners and prospective
                partners are strongly advised to seek information/clarifications
                by contacting our business office directly and/or online through
                email at support@bigwayz.com before they enroll in a program or
                services of Inginomicx Technologies Pvt Ltd. (‘Bigwayz’) and
                communicate with any website other than www.bigwayz.com. Members
                of the public are hereby advised not to send/receive money
                to/from such scammers, as Inginomicx Technologies Pvt Ltd.
                (‘Bigwayz’) will have NO LIABILITY whatsoever for any and all
                losses/damages suffered by anyone who falls victim to such
                scams/letters from fraudsters. Inginomicx Technologies Pvt Ltd.
                (‘Bigwayz’) hereby disclaims all such
                transactions/correspondence and warns its customers/partners and
                the general public to disregard such fraudsters and to exercise
                extreme caution at all times.
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Typography className={classes.title}>
                Changes to this Privacy Policy
              </Typography>
              <Typography className={classes.desc}>
                We may update our Privacy Policy from time to time. Thus, we
                advise you to review this page periodically for any changes. We
                will notify you of any changes by posting the new Privacy Policy
                on this page. These changes are effective immediately, after
                they are posted on this page.
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Typography className={classes.title}>Contact Us</Typography>
              <Typography className={classes.desc}>
                If you have any questions or suggestions about our Privacy
                Policy, do not hesitate to contact us.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </div>
      <MainFooter showContact={false}/>
    </>
  );
}
