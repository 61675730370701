import { Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Banner from "../../components/Banner";
import MainNavBar from "../../components/MainNavBar";
import BannerImage from "../../assets/website/carousel/insurance.png";
import Logo from "../../assets/website/images/surakshalogo.png";
import { Button, Modal } from "react-bootstrap";
import {
  blackColor,
  lightGray,
  primaryColor,
  secondaryColor,
  whiteColor,
} from "../../config/webconstants";
import HospiCashImage from "../../assets/website/Insurance/hospicash.png";
import PhoneOutlinedIcon from "@material-ui/icons/PhoneOutlined";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import PersonalAccidentImage from "../../assets/website/Insurance/Personalaccidentcare.png";
import AccidentImage from "../../assets/website/Insurance/Accidentalhospitalizationcare.png";
import SuperTop from "../../assets/website/Insurance/Supertopup.png";
import TwoWheelerImage from "../../assets/website/Insurance/TwoWheelerInsurance.png";
import PrivateVehicleImage from "../../assets/website/Insurance/PrivateVehicleInsurance.png";
import CommericalImage from "../../assets/website/Insurance/CommercialInsurance.png";
import HealthImage from "../../assets/website/Insurance/HealthInsurance.png";
import MainFooter from "../../components/MainFooter";
import IIcon from "../../assets/website/Insurance/Iicon.png";
import axios from "axios";
import { Firebase, firestore, storage } from "../../config/firebase";
const API = axios.create({
  baseURL: "https://us-central1-bigwayz-backend.cloudfunctions.net",
});

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    flexDirection: "column",
    overflowX: "hidden",
    [theme.breakpoints.down("sm")]: {
      marginTop: "56px",
    },
  },
  logo: {
    marginLeft: "8%",
    width: 200,
    height: 80,
    objectFit: "contain",
    [theme.breakpoints.down("md")]: {
      width: 100,
      height: 80,
    },
  },
  tabbuttons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    margin: "5% 0px",
    [theme.breakpoints.down("md")]: {
      justifyContent: "space-around",
    },
  },
  container: {
    margin: "3% 15%",
    [theme.breakpoints.down("md")]: {
      margin: "3% 5%",
    },
  },
  tabbutton: {
    width: 200,
    color: blackColor,
    backgroundColor: lightGray,
    border: "none",
    "&:hover": {
      backgroundColor: primaryColor,
      color: whiteColor,
    },
    "&:focus": {
      backgroundColor: primaryColor,
      color: whiteColor,
      outline: "none !important",
      outlineOffset: "none !important",
    },
    [theme.breakpoints.down("sm")]: {
      width: 120,
      fontSize: 12,
      fontWeight: "600",
    },
  },
  tabbuttonSelected: {
    width: 200,
    color: whiteColor,
    backgroundColor: primaryColor,
    border: "none",
    "&:hover": {
      backgroundColor: primaryColor,
      color: whiteColor,
    },
    "&:focus": {
      backgroundColor: primaryColor,
      color: whiteColor,
      outline: "none !important",
      outlineOffset: "none !important",
      boxShadow: "none",
    },
    [theme.breakpoints.down("sm")]: {
      width: 120,
      fontSize: 12,
      fontWeight: "600",
    },
  },
  cardpaper: {
    backgroundColor: lightGray,
    // border:'1px solid',
    // borderColor:primaryColor,
    margin: "2%",
    padding: "10px",
    height: 300,
    borderRadius: 20,
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      height: 200,
    },
  },
  cardimage: {
    height: 200,
    width: "100%",
    objectFit: "contain",
    [theme.breakpoints.down("md")]: {
      height: 80,
    },
  },
  cardtitle: {
    fontSize: 16,
    fontWeight: 600,
    textAlign: "center",
    padding: "2px 0px",
    fontFamily: "Myraid",
  },
  cardyear: {
    fontSize: 12,
    textAlign: "center",
    fontFamily: "Myraid",
  },
  selectbutton: {
    width: "96%",
    color: blackColor,
    backgroundColor: lightGray,
    border: "none",
    marginLeft: "2%",
    marginTop: "5%",
    borderRadius: 20,
    fontFamily: "Myraid",
    "&:hover": {
      backgroundColor: lightGray,
      color: blackColor,
    },
    "&:focus": {
      backgroundColor: lightGray,
      color: blackColor,
      outline: "none !important",
      outlineOffset: "none !important",
      boxShadow: "none",
    },
  },
  selectbuttonselected: {
    width: "96%",
    color: whiteColor,
    backgroundColor: primaryColor,
    border: "none",
    marginLeft: "2%",
    marginTop: "5%",
    borderRadius: 20,
    fontFamily: "Myraid",
    "&:hover": {
      backgroundColor: primaryColor,
      color: whiteColor,
    },
    "&:focus": {
      backgroundColor: primaryColor,
      color: whiteColor,
      outline: "none !important",
      outlineOffset: "none !important",
      boxShadow: "none",
    },
  },
  boxcontainer: {
    border: `1px solid ${primaryColor}`,
    backgroundColor: primaryColor,
    margin: "5% 0px",
    borderRadius: 20,
  },
  boxheader: {
    fontSize: 20,
    color: whiteColor,
    padding: "10px 5%",
  },
  boxtitle: {
    fontSize: 20,
  },
  boxsubtitle: {
    fontSize: 14,
  },
  boxcontent: {
    margin: 2,
    backgroundColor: whiteColor,
    padding: 20,
    borderRadius: 20,
  },
  boxcontentimage: {
    width: "100%",
    height: 350,
    objectFit: "contain",
    paddingLeft: "5%",
    marginBottom: 20,
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: 150,
      paddingLeft: 0,
    },
  },
  contentleft: {
    marginTop: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  contentelefttitle: {
    fontSize: 20,
    textAlign: "center",
    padding: "4% 0px",
  },
  textfieldcontainer: {
    borderRadius: 8,
    border: `2px solid ${secondaryColor}`,
    display: "flex",
  },
  phoneiconcontainer: {
    borderRight: `2px solid ${secondaryColor}`,
  },
  phoneicon: {
    color: secondaryColor,
    fontSize: 20,
    margin: "10px 10px",
    fontFamily: "Myraid",
  },
  textfeild: {
    border: "none",
    width: "100%",
    borderRadius: 8,
    borderColor: secondaryColor,
    paddingLeft: 20,
    color: blackColor,

    "&:focus": {
      width: "100%",
      borderRadius: 8,
      borderColor: secondaryColor,
      outline: "none",
      boxShadow: "none",
    },
  },
  submitbutton: {
    margin: "3% 0px",
    color: whiteColor,
    backgroundColor: secondaryColor,
    border: "none",
    borderRadius: 8,
    padding: "5px 50px",
    letterSpacing: 1,
    fontFamily: "Myraid",
    fontWeight: 600,
    "&:hover": {
      backgroundColor: secondaryColor,
      color: whiteColor,
    },
    "&:focus": {
      backgroundColor: secondaryColor,
      color: whiteColor,
      outline: "none !important",
      outlineOffset: "none !important",
      boxShadow: "none",
    },
  },
  contentleftfooter: {
    fontFamily: "Myraid",
    fontSize: 14,
    fontWeight: "500",
  },
  contentright: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  contentrighttitle: {
    textAlign: "center",
    fontSize: 18,
    fontFamily: "Myraid",
    fontWeight: 600,
  },
  contentrightdesc: {
    fontSize: 16,
    fontFamily: "Myraid",
    textAlign: "justify",
  },
  insurancefooter: {
    fontSize: 16,
    fontFamily: "Myraid",
    fontWeight: 600,
    color: primaryColor,
  },
  registerbutton: {
    marginTop: "10px",
    color: whiteColor,
    backgroundColor: primaryColor,
    border: "none",
    borderRadius: 8,
    padding: "5px 50px",
    letterSpacing: 1,
    fontFamily: "Myraid",
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    fontWeight: 600,
    "&:hover": {
      backgroundColor: primaryColor,
      color: whiteColor,
    },
    "&:focus": {
      backgroundColor: primaryColor,
      color: whiteColor,
      outline: "none !important",
      outlineOffset: "none !important",
      boxShadow: "none",
    },
  },
  registerformcontainer: {
    backgroundColor: primaryColor,
    padding: "20px 5%",
    borderRadius: 20,
    borderTopLeftRadius: 0,
  },
  inputfeild: {
    border: "none",
    borderBottom: "5px solid #B54B4E",
    backgroundColor: "#B54B4E",
    outline: "none",
    backgroundColor: "transparent",
    color: whiteColor,
    margin: 20,
    fontSize: 18,
    paddingBottom: 10,
    width: "95%",
    fontFamily: "'Montserrat',san-serif",
    [theme.breakpoints.down("md")]: {
      fontSize: 15,
      margin: 5,
    },
  },
  ContactButtondiv: {
    width: "100%",
    marginTop: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  ContactButton: {
    marginTop: "90%",
    width: "100%",
    height: "50px",
    backgroundColor: "#EE6569",
    borderRadius: "30px",
    fontSize: "16px",
    fontFamily: "Myraid",
    color: "white",
    boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.5)",
    border: "none",
    "&:hover": {
      backgroundColor: "#EE6569",
    },
    [theme.breakpoints.down("md")]: {
      margin: 20,
      width: "50%",
    },
  },
  iicon: {
    height: 10,
    width: "20px",
    paddingLeft: 2,
    objectFit: "contain",
  },
}));

export default function Insurance() {
  const classes = useStyles();
  const Image = [{ img: BannerImage }];
  const [tab, setTab] = useState("suraksha");
  const [service, setService] = useState("");
  const [Register, setRegister] = useState(false);
  const [otpnumber, setotpnumber] = useState(null);
  const [otpoption, setotpoption] = useState(false);
  const [verified, setverified] = useState(false);
  const [contactdata, setcontactdata] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
    appLink: "https://bigwayz.in/",
  });
  const [isent, setissent] = useState(false);

  useEffect(() => {
    if (tab === "suraksha") {
      setService("Two Wheeler Insurance");
      return;
    }
    if (tab === "Group360") {
      setService("GC 360 (BIGWAYZ HOSPICASH 1K)");
      return;
    }
  }, []);

  const changeInput = (event) => {
    setcontactdata((prevProps) => ({
      ...prevProps,
      [event.target.name]: event.target.value,
    }));
  };

  const ContactSubmit = (e) => {
    e.preventDefault();
    contactdata.appLink = `http://tx3.in/K5Bt/${contactdata.phone}`;
    API.post("/website_contact_us", contactdata).then((response) => {
      console.log(response);
    });
    setissent(true);
    console.log(contactdata);
    setcontactdata({
      name: "",
      phone: "",
      email: "",
      message: "",
      appLink: "https://bigwayz.com/",
    });
    console.log("sent");
  };

  const SurakshaCards = [
    {
      image: TwoWheelerImage,
      title: "Two Wheeler Insurance",
      subtitle: "",
    },
    {
      image: PrivateVehicleImage,
      title: "Private Vehicle Insurance",
      subtitle: "",
    },
    {
      image: CommericalImage,
      title: "Commercial Insurance",
      subtitle: "",
    },
    {
      image: HealthImage,
      title: "Health Insurance",
      subtitle: "",
    },
  ];

  const Group360 = [
    {
      image: HospiCashImage,
      title: "GC 360",
      subheading: "BIGWAYZ HOSPICASH 1K",
      subtitle: "₹ 425 | 1 year",
    },
    {
      image: PersonalAccidentImage,
      title: "GC 360",
      subheading: "BIGWAYZ PA CARE",
      subtitle: "₹ 425 | 1 year",
    },
    {
      image: AccidentImage,
      title: "GC 360",
      subheading: "BIGWAYZ ACCI CARE",
      subtitle: "₹ 425 | 1 year",
    },
    {
      image: SuperTop,
      title: "GC 360",
      subheading: "BIGWAYZ ACCI CARE PLUS",
      subtitle: "₹ 720 | 1 year",
    },
  ];

  const data1 = {
    title: "Two Wheeler Insurance",
    subtitle:
      "Comprehensive, Third Party And Own Damage Two-Wheeler Insurance plans.",
    image: TwoWheelerImage,
    righttitle: "BENEFITS",
    desc1:
      "Get multiple quotes & choose the best policy for your need to be stress-free in case of any mishap involving your Two-wheeler by covering your financial dues to have an assured future.",
    desc2:
      "Two wheeler insurance is an agreement between the vehicle owner & the insurer where the insurer covers the cost of damages and repairs in case of some unforeseen incident involving the vehicle. A variety of policies are available for different purposes,including a third party liability cover that secures you from damages caused to or by a third party. A Comprehensive Two Wheeler policy that secures you with a better cover against a wider set of possibilities like accidents & natural calamities that may damage your vehicle and man-made causes like vandalism & riots.",
  };

  const data2 = {
    title: "Private Vehicle Insurance",
    subtitle: "Create a comprehensive four wheeler insurance plan",
    image: PrivateVehicleImage,
    righttitle: "BENEFITS",
    desc1:
      "A private vehicle insurance refers to an agreement between a car owner & the insurer where the insurer provides a financial cover in case of any unforeseen incidents are involved. Different kinds of vehicle insurances like third party car insurance secures you from financial expenses in case a third party has incurred losses or damage in an incident involving the insured vehicle. A comprehensive car insurance policy provides third party liability insurance along with protection from losses incurred due to accidents, natural disasters like storms & floods and man made causes like vandalism, theft, riots etc. Many of these also include personal accidental cover that secures you from physical injuries related to hospital charges.",
    desc2: "Get your Car Insurance quote right away!",
  };

  const data3 = {
    title: "Commercial Vehicle Insurance",
    subtitle: "Create a comprehensive four Commercial Vehicle insurance plan",
    image: CommericalImage,
    righttitle: "BENEFITS",
    desc1:
      "Choose the best suited policy for your commercial vehicle to be secured & for a tension free future in case of any undesired circumstances involving your vehicle.",
    desc2:
      "Commercial vehicle Insurance are designed to secure heavy vehicles that are mostly used for transportation purposes in a business against different unforeseen events like damage, theft, vandalism or a third party liability. Your commercial vehicle not only holds the value that you bought it for but it also is an irreplaceable part of your business. Therefore, not insuring it means that you are not only exposed to heavy financial burden in form of damage, repairs & third party compensation in case of an accident but also risk losses in your business and possibly its shut down.",
  };

  const data4 = {
    title: "Health Insurance",
    subtitle: "Get a comprehensive Health insurance for your specific needs!",
    image: HealthImage,
    righttitle: "BENEFITS",
    desc1:
      "A health insurance is not only a smart decision to make but also a necessity to protect you & your loved ones in hard times.",
    desc2:
      "Health insurance plans enable customers to safeguard from medical expenses & from the financial burden of health related complications of the insured person through the insurer. Rising medical costs & changing lifestyle all make it necessary for you to have health insurance that covers you from some unforeseen health complication.",
  };

  const data5 = {
    title: "GC 360 (BIGWAYZ HOSPICASH 1K)",
    subtitle: "Hospitalization Cash Cover",
    image: HospiCashImage,
    righttitle: "What’s Covered?",
    list1:
      "Get a fixed daily cash amount of ₹1000 per day upto 30 days in a year with 1 day deductible.",
    list2: "₹2000 per day in case of ICU hospitalization.",
    list3: "Quick & easy paperwork along with lifetime renewability.",
    list4: "",
    desc:
      "Take care of any additional expenses that might occur during your hospitalization with GC 360 (BIGWAYZ HOSPICASH 1K). The insurer pays the amount whether it is needed for something specific or not, providing a nice safety net of cash for you, if you may need it.",
  };

  const data6 = {
    title: "GC 360 (BIGWAYZ PA CARE)",
    subtitle: "Personal Accident Care",
    image: PersonalAccidentImage,
    righttitle: "What’s Covered?",
    list1: "Sum Insured: ₹10 Lakh",
    list2: "Accidental Death: 100% of Sum Insured",
    list3:
      "PTD & PPD: Upto Sum Insured, as per PTD & PPD table of Suraksha Care",
    list4:
      "Add-on benefits include hospitalization expenses, compassionate visit & a daily allowance.",
    desc: `Don't allow accidents to cripple the family's financial health with a comprehensive personal accident plan that covers large number of common cases like total disablement, fractures, burndamage and much more.`,
  };

  const data7 = {
    title: "GC 360 (BIGWAYZ ACCI CARE)",
    subtitle: "Accidental Hospitalization Care",
    image: AccidentImage,
    righttitle: "What’s Covered?",
    list1: "Sum Insured: ₹10,000",
    list2: "Accidental Death: 100% of Sum Insured",
    list3: "Accidental Hospitalization: Up to Rs 1 Lakh",
    list4: "Accidental OPD: Up to ₹10,000",
    desc:
      "Don't let Hospital & OPD Expenses due to an Accident disturb your peace of mind. Secure yourself and future with Accidental Hospitalization Care to shield you from hefty medical expenses in case of serious long term health conditions. Get Direct in house quick claim settlement, cover for charges on ambulance charges, blood purchase, travel & transportation etc.",
  };
  const data8 = {
    title: "GC 360 (BIGWAYZ ACCI CARE PLUS)",
    subtitle: "Personal Accident Care & Accidental Hospitalization Care",
    image: SuperTop,
    righttitle: "What’s Covered?",
    list1: "Sum Insured: 10 Lakh",
    list2: "Accidental Death: 100% of Sum Insured",
    list3: "Accidental Hospitalization: Up to 1 Lakh",
    list4: "Accidental OPD: Up to 10,000",
    desc:
      "Safeguard against serious injuries with combined benefits of Personal Accident Care + Accidental Hospitalization Care with a tempting policy premium. PTD & PPD: Upto Sum Insured, as per PTD & PPD table of Suraksha Care",
  };

  const BoxContainer = (props) => {
    const [mobileNumber, setMobileNumber] = useState("");
    const [optSent, setOptSent] = useState(false);
    const [otp, setOtp] = useState("false");

    const onSignInSubmit = (e) => {
      if (mobileNumber.length != 10 || mobileNumber == "") {
        alert("Please enter a valid mobile number");
        return;
      }
      console.log("onSignInSubmit", mobileNumber);
      const phoneNumber = "+91" + mobileNumber;
      console.log(phoneNumber);
      window.recaptchaVerifier = new Firebase.auth.RecaptchaVerifier(
        "capthca",
        {
          size: "invisible",
          callback: function(response) {
            console.log("recature resolved");
          },
        }
      );
      Firebase.auth()
        .signInWithPhoneNumber(phoneNumber, window.recaptchaVerifier)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult;
          console.log("OTP has been sent");
          window.recaptchaVerifier.clear();
          window.recaptchaVerifier = new Firebase.auth.RecaptchaVerifier(
            "capthca",
            {
              size: "invisible",
              callback: function(response) {
                console.log("recature resolved");
              },
            }
          );
          setOptSent(true);
        })
        .catch((error) => {
          console.log(error);
          window.recaptchaVerifier.clear();
          window.recaptchaVerifier = new Firebase.auth.RecaptchaVerifier(
            "capthca",
            {
              size: "invisible",
              callback: function(response) {
                console.log("recature resolved");
              },
            }
          );
          console.log("SMS not sent");
          window.location.reload()
          alert("Something went wrong! Please try again.");
        });
    };

    const redirectSSO = () => {
      firestore
        .collection("ssousers")
        .doc(mobileNumber)
        .get()
        .then((snapshot) => {
          if (snapshot.exists) {
            console.log("found user =", snapshot.data(), mobileNumber);
            let data = {
              phone: mobileNumber,
              name: snapshot.data().name,
              role: snapshot.data().role,
              partnerUserId: "BIGWYZ",
              code: "Monetize",
            };
            API.post("/CreateSession", data).then((res) => {
              console.log("CreateSession", res.data);
              let token = res.data.sessionId.toString();
              let url = "";
              if (tab == "Group360") {
                url = `https://monetize.ensuredit.com/sso?sessionId=${token}&partner=BIGWYZ`;
              } else {
                let type = "MOTOR_2W";
                if (service === "Two Wheeler Insurance") {
                  type = "MOTOR_2W";
                } else if (service === "Private Vehicle Insurance") {
                  type = "MOTOR_4W";
                } else if (service === "Commercial Insurance") {
                  type = "MOTOR_CV";
                } else if (service === "Health Insurance") {
                  type = "HEALTH";
                }
                url = `https://kmddigital.in/sso?partner=BWYZ&sessionId=${token}&insuranceType=${type}&userType=${data.role}`;
              }
              console.log(url);
              window.open(url, "_blank");
              setOtp("false");
            });
          } else {
            alert("Please register with Bigwayz to use this service.");
          }
        });
    };
    const onSubmitOTP = () => {
      console.log("onSubmitOTP");
      const code = otp;
      console.log("otp", code);
      window.confirmationResult
        .confirm(code)
        .then((result) => {
          console.log("redirecting to sso now....");
          redirectSSO();
          console.log("clearing recaptcha.....");
          setOptSent(false);
        })
        .catch((error) => {
          console.log("otp error", error);
          setOptSent(false);
        });
    };
    console.log(optSent, "optSent");
    return (
      <Paper elevation={0} className={classes.boxcontainer}>
        <div className={classes.boxheader}>
          <Typography className={classes.boxtitle}>
            {props.data.title}
          </Typography>
          <Typography className={classes.boxsubtitle}>
            {props.data.subtitle}
          </Typography>
        </div>
        <div className={classes.boxcontent}>
          <Grid container spacimg={3}>
            <Grid xs={12} sm={6} alignItems="center" justify="column">
              <img
                src={props.data.image}
                alt="image1"
                className={classes.boxcontentimage}
              />
              <div className={classes.contentleft}>
                <Typography className={classes.contentelefttitle}>
                  Login for {props.data.title}
                </Typography>
                <div className={classes.textfieldcontainer}>
                  <div className={classes.phoneiconcontainer}>
                    <PhoneOutlinedIcon className={classes.phoneicon} />
                  </div>
                  <input
                    id="inputID"
                    type="text"
                    placeholder="Enter Mobile Number"
                    onChange={(e) => setMobileNumber(e.target.value)}
                    className={classes.textfeild}
                  />
                </div>
                {/* {optSent == true ? (
                  <div className={classes.textfieldcontainer}>
                    <div className={classes.phoneiconcontainer}>
                      <PhoneOutlinedIcon className={classes.phoneicon} />
                    </div>
                    <input
                      id="inputID"
                      type="text"
                      placeholder="Enter OTP"
                      onChange={(e) => setOtp(e.target.value)}
                      className={classes.textfeild}
                    />
                  </div>
                ) : null} */}

                <Modal show={optSent} onHide={() => setOptSent(false)}>
                  <Modal.Header>
                    <Modal.Title>Please enter OTP</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <input
                      id="inputID"
                      type="text"
                      placeholder="Enter OTP here"
                      onChange={(e) => setOtp(e.target.value)}
                      className={classes.textfeild}
                    />
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={() => setOptSent(false)}
                    >
                      Close
                    </Button>
                    <Button variant="primary" onClick={onSubmitOTP}>
                      Submit
                    </Button>
                  </Modal.Footer>
                </Modal>

                <Typography className={classes.contentleftfooter}>
                  <span>
                    <img src={IIcon} alt="" className={classes.iicon} />
                  </span>
                  Bigwayz Registered User
                </Typography>
                <Button
                  className={classes.submitbutton}
                  onClick={() => {
                    redirectSSO();
                    return
                    if (optSent) {
                      onSubmitOTP();
                    } else {
                      onSignInSubmit();
                    }
                  }}
                >
                  {optSent == true ? "Submit" : "Login"}
                </Button>
              </div>
            </Grid>
            <Grid xs={12} sm={6} className={classes.contentright}>
              {tab === "suraksha" ? (
                <>
                  <Typography className={classes.contentrighttitle}>
                    {props.data.righttitle}
                  </Typography>
                  <br />
                  <Typography className={classes.contentrightdesc}>
                    {props.data.desc1}
                  </Typography>
                  <br />
                  <Typography className={classes.contentrightdesc}>
                    {props.data.desc2}
                  </Typography>
                </>
              ) : (
                <>
                  <Typography className={classes.contentrighttitle}>
                    {props.data.righttitle}
                  </Typography>
                  <br />
                  <ul>
                    <li className={classes.contentrightdesc}>
                      {props.data.list1}
                    </li>
                    <br />
                    <li className={classes.contentrightdesc}>
                      {props.data.list2}
                    </li>
                    <br />

                    <li className={classes.contentrightdesc}>
                      {props.data.list3}
                    </li>
                    <br />
                    {props.data.list4 != "" ? (
                      <li className={classes.contentrightdesc}>
                        {props.data.list4}
                      </li>
                    ) : null}
                  </ul>
                  <br />
                  <Typography className={classes.contentrightdesc}>
                    {props.data.desc}
                    {props.data.subtitle == "Personal Accident Care" ? (
                      <Typography className={classes.contentrightdesc}>
                        {/* <br /> *As per PTT & PPD Table of Suraksha Care */}
                      </Typography>
                    ) : null}
                  </Typography>
                </>
              )}
            </Grid>
          </Grid>
        </div>
      </Paper>
    );
  };

  return (
    <>
      <MainNavBar />
      <div className={classes.root}>
        {/* <div>
                <img src={Logo} alt="logo" className={classes.logo}/>
            </div> */}
        <Banner Image={Image} showButtons={false} />
        <div className={classes.container}>
          <div className={classes.tabbuttons}>
            <Button
              onClick={() => {
                setTab("suraksha");
                console.log('setTab("suraksha");');
                setService("Two Wheeler Insurance");
              }}
              className={
                tab === "suraksha"
                  ? classes.tabbuttonSelected
                  : classes.tabbutton
              }
            >
              Suraksha Plus
            </Button>

            <Button
              onClick={() => {
                setTab("Group360");
                console.log(' setTab("Group360");');
                setService("Hospi cash");
              }}
              className={
                tab === "Group360"
                  ? classes.tabbuttonSelected
                  : classes.tabbutton
              }
            >
              Suraksha Care
            </Button>
          </div>

          <Grid container spacing={3}>
            {tab === "Group360" &&
              Group360.map((item) => (
                <Grid item xs={6} sm={3}>
                  <Paper
                    elevation={0}
                    className={classes.cardpaper}
                    onClick={() => {
                      setService(item.subheading);
                      console.log(item.subheading);
                    }}
                  >
                    <img
                      src={item.image}
                      alt="image1"
                      className={classes.cardimage}
                    />
                    <Typography className={classes.cardtitle}>
                      {item.title}
                    </Typography>
                    <Typography className={[classes.cardtitle]}>
                      {item.subheading}
                    </Typography>
                    <Typography className={classes.cardyear}>
                      {item.subtitle}
                    </Typography>
                  </Paper>
                  <Button
                    onClick={() => {
                      setService(item.subheading);
                      console.log(item.title);
                    }}
                    className={
                      service === item.subheading
                        ? classes.selectbuttonselected
                        : classes.selectbutton
                    }
                  >
                    Select
                  </Button>
                </Grid>
              ))}
            {tab === "suraksha" &&
              SurakshaCards.map((item) => (
                <Grid item xs={6} sm={3}>
                  <Paper
                    elevation={0}
                    className={classes.cardpaper}
                    onClick={() => {
                      setService(item.title);
                      console.log(item.title);
                    }}
                  >
                    <img
                      src={item.image}
                      alt="image1"
                      className={classes.cardimage}
                    />
                    <Typography className={classes.cardtitle}>
                      {item.title}
                    </Typography>
                    <Typography className={classes.cardyear}>
                      {item.subtitle}
                    </Typography>
                  </Paper>
                  <Button
                    onClick={() => {
                      setService(item.title);
                      console.log(item.title);
                    }}
                    className={
                      service === item.title
                        ? classes.selectbuttonselected
                        : classes.selectbutton
                    }
                  >
                    Select
                  </Button>
                </Grid>
              ))}
          </Grid>

          <div>
            {service === "Two Wheeler Insurance" && (
              <BoxContainer data={data1} />
            )}
            {service === "Private Vehicle Insurance" && (
              <BoxContainer data={data2} />
            )}
            {service === "Commercial Insurance" && (
              <BoxContainer data={data3} />
            )}
            {service === "Health Insurance" && <BoxContainer data={data4} />}
            {service === "BIGWAYZ HOSPICASH 1K" && (
              <BoxContainer data={data5} />
            )}
            {service === "BIGWAYZ PA CARE" && <BoxContainer data={data6} />}
            {service === "BIGWAYZ ACCI CARE" && <BoxContainer data={data7} />}
            {service === "BIGWAYZ ACCI CARE PLUS" && (
              <BoxContainer data={data8} />
            )}
            <div id="capthca"></div>

            <Typography className={classes.insurancefooter}>
              Not registered with Bigwayz, register now to buy an insurance!
            </Typography>

            {Register ? (
              <>
                <Button
                  className={classes.registerbutton}
                  onClick={() => setRegister(!Register)}
                >
                  Cancel
                </Button>
                <div className={classes.registerformcontainer}>
                  <Grid container spacin={3}>
                    <Grid item xs={12} sm={8} className={classes.gridform}>
                      <input
                        type="text"
                        placeholder="Full Name"
                        name="name"
                        value={contactdata.name}
                        onChange={(e) => changeInput(e)}
                        placeholderTextColor={whiteColor}
                        className={classes.inputfeild}
                        required
                      ></input>
                      <input
                        type="text"
                        placeholder="Mobile Number"
                        placeholderTextColor={whiteColor}
                        className={classes.inputfeild}
                        name="phone"
                        value={contactdata.phone}
                        onChange={(e) => changeInput(e)}
                        required
                      ></input>
                      <input
                        type="text"
                        placeholder="Email"
                        placeholderTextColor={whiteColor}
                        className={classes.inputfeild}
                        name="email"
                        value={contactdata.email}
                        onChange={(e) => changeInput(e)}
                        required
                      ></input>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <div className={classes.ContactButtondiv}>
                        <Button
                          type="submit"
                          className={classes.ContactButton}
                          onClick={ContactSubmit}
                        >
                          Submit <ChevronRightIcon />
                        </Button>
                      </div>
                      {isent === true && (
                        <Typography
                          style={{
                            color: "white",
                            fontSize: "20px",
                            fontWeight: "bold",
                          }}
                        >
                          Thank you for your interest, We will get in touch with
                          you soon!
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </div>
              </>
            ) : (
              <Button
                className={classes.registerbutton}
                style={{ borderRadius: 8 }}
                onClick={() => setRegister(!Register)}
              >
                Register
              </Button>
            )}
          </div>
        </div>
        <MainFooter showContact={false} />
      </div>
    </>
  );
}
