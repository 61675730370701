import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Typography,TextField, Paper } from '@material-ui/core';
import {lightGray, primaryColor, secondaryColor, whiteColor } from '../../../config/constants'; 
import {Link} from "react-router-dom";
import cardImage from "../../../assets/images/cardimage.png"
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import LinkIcon from '@material-ui/icons/Link';


const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    title:{
        fontSize:30,
        color:secondaryColor,
        fontWeight:700,
        margin:20
    },
    background:{
        backgroundColor:"rgba(240, 102, 105, 0.13)",
        padding:30,
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between'
    },
    background1:{
        padding:30,
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between'
    },
    avatartitle:{
        display:'flex',
        width:'20%'
    },
    registeredname:{
        padding:'10px',
        fontSize:18
    },
    addbutton:{
        display:'flex',
        float:'right',
        margin:30,
        fontSize:16,
        borderRadius:8,
        background:secondaryColor,
        color:whiteColor,
        padding:5,
        textAlign:'center',
        cursor:'pointer',
        textDecoration:'none'
    },
    formcontainer:{
        margin:20
    },
    editbutton:{
        color:whiteColor,
        backgroundColor:secondaryColor,
        borderRadius:18,
        padding:8,
        width:100,
        textAlign:'center',
        margin:5,
        cursor:'pointer',
        maxHeight:35,
        textDecoration:'none'
    },
    date:{
        fontSize:16,
        fontWeight:300
    },
    formtitle:{
        color:primaryColor,
        fontWeight:600,
        fontSize:16,
        paddingBottom:10,
        paddingTop:10
    },
    forminput:{
        width:'100%',
        borderColor:secondaryColor,
        background:whiteColor,
        [theme.breakpoints.only('xs')]: {
           minWidth:'100%'
          },
    },
    descriptioncontainer:{
        padding:20,
        borderRadius:12
    },
    forminputdescription:{
        width:'100%',
        borderColor:secondaryColor,
        background:whiteColor,
        marginTop:20,
        [theme.breakpoints.only('xs')]: {
           minWidth:'100%'
          },
    },
    cardimage:{
        width:"100%",
        height:250,
        marginTop:20,
        objectFit:'contain'
    },
    cardcontainer:{
        paddingTop:20,
        paddingBottom:20,
        borderRadius:8,
        marginTop:40,
        width:'90%',
        marginLeft:'5%',
        backgroundColor:'#FAEAEA'
    },
    header:{
        paddingTop:10,
        paddingBottom:10,
        borderBottom:'1px solid #cdcdcd',
        display:'flex',
        justifyContent:'space-between'
    },
    tag:{
        background:secondaryColor,
        marginLeft:10,
        padding:5,
        color:whiteColor,
        borderRadius:5,
    },
    taxname:{
        marginLeft:20,
        paddingTop:20,
    },
    status:{
        marginRight:10
    },
    linkicon:{
        marginRight:10,
        paddingTop:20,
        float:'right'
    },
    maincardcontainer:{
        paddingTop:30,
        paddingRight:10,

    },
    transaction:{
        marginTop:10,
        marginBottom:10,
        color:secondaryColor,
        fontSize:18,
        fontWeight:600,
        display:'flex',
        justifyContent:'flex-end'
    },
    subtitle:{
        marginTop:10,
        marginBottom:10,
        fontSize:14,
        fontWeight:600,
        display:'flex',
        justifyContent:'flex-end'
    },
    subtitlelight:{
        marginTop:10,
        marginBottom:10,
        fontSize:12,
        fontWeight:600,
        color:lightGray,
        display:'flex',
        justifyContent:'flex-end'
    }
  }));
  

export default function SupportTicket() {
    const classes = useStyles();
    const [value, setValue] = React.useState('Active');

    const handleChange = (event) => {
      setValue(event.target.value);
    };


    return (
        <div>
        <Grid container spacing={0}>
            <Grid item xs={6}>
            <Typography className={classes.title}>
                 Manage KYC Details
            </Typography>
            </Grid>
            <Grid item xs={6}>
                <Link to ="/supportticket" size="small" color="primary"  className={classes.addbutton}>
                        Go Back
                </Link>
                </Grid>
        </Grid> 
        <div className={classes.formcontainer}> 
        <Grid container spacing={0}>
            <Grid item xs={6}>
            <Typography className={classes.formtitle}>Aadhar Number</Typography>
                <TextField className={classes.forminput} placeholder="Enter Aadhar Number" variant="outlined" />
            <Typography className={classes.formtitle}>Adress</Typography>
            <TextField className={classes.forminput} placeholder="Enter Address" variant="outlined" />
                <Typography className={classes.formtitle}>Ticket Priority</Typography>
                <div>
                <FormControl component="fieldset">
                <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>
                    <div style={{display:'flex',justifyContent:'space-between'}}>
                    <FormControlLabel value="Active" control={<Radio />} label="Active" />
                    <FormControlLabel value="Pending" control={<Radio />} label="Pending" />
                    <FormControlLabel value="Closed" control={<Radio />} label="Closed" />
                    </div>
                </RadioGroup>
                </FormControl>
                </div>
                <div size="small" color="primary"  className={classes.editbutton}>
                    Save 
                </div>
            </Grid>
            <Grid item xs={6}>
                <Paper elevation={0} className={classes.cardcontainer}>
                    <div className={classes.header}>
                        <div>
                            <div className={classes.tag}>
                                Complaint Number : 12121212211
                            </div>
                            <div className={classes.taxname}>
                              TAX ID : 123
                            </div>
                        </div>
                        <div>
                        <div className={classes.status}>
                                In Progress
                        </div>
                        <div className={classes.linkicon}>
                                <LinkIcon style={{color:secondaryColor}}/>
                            </div>
                        </div>
                    </div>

                    <div className={classes.maincardcontainer}>
                        <div style={{display:'flex',justifyContent:'flex-end'}}>
                            Data recharge
                        </div>
                        <div className={classes.transaction}>
                            Transcation Related
                        </div>
                        <div className={classes.subtitle}>
                            Transcation Related
                        </div>
                        <div className={classes.subtitlelight}>
                            Not able To use
                        </div>
                        <div className={classes.subtitlelight}>
                            Raised on March 26th 2020
                        </div>
                    </div>
                </Paper>
            </Grid>
        </Grid> 
        </div>
        </div>




    )
}
