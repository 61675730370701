import React, {useEffect, useState} from "react"
import {useParams} from "react-router-dom"
import axios from "axios"
import {makeStyles} from "@material-ui/core/styles"
import {useHistory, useLocation} from "react-router"
import Paper from "@material-ui/core/Paper"
import {Link} from "react-router-dom"
import {firestore, storage} from "../../../config/firebase"
import {
  primaryColor,
  secondaryColor,
  whiteColor,
} from "../../../config/constants"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import "./SpecificUser.css"

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    overflow: "auto",
    textAlign: "left",
    height: "auto!important",
    color: theme.palette.text.secondary,
    margin: 20,
  },
  cover: {
    width: "100%",
    height: "100%",
  },
  page: {
    height: "100%",
  },
  addbutton: {
    display: "flex",
    float: "right",

    marginTop: 5,
    width: 110,
    background: secondaryColor,
    color: whiteColor,
    padding: 3,
    textAlign: "center",
    cursor: "pointer",
    textDecoration: "none",
    textAlign: "center",
    borderRadius: "5px",
  },
}))

// import moment from "moment";
// import {
//     CNavbar,
//     CNav,
//     CNavbarBrand,
//     CNavbarToggler,
//     CCollapse,
//     CNavLink,
//     CDropdown,
//     CNavItem,
//     CButton,
//     CFormInput,
//     CForm,
//     CDropdownItem,
//     CDropdownDivider,
//     CDropdownMenu,
//     CDropdownToggle,
//     CContainer,
//     CNavbarNav,
//     CFormSelect
//   } from "@coreui/react";
//   import {
//     CSidebar,
//     CTable,
//     CTableHead,
//     CTableRow,
//     CTableHeaderCell,
//     CTableBody,
//     CTableDataCell,
//     CNavTitle,
//     cilPuzzle,
//     CNavGroup,
//     CSidebarFooter,
//     CSidebarHeader,
//     CSidebarToggler,
//     CSidebarNav,
//     CSidebarBrand,
//     CBadge,
//      CFormLabel,CFormTextarea ,CInputGroup,CInputGroupText
//   } from "@coreui/react";

//   import $ from "jquery";
//   import {
//     AiOutlineUser,
//     BiPackage,
//     FaUser,
//     VscGraph,
//     RiFolderWarningLine,
//     IoCashOutline,
//     RiLinksFill,
//     FaBoxOpen,
//     FaUserCircle,
//     FaChartBar,
//     FaExclamationCircle,
//     FaCashRegister,
//     FaLink,
//     FaParagraph,
//     FaHandshake,
//   } from "react-icons/fa";

function SpecificUser() {
        
        // console.log('specific complaint',props._id)
  // const [Roles,setRoles] = useState([])
  // const [Loading ,setLoading] = useState(true)

  // const [Email,setEmail] = useState('')
  // const [Name,setName] = useState('')
  // const [PhoneNumber,setPhoneNumber] = useState('')
  // const [Address,setAddress] = useState('')

  const classes = useStyles()

  console.log("specific user page")

  const [SpecificUserData, setSpecificUserData] = useState([])

  // const [ValidationErrorMessage,setValidationErrorMessage] = useState('')

  // const { id } = useParams();

  const history = useHistory()

  const location = useLocation()

  // console.log('id',id)

  const geSpecificUserData = () => {
    console.log("seeing name", location.userName)

    firestore
      .collection("users")
      .doc(location.userName)
      .get()
      .then((snapshot) => {
        console.log('sssssjjjjjjjjjjjjjjj',snapshot.data())
        setSpecificUserData([snapshot.data()])
      })
      .catch((err) => {
        console.log("error", err)
      })
  }

  useEffect(() => {
    geSpecificUserData()

    //     setTimeout(()=>{
    //         document.getElementById("paper").focus()
    //       },3000)
  }, [])

  //   const mytime = (timestamp) => {
  //     let date = Date.parse(timestamp);

  //     // let datenew = date*1000

  //     let correct = new Date(date);

  //     // console.log('correct',correct,typeof(correct))

  //     let mstring = correct.toString().slice(16, 24);

  //     var formatted = moment(mstring, "HH:mm:ss").format("LTS");

  //     return formatted;
  //   };

  return (
    <Paper className={classes.paper}>
      <div className={classes.page}>
        <Button
          onClick={() => history.push("/dashboard")}
          style={{backgroundColor: primaryColor}}
          className={classes.addbutton}
          variant="contained"
        >
          {" "}
          Go back{" "}
        </Button>

        {SpecificUserData.length > 0 && (
          <div className="user-details">
            <h4 id="paper"> User Details </h4>

            <div className="user-details-block">
              <div className="user-details-item">
                <div className="first-half"> Name </div>
                <div className="second-half">
                  {" "}
                  {SpecificUserData[0].firstName +
                    " " +
                    SpecificUserData[0].lastName}{" "}
                </div>
              </div>

              <div className="user-details-item">
                <div className="first-half"> Gender </div>
                <div className="second-half">
                  {" "}
                  {SpecificUserData[0].gender}{" "}
                </div>
              </div>

              <div className="user-details-item">
                <div className="first-half"> Role </div>
                <div className="second-half"> {SpecificUserData[0].role} </div>
              </div>

              <div className="user-details-item">
                <div className="first-half"> Mail </div>
                <div className="second-half"> {SpecificUserData[0].email} </div>
              </div>

              <div className="user-details-item">
                <div className="first-half"> Dob </div>
                <div className="second-half"> {SpecificUserData[0].dob} </div>
              </div>
              <div className="user-details-item">
                <div className="first-half"> Address </div>
                <div className="second-half">
                  {" "}
                  {SpecificUserData[0].homeAddress
                    ? SpecificUserData[0].homeAddress
                    : ""}{" "}
                </div>
              </div>
              <div className="user-details-item">
                <div className="first-half"> OnboardedBy </div>
                <div className="second-half">
                  {" "}
                  {SpecificUserData[0].onboardedBy}{" "}
                </div>
              </div>
              <div className="user-details-item">
                <div className="first-half"> Gstin </div>
                <div className="second-half"> {SpecificUserData[0].gstin} </div>
              </div>

              <div className="user-details-item">
                <div className="first-half"> Home City </div>
                <div className="second-half">
                  {" "}
                  {SpecificUserData[0].homeCity}{" "}
                </div>
              </div>

              <div className="user-details-item">
                <div className="first-half"> Home State </div>
                <div className="second-half">
                  {" "}
                  {SpecificUserData[0].homeState}{" "}
                </div>
              </div>

              <div className="user-details-item">
                <div className="first-half"> Home Pincode </div>
                <div className="second-half">
                  {" "}
                  {SpecificUserData[0].homePincode}{" "}
                </div>
              </div>

              <div className="user-details-item">
                <div className="first-half"> WhatsAppNumber </div>
                <div className="second-half">
                  {" "}
                  {SpecificUserData[0].whatsAppNumber}{" "}
                </div>
              </div>

              <div className="user-details-item mt-3">
                <div className="first-half"> Business Name </div>
                <div className="second-half">
                  {" "}
                  {SpecificUserData[0].businessName}{" "}
                </div>
              </div>

              <div className="user-details-item">
                <div className="first-half"> Business Address </div>
                <div className="second-half">
                  {" "}
                  {SpecificUserData[0].businessAddress}{" "}
                </div>
              </div>
              <div className="user-details-item">
                <div className="first-half"> Business Category </div>
                <div className="second-half">
                  {" "}
                  {SpecificUserData[0].businessCategory}{" "}
                </div>
              </div>
              <div className="user-details-item">
                <div className="first-half"> Business City </div>
                <div className="second-half">
                  {" "}
                  {SpecificUserData[0].businessCity}{" "}
                </div>
              </div>

              <div className="user-details-item">
                <div className="first-half"> Business State </div>
                <div className="second-half">
                  {" "}
                  {SpecificUserData[0].businessState}{" "}
                </div>
              </div>

              <div className="user-details-item">
                <div className="first-half"> Business Pan </div>
                <div className="second-half">
                  {" "}
                  {SpecificUserData[0].businessPan}{" "}
                </div>
              </div>

              <div className="user-details-item">
                <div className="first-half"> Business PinCode </div>
                <div className="second-half">
                  {" "}
                  {SpecificUserData[0].businessPinCode}{" "}
                </div>
              </div>

              <div className="user-details-item mt-3">
                <div className="first-half"> Bank Name </div>
                <div className="second-half">
                  {" "}
                  {SpecificUserData[0].bankName}{" "}
                </div>
              </div>

              <div className="user-details-item">
                <div className="first-half"> Bank Account Holder Name </div>
                <div className="second-half">
                  {" "}
                  {SpecificUserData[0].nameAsPerBank}{" "}
                </div>
              </div>

              <div className="user-details-item">
                <div className="first-half"> Bank Ifsc </div>
                <div className="second-half"> {SpecificUserData[0].ifsc} </div>
              </div>
              <div className="user-details-item">
                <div className="first-half"> Account Number </div>
                <div className="second-half">
                  {" "}
                  {SpecificUserData[0].accountNumber}{" "}
                </div>
              </div>
              <div className="user-details-item">
                <div className="first-half"> Type Of Account </div>
                <div className="second-half">
                  {" "}
                  {SpecificUserData[0].typeOfAccount}{" "}
                </div>
              </div>
              <div className="user-details-item">
                <div className="first-half"> Bank Email</div>
                <div className="second-half">
                  {" "}
                  {SpecificUserData[0].bankEmail}{" "}
                </div>
              </div>

              <div className={classes.root}>
                <Grid container spacing={3}>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    className="d-flex align-items-center justify-content-start"
                  >
                    <div className="first-half"> User Photo </div>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Paper className={classes.paper}>
                      {" "}
                      <div className="second-half">
                        {" "}
                        <img
                          className={classes.cover}
                          src={SpecificUserData[0].userPhoto}
                          alt=""
                        />{" "}
                      </div>{" "}
                    </Paper>
                  </Grid>
                </Grid>
              </div>

              <div className={classes.root}>
                <Grid container spacing={3}>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    className="d-flex align-items-center justify-content-start"
                  >
                    <div className="first-half"> Aadhar Front </div>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Paper className={classes.paper}>
                      {" "}
                      <div className="second-half">
                        {" "}
                        <img
                          className={classes.cover}
                          src={SpecificUserData[0].adhaarFrontImage}
                          alt=""
                        />{" "}
                      </div>{" "}
                    </Paper>
                  </Grid>
                </Grid>
              </div>
              <div className={classes.root}>
                <Grid container spacing={3}>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    className="d-flex align-items-center justify-content-start"
                  >
                    <div className="first-half"> Aadhar Back </div>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Paper className={classes.paper}>
                      {" "}
                      <div className="second-half">
                        {" "}
                        <img
                          className={classes.cover}
                          src={SpecificUserData[0].adhaarBackImage}
                          alt=""
                        />{" "}
                      </div>{" "}
                    </Paper>
                  </Grid>
                </Grid>
              </div>
              <div className={classes.root}>
                <Grid
                  container
                  spacing={3}
                  className="d-flex align-items-center justify-content-start"
                >
                  <Grid item xs={12} sm={6}>
                    <div className="first-half"> Pan card </div>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Paper className={classes.paper}>
                      {" "}
                      <div className="second-half">
                        {" "}
                        <img
                          className={classes.cover}
                          src={SpecificUserData[0].panImage}
                          alt=""
                        />{" "}
                      </div>{" "}
                    </Paper>
                  </Grid>
                </Grid>
              </div>
              <div className={classes.root}>
                <Grid
                  container
                  spacing={3}
                  className="d-flex align-items-center justify-content-start"
                >
                  <Grid item xs={12} sm={6}>
                    <div className="first-half"> Signature Photo </div>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Paper className={classes.paper}>
                      {" "}
                      <div className="second-half">
                        {" "}
                        <img
                          className={classes.cover}
                          src={SpecificUserData[0].signaturePhoto}
                          alt=""
                        />{" "}
                      </div>{" "}
                    </Paper>
                  </Grid>
                </Grid>
              </div>
              <div className={classes.root}>
                <Grid
                  container
                  spacing={3}
                  className="d-flex align-items-center justify-content-start"
                >
                  <Grid item xs={12} sm={6}>
                    <div className="first-half"> Education </div>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Paper className={classes.paper}>
                      {" "}
                      <div className="second-half">
                        {" "}
                        <img
                          className={classes.cover}
                          src={SpecificUserData[0].educationQualification}
                          alt=""
                        />{" "}
                      </div>{" "}
                    </Paper>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        )}
      </div>
    </Paper>
  )
}

export default SpecificUser
