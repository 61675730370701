import React,{useEffect,useState} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {IconButton, TextField, Typography} from '@material-ui/core';
import {primaryColor, secondaryColor, whiteColor} from '../../../config/constants';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardImage from "../../../assets/images/cardimage.png"
import {Link, useHistory} from "react-router-dom";
import {firestore,storage,} from '../../../config/firebase';
import Firebase from 'firebase/app';
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Snackbar from "@material-ui/core/Snackbar";
import Paper from "@material-ui/core/Paper";
import BackupIcon from '@material-ui/icons/Backup';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
    //   padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    imageupload:{

    },
    title:{
        fontSize:24,
        color:secondaryColor,
        fontWeight:700,
        margin:20
    },

    addbuttontitle:{
        textAlign:'center'
    },
    titlethisweek:{
        marginTop:30,
        margin:20,
        fontSize:20,
        fontWeight:600
    },
    cardscontainer:{
        margin:10
    },
    media: {
        height: 180,
        objectFit:'conatin',
        width:'100% '
    },
    deleteicon:{
        fill: secondaryColor,
        cursor:'pointer',
    },
    addbutton:{
        display:'flex',
        float:'right',
        margin:16,
        width:110,
        background:secondaryColor,
        color:whiteColor,
        padding:3,
        textAlign:'center',
        cursor:'pointer',
        textDecoration:"none"
    },
    editbutton:{
        color:whiteColor,
        backgroundColor:'red',
        borderRadius:3,
        padding:3,
        textAlign:'center',
        margin:5,
        cursor:'pointer',
        maxHeight:35,
        textDecoration:'none'
    },
    dialogtitle:{
        display:'flex',
        flexDirection:'row',
        width:'100%',
        justifyContent: 'space-between',
        padding:15
    },
    dialogtitlename:{
        fontSize:20,
        color:secondaryColor
    },
    textfeild:{
        width:'90%',
        marginLeft:'5%',
        marginBottom:10
    },
    cardroot:{
        flex:1,
        height:260
    },
    description:{
        textOverflow:'ellipsis',
        overflow:'hidden',
        height:120,
        // overflow:'scroll'
    },
    formcontainer:{
        marginLeft:20,
        marginRigth:20
    },
    formtitle:{
        color:primaryColor,
        fontWeight:600,
        fontSize:16,
        paddingBottom:10,
        paddingTop:10
    },
    forminput:{
        minWidth:600,
        borderColor:secondaryColor,
        background:whiteColor,
        [theme.breakpoints.only('xs')]: {
            minWidth:'100%'
        },
    },
    deletebutton:{
        fontSize:12,
        padding:10,
    },
    imageuploadbutton:{
        marginTop:50
    },
    buttons:{
        backgroundColor:secondaryColor,
        color:whiteColor,
        marginLeft:10
    },
  }));


export default function AppBanners() {
    const classes = useStyles();
    const [allBanners,setAllBanners] = React.useState([])
    const [bannerOpen,setBannerOpen] = React.useState(true)
    const [snackBarOpen,setSnackBarOpen] = React.useState(true)
    const [image,setImage] = React.useState('')
    const [addBannerButton,setAddBannerButton] = React.useState(true)
    const [uploadImage,setUploadImage] = React.useState(true)
    const [uploadedImage, setUploadedImage] = useState();
    const [fileName, setFileName] = useState();


    useEffect(() => {
        getAllBanners();
      }, []);
    const history = useHistory();


    const getAllBanners = () =>{
          firestore.collection('appBanners').doc('appBanners').get().then(snapshot => {
              if(snapshot.exists == false){
                  // no products available
              }else {
                  const banners_array = snapshot.data().banners_array;
                  setAllBanners(banners_array)
              }
          })
      }

      const deleteBanner = (item) => {
          console.log('deleting item =', item)
          firestore.collection('appBanners').doc('appBanners').update({
              'banners_array': Firebase.firestore.FieldValue.arrayRemove(item)
          },{merge:true})
      }



      const handleaddbutton = () =>{
          setAddBannerButton(false)
      }

      function uploadImageFunction(files) {
        let file = files[0];
        setFileName(file.name)
        const uploadTask = storage.ref(`/adminImages/${file.name}`).put(file)

        uploadTask.on('state_changed',
            (snapShot) => {
            }, (err) => {
                console.log(err)
            }, () => {
                storage.ref('adminImages').child(file.name).getDownloadURL()
                    .then(fireBaseUrl => {
                        // getting url here
                        setUploadedImage(fireBaseUrl)
                        console.log("image uploaded",fireBaseUrl)
                        setUploadImage(false)

                    })
            })
}

const handleremoveimage =() =>{
    setUploadedImage(null)
    storage.ref('adminImages').child(fileName).delete()
    setUploadImage(true)

}

const addBanner = () => {
    const banner = {
        image: uploadedImage,
        timestamp: Date.now()
    }
    firestore.collection('appBanners').doc('appBanners').set({
        'banners_array': Firebase.firestore.FieldValue.arrayUnion(banner)
    },{merge:true})
    setUploadImage(true)
}


    return (
        <div>
            <Grid container spacing={0}>
                <Grid item xs={6}>
                    <Typography className={classes.title}>
                        Manage App Banners
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    {   addBannerButton ?
                        <Button onClick={handleaddbutton} style={{backgroundColor: primaryColor}} className={classes.addbutton} variant="contained">Add Banner</Button>
                        :
                        <Button onClick={()=>setAddBannerButton(true)} style={{backgroundColor: secondaryColor}} className={classes.addbutton} variant="contained">Cancel</Button>

                    }
                </Grid>
            </Grid>
        <div className={classes.cardscontainer}>
          { 
            !addBannerButton ? 
           <Paper className={classes.paper} style={{backgroundColor: '#efefef', height: '350px', margin: '20px'}}>
              { 
                uploadImage ?
                <div>
                    <BackupIcon className={classes.imageuploadbutton}  style={{fontSize:100,color:secondaryColor}}/>
                <div>
                <Button
                    className={classes.buttons}
                    variant="contained"
                    component="label"
                    color={secondaryColor}
                    >
                    Upload File
                    <input
                        type="file" 
                        hidden
                        onChange={e => uploadImageFunction(e.target.files)}
                    />
                </Button>
                </div>
                </div>
                :
            (    <div>               
                    <img src={uploadedImage} alt="" style={{ height: '300px',objectFit:'cover',width:'100%'}}/>    
                    <div style={{display:'flex',justifyContent:'center'}}>
                    <Button variant="contained" className={classes.buttons} onClick={handleremoveimage} >
                        Remove
                    </Button>
                    <Button variant="contained" className={classes.buttons} onClick={addBanner} >
                        Upload
                    </Button>
                    </div>
                </div>)
 
            }
            </Paper>
            :null
          }
        <Grid container spacing={3}>
            { allBanners.map(item => {
                return(
                    <Grid item xs={12} sm={4}>
                    <Card className={classes.cardroot}>
                            <div>
                                <img
                                className={classes.media}
                                src ={item.image}
                                // title="App Banner"
                                />
                            </div>
                            <CardActions>
                                 <Button onClick={() => {deleteBanner(item)}} style={{backgroundColor: secondaryColor,marginBottom:10}} className={classes.addbutton} variant="contained">Delete</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                )
            })
    }

        </Grid>
        </div>
        </div>

    )
}
