import React,{useState} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {  TextField, Typography } from '@material-ui/core';
import { primaryColor, secondaryColor, whiteColor } from '../../../config/constants';
import { useTheme } from '@material-ui/core/styles';
import {Link,useLocation} from "react-router-dom";
import { firestore } from '../../../config/firebase';


const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    title:{
        fontSize:30,
        color:secondaryColor,
        fontWeight:700,
        margin:20
    },
    background:{
        backgroundColor:"rgba(240, 102, 105, 0.13)",
        padding:30,
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between'
    },
    addbutton:{
        display:'flex',
        float:'right',
        margin:30,
        fontSize:16,
        borderRadius:8,
        background:secondaryColor,
        color:whiteColor,
        padding:5,
        textAlign:'center',
        cursor:'pointer',
        textDecoration:'none'
    },
    editbutton:{
        color:whiteColor,
        backgroundColor:secondaryColor,
        borderRadius:8,
        padding:8,
        textAlign:'center',
        margin:5,
        cursor:'pointer',
        maxHeight:35,
        textDecoration:'none',
        width:100,
    },
    formcontainer:{
        margin:20
    },
    formtitle:{
        color:primaryColor,
        fontWeight:600,
        fontSize:16,
        paddingBottom:10,
        paddingTop:10
    },
    forminput:{
        minWidth:600,
        borderColor:secondaryColor,
        background:whiteColor,
        [theme.breakpoints.only('xs')]: {
           minWidth:'100%'
          },
    }
  }));


export default function EditBusiness() {
    const classes = useStyles();
    const [businessName,setBusinessName] = useState("")
    const [pan,setPan] = useState('')
    const [gst,setGst] = useState('')
    const location = useLocation();

    const handleSubmit =() =>{
        console.log(pan,gst,businessName,location.phone)
        firestore.collection("users").doc(location.phone).update({
            pan:pan,
            businessName:businessName,
            gst : gst
        });

        alert('successfull edited')
        setPan('')
        setGst('')
    }
    return (
        <div>
            <Grid container spacing={0}>
                <Grid item xs={6}>
                <Typography className={classes.title}>
                    Edit
                </Typography>
                </Grid>
                <Grid item xs={6}>
                <Link to={{pathname:'/userdetails', phone:location.phone}} size="small" color="primary"  className={classes.addbutton}>
                        Go Back
                </Link>
                </Grid>
            </Grid>

            <div className={classes.formcontainer}>
                <Typography className={classes.formtitle}>Business Name Number</Typography>
                <TextField className={classes.forminput} placeholder="Enter Business Name" variant="outlined" onChange={(e)=>setBusinessName(e.target.value)} />
                <Typography className={classes.formtitle}>Pan Number</Typography>
                <TextField className={classes.forminput} placeholder="Enter Pan Number" variant="outlined" onChange={(e)=>setPan(e.target.value)} />
                <Typography className={classes.formtitle}>GST</Typography>
                <TextField className={classes.forminput} placeholder="Enter GST " variant="outlined" onChange={(e)=>setGst(e.target.value)} />
                <div>

            </div>

            </div>
            <div size="small" color="primary"  className={classes.editbutton} onClick={handleSubmit}>
                    Save
             </div>
        </div>

    )
}
