import React, { useState, useEffect } from "react";
import MainNavBar from "../../components/MainNavBar";
import { Container } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import {
  blackColor,
  lightGray,
  normalSize,
  primaryColor,
  secondaryColor,
  titleSize,
  whiteColor,
} from "../../config/webconstants";
import { Grid, Typography, Card } from "@material-ui/core";
import Logo from "../../assets/website/images/Bigwayzlogo.png";
import Benifit from "../../assets/website/images/benefits.png";
import MainFooter from "../../components/MainFooter";
import Banner from "../../components/Banner";
import "aos/dist/aos.css";
import Aos from "aos";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import Group from "../../assets/website/images/Group.png";
import Phone from "../../assets/website/images/phone.png";
import Timeline from "../../assets/website/images/Timeline.png";
import { ClassSharp } from "@material-ui/icons";
import carouselImage from "../../assets/website/carousel/entrepreneurbanner.png";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    flexDirection: "column",
    overflowX: "hidden",
    [theme.breakpoints.down("sm")]: {
      marginTop: "56px",
    },
  },
  button1: {
    color: secondaryColor,
    background: "transparent",
    margin: "0px 20px",
    width: 200,
    border: "1px solid",
    borderColor: secondaryColor,
    "&:hover": {
      color: secondaryColor,
      borderColor: secondaryColor,
      background: "transparent",
    },
  },
  button2: {
    color: whiteColor,
    borderColor: primaryColor,
    background: primaryColor,
    margin: "0px 20px",
    width: 160,
    "&:hover": {
      color: whiteColor,
      borderColor: primaryColor,
      background: primaryColor,
    },
  },
  carouselcaptions: {
    position: "absolute",
    right: "15%",
    bottom: "8.25rem",
    left: "5%",
    paddingTop: "1.25rem",
    paddingBottom: "1.25rem",
    color: "#fff",
    display: "flex",
  },
  controlicon: {
    fontSize: 50,
    color: primaryColor,
  },
  headertitle: {
    fontSize: 35,
    textAlign: "center",
    fontWeight: 600,
    paddingTop: 50,
    paddingBottom: 20,
    color: "white",
    fontFamily: "Myraid",
  },
  whocontainer: {
    backgroundColor: primaryColor,
    paddingBottom: 50,
  },
  whoimage: {
    height: 60,
    width: "100%",
    objectFit: "contain",
  },
  whosubtitle: {
    fontSize: 18,
    textAlign: "center",
    margin: "20px 0px",
    fontWeight: "600",
    fontFamily: "Myraid",
  },
  whoicons: {
    height: 50,
    width: "100%",
    objectFit: "contain",
  },
  whoTypo: {
    color: "white",
    marginTop: "25px",
    fontSize: titleSize,
    fontFamily: "Myraid",
    textAlign: "justify",
  },
  buttoncontainer: {
    margin: "20px 20px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    width: "95%",
  },
  bigbutton: {
    color: secondaryColor,
    backgroundColor: whiteColor,
    padding: "20px 60px",
    width: "20",
    borderRadius: 8,
    elevation: 3,
    fontSize: 20,
    fontWeight: "600",
    letterSpacing: "1px",
  },
  vectoreimage: {
    width: "100%",
    height: 200,
    objectFit: "contain",
  },
  vectortitle: {
    textAlign: "center",
    paddingTop: 20,
    fontSize: 20,
    fontWeight: 400,
    color: primaryColor,
    letterSpacing: 1,
    margin: "0px 15%",
  },
  bigbuttoncontainer: {
    margin: "8% 0px",
  },

  benefitsHeader: {
    color: primaryColor,
    fontSize: "40px",
    fontFamily: "Myraid",
    padding: "20px 0px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px",
      padding: "5px 0px",
    },
  },
  befitsbuleHeader: {
    color: secondaryColor,
    fontSize: "50px",
    fontWeight: "solid",
    fontFamily: "Myraid",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
    },
  },
  benifitsDiv: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      width: "100%",
      margin: "0px auto",
    },
  },
  benifitsTypoDiv: {
    marginTop: "40px",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      margin: "20px auto",
    },
  },
  benefitsBulletTypgraphy: {
    color: blackColor,
    fontSize: titleSize,
    display: "flex",
    fontFamily: "Myraid",
    fontWeight: "600",
    width: "85%",
    [theme.breakpoints.down("sm")]: {
      fontSize: normalSize,
      marginBottom: "5px",
    },
  },
  benifitImage: {
    width: "100%",
    height: "500px",
    objectFit: "cover",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      height: "300px",
    },
  },
  MiddleEnterDiv: {
    margin: "auto",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  MiddleEnterTypo: {
    fontSize: "32px",
    fontWeight: "bold",
    color: "#02BACD",
    fontFamily: "Myraid",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
    },
  },
  MiddleEnterTimelineDiv: {
    width: "80%",
    margin: "auto",
    marginTop: "100px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  MiddleEnterImage: {
    width: "100%",
  },
  LastEnterContent: {
    width: "60%",
    margin: "50px auto",
    border: "none",
    boxShadow: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    background: "none",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      width: "90%",
      margin: "30px auto",
    },
  },
  LastEnterContentHeader: {
    fontSize: "32px",
    fontWeight: "bold",
    color: "#02BACD",
    fontFamily: "Myraid",
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px",
      display:'none',
    },
  },
  LastEnterContentHeaderMobile: {
    fontSize: "32px",
    fontWeight: "bold",
    display:'none',
    color: "#02BACD",
    fontFamily: "Myraid",
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px",
      display:'block',
    },
  },
  lastEnterTypo: {
    fontSize: titleSize,
    fontWeight: "600",
    color: "#02BACD",
    padding: "5px 0px",
    fontFamily: "Myraid",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      fontWeight: "500",
      padding: "10px 0px",
    },
  },
  lastEnterTypoRed: {
    fontSize: titleSize,
    fontWeight: "600",
    color: "#F1666A",
    padding: "5px 0px",
    fontFamily: "Myraid",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      fontWeight: "500",
      padding: "10px 0px",
    },
  },
  LastEnterImage: {
    height: "500px",
    [theme.breakpoints.down("sm")]: {
      height: "60%",
      width: "60%",
    },
  },
}));

const Image = [{ img: carouselImage }];

export default function Enterpreneur() {
  const classes = useStyles();
  useEffect(() => {
    Aos.init({
      duration: 2000,
    });
  }, []);
  return (
    <>
      <MainNavBar />
      <div className={classes.root}>
        <Banner Image={Image} showOneTile={true} />

        <div className={classes.whocontainer}>
          <Typography
            className={classes.headertitle}
            data-aos="fade-up"
            data-aos-duration="6000"
          >
            We understand your world!
          </Typography>
          <Container>
            <Typography
              className={classes.whoTypo}
              data-aos="fade-up"
              data-aos-duration="6000"
            >
              Bigwayz offers an exciting opportunity for aspiring entrepreneurs
              across India to start your own digital business to generate
              sustainable revenue with Digital Banking, E- Commerce & Insurance
              services. Earn by onboarding merchants, resellers & agents to
              promote Bigwayz services to customers followed with earnings from
              every transactions also. Be part of the mission of digitizing
              India’s retail ecosystem by offering Bigwayz services.
            </Typography>

            {/* <Typography className={classes.whoTypo}>
                        What’s more: Bigwayz offers an opportunity to expand the Retail outlets with a virtual inventory of 10,000+ products from 200+ brands. Find more than an extra income & start your business to power business’s with Bigwayz marketing support services to build a trusted Customer base.
                        </Typography> */}
          </Container>
        </div>

        <div className={classes.benifitsDiv}>
          <div>
            <img
              src={Benifit}
              alt="logo"
              className={classes.benifitImage}
              data-aos="fade-up"
              data-aos-duration="6000"
            />
          </div>

          <div className={classes.benifitsTypoDiv}>
            <Typography
              className={classes.benefitsHeader}
              data-aos="fade-up"
              data-aos-duration="6000"
            >
              BENEFITS OF PARTNERING WITH BIGWAYZ
            </Typography>
            {/* <Typography className={classes.befitsbuleHeader}>OF THE BIGWAYZ APP</Typography> */}
            <ul>
              <li>
                <Typography
                  className={classes.benefitsBulletTypgraphy}
                  data-aos="fade-up"
                  data-aos-duration="6000"
                >
                  Opportunity to offer a wide range of products & services.
                </Typography>
              </li>
              <li>
                <Typography
                  className={classes.benefitsBulletTypgraphy}
                  data-aos="fade-up"
                  data-aos-duration="6000"
                >
                  Minimal investment & easy setup of a Digital Business.
                </Typography>
              </li>
              <li>
                <Typography
                  className={classes.benefitsBulletTypgraphy}
                  data-aos="fade-up"
                  data-aos-duration="6000"
                >
                  Focused induction and product training program.
                </Typography>
              </li>
              <li>
                <Typography
                  className={classes.benefitsBulletTypgraphy}
                  data-aos="fade-up"
                  data-aos-duration="6000"
                >
                  Quick retailer onboarding process through instant KYC.
                </Typography>
              </li>
              <li>
                <Typography
                  className={classes.benefitsBulletTypgraphy}
                  data-aos="fade-up"
                  data-aos-duration="6000"
                >
                  Earn with your growing network from each transaction.
                </Typography>
              </li>
              <li>
                <Typography
                  className={classes.benefitsBulletTypgraphy}
                  data-aos="fade-up"
                  data-aos-duration="6000"
                >
                  Range of marketing tools for effective lead generation.
                </Typography>
              </li>
              <li>
                <Typography
                  className={classes.benefitsBulletTypgraphy}
                  data-aos="fade-up"
                  data-aos-duration="6000"
                >
                  Exciting rewards & recognition Programs.
                </Typography>
              </li>
            </ul>
          </div>
        </div>

        <div className={classes.MiddleEnterDiv}>
          <Typography
            className={classes.MiddleEnterTypo}
            data-aos="fade-up"
            data-aos-duration="6000"
          >
            SIMPLE STEPS TO BE PART OF BIGWAYZ & EARN MORE:
          </Typography>
        </div>

        <div className={classes.MiddleEnterTimelineDiv}>
          <img
            src={Timeline}
            alt="Timeline"
            className={classes.MiddleEnterImage}
            data-aos="fade-up"
            data-aos-duration="6000"
          />
        </div>

        <Card className={classes.LastEnterContent}>
          <div>
            <Typography
              className={classes.LastEnterContentHeader}
              data-aos="fade-up"
              data-aos-duration="6000"
            >
             Join Now in 3-4 steps
            </Typography>
            <Typography
              className={classes.LastEnterContentHeaderMobile}
              data-aos="fade-up"
              data-aos-duration="6000"
            >
              Join Now
            </Typography>
            <div>
              <Typography
                className={classes.lastEnterTypo}
                data-aos="fade-up"
                data-aos-duration="6000"
              >
                1. Contact us for Bigwayz invitation Link.
              </Typography>
              <Typography
                className={classes.lastEnterTypoRed}
                data-aos="fade-up"
                data-aos-duration="6000"
              >
                2. Download Bigwayz App & create an account using your mobile
                number.
              </Typography>
              <Typography
                className={classes.lastEnterTypo}
                data-aos="fade-up"
                data-aos-duration="6000"
              >
                3. Upload documents & Complete KYC.
              </Typography>
              <Typography
                className={classes.lastEnterTypoRed}
                data-aos="fade-up"
                data-aos-duration="6000"
              >
                <strong>4. Start your business & earn more!</strong>
              </Typography>
            </div>
          </div>
          <img src={Phone} className={classes.LastEnterImage} />
        </Card>

        <MainFooter />
      </div>
    </>
  );
}
