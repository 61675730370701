import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {  TextField, Typography } from '@material-ui/core';
import { primaryColor, secondaryColor, whiteColor } from '../../../config/constants';
import { useTheme } from '@material-ui/core/styles';
import {Link} from "react-router-dom";
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    title:{
        fontSize:30,
        color:secondaryColor,
        fontWeight:700,
        margin:20
    },
    background:{
        backgroundColor:"rgba(240, 102, 105, 0.13)",
        padding:30,
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between'
    },
    addbutton:{
        display:'flex',
        float:'right',
        margin:30,
        fontSize:16,
        borderRadius:8,
        background:secondaryColor,
        color:whiteColor,
        padding:5,
        textAlign:'center',
        cursor:'pointer',
        textDecoration:'none'
    },
    editbutton:{
        color:whiteColor,
        backgroundColor:secondaryColor,
        borderRadius:18,
        padding:8,
        textAlign:'center',
        margin:5,
        cursor:'pointer',
        maxHeight:35,
        textDecoration:'none',
        width:100,
        margin:20
    },
    formcontainer:{
        margin:20
    },
    formtitle:{
        color:primaryColor,
        fontWeight:600,
        fontSize:16,
        paddingBottom:10,
        paddingTop:10
    },
    forminput:{
        minWidth:600,
        borderColor:secondaryColor,
        background:whiteColor,
        [theme.breakpoints.only('xs')]: {
           minWidth:'100%'
          },
    }
  }));
  

export default function EditBankDetails() {
    const classes = useStyles();
    const theme = useTheme();
    const [selectedDate, setSelectedDate] = React.useState(new Date('2014-08-18T21:11:54'));
    const [value, setValue] = React.useState('female');

    const handleDateChange = (date) => {
      setSelectedDate(date);
    };
    const handleChange = (event) => {
        setValue(event.target.value);
      };

    return (
        <div>
            <Grid container spacing={0}>
                <Grid item xs={6}>
                <Typography className={classes.title}>
                    Edit Ishan Lakhwani
                </Typography>
                </Grid>
                <Grid item xs={6}>
                <Link to ="/userdetails" size="small" color="primary"  className={classes.addbutton}>
                        Go Back
                </Link>
                </Grid>
            </Grid>

            <div className={classes.formcontainer}>
                <Typography className={classes.formtitle}>Account Number</Typography>
                <TextField className={classes.forminput} placeholder="Enter Account Number" variant="outlined" />
                <Typography className={classes.formtitle}>IFSC</Typography>
                <TextField className={classes.forminput} placeholder="Enter IFSC" variant="outlined" />
                <Typography className={classes.formtitle}>Branch Name</Typography>
                <TextField className={classes.forminput} placeholder="Enter Branch Name" variant="outlined" />
            </div>
            <div size="small" color="primary"  className={classes.editbutton}>
                    Save 
             </div>
        </div>

    )
}
