import React, { useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import PropTypes from "prop-types";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { Switch, Route, Link, BrowserRouter } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { useHistory } from "react-router-dom";
import List from "@material-ui/core/List";
import { fade } from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
// import { firestore, storage } from "../../../config/firebase";
import { firestore } from "../../config/firebase";
import { v4 as uuidv4 } from 'uuid'
import Firebase from "firebase";

//icons

import VideoLibraryIcon from "@material-ui/icons/VideoLibrary";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import AssignmentLateIcon from "@material-ui/icons/AssignmentLate";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import FolderSpecialOutlinedIcon from "@material-ui/icons/FolderSpecialOutlined";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import LinkIcon from "@material-ui/icons/Link";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import SendIcon from "@material-ui/icons/Send";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import InsertChartIcon from "@material-ui/icons/InsertChart";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import StoreIcon from "@material-ui/icons/Store";
import InboxIcon from "@material-ui/icons/Inbox";
import LoyaltyIcon from "@material-ui/icons/Loyalty";
import MoneyIcon from "@material-ui/icons/Money";
import MenuIcon from "@material-ui/icons/Menu";
import PaymentIcon from "@material-ui/icons/Payment";
import SpeakerNotesIcon from "@material-ui/icons/SpeakerNotes";
import ViewCarouselIcon from "@material-ui/icons/ViewCarousel";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import ReceiptIcon from "@material-ui/icons/Receipt";
import ContactlessIcon from "@material-ui/icons/Contactless";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";

// Folder Links
import BusineesCategories from "./BusineesCategories";
import {
  primaryColor,
  secondaryColor,
  whiteColor,
} from "../../config/constants";

import ManageUsers from "./RegisteredUsers/ManageUsers";
import SpecificUser from "./RegisteredUsers/SpecificUser";
import NewsFeed from "./NewsFeeds/NewsFeed";
import EditFaq from "./FAQ/EditFaq";
import UserDetails from "./RegisteredUsers/UserDetails";
import EditUserDetails from "./RegisteredUsers/EditUserDetails";
import EditContactDetails from "./RegisteredUsers/EditContactDetails";
import EditAddress from "./RegisteredUsers/EditAddress";
import EditBankDetails from "./RegisteredUsers/EditBankDetails";
import EditBusiness from "./RegisteredUsers/EditBusiness";
import AddNews from "./NewsFeeds/AddNews";
import EditNews from "./NewsFeeds/EditNews";
import AddFAQPage from "./FAQ/AddFAQPage";
import EditFAQPage from "./FAQ/EditFAQPage";
import Alerts from "./Notitfications/Alerts/Alerts";
import Promocodes from "./Notitfications/Promocodes/Promocodes";
import SupportTicket from "./SupportTickets/SupportTickets";
import CreateSupportTicket from "./SupportTickets/CreateSupportTicket";
import SupportTicketDetail from "./SupportTickets/SupportTicketDetail";
import VideoLibrary from "./VideoLibrary/VideoLibrary";
import AddVideo from "./VideoLibrary/AddVideo";
import EditVideo from "./VideoLibrary/EditVideo";
import Cashout from "./CashOut/Cashout";
import CashoutDetails from "./CashOut/CashoutDetails";
import KYC from "./KYC/KYC";
import KYCDetails from "./KYC/KYCDetails";
import AdminRoles from "./AdminUsers/AdminRoles";
import AdminUserList from "./AdminUsers/AdminUserList";
import AddAdminUser from "./AdminUsers/AddAdminUser";
import AddAdminRoles from "./AdminUsers/AddAdminRoles";
import AdminDetailsPage from "./AdminUsers/AdminDetailsPage";
import BusinessCategories from "./BusinessCategories/BusinessCategories";
import AddCategory from "./BusinessCategories/AddCategory";
import OnboardingNavigator from "./Visualization";
import AppBanners from "./AppBanners";
import AddBanner from "./AppBanners/AddBanner";
import PackagesList from "./Packages";
import AddPackage from "./Packages/CreatePackage";
import DiscountCoupons from "./DiscountCoupons";
import AddDiscount from "./DiscountCoupons/AddDiscount";
import AddPromocode from "./PromoCode/AddPromocode";
import PromoCodes from "./PromoCode";
import BankName from "./BankName";
import DownloadBanners from "./DownloadBanners";
import AddDownloadBanners from "./DownloadBanners/AddDownloadBanners";
import EditAdminRoles from "./AdminUsers/EditAdminRole";
import Services from "./Services";
import EditService from "./Services/EditService";
import Transactions from "./Transactions/SupportTickets";
import WebsiteLeads from "./RegisteredUsers/WebsiteLeads";

// import Accordion from '@material-ui/core/Accordion';
// import AccordionSummary from '@material-ui/core/AccordionSummary';
// import AccordionDetails from '@material-ui/core/AccordionDetails';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "&,  .MuiMenu-paper": {
      minWidth: 200,
    },
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backroundColor: "#05bacc",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    backroundColor: "#05bacc",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  sidebarlink: {
    color: "black",
    textDecoration: "none",
  },
  logo: {
    width: "100%",
    height: "60px",
    objectFit: "contain",
    padding: "10px",
    paddingLeft: "40px",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
  menu: {
    "& .MuiMenuItem-root": {
      width: 300,
    },
    "& .MuiList-padding": {
      padding: "0px",
    },
    "& .MuiListItem-gutters": {
      padding: "0px",
    },
    "& .MuiOutlinedInput-input": {
      padding: 5,
    },
  },
  menutitle: {
    color: whiteColor,
    paddingLeft: 10,
    paddingTop: 10,
    paddingBotton: 5,
    position: "absoulte",
    fontSize: 12,
  },
  menufooter: {
    position: "absolute",
    bottom: 0,
    backgroundColor: primaryColor,
    width: "100%",
  },
  menuAddButton: {
    color: whiteColor,
    paddingTop: 3,
    paddingBottom: 3,
    position: "absoulte",
    fontSize: 12,
    width: 50,
    backgroundColor: primaryColor,
    margin: 5,
    textAlign: "center",
    borderRadius: 4,
    cursor: "pointer",
  },
  addlink: {
    padding: 10,
    display: "flex",
    justifyContent: "space-between",
  },
  tabText: {
    fontFamily: "Myraid",
    fontWeight: "800",
  },
}));

export default function Dashboard(props) {
  // const { container } = props;
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = React.useState(true);
  const [name, setName] = React.useState("");
  const [userName, setUserName] = React.useState("");
  const [role, setRole] = React.useState(true);
  const [currentUser, setCurrentUser] = React.useState(null);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);

  const [openNest, setOpenNest] = React.useState(false);
  const [openAdmin, setOpenAdmin] = React.useState(false);
  const [openAnalytics, setOpenAnalytics] = React.useState(false);
  const [openBigwayz, setOpenBigwayz] = React.useState(false);
  const [openServices, setOpenServices] = React.useState(false);
  const [openFinance, setOpenFinance] = React.useState(false);
  const [openEngagements, setOpenEngagements] = React.useState(false);

  const [Links,setLinks] = React.useState([])

  const [addLink, setAddLink] = React.useState(false);
  const [addLinkName, setAddLinkName] = React.useState("");

  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClickAnalytics = () => {
    setOpenAnalytics(!openAnalytics);
  };

  const handleClickAdmin = () => {
    setOpenAdmin(!openAdmin);
  };
  const handleClickEngagements = () => {
    setOpenEngagements(!openEngagements);
  };
  const handleClickFinance = () => {
    setOpenFinance(!openFinance);
  };

  const handleClickBigwayz = () => {
    setOpenBigwayz(!openBigwayz);
  };

  const handleClickServices = () => {
    setOpenServices(!openServices);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };


const  AddlinkToArray =()=>{

  if(addLinkName.trim().length === 0) {

  return  alert('please add link name')

  }


  const newLink ={
    id:uuidv4(),
    link:addLinkName,
    timestamp: Date.now()
  }

  // setLinks([...Links,newLink])

    firestore.collection('businessCategories').doc('importantLinks').set({
        'important_links_array': Firebase.firestore.FieldValue.arrayUnion(newLink)
    },{merge:true})

    getAllLinks()

  setAddLink(!addLink);
  setAddLinkName("")

}

  const handleAddlink = () => {
    setAddLink(!addLink);
    // setLinks([...Links,addLinkName])
  };



  const getAllLinks=()=>{

    firestore
      .collection("businessCategories")
      .doc("importantLinks")
      .get()
      .then((snapshot) => {
        if (snapshot.exists == false) {
          // no products available
        } else {
          const categories_array = snapshot.data().important_links_array;
          console.log("all cat= ", categories_array);
          setLinks(categories_array);
          // setLoading(false);
        }
      })
  }

  useEffect(() => {

    getAllLinks()

    const user = JSON.parse(localStorage.getItem("bigwayzUser"));
    console.log("user in dashboard =", user);

    if (user == null || user == undefined) {
      history.push("/admin_inginomicxtechnologies_v1.0");
    } else {
      firestore
        .collection("adminRoles")
        .doc(user.userRole)
        .get()
        .then((snap) => {
          console.log("setCurrentUser", snap.data());
          setCurrentUser(snap.data());
        });
      setUserName(user.userName);
      setName(user.name);
      const role = user.userRole;
      setRole(role);
    }



  }, []);






  function logout() {
    if (userName != "" && userName != null && userName != undefined) {
      firestore
        .collection("adminUsers")
        .doc(userName)
        .set(
          {
            lastLogout: Date.now(),
          },
          { merge: true }
        );
    }
    localStorage.removeItem("bigwayzUser");
    history.push("/");
  }

  const drawer = (
    <div>
      <List>
        {currentUser?.accessTo?.includes("1") ? (
          <ListItem button onClick={handleClickAdmin}>
            <ListItemIcon>
              <SupervisedUserCircleIcon />
            </ListItemIcon>
            <ListItemText className={classes.tabText} primary="Employees" />
            {openAdmin ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
        ) : null}

        <Collapse in={openAdmin} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to={"/adminroles"}
            >
              <ListItemIcon>
                <AccountBoxIcon />
              </ListItemIcon>
              <ListItemText primary="Employee Roles" />
            </ListItem>
          </List>
          <List component="div" disablePadding>
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to={"/adminusers"}
            >
              <ListItemIcon>
                <PeopleAltIcon />
              </ListItemIcon>
              <ListItemText primary="Employees List" />
            </ListItem>
          </List>
        </Collapse>

        {role == "SUPERADMIN" ? <Divider /> : null}

        {/*<ListItem button onClick={handleClickAnalytics}>*/}
        {currentUser?.accessTo?.includes("2") ? (
          <ListItem
            button
            className={classes.nested}
            component={Link}
            to={"/dashboard"}
          >
            <ListItemIcon>
              <InsertChartIcon />
            </ListItemIcon>
            <ListItemText primary="Registered Users" />
          </ListItem>
        ) : null}

        {currentUser?.accessTo?.includes("3") ? (
          <ListItem
            button
            className={classes.nested}
            component={Link}
            to={"/transactions"}
          >
            {" "}
            <ListItemIcon>
              <ReceiptIcon />
            </ListItemIcon>
            <ListItemText primary="Transactions" />
          </ListItem>
        ) : null}
        {currentUser?.accessTo?.includes("4") ? (
          <ListItem
            button
            className={classes.nested}
            component={Link}
            to={"/cashout"}
          >
            {" "}
            <ListItemIcon>
              <MoneyIcon />
            </ListItemIcon>
            <ListItemText primary="Cashout Requests" />
          </ListItem>
        ) : null}

        {currentUser?.accessTo?.includes("5") ? (
          <ListItem
            button
            className={classes.nested}
            component={Link}
            to={"/websiteleads"}
          >
            {" "}
            <ListItemIcon>
              <BusinessCenterIcon />
            </ListItemIcon>
            <ListItemText primary="Website Leads" />
          </ListItem>
        ) : null}
        <Divider />

        {currentUser?.accessTo?.includes("6") ? (
          <List component="div" disablePadding>
            <ListItem button to={"/packages"} component={Link}>
              <ListItemIcon>
                <ShoppingCartIcon />
              </ListItemIcon>
              <ListItemText primary="Product Packages" />
            </ListItem>
          </List>
        ) : null}
        {currentUser?.accessTo?.includes("7") ? (
          <List component="div" disablePadding>
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to={"/businessCategories"}
            >
              <ListItemIcon>
                <FileCopyIcon />
              </ListItemIcon>
              <ListItemText primary="Business Categories" />
            </ListItem>
          </List>
        ) : null}
        {currentUser?.accessTo?.includes("8") ? (
          <List component="div" disablePadding>
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to={"/services"}
            >
              <ListItemIcon>
                <FileCopyIcon />
              </ListItemIcon>
              <ListItemText primary="Services" />
            </ListItem>
          </List>
        ) : null}
        {currentUser?.accessTo?.includes("9") ? (
          <List component="div" disablePadding>
            <ListItem
              component={Link}
              to={"/editfaq"}
              className={classes.sidebarlink}
            >
              <ListItemIcon>
                <LiveHelpIcon />
              </ListItemIcon>
              <ListItemText primary="Edit FAQ" />
            </ListItem>
          </List>
        ) : null}
        {currentUser?.accessTo?.includes("10") ? (
          <List component="div" disablePadding>
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to={"/discountcoupon"}
            >
              <ListItemIcon>
                <LoyaltyIcon />
              </ListItemIcon>
              <ListItemText primary="Discount Coupons" />
            </ListItem>
          </List>
        ) : null}
        <Divider />
        {currentUser?.accessTo?.includes("11") ? (
          <ListItem button onClick={handleClickEngagements}>
            <ListItemIcon>
              <PhoneAndroidIcon />
            </ListItemIcon>
            <ListItemText primary="Engagements" />
            {openEngagements ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
        ) : null}

        <Collapse in={openEngagements} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to={"/alerts"}
            >
              <ListItemIcon>
                <SendIcon />
              </ListItemIcon>
              <ListItemText primary="Alerts" />
            </ListItem>
          </List>

          <List component="div" disablePadding>
            <ListItem
              component={Link}
              to={"/newsfeed"}
              className={classes.sidebarlink}
            >
              <ListItemIcon>
                <SpeakerNotesIcon />
              </ListItemIcon>
              <ListItemText primary="News Feed" />
            </ListItem>
          </List>
          <List component="div" disablePadding>
            <ListItem
              component={Link}
              to={"/videolibrary"}
              className={classes.sidebarlink}
            >
              <ListItemIcon>
                <VideoLibraryIcon />
              </ListItemIcon>
              <ListItemText primary="Video Library" />
            </ListItem>
          </List>

          <List component="div" disablePadding>
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to={"/appBanners"}
            >
              <ListItemIcon>
                <ViewCarouselIcon />
              </ListItemIcon>
              <ListItemText primary="Banners" />
            </ListItem>
          </List>
          <List component="div" disablePadding>
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to={"/promocode"}
            >
              <ListItemIcon>
                <CardGiftcardIcon />
              </ListItemIcon>
              <ListItemText primary="Promocodes" />
            </ListItem>
          </List>
        </Collapse>

        <Divider />

        <Collapse in={openServices} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to={"/adminroles"}
            >
              <ListItemIcon>
                <MenuIcon />
              </ListItemIcon>
              <ListItemText primary="Finz" />
            </ListItem>
          </List>
          <List component="div" disablePadding>
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to={"/adminusers"}
            >
              <ListItemIcon>
                <MenuIcon />
              </ListItemIcon>
              <ListItemText primary="Assist" />
            </ListItem>
          </List>
          <List component="div" disablePadding>
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to={"/adminusers"}
            >
              <ListItemIcon>
                <MenuIcon />
              </ListItemIcon>
              <ListItemText primary="Suraksha" />
            </ListItem>
          </List>
          <List component="div" disablePadding>
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to={"/adminusers"}
            >
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary="Leads" />
            </ListItem>
          </List>
          <List component="div" disablePadding>
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to={"/adminusers"}
            >
              <ListItemIcon>
                <StoreIcon />
              </ListItemIcon>
              <ListItemText primary="Sell Your Products" />
            </ListItem>
          </List>
        </Collapse>
        <Divider />

        <Collapse in={openFinance} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to={"/adminroles"}
            >
              <ListItemIcon>
                <PaymentIcon />
              </ListItemIcon>
              <ListItemText primary="Payment Status" />
            </ListItem>
          </List>
          <List component="div" disablePadding>
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to={"/adminusers"}
            >
              <ListItemIcon>
                <ReceiptIcon />
              </ListItemIcon>
              <ListItemText primary="GST" />
            </ListItem>
          </List>
          <List component="div" disablePadding>
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to={"/adminusers"}
            >
              <ListItemIcon>
                <ReceiptIcon />
              </ListItemIcon>
              <ListItemText primary="TDS" />
            </ListItem>
          </List>
          <List component="div" disablePadding>
            <ListItem
              button
              className={classes.nested}
              component={Link}
              to={"/adminusers"}
            >
              <ListItemIcon>
                <ReceiptIcon />
              </ListItemIcon>
              <ListItemText primary="Expenses" />
            </ListItem>
          </List>
        </Collapse>

        <Divider />
        {currentUser?.accessTo?.includes("12") ? (
          <ListItem
            component={Link}
            to={"/addbankname"}
            className={classes.sidebarlink}
          >
            <ListItemIcon>
              <AccountBalanceIcon />
            </ListItemIcon>
            <ListItemText primary="Bank Names" />
          </ListItem>
        ) : null}
        {currentUser?.accessTo?.includes("13") ? (
          <ListItem
            component={Link}
            to={"/downloadbanners"}
            className={classes.sidebarlink}
          >
            <ListItemIcon>
              <SystemUpdateAltIcon />
            </ListItemIcon>
            <ListItemText primary="Download Banners" />
          </ListItem>
        ) : null}
        {currentUser?.accessTo?.includes("14") ? (
          <ListItem
            component={Link}
            to={"/supportticket"}
            className={classes.sidebarlink}
          >
            <ListItemIcon>
              <ConfirmationNumberIcon />
            </ListItemIcon>
            <ListItemText primary="Support" />
          </ListItem>
        ) : null}
        <Divider />

        <Divider />

        <ListItem button onClick={() => logout()}>
          <ListItemIcon>
            <PowerSettingsNewIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
      </List>
    </div>
  );


  const deleteLink =(L)=>{

    console.log("deleting item =", L);
    firestore
      .collection("businessCategories")
      .doc("importantLinks")
      .update(
        {
          important_links_array: Firebase.firestore.FieldValue.arrayRemove(L),
        },
        { merge: true }
      );

      getAllLinks()


  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="absolute"
        style={{ backgroundColor: secondaryColor }}
        className={clsx(classes.appBar, open && classes.appBarShift)}
        elevation={0}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
            style={{ fontFamily: "Myraid", fontWeight: "800" }}
          >
            {role}
          </Typography>
          <div className={classes.menu}>
            <IconButton onClick={handleClickMenu}>
               <FolderSpecialOutlinedIcon
                style={{
                  marginLeft: "10px",
                  marginRight: "10px",
                  color: whiteColor,
                }}
              /> 
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleCloseMenu}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              className={classes.menu}
            >
              <div style={{ maxHeight: "200px" ,overFlow:"scroll"}}>
                <MenuItem>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      backgroundColor: secondaryColor,
                      width: "100%",
                    }}
                  >
                    <div className={classes.menutitle}>Links Folder</div>
                    <div
                      className={classes.menuAddButton}
                      onClick={handleAddlink}
                    >
                      Add
                    </div>
                  </div>
                </MenuItem>
                {addLink ? (
                  <div className={classes.addlink}>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      autoComplete="off"
                      onChange={(e) => setAddLinkName(e.target.value)}
                    />
                    <div
                      className={classes.menuAddButton}
                      onClick={AddlinkToArray}
                    >
                      Done
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {
                  
                  Links.length > 0 ? Links.map((EL)=>{

        return   <MenuItem key={EL.id}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      margin: 10,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: 250,
                      }}
                    >
                      <LinkIcon
                        style={{
                          fontSize: 23,
                          paddingTop: 5,
                          color: secondaryColor,
                        }}
                      />
                      <a
                        href={EL.link}
                        target="_blank"
                        style={{
                          fontSize: 12,
                          paddingTop: 5,
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                        }}
                      >
                        {EL.link}
                      </a>
                    </div>
                    <div>
                      <IconButton style={{ padding: 0 }}>
                        <DeleteOutlineIcon
                          style={{ fontSize: 18, color: secondaryColor }}
                          onClick={() => deleteLink(EL)}
                        />
                      </IconButton>
                    </div>
                  </div>
                </MenuItem>


                })
           


                 : (
                  <>no links</>
                )}
     
              </div>
            </Menu>
          </div>
          <Typography style={{ fontFamily: "Myraid", fontWeight: "800" }}>
            {" "}
            Hello {name}
          </Typography>
        </Toolbar>
      </AppBar>
      <BrowserRouter>
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          open={open}
        >
          <div className={classes.toolbarIcon}>
            <img src="Bigwayzlogo.jpg" className={classes.logo} alt="" />
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          {drawer}
        </Drawer>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Switch>
            <Route
              exact
              path="/dashboard"
              render={() => (
                <div>
                  <BusineesCategories />
                </div>
              )}
            />
            {/*Admin Routes*/}
            <Route
              path="/adminroles"
              render={() => (
                <div>
                  <AdminRoles />
                </div>
              )}
            />
            <Route
              path="/editadminroles"
              render={() => (
                <div>
                  <EditAdminRoles />
                </div>
              )}
            />
            <Route
              path="/adminusers"
              render={() => (
                <div>
                  <AdminUserList />
                </div>
              )}
            />
            <Route
              path="/addadmin"
              render={() => (
                <div>
                  <AddAdminUser />
                </div>
              )}
            />
            <Route
              path="/addrole"
              render={() => (
                <div>
                  <AddAdminRoles />
                </div>
              )}
            />
            <Route
              path="/admindetails"
              render={() => (
                <div>
                  <AdminDetailsPage />
                </div>
              )}
            />
            <Route
              path="/visualization"
              render={() => (
                <div>
                  <OnboardingNavigator />
                </div>
              )}
            />

            {/*Bigwayz Routes*/}
            <Route
              path="/businessCategories"
              render={() => (
                <div>
                  <BusinessCategories />
                </div>
              )}
            />
            <Route
              path="/addCategory"
              render={() => (
                <div>
                  <AddCategory />
                </div>
              )}
            />

            {/*Engagemnet Routes*/}
            <Route
              path="/appBanners"
              render={() => (
                <div>
                  <AppBanners />
                </div>
              )}
            />
            <Route
              path="/addBanner"
              render={() => (
                <div>
                  <AddBanner />
                </div>
              )}
            />

            {/* User page routes */}

            <Route
              path="/manageusers"
              render={() => (
                <div>
                  <ManageUsers />
                </div>
              )}
            />

            <Route
              path="/specificUser"
              render={() => (
                <div>
                  <SpecificUser/>
                </div>
              )}
            />

            <Route
              path="/createSupportTicket"
              render={() => (
                <div>
                  <CreateSupportTicket/>
                </div>
              )}
            />

            <Route
              path="/edituser"
              render={() => (
                <div>
                  <EditUserDetails />
                </div>
              )}
            />
            <Route
              path="/userdetails"
              render={() => (
                <div>
                  <UserDetails />
                </div>
              )}
            />
            <Route
              path="/editcontactdetails"
              render={() => (
                <div>
                  <EditContactDetails />
                </div>
              )}
            />
            <Route
              path="/editAddress"
              render={() => (
                <div>
                  <EditAddress />
                </div>
              )}
            />
            <Route
              path="/editbankdetails"
              render={() => (
                <div>
                  <EditBankDetails />
                </div>
              )}
            />
            <Route
              path="/editbusinessdetails"
              render={() => (
                <div>
                  <EditBusiness />
                </div>
              )}
            />
            {/* News Page Routes */}
            <Route
              path="/newsfeed"
              render={() => (
                <div>
                  <NewsFeed />
                </div>
              )}
            />
            <Route
              path="/addnewsfeed"
              render={() => (
                <div>
                  <AddNews />
                </div>
              )}
            />
            <Route
              path="/editnewsfeed"
              render={() => (
                <div>
                  <EditNews />
                </div>
              )}
            />
            {/* FAQ Routes */}
            <Route
              path="/editfaq"
              render={() => (
                <div>
                  <EditFaq />
                </div>
              )}
            />
            <Route
              path="/addfaqpage"
              render={() => (
                <div>
                  <AddFAQPage />
                </div>
              )}
            />
            <Route
              path="/editfaqpage"
              render={() => (
                <div>
                  <EditFAQPage />
                </div>
              )}
            />

            <Route
              path="/transactions"
              render={() => (
                <div>
                  <Transactions />
                </div>
              )}
            />

            <Route
              path="/supportticket"
              render={() => (
                <div>
                  <SupportTicket />
                </div>
              )}
            />
            <Route
              path="/supportticketdetail"
              render={() => (
                <div>
                  <SupportTicketDetail />
                </div>
              )}
            />

            {/* Video Library */}
            <Route
              path="/videolibrary"
              render={() => (
                <div>
                  <VideoLibrary />
                </div>
              )}
            />
            <Route
              path="/addvideo"
              render={() => (
                <div>
                  <AddVideo />
                </div>
              )}
            />
            <Route
              path="/editvideo"
              render={() => (
                <div>
                  <EditVideo />
                </div>
              )}
            />

            {/* cashout */}
            <Route
              path="/cashout"
              render={() => (
                <div>
                  <Cashout />
                </div>
              )}
            />

            <Route
              path="/alerts"
              render={() => (
                <div>
                  <Alerts />
                </div>
              )}
            />
            <Route
              path="/websiteleads"
              render={() => (
                <div>
                  <WebsiteLeads />
                </div>
              )}
            />
            <Route
              path="/cashoutdetails"
              render={() => (
                <div>
                  <CashoutDetails />
                </div>
              )}
            />

            {/* KYC */}
            <Route
              path="/kyc"
              render={() => (
                <div>
                  <KYC />
                </div>
              )}
            />
            <Route
              path="/kycdetails"
              render={() => (
                <div>
                  <KYCDetails />
                </div>
              )}
            />

            {/* Pacakges */}
            <Route
              path="/packages"
              render={() => (
                <div>
                  <PackagesList />
                </div>
              )}
            />
            <Route
              path="/addpackage"
              render={() => (
                <div>
                  <AddPackage />
                </div>
              )}
            />

            {/*discount coupons  */}
            <Route
              path="/discountcoupon"
              render={() => (
                <div>
                  <DiscountCoupons />
                </div>
              )}
            />
            <Route
              path="/adddiscount"
              render={() => (
                <div>
                  <AddDiscount />
                </div>
              )}
            />

            {/* PromoCodes */}
            <Route
              path="/promocode"
              render={() => (
                <div>
                  <PromoCodes />
                </div>
              )}
            />
            <Route
              path="/addpromocode"
              render={() => (
                <div>
                  <AddPromocode />
                </div>
              )}
            />

            {/* Add Bank Name */}
            <Route
              path="/addbankname"
              render={() => (
                <div>
                  <BankName />
                </div>
              )}
            />

            {/* Download Banners */}
            <Route
              path="/downloadbanners"
              render={() => (
                <div>
                  <DownloadBanners />
                </div>
              )}
            />
            <Route
              path="/adddownloadbanners"
              render={() => (
                <div>
                  <AddDownloadBanners />
                </div>
              )}
            />

            {/* services */}
            <Route
              path="/services"
              render={() => (
                <div>
                  <Services />
                </div>
              )}
            />
            <Route
              path="/editservices"
              render={() => (
                <div>
                  <EditService />
                </div>
              )}
            />
          </Switch>
        </main>
      </BrowserRouter>
    </div>
  );
}

Dashboard.propTypes = {
  container: PropTypes.instanceOf(
    typeof Element === "undefined" ? Object : Element
  ),
};
