import React, {useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Icon, IconButton, Typography } from '@material-ui/core';
import {blackColor, lightGray, primaryColor, secondaryColor, whiteColor} from '../../../config/constants';
import {Link, useHistory} from "react-router-dom";
import Button from "@material-ui/core/Button";
import {firestore,storage,} from '../../../config/firebase';
import DeleteIcon from "@material-ui/icons/Delete";
import Firebase from "firebase";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";


const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      margin: theme.spacing(2),
      textAlign: 'left',
      color: theme.palette.text.secondary,
    },
    title:{
        fontSize:24,
        color:secondaryColor,
        fontWeight:700,
        margin:20
    },
    addbuttontitle:{
        textAlign:'center',
        cursor:'pointer',
        textDecoration:'none',
        color:whiteColor
    },
    background:{
        backgroundColor:"rgba(240, 102, 105, 0.13)",
        padding:20,
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between'
    },
    background1:{
        padding:20,
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between'
    },
    dialogtitle:{
        display:'flex',
        flexDirection:'row',
        width:'100%',
        justifyContent: 'space-between',
        padding:15
    },
    dialogtitlename:{
        fontSize:20,
        color:secondaryColor
    },
    textfeild:{
        width:'90%',
        marginLeft:'5%',
        marginBottom:10
    },
    addbutton:{
        display:'flex',
        float:'right',
        margin:16,
        width:110,
        background:secondaryColor,
        color:whiteColor,
        padding:3,
        textAlign:'center',
        cursor:'pointer',
        textDecoration:"none"
    },
    editbutton:{
        color:whiteColor,
        backgroundColor:secondaryColor,
        borderRadius:8,
        padding:8,
        textAlign:'center',
        margin:5,
        cursor:'pointer',
        maxHeight:35,
        textDecoration:'none'
    },

  }));


export default function EditFaq() {
    const classes = useStyles();
    const [allFAQ, setAllFAQ] = React.useState([{}]);
    const history = useHistory();

    useEffect(() => {
        getAllFAQ();
    },[])

    const getAllFAQ = () => {
        firestore.collection('faq').doc('faq').get().then(snapshot => {
            if(snapshot.exists == false){
                // no products available
            }else {
                const faq_array = snapshot.data().faq_array;
                console.log('all faq ==', faq_array )
                setAllFAQ(faq_array)
            }
        })
    }


    function deleteFAQ(item) {
        console.log('deleting item =', item)
        firestore.collection('faq').doc('faq').update({
            'faq_array': Firebase.firestore.FieldValue.arrayRemove(item)
        },{merge:true})
    }

    return (
        <div>
            <Alert severity="info">
                <AlertTitle>FAQ Info</AlertTitle>
                The below FAQs will be show in <strong>Bigwayz User App!</strong> To make the app optimized we've disabled the edit FAQ function, if you want to change and FAQ kindly delete the current FAQ and add one, by clicking on the below button.
            </Alert>
            <Grid container spacing={0}>
                <Grid item xs={6}>
                    <Typography className={classes.title}>
                        FAQ's
                    </Typography>
                </Grid>
                <Grid item xs={4}>

                </Grid>
                <Grid item xs={1} style={{marginLeft: '20px', marginTop: '5px'}}>
                    <Button onClick={() => history.push('/addfaqpage')} style={{backgroundColor: primaryColor}} className={classes.addbutton} variant="contained">Add FAQ</Button>
                </Grid>
            </Grid>
            <Paper className={classes.paper}>
                {allFAQ.map((item,index) => {
                    return(
                        <>
                            {  index% 2 == 0 ?
                                <div className={classes.background}>
                                    <div style={{flexDirection:'column'}}>
                                    <Typography>
                                        <strong>{item.question+ "\n"}</strong>
                                    </Typography>
                                        {item.answer}
                                    </div>
                                        <DeleteIcon onClick={() => deleteFAQ(item)} style={{color: 'red'}}/>

                                </div>
                                :
                                <div className={classes.background1}>
                                    <div style={{flexDirection:'column'}}>
                                        <Typography>
                                            <strong>{item.question+ "\n"}</strong>
                                        </Typography>
                                        {item.answer}
                                    </div>
                                    <div style={{ cursor:'pointer'}}>
                                        <DeleteIcon onClick={() => deleteFAQ(item)} style={{color: 'red', cursor:'pointer'}}/>
                                    </div>
                                </div>
                            }
                        </>
                    )
                })
                }
            </Paper>
        </div>

    )
}
