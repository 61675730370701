import React,{useState} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Checkbox, FormGroup, Paper, TextField, Typography } from '@material-ui/core';
import { primaryColor, secondaryColor, whiteColor } from '../../../config/constants';
import {Link,useLocation} from "react-router-dom";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { firestore } from '../../../config/firebase';
import Button from "@material-ui/core/Button";
import {useHistory} from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import axios from "axios"

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    title:{
        fontSize:24,
        color:secondaryColor,
        fontWeight:700,
        margin:20
    },
    background:{
        backgroundColor:"rgba(240, 102, 105, 0.13)",
        padding:30,
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between'
    },
    addbutton:{
        display:'flex',
        float:'right',
        margin:16,
        width:110,
        background:secondaryColor,
        color:whiteColor,
        padding:3,
        textAlign:'center',
        cursor:'pointer',
        textDecoration:"none"
    },
    editbutton:{
        color:whiteColor,
        backgroundColor:secondaryColor,
        // borderRadius:18,
        padding:8,
        textAlign:'center',
        marginLeft:20,
        marginTop:10,
        cursor:'pointer',
        maxHeight:35,
        textDecoration:'none',
        width:200,
    },
    formcontainer:{
        marginLeft:20,
        marginRigth:20,
        padding:20,
        width:'80%'
    },
    formtitle:{
        color:primaryColor,
        fontWeight:600,
        fontSize:16,
        paddingBottom:10,
        paddingTop:10
    },
    forminput:{
        minWidth:600,
        borderColor:secondaryColor,
        background:whiteColor,
        [theme.breakpoints.only('xs')]: {
           minWidth:'100%'
          },
    }
  }));


export default function EditAdminRoles(props) {
    const classes = useStyles();
    const location = useLocation();
    const [value, setValue] = React.useState(location.data.status);
    const [rolename,setRoleName] = React.useState(location.data.name)
    const [accessTo,setAccessTo] = React.useState('')
    const [snackBarOpen, setSnackBarOpen] = React.useState(false);
    const history = useHistory();

    console.log("uselocation",location.data)

    const addAdminRole = () => {
        axios.post('https://us-central1-bigwayz-backend.cloudfunctions.net/add_admin_role',{
            "name": rolename,
            "status": value,
            "accessTo": accessTo,
            "timestamp": location.data.timestamp.toString()
        })
        .then(res => {
            console.log(res.data)
             setSnackBarOpen(true)
            setTimeout(() => {
                history.push('/adminroles')
            }, 1000);
        })
        // firestore.collection('adminRoles').doc(location.data.timestamp.toString()).set({
        //     name: rolename,
        //     status: value,
        //     accessTo: accessTo,
        //     timestamp: timestamp
        // },{merge: true})
        
    }

    const handleChange = (event) => {
        setValue(event.target.value);
      };

    return (
        <div>
            <Grid container spacing={0}>
                <Grid item xs={6}>
                <Typography className={classes.title}>
                    Edit Admin Role
                </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Button onClick={() => history.push('/adminroles')} style={{backgroundColor: primaryColor}} className={classes.addbutton} variant="contained">Go Back</Button>
                </Grid>
            </Grid>

            <Paper className={classes.formcontainer}>
                <Typography className={classes.formtitle} >Role Name</Typography>
                <TextField className={classes.forminput} placeholder="Role name" variant="outlined" value={rolename} onChange={(e)=>setRoleName(e.target.value)} />

                <Typography className={classes.formtitle}>Status</Typography>

                <FormControl component="fieldset">
                <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>
                    <div style={{display:'flex'}}>
                    <FormControlLabel value="active" control={<Radio />} label="Active" />
                    <FormControlLabel value="inactive" control={<Radio   />} label="Inactive" />
                    </div>
                </RadioGroup>
                </FormControl>
                <Typography className={classes.formtitle} >Select </Typography>
            <FormGroup row>
            <FormControlLabel control={<Checkbox onChange={()=>setAccessTo([...accessTo,"1"])}  name="1" />} label="Employee" />
            <FormControlLabel control={<Checkbox onChange={()=>setAccessTo([...accessTo,"2"])} name="2" />} label="general assist" />
            <FormControlLabel control={<Checkbox onChange={()=>setAccessTo([...accessTo,"3"])} name="3" />} label="packages" />
            <FormControlLabel control={<Checkbox onChange={()=>setAccessTo([...accessTo,"4"])} name="4" />} label="Business Categories" />
            <FormControlLabel control={<Checkbox onChange={()=>setAccessTo([...accessTo,"5"])} name="4" />} label="Download Banners" />
            <FormControlLabel control={<Checkbox onChange={()=>setAccessTo([...accessTo,"6"])} name="4" />} label="Support" />
            <FormControlLabel control={<Checkbox onChange={()=>setAccessTo([...accessTo,"7"])} name="4" />} label="Engagement" />
            </FormGroup>

                <div>
                <Button onClick={() => addAdminRole()} style={{backgroundColor: secondaryColor}} className={classes.editbutton} variant="contained">UpDate Role</Button>
                </div>
            </Paper>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={snackBarOpen}
                onClose={() => setSnackBarOpen(false)}
                message="User Added Successfully!"

            />

        </div>

    )
}
