import React,{useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import { secondaryColor, whiteColor } from '../../../config/constants';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardImage from "../../../assets/images/cardimage.png"
import {Link, useHistory} from "react-router-dom";
import {firestore,storage,} from '../../../config/firebase';
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    title:{
        fontSize:24,
        color:secondaryColor,
        fontWeight:700,
        margin:20
    },
    addbutton:{
        display:'flex',
        float:'right',
        margin:24,
        fontSize:20,
        width:110,
        borderRadius:8,
        background:secondaryColor,
        color:whiteColor,
        padding:5,
        textAlign:'center',
        cursor:'pointer',
        textDecoration:"none"
    },
    addbuttontitle:{
        textAlign:'center'
    },
    titlethisweek:{
        marginTop:30,
        margin:20,
        fontSize:20,
        fontWeight:600
    },
    cardscontainer:{
        margin:20
    },
    media: {
        height: 140,
    },
    editbutton:{
        color:whiteColor,
        backgroundColor:secondaryColor,
        borderRadius:8,
        padding:5,
        width:80,
        textAlign:'center',
        margin:5,
        cursor:'pointer',
        textDecoration:'none'
    },
    dialogtitle:{
        display:'flex',
        flexDirection:'row',
        width:'100%',
        justifyContent: 'space-between',
        padding:15
    },
    dialogtitlename:{
        fontSize:20,
        color:secondaryColor
    },
    textfeild:{
        width:'90%',
        marginLeft:'5%',
        marginBottom:10
    },
    cardroot:{
        flex:1,
        height:400
    },
    description:{
        textOverflow:'ellipsis',
        overflow:'hidden',
        height:120,
    }
  }));


export default function NewsFeed() {
    const classes = useStyles();
    const [newsData,setNewsData] = React.useState([])
    const history = useHistory();

    useEffect(() => {
        getAllNewsFeed();
      }, []);

      const  getAllNewsFeed = () =>{
        firestore.collection('videoLibrary').get().then(snapshot => {
            const allNews = snapshot.docs.map(doc =>  {
                return doc.data()
            });
            console.log(allNews)
            setNewsData(allNews)
        })

      }

    return (
        <div>
        <Grid container spacing={0}>
            <Grid item xs={6}>
            <Typography className={classes.title}>
                Manage Video Library
            </Typography>
            </Grid>
            <Grid item xs={6}>
            <Link  to="/addvideo"  className={classes.addbutton} >
            <AddCircleOutlineIcon style={{fontSize:22}} />
            <Typography className={classes.addbuttontitle}>
                Add Video
            </Typography>
            </Link>
            </Grid>
        </Grid>
        <div className={classes.cardscontainer}>
        <Grid container spacing={3}>
            { newsData.map(item => {
                return(
                    <Grid item xs={12} sm={4}>
                    <Card className={classes.cardroot}>
                            <div style={{maxHeight:350}}>
                                <CardMedia
                                className={classes.media}
                                image ={item.image}
                                title="Contemplative Reptile"
                                />
                                <CardContent>
                                <div gutterBottom  display="inline" variant="h5" component="h2" style={{textOverflow:'ellipsis',overflow:'hidden',width:'100%',fontSize:20,marginBottom:20}}>
                                    {item.title}
                                </div>
                                <Typography variant="body2" color="textSecondary" component="p" className={classes.description}>
                                   {item.description}
                                </Typography>
                                </CardContent>
                            </div>
                            <CardActions>
                                <Button onClick={() => history.push('/editvideo')} className={classes.editbutton}>Edit</Button>
                                {/*<Link to={{pathname:'/editvideo', item:item}} size="small" color="primary"  className={classes.editbutton} >*/}
                                {/*Edit*/}
                                {/*</Link>*/}
                            </CardActions>
                        </Card>
                    </Grid>
                )
            })
    }

        </Grid>
        </div>
        </div>

    )
}
