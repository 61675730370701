import React,{useState} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {  TextField, Typography } from '@material-ui/core';
import { primaryColor, secondaryColor, whiteColor } from '../../../config/constants';
import { useTheme } from '@material-ui/core/styles';
import {Link,useLocation} from "react-router-dom";
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { LocalConvenienceStoreOutlined } from '@material-ui/icons';
import { firestore } from '../../../config/firebase';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    title:{
        fontSize:30,
        color:secondaryColor,
        fontWeight:700,
        margin:20
    },
    background:{
        backgroundColor:"rgba(240, 102, 105, 0.13)",
        padding:30,
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between'
    },
    addbutton:{
        display:'flex',
        float:'right',
        margin:30,
        fontSize:16,
        borderRadius:8,
        background:secondaryColor,
        color:whiteColor,
        padding:5,
        textAlign:'center',
        cursor:'pointer',
        textDecoration:'none'
    },
    editbutton:{
        color:whiteColor,
        backgroundColor:secondaryColor,
        borderRadius:18,
        padding:8,
        textAlign:'center',
        margin:5,
        cursor:'pointer',
        maxHeight:35,
        textDecoration:'none',
        width:100,
    },
    formcontainer:{
        margin:20
    },
    formtitle:{
        color:primaryColor,
        fontWeight:600,
        fontSize:16,
        paddingBottom:10,
        paddingTop:10
    },
    forminput:{
        minWidth:600,
        borderColor:secondaryColor,
        background:whiteColor,
        [theme.breakpoints.only('xs')]: {
           minWidth:'100%'
          },
    }
  }));


export default function EditUserDetails() {
    const classes = useStyles();
    const [selectedDate, setSelectedDate] = React.useState(Date.now());
    const [value, setValue] = React.useState('female');
    const [userId,setUserId] = React.useState('')
    const [date,setDate] = React.useState("")
    const location = useLocation();

    const handleDateChange = (date) => {
      setSelectedDate(date);
      console.log(selectedDate)
    };
    const handleChange = (event) => {
        setValue(event.target.value);
      };

      React.useEffect(() => {

       },[]);


       const handleSubmit =() =>{
           updateUserDetails();
           alert('addes successfully')
       }

       const updateUserDetails = () =>{
        firestore.collection("users").doc(location.phone).update({
            userId: userId,
            gender : value,
            dob : selectedDate
        });
       }

    return (
        <div>
            <Grid container spacing={0}>
                <Grid item xs={6}>
                <Typography className={classes.title}>
                    Edit
                </Typography>
                </Grid>
                <Grid item xs={6}>
                <Link  to={{pathname:'/userdetails', phone:location.phone}} size="small" color="primary"  className={classes.addbutton}>
                        Go Back
                </Link>
                </Grid>
            </Grid>

            <div className={classes.formcontainer}>
                <Typography className={classes.formtitle} >USER ID</Typography>
                <TextField className={classes.forminput} placeholder="enter User ID" variant="outlined"  onChange={(e)=>setUserId(e.target.value)} />
                <Typography className={classes.formtitle}>Date Of Birth</Typography>
                <div>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    className={classes.forminput}
                    margin="normal"
                    inputVariant="outlined"
                    id="date-picker-dialog"
                    label="Date picker dialog"
                    format="dd - MM - yyyy"
                    value={selectedDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
                </MuiPickersUtilsProvider>
                </div>
                <Typography className={classes.formtitle}>Gender</Typography>

                <FormControl component="fieldset">
                <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>
                    <div style={{display:'flex'}}>
                    <FormControlLabel value="female" control={<Radio />} label="Female" />
                    <FormControlLabel value="male" control={<Radio   />} label="Male" />
                    <FormControlLabel value="other" control={<Radio   />} label="Other" />
                    </div>
                </RadioGroup>
                </FormControl>
            </div>
            <div size="small" color="primary"  className={classes.editbutton} onClick={handleSubmit}>
                    Save
             </div>
        </div>

    )
}
