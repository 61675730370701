import React, {useEffect, useState} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {  TextField, Typography } from '@material-ui/core';
import { primaryColor, secondaryColor, whiteColor } from '../../../config/constants';
import {Link,useLocation} from "react-router-dom";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { firestore } from '../../../config/firebase';
import Button from "@material-ui/core/Button";
import {useHistory} from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Firebase from 'firebase/app';
import Paper from "@material-ui/core/Paper";
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'left',
      color: theme.palette.text.secondary,
        margin: 20
    },
    title:{
        fontSize:24,
        color:secondaryColor,
        fontWeight:700,
        margin:5,
        marginLeft: 20
    },
    background:{
        backgroundColor:"rgba(240, 102, 105, 0.13)",
        padding:30,
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between'
    },
    addbutton:{
        display:'flex',
        float:'right',
        margin:5,
        marginTop:5,
        width:110,
        background:secondaryColor,
        color:whiteColor,
        padding:3,
        textAlign:'center',
        cursor:'pointer',
        textDecoration:"none"
    },
    editbutton:{
        color:whiteColor,
        backgroundColor:secondaryColor,
        padding:8,
        textAlign:'center',
        marginLeft:20,
        marginTop:10,
        cursor:'pointer',
        maxHeight:35,
        textDecoration:'none',
        width:150,
    },
    formcontainer:{
        marginLeft:20,
        marginRigth:20
    },
    formtitle:{
        color:primaryColor,
        fontWeight:600,
        fontSize:16,
        paddingBottom:10,
        paddingTop:10
    },
    forminput:{
        minWidth:600,
        borderColor:secondaryColor,
        background:whiteColor,
        [theme.breakpoints.only('xs')]: {
           minWidth:'100%'
          },
    },

  }));


export default function AddCategory() {
    const classes = useStyles();
    const [value, setValue] = React.useState('female');
    const [userName,setUserName] = React.useState('')
    const [snackBarOpen, setSnackBarOpen] = React.useState(false);
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        // addCategory();
        if(location.item != null && location.item != undefined){
            console.log('got category from locaiton =', location.item)
            setUserName(location.item.name)
            setValue(location.item.value)
        }
    },[])

    const addCategory = () => {
        const category = {
            name: userName,
            status: value,
            timestamp: Date.now()
        }
        firestore.collection('businessCategories').doc('businessCategories').set({
            'categories_array': Firebase.firestore.FieldValue.arrayUnion(category)
        },{merge:true})
        history.push('/businessCategories')
    }


    const handleChange = (event) => {
        setValue(event.target.value);
      };

    return (
        <>
        <Paper className={classes.paper}>
            <Grid container spacing={0}>
                <Grid item xs={6}>
                <Typography className={classes.title}>
                    Add App Banner
                </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Button onClick={() => history.push('/appBanners')} style={{backgroundColor: primaryColor}} className={classes.addbutton} variant="contained">Go Back</Button>
                </Grid>
            </Grid>

            <div className={classes.formcontainer}>
                <Typography className={classes.formtitle} >Category Name</Typography>
                <TextField className={classes.forminput} placeholder="Category Name" variant="outlined"  onChange={(e)=>setUserName(e.target.value)} />

                <Typography className={classes.formtitle}>Status</Typography>

                <FormControl component="fieldset">
                <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>
                    <div style={{display:'flex'}}>
                    <FormControlLabel value="active" control={<Radio />} label="Active" />
                    <FormControlLabel value="inactive" control={<Radio   />} label="Inactive" />
                    </div>
                </RadioGroup>
                </FormControl>
            </div>
                <Button onClick={() => addCategory()} style={{backgroundColor: secondaryColor}} className={classes.editbutton} variant="contained">Add Category</Button>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={snackBarOpen}
                onClose={() => setSnackBarOpen(false)}
                message="User Added Successfully!"
            />
        </Paper>
            <Paper className={classes.paper}>
                Information: These categories will be selected by users during the Registration process in the Bigwayz app, please keep the names simple for the users, and single word categories or category names without a space are preferred.
            </Paper>
        </>

    )
}
